import React from "react";
import EntregueIcon from "../../assets/img/sistema/entregue.svg";

function Entregue({ title }) {
    return (
        <p className="visualizado">
            <img src={EntregueIcon} alt="" />
            {title}
        </p>
    );
}

export default Entregue;
