import styled from "styled-components";

import { styled as styledMui } from "@mui/material/styles";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import imgErrorVermelho from "../../assets/img/sistema/atencao.svg";
import imgErrorAmarelo from "../../assets/img/sistema/atencao-amarelo.svg";

export const StyledBox = styledMui(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingTop: "40px",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
  ".MuiDataGrid-footerContainer": {
    display: "none",
  },
}));

export const AntDesignStyledDataGridPro = styledMui(DataGridPro)(
  ({ theme }) => ({
    border: `unset`,
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: ['"Oxanium",Arial,Helvetica,sans-serif'].join(","),
    WebkitFontSmoothing: "auto",
    textTransform: "uppercase",
    letterSpacing: "normal",

    "&.clickable": {
      ".MuiDataGrid-row": {
        cursor: "pointer",
        "&.erro": {
          color: "red",
        },
        "&.alerta": {
          color: "#FFCC00",
        },
      },
    },

    ".MuiDataGrid-row": {
      "&.erro": {
        position: "relative",
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "50%",
          right: "25px",
          transform: "translateY(-50%)",
          backgroundImage: `url(${imgErrorVermelho})`,
          backgroundRepeat: "no-repeat",
          width: "24px",
          height: "24px",
          zIndex: 20,
          transition: "backgroundColor 0.5s",
        },
        "& .MuiDataGrid-cell": {
          color: "#EB001B",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "100%",
          letterSpacing: "0.08em",
          textTransform: "uppercase",
          borderBottom: `1px solid #EB001B`,
        },
      },
      "&.alerta": {
        position: "relative",
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "50%",
          right: "25px",
          transform: "translateY(-50%)",
          backgroundImage: `url(${imgErrorAmarelo})`,
          backgroundRepeat: "no-repeat",
          width: "24px",
          height: "24px",
          zIndex: 20,
          transition: "backgroundColor 0.5s",
        },
        "& .MuiDataGrid-cell": {
          color: "#FFCC00",
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "100%",
          letterSpacing: "0.08em",
          textTransform: "uppercase",
          borderBottom: `1px solid #FFCC00`,
        },
      },
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      boxShadow: "unset",
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {},
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `unset`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-pinnedColumns": {
      borderRight: `1px solid transparent`,
      boxShadow: "unset",
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: ['"Oxanium",Arial,Helvetica,sans-serif'].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.65)",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      "& .MuiCheckbox-root svg": {
        width: 16,
        height: 16,
        backgroundColor: "transparent",
        border: `1px solid ${
          theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
        }`,
        borderRadius: 2,
      },
      "& .MuiCheckbox-root svg path": {
        display: "none",
      },
      "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
        backgroundColor: "#1890ff",
        borderColor: "#1890ff",
      },
      "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
        position: "absolute",
        display: "table",
        border: "2px solid #fff",
        borderTop: 0,
        borderLeft: 0,
        transform: "rotate(45deg) translate(-50%,-50%)",
        opacity: 1,
        transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
        content: '""',
        top: "50%",
        left: "39%",
        width: 5.71428571,
        height: 9.14285714,
      },
      "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
        {
          width: 8,
          height: 8,
          backgroundColor: "#1890ff",
          transform: "none",
          top: "39%",
          border: 0,
        },
    },
  })
);

export const AntDesignStyledGridToolbar = styledMui(GridToolbar)(
  ({ theme }) => ({
    "& .MuiDataGrid-toolbarContainer": {
      background: "red",
    },
    "& .MuiDataGrid-main": {
      dispay: "none",
    },
    background: "red",
  })
);

export const PaginationStyles = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
