import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";

import { Creators as AcaoCreators } from "../../store/ducks/acao";
import { nomeArq } from "../../utils/helper";

import upload from "../../assets/img/sistema/upload.svg";
import fechar from "../../assets/img/sistema/fechar-modal.svg";

function InputFile(props) {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.acao);
    const { nome_arquivo, resumo, id_disparo } = data;

    const { field, form, change } = props;
    const [arquivo, setArquivo] = useState(nome_arquivo);

    function removeFile() {
        if (id_disparo) {
        } else {
            confirmAlert({
                title: "Remover Excel",
                message: "Deseja remover a planilha",
                buttons: [
                    {
                        label: "Sim",
                        onClick: () => {
                            if (resumo?.acao_id) {
                                dispatch(AcaoCreators.clearResumo());
                                change("dados");
                            }

                            form.setFieldValue(field.name, "");
                            setArquivo("");
                            dispatch(
                                AcaoCreators.createObject("nome_arquivo", "")
                            );
                        },
                    },
                    {
                        label: "Não",
                        onClick: () => {},
                    },
                ],
            });
        }
    }

    function handleFile(arq) {
        if (arq) {
            const file = arq;
            const reader = new FileReader();

            reader.onload = function (event) {
                form.setFieldValue(field.name, "");
                form.setFieldValue(field.name, event.target.result);
            };

            reader.readAsDataURL(file);

            dispatch(AcaoCreators.createObject("nome_arquivo", file.name));
            setArquivo(arq.name);
        }
    }

    return (
        <div className="container-form">
            <label
                htmlFor="file"
                className={arquivo ? "custom-file nome-arquivo" : "custom-file"}
            >
                <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="file"
                    onChange={(event) => {
                        handleFile(event.target.files[0]);
                    }}
                />

                {arquivo
                    ? arquivo.length > 25
                        ? nomeArq(arquivo, 25)
                        : arquivo
                    : "Upload do Arquivo"}
            </label>

            {arquivo ? (
                <img src={fechar} onClick={() => removeFile()} alt="" />
            ) : (
                <img src={upload} alt="" />
            )}
        </div>
    );
}

export default InputFile;
