import { store } from "../store";

function hasPermissions(permissions) {
  const { permissions: userPermissions } = store.getState().user;
  // const userPermissions = ["acao.index", "acao.processAction", "usuario.store"];

  return permissions.every((permission) =>
    userPermissions.includes(permission)
  );
}

export { hasPermissions };
