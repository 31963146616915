/**
 * Types
 */
export const Types = {
    TEMP_TEMPLATE: "TEMP_TEMPLATE",
    TEMP_PREVIEW_ACAO: "TEMP_PREVIEW_ACAO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    tempArr: [],
    templateAcao: {},
};

export default function Temp(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.TEMP_TEMPLATE:
            return {
                ...state,
                tempTemplate: [...action.payload.data],
            };

        case Types.TEMP_PREVIEW_ACAO:
            return {
                ...state,
                templateAcao: { ...state.templateAcao, ...action.payload },
            };
        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    tempTemplate: (data) => {
        return {
            type: Types.TEMP_TEMPLATE,
            payload: { data },
        };
    },

    tempPreviewAcao: (feild, data) => {
        return {
            type: Types.TEMP_PREVIEW_ACAO,
            payload: { [feild]: data },
        };
    },
};
