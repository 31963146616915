import { put, call, select } from "redux-saga/effects";
import { Types as LoginTypes } from "../ducks/login";
import { Types as UserTypes } from "../ducks/user";
import { Types as AcaoTypes } from "../ducks/acao";
import { Types as NotificacaoTypes } from "../ducks/notificacao";

import { api, setToken } from "../../services/api";

export function* login({ payload }) {

  console.log("login saga");
  const { data } = payload;

  const { cpf, password } = data;

  console.log("data", data);

  try {
    console.log("dentro do try");
   
    
    
    console.log("Iniciando a chamada de login via API");
    const response = yield call(api.post, 'login', { cpf, password });
    console.log("Resposta bruta recebida:", response);

    // Agora você pode desestruturar a resposta para obter os dados
    const { data, status, headers } = response;
    console.log("Dados:", data);
    console.log("Status:", status);
    console.log("Cabeçalhos:", headers);

    console.log("depois de receber2");
    console.log('data', data);

    const { access_token, expires_in, user, role } = data;
    const { name, id } = user;
    const [nivel] = role;

    const headerParams = {
      Authorization: `Bearer ${access_token}`,
    };

    console.log(headerParams);

    yield call(
      api.post,
      `/generate-auth-code`,
      {},
      { headers: headerParams }
    );

    const permissionsData = yield call(api.get, `me/permissions`, {
      headers: headerParams,
    });

    const permissions = permissionsData.data;

    yield put({
      type: UserTypes.SUCCESS_USER,
    });

    yield put({
      type: LoginTypes.SUCCESS_LOGIN,
      payload: {
        token: access_token,
        cpf,
        expires_in,
        now: new Date().getTime(),
        name,
        nivel,
        id,
        permissions,
      },
    });

  } catch (error) {
    console.log("Erro na chamada da API:", error);
    console.log("Detalhes do erro:", error.response ? error.response : error.message);
    yield put({
      type: LoginTypes.FAILUIRE_LOGIN,
    });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "CPF ou senha inválida",
        tipo: "erro",
      },
    });
  }
}

export function* logout({ payload }) {
  const { history } = payload;
  try {
    const { status } = yield call(api.post, `logout`);

    if (status === 200) {
      yield put({
        type: UserTypes.SUCCESS_USER,
      });

      yield put({
        type: UserTypes.SET_USER,
        payload: {
          token: false,
          cpf: "",
        },
      });

      yield put({
        type: AcaoTypes.CLEAR_ALL_ACAO,
      });

      history.push("/");
      window.location.reload();
    }
  } catch (error) {}
}

export function* refresh() {
  try {
    const { data } = yield call(api.post, `refresh`);

    const { access_token, expires_in } = data;

    yield put({
      type: UserTypes.REFRESH_TOKEN,
      payload: {
        token: access_token,
        expires_in,
        now: new Date().getTime(),
      },
    });

    setToken();
  } catch (error) {}
}

export function* checkCode({ payload }) {
  const { value, history } = payload;

  const { data: dados } = yield select((state) => state.login);

  try {
    const { data } = yield call(
      api.post,
      `/confirm-auth-code`,
      { code: value },
      {
        headers: { Authorization: `Bearer ${dados.token}` },
      }
    );

    if (data.success) {
      yield put({
        type: UserTypes.SET_USER,
        payload: {
          ...dados,
        },
      });

      yield put({
        type: LoginTypes.SUCCESS_LOGIN_CODE,
      });

      if (data.google2fa && data.google2fa.qrCode) {
        history.push({
          pathname: "/auth/register-authenticator",
          state: { qrCodeImage: data.google2fa.qrCode }
        });
      } else {
        history.push("/auth/validate-authenticator");
      }
      
    } else {
      yield put({
        type: LoginTypes.STATUS_ERROR_CODE,
      });
    }
  } catch (error) {
    yield put({
      type: LoginTypes.STATUS_ERROR_CODE,
    });
  }
}
