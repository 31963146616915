import { put, call } from "redux-saga/effects";

import { Types as ContratanteTypes } from "../ducks/contratante";
import { Types as NotificacaoTypes } from "../ducks/notificacao";

import { api } from "../../services/api";

export function* store({ payload }) {
    const { data, history } = payload;

    try {
        yield call(api.post, `contratante`, { ...data });

        history.push({
            pathname: "/contratantes",
            contratante: true,
        });

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Sucesso",
                tipo: "sucesso",
            },
        });
    } catch (error) {
        yield put({
            type: ContratanteTypes.FAILUIRE,
        });
        console.log(error);
    }
}

export function* updateContratante({ payload }) {
    const { data, history, id } = payload;
    try {
        yield call(api.post, `contratante/${id}`, { ...data });

        history.push({
            pathname: "/contratantes",
            contratante: true,
        });

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Sucesso",
                tipo: "sucesso",
            },
        });
    } catch (error) {
        yield put({
            type: ContratanteTypes.FAILUIRE,
        });
        console.log(error);
    }
}

export function* deleteContratante({ payload }) {
    const { id } = payload;

    try {
        yield call(api.delete, `contratante/${id}`);

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Sucesso",
                tipo: "sucesso",
            },
        });
    } catch (error) {
        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Existem vinculos com esse contrante",
                tipo: "erro",
            },
        });

        yield put({
            type: ContratanteTypes.FAILUIRE,
        });
    }
}

export function* show() {
    try {
        const { data } = yield call(api.get, `contratante`);

        const dados = data.map((item) => {
            const {
                nome,
                area,
                cnpj,
                contato,
                email,
                celular,
                telefone: tel,
                id,
            } = item;

            return {
                nome,
                area,
                cnpj,
                contato,
                email,
                telefone: `${tel} / ${celular}`,
                id,
            };
        });

        yield put({
            type: ContratanteTypes.SUCCESS,
        });

        yield put({
            type: ContratanteTypes.DATA,
            payload: {
                data: dados,
            },
        });
    } catch (error) {
        yield put({
            type: ContratanteTypes.FAILUIRE,
        });
        console.log(error);
    }
}
