import Login from "../pages/login";
import NovoUsuario from "../pages/novoUsuario";
import EditaUsuario from "../pages/novoUsuario/editar";
import Usuario from "../pages/usuarios";
import GerenciarPerfis from "../pages/gerenciarPerfis";
import DetalhePerfil from "../pages/gerenciarPerfis/detalhe";
import Sistema from "../pages/sistema/index";
import Acoes from "../pages/acoes/index";
import EditaAcoes from "../pages/acoes/editar";
import NovaAcoes from "../pages/acoes/nova";
import Dashboard from "../pages/acoes/dashboard";
import ResumoAcoes from "../pages/acoes/resumo";
import FinalAcoes from "../pages/acoes/final";
import Contratantes from "../pages/contratantes/index";
import NovoContratante from "../pages/contratantes/novo";
import PedidosVoucher from "../pages/pedidosVoucher";
import NovoPedido from "../pages/pedidosVoucher/novo";
import DetalhePedido from "../pages/pedidosVoucher/detalhe";
import AddVoucher from "../pages/pedidosVoucher/addVoucher";
import DownloadPedido from "../pages/downloadPedidos";
import { PageTemplate } from "../pages/template";
import { PageAddTemplate } from "../pages/AddTemplate";
import { PageEditarTemplate } from "../pages/EditarTemplate";
import { PageClientes } from "../pages/clientes";
import { PageNovoCliente } from "../pages/clientes/Novo/";
import { PageEditarCliente } from "../pages/clientes/Editar";
import Processamento from "../pages/acoes/processamento";
import DetalheProcessamento from "../pages/acoes/detalheProcessamento";
import RegisterAuthenticator from "../pages/auth/registerAuthenticator";
import ValidateAuthenticator from "../pages/auth/validateAuthenticator";

const routes = [
  { path: "/sistema", component: Sistema, isPrivate: true },
  {
    path: "/contratantes",
    exact: true,
    component: Contratantes,
    isPrivate: true,
  },
  { path: "/contratantes/novo", component: NovoContratante, isPrivate: true },
  { path: "/contratantes/:id", component: NovoContratante, isPrivate: true },
  {
    path: "/acoes",
    exact: true,
    component: Acoes,
    isPrivate: true,
    permissions: ["acao.index"],
  },
  { path: "/acoes/final", component: FinalAcoes, isPrivate: true },
  { path: "/acoes/resumo", component: ResumoAcoes, isPrivate: true },
  {
    path: "/acoes/nova",
    component: NovaAcoes,
    isPrivate: true,
    permissions: ["acao.processAction"],
  },
  // {
  //   path: "/acoes/editar/:id",
  //   component: NovaAcoes,
  //   isPrivate: true,
  //   permissions: ["acao.update"],
  // },
  {
    path: "/acoes/detalhe/:id",
    component: EditaAcoes,
    isPrivate: true,
    permissions: ["acao.show"],
  },
  {
    path: "/acoes/dashboard/:id",
    component: Dashboard,
    isPrivate: true,
    permissions: ["acao.show"],
  },
  {
    path: "/usuarios/gerenciar/:id",
    exact: true,
    component: DetalhePerfil,
    isPrivate: true,
    permissions: ["usuario.store"],
  },
  {
    path: "/usuarios/gerenciar",
    exact: true,
    component: GerenciarPerfis,
    isPrivate: true,
    permissions: ["usuario.store"],
  },
  {
    path: "/usuarios/editar/:id",
    exact: true,
    component: EditaUsuario,
    isPrivate: true,
    permissions: ["usuario.update"],
  },
  {
    path: "/usuarios/novo",
    exact: true,
    component: NovoUsuario,
    isPrivate: true,
    permissions: ["usuario.store"],
  },
  {
    path: "/usuarios/:id",
    exact: true,
    component: NovoUsuario,
    isPrivate: true,
    permissions: ["usuario.show"],
  },
  {
    path: "/usuarios",
    exact: true,
    component: Usuario,
    isPrivate: true,
    permissions: ["usuario.index"],
  },
  {
    path: "/pedidos/detalhe/:id",
    component: DetalhePedido,
    isPrivate: true,
    permissions: ["pedido.show"],
  },
  {
    path: "/pedidos/voucher",
    component: AddVoucher,
    isPrivate: true,
    permissions: ["pedido.show"],
  },
  {
    path: "/pedidos/novo",
    component: NovoPedido,
    isPrivate: true,
    permissions: ["pedido.store"],
  },
  {
    path: "/pedidos",
    component: PedidosVoucher,
    isPrivate: true,
    permissions: ["pedido.index"],
  },

  {
    path: "/download",
    component: DownloadPedido,
    isPrivate: true,
    permissions: ["pedido.area.user"],
  },

  {
    path: "/template/editar/:id",
    component: PageEditarTemplate,
    isPrivate: true,
    permissions: ["template.update"],
  },
  {
    path: "/template/novo",
    component: PageAddTemplate,
    isPrivate: true,
    permissions: ["template.store"],
  },
  {
    path: "/template",
    component: PageTemplate,
    isPrivate: true,
    permissions: ["template.index"],
  },

  {
    path: "/clientes/editar/:id",
    component: PageEditarCliente,
    isPrivate: true,
    permissions: ["cliente.update"],
  },
  {
    path: "/clientes/novo",
    component: PageNovoCliente,
    isPrivate: true,
    permissions: ["cliente.store"],
  },
  {
    path: "/clientes",
    component: PageClientes,
    isPrivate: true,
    permissions: ["cliente.index"],
  },
  {
    path: "/processamento/detalhe/:id",
    component: DetalheProcessamento,
    isPrivate: true,
    permissions: ["acao.index.multi"],
  },
  {
    path: "/processamento",
    component: Processamento,
    isPrivate: true,
    permissions: ["acao.index.multi"],
  },

  { path: "/", exact: true, component: Login, isPrivate: false },

  {
    path: "/auth/register-authenticator",
    exact: true,
    component: RegisterAuthenticator,
    isPrivate: false,
    permissions: ["auth.register"]
  },
  {
    path: "/auth/validate-authenticator",
    exact: true,
    component: ValidateAuthenticator,
    isPrivate: false,
    permissions: ["auth.register"]
  },
];

//  4 5 6 7 8 9 10 12
export default routes;
