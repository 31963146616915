import React from "react";
import { TemplateProvider } from "../../Hook/useTemplate";

import { NovoTemplate } from "../../components/NovoTemplate";

export function PageAddTemplate() {
    return (
        <TemplateProvider>
            <NovoTemplate />
        </TemplateProvider>
    );
}
