import React, { useState } from "react";

import olho from "../../assets/img/sistema/olho-login.svg";

import { Container } from "./styles";

function InputPassword(props) {
    const { field, form, placeholder } = props;
    const { value, handleChange } = form;
    const { name } = field;

    const [type, setType] = useState("password");

    function toogle() {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    }
    return (
        <Container className="container-form">
            <input
                type={type}
                placeholder={placeholder}
                name={name}
                autoComplete="new-password"
                value={value}
                onChange={handleChange}
            />
            <span
                className={`check-password ${type === "text" ? "active" : ""}`}
                onClick={() => toogle()}
            >
                <img src={olho} alt="" className="" />
            </span>
        </Container>
    );
}

export default InputPassword;
