import styled from "styled-components";

export const Container = styled.div`
  .container-tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #017cb1;
    margin-bottom: -21px;
    position: relative;
    z-index: 5;
    .tab {
      width: 192px;
      height: 47px;
      border: 1px solid #9d9d9d;
      border-bottom: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: relative;
      z-index: 10;
      margin-bottom: -1px;
      cursor: pointer;
      margin-right: 8px;
      &.active {
        border: 1px solid #017cb1;
        border-bottom: 1px solid #f6f6f6;
        p {
          color: #000;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #9b9b9b;
      }
    }
  }

  .content {
    position: relative;
    .container-item {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0;
      width: 100%;
      transition: opacity 0.5s ease-in-out;
      display: none;
      .item-img {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      &.active {
        position: static;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        display: block;
      }
    }
  }
`;
