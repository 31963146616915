import { put, call } from "redux-saga/effects";
import { Types as AcoesTypes } from "../ducks/acoes";
import { api } from "../../services/api";
import { returnLastItemUrl } from "../../utils/helper";
import { getAcoes } from "../../utils/helper";

export function* ShowAcoes({ payload }) {
    const { queryParams } = payload;

    try {
        const {
            data: { data, links, path, ...rest },
        } = yield call(
            api.get,
            `acao${queryParams !== "?" ? queryParams : ""}`
        );

        yield put({
            type: AcoesTypes.ACOES_SUCCESS,
        });

        yield put({
            type: AcoesTypes.DATA_ACOES,
            payload: {
                params: queryParams !== "?" ? queryParams : "?",
                buscaActive: true,
                data: getAcoes(data),
                paginacao: {
                    ...rest,
                    path: returnLastItemUrl(path),
                },
            },
        });
    } catch (error) {
        yield put({
            type: AcoesTypes.ACOES_FAILUIRE,
        });
        console.log(error);
    }
}

export function* paginacao({ payload }) {
    const { id } = payload;

    try {
        const {
            data: { data, links, path, ...rest },
        } = yield call(api.get, `acao?page=${id}`);

        yield put({
            type: AcoesTypes.ACOES_SUCCESS,
        });

        yield put({
            type: AcoesTypes.DATA_ACOES,
            payload: {
                buscaActive: true,
                data: getAcoes(data),
                paginacao: {
                    ...rest,
                    path: returnLastItemUrl(path),
                },
            },
        });
    } catch (error) {
        yield put({
            type: AcoesTypes.ACOES_FAILUIRE,
        });
        console.log(error);
    }
}
