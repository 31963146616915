import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Mask from "react-input-mask";
import browserHistory from "../../../config/browserHistory";
import { initalValues, validadeForm } from "./config";
import { useContex } from "./Hook";

import { MemorizedCampo } from "./areas";
import { Contato } from "./contato";

import Button from "../../button";
import Topo from "../../topo/cadastro";

import { Novo } from "./styles";

export function FormCliente() {
  const [success, setSuccess] = useState(false);
  const { setModal, contatos, store } = useContex();

  return (
    <Novo>
      <Topo
        activeFiltro={success}
        toggleFiltro={setSuccess}
        title="Usuários do Sistema"
        linkCadastro=""
      />

      <div className="container">
        <Formik
          initialValues={initalValues}
          validationSchema={validadeForm}
          onSubmit={async (values, actions) => {
            const areas = contatos.map((item) => {
              return {
                nome: item.area.label,
                contatos: [
                  {
                    nome: item.nome,
                    telefone: "55" + item.telefone.replace(/\D/g, ""),
                    celular: "55" + item.celular.replace(/\D/g, ""),
                    email: item.email,
                  },
                ],
              };
            });

            await store({ ...values, areas });
          }}
        >
          {(props) => {
            const { handleChange, values } = props;
            const { cnpj } = values;

            return (
              <Form>
                <h1>Dados do cliente</h1>
                <div className="container-form-double">
                  <div className="container-form user">
                    <Field
                      type="text"
                      placeholder="NOME FANTASIA"
                      name="nome"
                    />
                  </div>
                  <ErrorMessage component="span" name="nome" />

                  <div className="container-form cliente">
                    <Field
                      type="text"
                      placeholder="RAZÃO SOCIAL"
                      name="razao_social"
                    />
                  </div>
                  <ErrorMessage component="span" name="razao_social" />
                </div>

                <div className="container-form cliente">
                  <Mask
                    mask="99.999.999/9999-99"
                    placeholder="CNPJ"
                    name="cnpj"
                    type="tel"
                    onChange={handleChange}
                    value={cnpj}
                  />
                </div>
                <ErrorMessage component="span" name="cnpj" />

                <h1 className="areas">Áreas</h1>

                <Field component={MemorizedCampo} />

                <h1 className="contatos">Contatos</h1>

                <Button
                  onClick={() => setModal(true)}
                  type="button"
                  title="ADICIONAR CONTATO"
                  className="add-contato"
                />

                {contatos?.map((contato) => {
                  const { nome, email, celular, telefone } = contato;
                  return (
                    <Contato
                      key={contato.area.value}
                      id={contato.id}
                      area={contato.area}
                      celular={celular}
                      email={email}
                      nome={nome}
                      telefone={telefone}
                    />
                  );
                })}

                <div className="btns">
                  <Button
                    title="CANCELAR"
                    bgc={"#E25B5B"}
                    onClick={() => browserHistory.back()}
                  />
                  <Button title="SALVAR" className="add-cliente" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Novo>
  );
}
