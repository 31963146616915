import { useState } from "react";
import { FormCliente } from "./form";
import { Sucess } from "./sucess";

export function ModalNovoCliente() {
    const [sucess, setSucess] = useState(false);

    return (
        <>
            {sucess ? (
                <Sucess setSucess={setSucess} />
            ) : (
                <FormCliente setSucess={setSucess} />
            )}
        </>
    );
}
