import styled from "styled-components";
import border from "../../assets/img/sistema/border.svg";
import { Container as Button } from "../../components/button/styles";

export const Container = styled.div`
    background: linear-gradient(180deg, #0194d1 77.96%, #015e89 100%);
    width: 634px;
    height: 90vh;
    padding-top: 20px;
    position: relative;
    overflow-y: scroll;
    @media (max-width: 1024px) {
        height: auto;
    }

    @media (max-width: 480px) {
        height: 90vh;
        width: 100%;
    }
`;

export const ValidarCode = styled.form`
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: all 0.5s;
    pointer-events: none;
    width: 100%;
    height: 100%;
    &.active {
        position: static;
        opacity: 1;
        transition: all 0.5s;
        pointer-events: all;
    }

    .timer {
        text-align: center;
        font-size: 40px;
    }

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        width: 410px;
        padding-bottom: 20px;
        img {
            display: block;
            margin-bottom: 33px;
        }

        h3 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 33px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            strong {
                font-weight: bold;
            }
            margin-bottom: 33px;
        }

        span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            text-align: center;

            color: #ffffff;
            display: block;
            margin-bottom: 29px;
        }

        small {
            display: block;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 34px;
            strong {
                font-weight: bold;
            }
        }

        h5 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 18px;
        }

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 6px;
            color: #ffffff;
            position: relative;
            margin-bottom: 34px;
            &:before {
                content: "";
                display: block;
                width: 119px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: -140px;
            }
            &:after {
                content: "";
                display: block;
                width: 119px;
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 50%;
                right: -140px;
            }
        }

        .container-inputs {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: center;
            justify-content: space-between;
            width: 305px;
            margin-bottom: 20px;
            position: relative;
            &.error {
                input {
                    border: 2px solid #ff0000;
                    transition: all 0.5s;
                }
                .container-erro {
                    opacity: 1;
                    transition: all 0.5s;
                }
            }

            .container-erro {
                display: flex;
                grid-column: span 4;
                justify-content: center;
                display: flex;
                align-items: center;
                margin-top: 13px;
                opacity: 0;
                transition: all 0.5s;
                img {
                    margin-bottom: 0px;
                    margin-right: 8px;
                }
                h5 {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: normal;
                    text-align: center;
                    color: #ff000f;
                    margin-bottom: 0px;
                    white-space: nowrap;
                    margin-bottom: 0px;
                }
            }
            input {
                border: 2px solid transparent;
                border: none;
                background: #e9e9e9;
                border-radius: 8px;
                width: 65px;
                height: 73px;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;

                color: #000000;
                text-align: center;
                transition: all 0.5s;
            }
        }

        ${Button} {
            width: 100%;
            height: 68px;
            background: transparent;
            border-radius: 4px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05em;
            color: #005d88;
            border-radius: 100px;
            position: relative;
            z-index: 30;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 101%;
                height: 103%;
                -webkit-mask: url(${border});
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                background-color: #fff;
                z-index: -1;
            }
        }

        .container-novo-sms {
            background: #ffffff;
            border-radius: 20px;
            width: 100%;
            height: 179px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 55px;
            button {
                border: none;
                background-color: transparent;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 1px;
                color: #878787;
                margin-top: 20px;
                opacity: 1;
                transition: all 0.5s;
                &.active {
                    pointer-events: none;
                    opacity: 0.5;
                    transition: all 0.5s;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        height: auto;
    }

    @media (max-width: 480px) {
        overflow: hidden;
        .content {
            width: 90%;
            img {
                margin-bottom: 0px;
            }

            h3 {
                font-size: 16px;
                margin-bottom: 0;
            }

            span {
                margin-bottom: 8px;
            }

            small {
                margin-bottom: 24px;
            }

            p {
                margin-bottom: 24px;
            }

            ${Button} {
                height: 50px;
                &:before {
                    height: 102%;
                }
            }

            .container-novo-sms {
                margin-bottom: 24px;
            }

            .container-inputs {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }
`;

export const StyleAlterarSenha = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: all 0.5s;
    padding-top: 60px;
    pointer-events: none;
    width: 100%;
    height: 100%;
    &.active {
        position: static;
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
    }

    .content {
        width: 396px;
        margin: 0 auto;
        img {
            &.logo {
                margin-bottom: 66px;
            }
        }
        h3 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 900;
            font-size: 25.275px;
            line-height: 31px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 23px;
        }

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #ffffff !important;
        }

        small {
            display: block;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 41px;
            &.dica-senha {
                margin-top: 16px;
            }
            strong {
                font-weight: bold;
            }
        }

        ${Button} {
            width: 100%;
            height: 68px;
            background: transparent;
            border-radius: 4px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05em;
            color: #005d88;
            border-radius: 100px;
            position: relative;
            z-index: 30;
            margin-bottom: 70px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 101%;
                height: 100%;
                -webkit-mask: url(${border});
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                background-color: #fff;
                z-index: -1;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            small {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
            }
        }
    }

    @media (max-width: 480px) {
        padding-top: 20px;
        .content {
            width: 90%;
            .container-form {
                input {
                    &::placeholder {
                        font-size: 12px;
                    }
                }
            }
            img {
                &.logo {
                    margin-bottom: 24px;
                    width: 100%;
                }
            }

            h3 {
                font-size: 18.275px;
                margin-bottom: 16px;
            }

            small {
                margin-bottom: 24px;
            }

            ${Button} {
                height: 50px;
                &:before {
                    height: 102%;
                }
            }
        }
    }
`;

export const StyleParabens = styled.form`
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition: all 0.5s;
    padding-top: 60px;
    pointer-events: none;
    width: 100%;
    height: 100%;
    &.active {
        position: static;
        opacity: 1;
        pointer-events: all;
        transition: all 0.5s;
    }

    .content {
        width: 396px;
        margin: 0 auto;
        img {
            &.logo {
                margin-bottom: 66px;
            }
        }

        h2 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 33px;
        }

        p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #ffffff;
            margin-bottom: 60px;
        }

        ${Button} {
            width: 100%;
            height: 68px;
            background: transparent;
            border-radius: 4px;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05em;
            color: #005d88;
            border-radius: 100px;
            position: relative;
            z-index: 30;
            margin-bottom: 70px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 101%;
                height: 100%;
                -webkit-mask: url(${border});
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                background-color: #fff;
                z-index: -1;
            }
        }
    }

    @media (max-width: 480px) {
        padding-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .content {
            width: 90%;
            img {
                &.logo {
                    width: 100%;
                    margin-bottom: 24px;
                }
            }

            h2 {
                font-size: 16px;
                line-height: normal;
                margin-bottom: 22px;
            }

            p {
                margin-bottom: 22px;
            }

            ${Button} {
                height: 50px;
                font-size: 16px;
                &:before {
                    height: 102%;
                }
            }
        }
    }
`;
