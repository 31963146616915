import React, { useState, useEffect, memo } from "react";

import { api } from "../../services/api";

import Button from "../button";
import ButtonCancelar from "../ButtonCancelar";
import Box from "../boxProduto";
import Modal from "../modal";
import ModalProdutos from "../modal/addProduto";

import { ContainerProdutos } from "./styles";

function Vouchers({ onSubmit, values = null }) {
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [objSelecionadosvalues, setObjSelecionadosvalues] = useState({});
  const [valueInputCard, setValueInputCard] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalAddProdutoIsOpen, setModalAddProdutoIsOpen] = useState(false);
  const [produtoResult, setProdutoResult] = useState([]);
  const [erros, setErros] = useState([]);

  function removeProduto(produto) {
    const produtos = produtoSelecionado.filter(({ id }) => id !== produto.id);
    setProdutoSelecionado(produtos);
  }

  useEffect(() => {
    api
      .get(`/produto`)
      .then(({ data }) => {
        setLoading(false);
        setProdutos(data.data.filter((prod) => prod.total_disponivel));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (objSelecionadosvalues) {
      const updateProduct = [...produtoSelecionado];

      const produto = updateProduct.map((produto) => {
        if (produto.id === objSelecionadosvalues.id) {
          produto.value = parseInt(valueInputCard);
        }

        return produto;
      });

      setProdutoResult(produto);
    }
  }, [objSelecionadosvalues, valueInputCard, produtoSelecionado]);

  return (
    <ContainerProdutos>
      <Button
        className="add"
        title="+ Adicionar Vouchers"
        type="button"
        color="#0191CD"
        bgc="#fff"
        onClick={() => setModalAddProdutoIsOpen(!modalAddProdutoIsOpen)}
      />
      <div className="produtos">
        {produtoSelecionado ? (
          produtoSelecionado.map((produto) => {
            return (
              <Box
                key={produto.id}
                setObjSelecionadosvalues={setObjSelecionadosvalues}
                setValueInputCard={setValueInputCard}
                produto={produto}
                erros={erros}
                click={(e) => removeProduto(produto)}
              />
            );
          })
        ) : (
          <Box />
        )}
      </div>
      <div className="btns">
        <ButtonCancelar pedido={true} />
        <Button
          title="Avançar"
          onClick={() => {
            const erroIds = produtoResult.reduce((acc, prod) => {
              if (!prod.value) {
                acc.push({
                  id: prod.id,
                  qtd: prod.total_disponivel,
                });
              }

              if (prod.value > prod.total_disponivel) {
                acc.push({
                  id: prod.id,
                  qtd: prod.total_disponivel,
                  limit: true,
                });
              }

              return acc;
            }, []);

            if (erroIds.length === 0) {
              onSubmit(produtoResult);
              setErros([]);
            } else {
              setErros(erroIds);
            }
          }}
        />
      </div>

      <Modal
        // onChange={onChange}
        rest={{ produtos, loading }}
        component={ModalProdutos}
        active={modalAddProdutoIsOpen}
        setProdutos={(produto) => {
          setProdutoSelecionado(produto);
        }}
        close={() => setModalAddProdutoIsOpen(false)}
      />
    </ContainerProdutos>
  );
}

export default memo(Vouchers);
