import React from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import NovoSelect from "../NovoSelect";

function EntregaCodigo(props) {
    const dispatch = useDispatch();
    const { form } = props;
    const { setFieldValue } = form;

    const options = [
        { value: 1, label: "Sim" },
        { value: 0, label: "Não" },
    ];

    return (
        <>
            <NovoSelect
                label="Entrega Código"
                options={options}
                onChange={({ value, label }) => {
                    setFieldValue("entrega_codigo", parseInt(value));
                    dispatch(AcaoCreators.preview("entrega_codigo", label));
                }}
            />
            <ErrorMessage component="span" name="entrega_codigo" />
        </>
    );
}

export default EntregaCodigo;
