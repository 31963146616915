import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { ObjIsEmpty } from "../../utils/helper";
import {
    FormInitialState,
    validadtionContratante,
} from "../../utils/configForm";

import { Creators as ContratanteCreators } from "../../store/ducks/contratante";

import Button from "../../components/button";
import Topo from "../../components/topo/cadastro";

import { Novo } from "./styles";

import logo from "../../assets/img/sistema/logo-menu.svg";

function NovoContratante() {
    const [contratantes, setContratantes] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const load = useSelector((state) => state.contratante.loading);
    const {
        location: { id },
    } = history;

    const getContratante = useCallback(async (id) => {
        const { data } = await api.get(`contratante/${id}`);
        const { nome, email, cnpj, area, telefone, celular, contato } = data;
        setContratantes({
            nome,
            email,
            cnpj,
            area,
            telefone,
            celular,
            contato,
        });
    }, []);

    useEffect(() => {
        if (id) {
            getContratante(id);
        }
    }, [id, getContratante]);

    const values = !ObjIsEmpty(contratantes)
        ? contratantes
        : FormInitialState.contratante;

    return (
        <Novo>
            <Topo notificacao={false} />
            <div className="container">
                <div className="s-esq">
                    <img src={logo} alt="" />
                    <span>Dados do Contratante</span>
                </div>
                <div className="s-dir">
                    <h1>Novo Contratante</h1>
                    <Formik
                        enableReinitialize
                        initialValues={values}
                        validationSchema={validadtionContratante}
                        onSubmit={(value, actions) => {
                            if (id) {
                                dispatch(
                                    ContratanteCreators.update(
                                        value,
                                        history,
                                        id
                                    )
                                );
                            } else {
                                dispatch(
                                    ContratanteCreators.store(value, history)
                                );
                            }
                        }}
                    >
                        {(props) => {
                            const { values, setFieldValue, handleChange } =
                                props;
                            const { cnpj, telefone, celular } = values;
                            return (
                                <Form>
                                    <div className="container-form">
                                        <CpfCnpj
                                            type="text"
                                            placeholder="CNPJ"
                                            name="cnpj"
                                            onChange={(event, type) => {
                                                setFieldValue(
                                                    "cnpj",
                                                    event.target.value
                                                );
                                            }}
                                            value={cnpj}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="cnpj"
                                        />
                                    </div>
                                    <div className="container-form">
                                        <Field
                                            type="text"
                                            placeholder="Nome do Contratante"
                                            name="nome"
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="nome"
                                        />
                                    </div>
                                    <div className="container-form">
                                        <Field
                                            type="text"
                                            placeholder="Área"
                                            name="area"
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="area"
                                        />
                                    </div>
                                    <div className="container-form">
                                        <InputMask
                                            type="text"
                                            placeholder="Telefone"
                                            name="telefone"
                                            mask="(99) 9999-9999"
                                            value={telefone}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="telefone"
                                        />
                                    </div>

                                    <div className="container-form">
                                        <Field
                                            type="text"
                                            placeholder="Contato"
                                            name="contato"
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="contato"
                                        />
                                    </div>
                                    <div className="container-form">
                                        <Field
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="email"
                                        />
                                    </div>
                                    <div className="container-form">
                                        <InputMask
                                            type="text"
                                            placeholder="Celular"
                                            name="celular"
                                            mask="(99) 99999-9999"
                                            value={celular}
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage
                                            component="span"
                                            name="celular"
                                        />
                                    </div>

                                    <Button
                                        title={id ? "Atualizar" : "Cadastrar"}
                                        className={load ? "disabled" : ""}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </Novo>
    );
}

export default NovoContratante;
