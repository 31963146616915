import styled from "styled-components";

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: #fff;
    width: 375px;
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #0096d4;
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #0096d4;
            margin-bottom: 105px;
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #0096d4;
        }
    }

    .container-content {
        width: 100%;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            text-align: left;
            color: #0096d4;
            width: 100%;
            display: block;
            margin-bottom: 18px;
        }
    }

    .container-form {
        width: 100% !important;
        input {
            width: 100%;
            background: #f7f7f7 !important;
        }
    }
`;
