import { useState, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { api } from "../../../../services/api";

const Context = createContext({});

export function NovoClienteProvider(props) {
  const { children } = props;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [areas, setAreas] = useState([]);

  const [contatos, setContatos] = useState([]);
  const [contato, setContato] = useState({});
  const [cliemtesEnUtilizacao, setCliemtesEnUtilizacao] = useState([]);

  async function store(values) {
    try {
      const response = await api.post("/cliente", { ...values });
      history.push("/clientes");
      toast.success("Cadastro realizado com sucesso!");

      return response;
    } catch (error) {
      toast.error("Erro ao realizar cadastro!");

      return error;
    }
  }

  return (
    <Context.Provider
      value={{
        store,

        modal,
        setModal,

        setContatos,
        contatos,

        contato,
        setContato,

        setAreas,
        areas,

        setModalAviso,
        modalAviso,

        cliemtesEnUtilizacao,
        setCliemtesEnUtilizacao,

        modalAlert,
        setModalAlert,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useContex() {
  const context = useContext(Context);
  return context;
}
