import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";
import {
  filterSelect,
  objTemplate,
  previewAcao,
  tipoTemplate,
} from "../../utils/helper";

import Select from "../select";

function TemplateAcao(props) {
  const dispatch = useDispatch();
  const { form } = props;
  const { setFieldValue, values } = form;
  const { template_id } = values;
  const { data } = useSelector((state) => state.acao);
  const { tempTemplate, toggle_acao } = data;
  const options = objTemplate(tipoTemplate(toggle_acao, tempTemplate));
  const optionsAtivos = options.filter((o) => o.status === 1);

  return (
    <div className="container-form">
      <Select
        label="Template da Ação"
        id={template_id}
        options={optionsAtivos}
        selectedValue={filterSelect(template_id, optionsAtivos)}
        change={({ value, has_image, fields, label }) => {
          setFieldValue("template_id", parseInt(value));
          dispatch(AcaoCreators.preview("template", label));

          dispatch(AcaoCreators.createObject("campos", fields));

          dispatch(
            AcaoCreators.createObject(
              "preview_tema",
              previewAcao(optionsAtivos, value)
            )
          );

          // if (!has_image) {
          //     setFieldValue("template_arte", "");
          //     dispatch(AcaoCreators.createObject("preview_tema", []));
          //     dispatch(AcaoCreators.createObject("nome_img", ""));
          //     dispatch(AcaoCreators.preview("template", ""));
          // }

          dispatch(AcaoCreators.createObject("has_image", has_image));
        }}
      />
      <ErrorMessage component="span" name="template_id" />
    </div>
  );
}

export default TemplateAcao;
