import styled from "styled-components";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Conteudo = styled.div`
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.preto};
      margin-bottom: 24px;
      &.variaveis {
        border-top: 1px solid #d4d4d4;
        padding-top: 50px;
        margin-top: 50px;
      }
    }

    .btns {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
    }

    .container-form {
      &.select {
        &.type {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .rdw-editor-main {
      height: 375px;
      overflow: scroll;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      padding: 20px;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 5px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .container-form {
      &.campos {
        input {
          padding-right: 55px;
        }
      }
    }

    button {
      &.add-campos {
        width: auto;

        height: auto;
        padding: 7px 9px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        margin-top: 0px;
      }
      margin-top: 20px;
    }

    .avisoAguardando {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 38px;
      color: #000;
      margin-bottom: 18px;
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;
