import React from "react";

import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import Select from "../select";
import { filterSelect } from "../../utils/helper";
import { Creators as AcaoCreators } from "../../store/ducks/acao";
// import { Container } from './styles';

function TipoVoucher(props) {
  const dispatch = useDispatch();

  const { id, options, form } = props;
  const { setFieldValue } = form;

  // useEffect(() => {
  //   if (id) {
  //     const [item] = options.filter((item) => item.value === id);
  //     if (item) {
  //       const { label = "" } = item;
  //       dispatch(AcaoCreators.preview("acao_voucher", label));
  //     }
  //   }
  // }, [dispatch, options, id]);

  return (
    <div className="container-form">
      <Select
        label="Categoria"
        id={id}
        selectedValue={filterSelect(id, options)}
        options={options}
        change={({ value, label }) => {
          setFieldValue("acao_voucher_tipo_id", parseInt(value));
          dispatch(AcaoCreators.preview("acao_voucher", label));
          dispatch(AcaoCreators.createObject("toggle_categoria", value));
        }}
      />
      <ErrorMessage component="span" name="acao_voucher_tipo_id" />
    </div>
  );
}

export default TipoVoucher;
