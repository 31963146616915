import { combineReducers } from 'redux';
import login from './login';
import user from './user';
import contratante from './contratante';
import acao from './acao';
import acoes from './acoes';
import temp from './temp';
import modal from './modal';
import notificacao from './notificacao';
import esqueciSenha from './esqueciSenha';
import pedidos from './pedidos';
import twoFactorAuth from './twoFactorAuth';

export default combineReducers({
  login,
  user,
  contratante,
  acao,
  acoes,
  temp,
  modal,
  notificacao,
  esqueciSenha,
  pedidos,
  twoFactorAuth,
});
