import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Formik, Form, ErrorMessage, Field } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import { checkParticipantesData, sendDisparo } from "../../utils/helper";
import { FormInitialState, validationFile } from "../../utils/configForm";

// import Modal from "../../components/modal";
// import Button from "../button";
import HandleButtonSubmit from "./handleButtonSubmit";
import InputFile from "../inputFile";
import ResumoUpload from "./resumoUpload";
import ErroUpload from "./ErroUpload";
import DadosTemplate from "./DadosTemplate";

function Participantes({ change, editar }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const modal = useSelector((state) => state.modal);
  const { data, error, disparo } = useSelector((state) => state.acao);
  const { campos, resumo } = data;

  const campo = campos ? Object.values(JSON.parse(campos)) : [];

  const valuesStore = checkParticipantesData(data, FormInitialState);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={valuesStore}
        validationSchema={validationFile}
        onSubmit={(value, actions) => {
          if (resumo) {
            if (data.acao_voucher_tipo_id === 2) {
              history.push("/processamento");
              window.location.reload();
            } else {
              dispatch(
                AcaoCreators.fire(resumo.acao_id, data.entrega_voucher, history)
              );
            }
          } else {
            const dados = sendDisparo(data, value);

            dispatch(AcaoCreators.dados(value));

            dispatch(AcaoCreators.store(dados));
          }
        }}
      >
        {(props) => {
          return (
            <Form
              encType="multipart/form-data"
              className={disparo ? "disparo" : ""}
            >
              <Field name="recipientes" component={InputFile} change={change} />
              <ErrorMessage component="span" name="recipientes" />

              <HandleButtonSubmit
                editar={editar}
                error={error}
                disparo={disparo}
                disparoAgendado={resumo}
                change={change}
                entrega_voucher={data.entrega_voucher}
                tipo_voucher={data.acao_voucher_tipo_id}
                categoria={data.acao_tipo_id}
              />

              <DadosTemplate fields={campo} />

              {resumo && !error ? <ResumoUpload {...resumo} /> : null}

              {error ? <ErroUpload /> : null}
            </Form>
          );
        }}
      </Formik>
      {/* <Modal
                component={ModalDetalhesArquivo}
                active={modal.modalDetalhesArquivoIsOpened}
            /> */}
    </>
  );
}

export default memo(Participantes);
