import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import {
  FormInitialState,
  validationAcoesDados,
  validationAcoesDadosImg,
} from "../../utils/configForm";

import { checkAcaoData } from "../../utils/helper";

import { DateTime } from "../dateTime";

import Button from "../button";

import TipoAcao from "./tipoAcao";
import TipoVoucher from "./tipoVoucher";
import Contratante from "./contratante";
import Areas from "./areas";

import Template from "./template";

function Dados(props) {
  const { change, editar, contratante, tipo } = props;
  const history = useHistory();
  const [validade, setValidade] = useState(validationAcoesDados);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.acao);
  const { toggle_acao, has_image } = data;
  const valuesStore = checkAcaoData(data, FormInitialState);

  const optionsAcao = [
    {
      label: "Monovoucher",
      value: 1,
    },
    {
      label: "Multivoucher",
      value: 2,
    },
    {
      label: "Comunicação",
      value: 3,
    },
  ];

  useEffect(() => {
    if (has_image) {
      setValidade(validationAcoesDadosImg);
    } else {
      setValidade(validationAcoesDados);
    }
  }, [has_image]);

  useEffect(() => {
    if (!editar && !data.recipientes) {
      dispatch(AcaoCreators.clearAll());
    }
  }, [editar, dispatch, data.recipientes]);

  return (
    <Formik
      enableReinitialize
      initialValues={valuesStore}
      validationSchema={validade}
      onSubmit={(value, actions) => {
        const { cliente_id, ...rest } = value;

        if (cliente_id === 9999) {
          if (!value.contratante_email) {
            actions.setFieldError("contratante_email", "Campo obrigatório");
            return;
          }

          if (!value.contratante_nome) {
            actions.setFieldError("contratante_nome", "Campo obrigatório");
            return;
          }

          dispatch(AcaoCreators.dados({ ...rest }));
        } else {
          dispatch(AcaoCreators.dados(value));
        }

        dispatch(AcaoCreators.disparo());
        change("disparo");
      }}
    >
      {(props) => {
        const { values, handleChange, setFieldValue } = props;

        const { cliente_id, nome, acao_tipo_id, job, acao_voucher_tipo_id } =
          values;

        return (
          <Form>
            <div className="container-form-double">
              <Field
                component={Contratante}
                id={cliente_id}
                options={contratante}
              />

              {cliente_id && <Field component={Areas} id={cliente_id} />}
            </div>

            {values.cliente_id === 9999 ? (
              <>
                <div className="container-form">
                  <Field
                    type="text"
                    placeholder="Nome"
                    name="contratante_nome"
                  />
                  <ErrorMessage component="span" name="contratante_nome" />
                </div>
                <div className="container-form">
                  <Field
                    type="text"
                    placeholder="E-mail"
                    name="contratante_email"
                  />
                  <ErrorMessage component="span" name="contratante_email" />
                </div>
              </>
            ) : null}

            <div className="container-form-double">
              <div className="container-form">
                <Field
                  type="text"
                  placeholder="Nome da Ação"
                  name="nome"
                  value={nome}
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    dispatch(AcaoCreators.preview("nome", e.target.value))
                  }
                />
                <ErrorMessage component="span" name="nome" />
              </div>

              <div className="container-form">
                <Field
                  type="text"
                  placeholder="Job"
                  name="job"
                  value={job}
                  onChange={handleChange}
                  onKeyUp={(e) =>
                    dispatch(AcaoCreators.preview("job", e.target.value))
                  }
                />
                <ErrorMessage component="span" name="job" />
              </div>
            </div>

            <Field
              component={TipoVoucher}
              id={acao_voucher_tipo_id}
              options={optionsAcao}
            />
            <div className="container-form-double">
              <DateTime
                initialValue={null}
                label="Data/horario de inicio"
                onChange={(dt_inicio) => {
                  setFieldValue(
                    "inicio",
                    dt_inicio ? dt_inicio.toISOString() : ""
                  );
                }}
              />

              <DateTime
                initialValue={null}
                label="Data/horario de fim"
                onChange={(dt_fim) => {
                  setFieldValue("fim", dt_fim ? dt_fim.toISOString() : "");
                }}
              />
            </div>
            <Field component={TipoAcao} id={acao_tipo_id} options={tipo} />

            <Template value={toggle_acao} />

            <Button
              title="Voltar"
              color="red"
              bgc="red"
              transparent
              onClick={() => history.goBack()}
            />

            <Button title="Avançar" />
          </Form>
        );
      }}
    </Formik>
  );
}

export default memo(Dados);
