import * as React from "react";

import Slide from "@mui/material/Slide";

import { ModalStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalRegisterLogin(props) {
    const { open, handleClose, children } = props;

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            {children}
        </ModalStyles>
    );
}
