import React, { useEffect, memo, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { getDetalhePedido, getToken } from "../../utils/helper";

import browserHistory from "../../config/browserHistory";

import { Conteudo } from "./styles";
import { Cadastro } from "../../components/topo/styles";
import Modal from "../../components/modal";
import ModalEnvioPedido from "../../components/modal/envioPedido";

import img from "../../assets/img/sistema/seta-azul.svg";
import img1 from "../../assets/img/sistema/pedido/codigo.svg";
import img2 from "../../assets/img/sistema/pedido/contratante.svg";
import img3 from "../../assets/img/sistema/pedido/data.svg";
import img9 from "../../assets/img/sistema/pedidos.svg";
import img10 from "../../assets/img/sync-svgrepo-com.svg";
import img6 from "../../assets/img/sistema/pedido/valor.svg";
import img7 from "../../assets/img/sistema/pedido/voucher.svg";
import img11 from "../../assets/img/sistema/adicionar.svg";

import ButtonCancelar from "../acoes/btnCancelar";
import { TabelaDetalhesPedido } from "./tabelaDetalhesPedido";

function DetalhesPedido() {
  const [loading, setLoading] = useState(false);
  const [loadingPedidoCliente, setLoadingPedidoCliente] = useState(false);
  const [loadingSinc, setLoadingSinc] = useState(false);
  const [loadingEstorno, setLoadingEstorno] = useState(false);
  const [loadingDowload, setLoadingDowload] = useState(false);
  const [detalhe, setDetalhe] = useState([]);
  const [estoqueId, setEstoqueId] = useState([]);
  const [idPedido, setIdPedido] = useState([]);
  const [topoDados, setTopoDados] = useState({});
  const [configPage, setConfigPage] = useState({});
  // const [recipiente, setRecipiente] = useState(null);

  const [modalEnvioPedidoIsOpen, setModalEnvioPedidoIsOpen] = useState(false);
  const [receiver, setReceiver] = useState();

  const { id } = useParams();

  const getItens = useCallback(() => {
    async function fetchData() {
      if (id) {
        setLoading(true);
        const { result, topo, page, estoque_id, id_pedido } =
          await getDetalhePedido(id);
        setConfigPage(page);
        setDetalhe(result);
        setTopoDados(topo);
        setEstoqueId(estoque_id);
        setIdPedido(id_pedido);
        // setRecipiente(recipiente);
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  async function paginacao(paginacao) {
    setLoading(true);

    const { result, topo, page } = await getDetalhePedido(id, paginacao);

    setConfigPage(page);
    setDetalhe(result);
    setTopoDados(topo);
    // setRecipiente(recipiente);

    setLoading(false);
  }

  async function sincronizar() {
    setLoadingSinc(true);
    const { data } = await api.get(`/pedido/${id}/refresh`);

    if (data.success) {
      toast.success("Sincronizado com sucesso!");
    } else {
      toast.error("Erro ao sincronizar");
    }

    setLoadingSinc(false);
    getItens();
  }

  async function estornar() {
    setLoadingEstorno(true);

    try {
      await api.post(`/pedido/estornar`, { pedido_id: parseInt(id) });
      toast.success("Pedido estornado com sucesso");
    } catch (error) {
      toast.error("Erro ao estornar pedido");
    }

    setLoadingEstorno(false);
    getItens();
  }

  async function EnviarPedidoCliente() {
    setLoadingPedidoCliente(true);

    try {
      const { data } = await api.post(`/pedido/send`, {
        pedido_id: parseInt(id),
      });
      toast.success("Pedido enviado com sucesso");
      setReceiver(data.receiver);
      setModalEnvioPedidoIsOpen(true);
    } catch (error) {
      toast.error("Erro ao enviar pedido");
    }

    setLoadingPedidoCliente(false);
  }

  const dowload = () => {
    setLoadingDowload(true);
    const headers = {
      authorization: `Bearer ${getToken()}`,
    };

    const config = {
      url: `${process.env.REACT_APP_BASE_HTTP}/pedido/${id}/export`,
      method: "GET",
      responseType: "blob",
      headers,
    };
    const outputFilename = `${Date.now()}.xlsx`;
    axios(config)
      .then((res) => {
        var data = new Blob([res.data]);
        var xlsxURL = window.URL.createObjectURL(data);
        var tempLink;
        tempLink = document.createElement("a");
        tempLink.href = xlsxURL;
        tempLink.setAttribute("download", outputFilename);
        tempLink.click();

        toast.success("Pedido exportado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao exportar pedido");
      });

    setLoadingDowload(false);
  };

  useEffect(() => {
    getItens();
  }, [id, getItens]);

  return (
    <Conteudo className="detalhe">
      <Cadastro>
        <p onClick={() => browserHistory.back()}>
          <img src={img} alt="" />
          {topoDados.nome ? topoDados.nome : ""}
        </p>
        <Link
          to={{
            pathname: `/pedidos/voucher`,
            id: id,
          }}
          className="addVoucher"
        >
          <img src={img11} alt="" />
          <span>ADICIONAR VOUCHERS</span>
        </Link>
      </Cadastro>
      {/* {recipiente?.id ? <DetalheEmail recipiente={recipiente} /> : null} */}

      {loading ? (
        <div className="container-loading">
          <Loading width={500} height={500} />
        </div>
      ) : (
        <div className="conteudo">
          <div className="topoDados">
            <div className="box-topo">
              {/* <div className="box">
                <img src={img5} alt="" />
                <span>TÍTULO DO PEDIDO</span>
                <p>{topoDados.nome ? topoDados.nome : ""}</p>
              </div> */}
              <div className="box">
                <img src={img1} alt="" />
                <span>código</span>
                <p>{topoDados.codigo}</p>
                {/* <br />
                <span>JOB</span>
                <p>{topoDados.job}</p> */}
              </div>
              <div className="box">
                <img src={img3} alt="" />
                <span>Data / hora</span>
                <p>{topoDados.created_at}</p>
              </div>
              <div className="box">
                <img src={img2} alt="" />
                <span>Cliente</span>
                <p>{topoDados.contratante ? topoDados.contratante : ""}</p>
              </div>
              <div className="box">
                <img src={img7} alt="" />
                <span>Qtd. voucher</span>
                <p>{topoDados.qtd_voucher}</p>
              </div>
              <div className="box">
                <img src={img6} alt="" />
                <span>Valor total</span>
                <p>{topoDados.valor_total}</p>
              </div>

              {/* {topoDados.can_export ? (
                                <div className="box download">
                                    <img src={img4} alt="" />
                                    <span>download do pedido</span>

                                    <div className="btns">
                                        {
                                            loadingDowload ? <Loading /> : null
                                            // <button onClick={() => dowload()}>
                                            //     Clique aqui
                                            // </button>
                                        }
                                    </div>
                                </div>
                            ) : null} */}

              <div className="box pedido-cliente">
                <img src={img10} alt="" />
                <span>Estornar Pedido</span>

                <div className="btns">
                  <ButtonCancelar
                    type="button"
                    title="Estornar"
                    message="Este pedido será estornado, deseja continuar?"
                    titleBtn="Estornar"
                    bgc="#D10101"
                    transparent={false}
                    // disabled={loading_estorno_acao}
                    onClick={() => estornar()}
                  />
                  {loadingEstorno ? <Loading width={50} /> : null}
                </div>

                <span>Download do pedido</span>

                <div className="btns">
                  <button onClick={() => dowload()}>Clique aqui</button>
                  {loadingDowload ? <Loading width={50} /> : null}
                </div>
              </div>
              <div className="box pedido-cliente">
                <img src={img9} alt="" />
                <span>Enviar Pedido</span>

                <div className="btns">
                  <button onClick={() => EnviarPedidoCliente()}>
                    Clique aqui
                  </button>
                  {loadingPedidoCliente ? <Loading width={50} /> : null}
                </div>

                <span>Sincronizar Pedido</span>

                <div className="btns">
                  <button onClick={() => sincronizar()}>Sincronizar</button>

                  {loadingSinc ? <Loading width={50} /> : null}
                </div>
              </div>
            </div>
          </div>

          <TabelaDetalhesPedido
            data={detalhe}
            totalPages={configPage?.last_page ?? 0}
            onPageChange={(page) => paginacao(page)}
            estoque_id={estoqueId}
            id_pedido={idPedido}
          />
        </div>
      )}
      <Modal
        rest={{ receiver }}
        component={ModalEnvioPedido}
        active={modalEnvioPedidoIsOpen}
        close={() => setModalEnvioPedidoIsOpen(false)}
      />
    </Conteudo>
  );
}

export default memo(DetalhesPedido);
