import React, { memo } from "react";
import WhatsApp from "./whatsApp";
import TemplateAcao from "./templateAcao";
import Sms from "./sms";
import Email from "./email";
// import { InputToggle } from "./toggle";

import { Field } from "formik";

function Template({ value }) {
  switch (value) {
    case 1:
      return (
        <>
          {/* <Field component={InputToggle} /> */}
          <Field component={TemplateAcao} />
          <Field component={Sms} />
        </>
      );
    case 2:
      return (
        <>
          {/* <Field component={InputToggle} /> */}
          <Field component={TemplateAcao} />
          <Field component={WhatsApp} />
        </>
      );

    case 3:
      return (
        <>
          {/* <Field component={InputToggle} /> */}
          <Field component={TemplateAcao} />
          <Field component={Email} />
        </>
      );

    default:
      return null;
  }
}

export default memo(Template);
