import styles from "./newFooter.module.css";
import LogoYetzFooter from "../../assets/img/general/LogoYetzFooter.svg";

const NewFooter = () => {
  return (
    <div className={styles.generalContainer}>
      <div className={styles.textContainer}>
        <p>YETZ LTDA | CNPJ: 28.325.166/0001-05 | 2024 | Todos os direitos reservados</p>
      </div>
      <div className={styles.separatorContainer}> </div>
      <div className={styles.logoContainer}>
        <img src={LogoYetzFooter} alt="Logo Yetz Footer" />
      </div>
    </div>
  );
}

export default NewFooter;