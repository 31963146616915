import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { api } from "../../services/api";
import {
  formatCurrentMoney,
  getDetalhePedidoVoucher,
} from "../../utils/helper";

import Topo from "../../components/topo/cadastro";
import Voucher from "../../components/formsNovoPedido/vouchers";
import Box from "../../components/boxProduto/boxView";
import Button from "../../components/button";
import ButtonCancelar from "../../components/ButtonCancelar";
import Loader from "../../components/Loading";

import { Nova } from "./styles";

import logoAprova from "../../assets/img/sistema/logo-aprova.svg";

function NovaAcao() {
  const history = useHistory();
  const [formSelecionada, alteraForm] = useState("vouchers");

  const [data, setData] = useState({});
  const [telaAprovar, setTelaAprovar] = useState(false);

  const { loading } = useSelector((state) => state.acao);
  const [loadingPedido, setLoadingPedido] = useState(false);
  const [detalhe, setDetalhe] = useState([]);

  const id = history.location.id;

  // const { resumo = {} } = data;

  useEffect(() => {
    if (!id) history.push("/pedidos");
    async function getItens() {
      if (id) {
        const { result } = await getDetalhePedidoVoucher(id);
        setDetalhe(result);
      }
    }
    getItens();
  }, [id, history]);

  const updateData = async (name, values, nextForm = null) => {
    setData({ ...data, [name]: values });

    if (nextForm) {
      alteraForm(nextForm);
    } else {
      console.log("final");
    }
  };

  function submitData() {
    setLoadingPedido(true);
    document.body.style.overflow = "hidden";

    const dados = {
      cliente_id: detalhe.cliente_id,
      cliente_area_contato_id: detalhe.cliente_area_contato_id,
      entrega_codigo: detalhe.entrega_codigo,
      nome: detalhe.nome,
      job: detalhe.job,
      pedido_original_id: detalhe.pedido_estoque_id,
      variacoes: data.variacoes.map((prod) => {
        return {
          variacao_id: prod.id,
          quantidade: prod.value,
        };
      }),
    };

    api
      .post(`/pedido`, {
        ...dados,
      })
      .then((data) => {
        document.body.style.overflow = "auto";
        setLoadingPedido(false);
        history.push("/pedidos");
      })
      .catch((error) => {
        setLoadingPedido(false);
        console.log(error);
      });
  }

  function _renderContent() {
    switch (formSelecionada) {
      case "vouchers": {
        return (
          <Voucher
            onSubmit={(values) => {
              updateData("variacoes", values, "aprovar");
            }}
          />
        );
      }

      case "aprovar": {
        setTelaAprovar(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  const qtdVouchers = data?.variacoes?.reduce((acc, produto) => {
    return (acc += produto.value);
  }, 0);

  const valorVouchers = data?.variacoes?.reduce((acc, produto) => {
    return (acc += produto.value * parseInt(produto.valor_reais));
  }, 0);

  return (
    <Nova>
      <Topo />

      {loadingPedido ? (
        <div className="container-loader">
          <Loader />
        </div>
      ) : null}

      {telaAprovar ? (
        <div
          className={`container containerAprovar ${loading ? "active" : ""}`}
        >
          <div className="s-esq">
            <img src={logoAprova} alt="" />
          </div>
          <div className="container-resumo-pedido">
            <h2>Resumo do Pedido</h2>

            <span className="active">
              Qtd. Vouchers
              <small>{qtdVouchers}</small>
            </span>
            <span className="active">
              Valor
              <small>{formatCurrentMoney(valorVouchers)}</small>
            </span>
          </div>
          <div className="s-dir">
            {data.variacoes.map((produto) => {
              return <Box key={produto.variacao_id} produto={produto} />;
            })}
            <div className="btns">
              <ButtonCancelar pedido={true} />

              <Button title="Aprovar Pedido" onClick={() => submitData()} />
            </div>
          </div>
        </div>
      ) : (
        <div className={`container ${loading ? "active" : ""}`}>
          <h1>Vouchers</h1>
          {_renderContent()}
        </div>
      )}
    </Nova>
  );
}

export default NovaAcao;
