import styled from "styled-components";

export const Container = styled.div`
  .container-form {
    width: 100%;
    label {
      &.custom-file {
        width: 100%;
      }
    }
  }
`;

export const ConfirmaStyles = styled.div`
  .container-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
  ul {
    margin-bottom: 32px;
    li {
      display: grid;
      grid-template-columns: 36px 1fr 220px;
      align-items: center;
      height: 68px;
      border-bottom: 1px solid #d4d4d4;

      img {
        display: block;
        margin-right: 16px;

        margin-top: 3px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #0097d5;
      }
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
