import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";

import { useHistory } from "react-router-dom";

import { api } from "../../services/api";

import { Permissions } from "../../components/Permission";

import Loading from "../../components/Loading";
import Button from "../../components/button";
import ButtonCancelar from "./btnCancelar";
import { CopiaDetalheAcao } from "../../components/CopiaDetalheAcao";
import Modal from "../../components/modal";
import { ModalCopyAcao } from "./modalCopyAcao";

import {
  getDetalhe,
  formatDataTime,
  openTab,
  openTabBlob,
  getLinkExport,
  getLinkExportPdf,
} from "../../utils/helper";

import browserHistory from "../../config/browserHistory";

import Tabela, {
  Pagination,
} from "../../components/tabelaMui/tabelaFiltroDetalheAcao";

// import Tabela, { Pagination } from "../../components/tabelaMui";

import { Editar } from "./styles";
import { Cadastro } from "../../components/topo/styles";

import img from "../../assets/img/sistema/seta-azul.svg";
import disparar from "../../assets/img/sistema/disparar.svg";
import estornar from "../../assets/img/sistema/estornar.svg";

import area from "../../assets/img/sistema/detalhe/area.svg";
import categoria from "../../assets/img/sistema/detalhe/categoria.svg";
import data from "../../assets/img/sistema/detalhe/data.svg";
import disparado from "../../assets/img/sistema/detalhe/disparado.svg";
import disparo from "../../assets/img/sistema/detalhe/disparo.svg";
import job from "../../assets/img/sistema/detalhe/job.svg";
import links from "../../assets/img/sistema/detalhe/links.svg";

import clonar from "../../assets/img/sistema/clonar.svg";
import dashboard from "../../assets/img/sistema/dashboard.svg";
import exportImg from "../../assets/img/sistema/export.svg";
import seta from "../../assets/img/sistema/select.svg";
// import estorno from "../../assets/img/sistema/estorno.svg";

function EditarAcao() {
  // const { name: solicitante } = useSelector((state) => state.user);
  const {
    loading_cancelar_acao,
    success_cancelar_acao,
    success_estorno_acao,
    loading_estorno_acao,
    loading_disparar_acao,
    success_disparar_acao,
    modal_copy_acao,
  } = useSelector((state) => state.acao);

  //loading_estorno_acao,

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [detalhe, setDetalhe] = useState([]);
  const [pedido, setPedido] = useState(null);
  const [filhas, setFilhas] = useState([]);
  const [tab, setTab] = useState([]);
  const [topoDados, setTopoDados] = useState({});
  const [configPage, setConfigPage] = useState({});
  const [tabIds, setTabIds] = useState({});
  const [recipiente, setRecipiente] = useState();
  const [linksDisparado, setLinksDisparado] = useState(0);
  const [redisparo, setRedisparo] = useState(0);
  const [rejeitadosMsg, setRejeitadosMsg] = useState(0);
  const [enviadosMsg, setEnviadosMsg] = useState(0);

  const [activeDropdownExport, setActiveDropdownExport] = useState(false);

  const id = history.location.state.id;

  const estornoIndividual = useCallback(async (id) => {
    try {
      await api.post(`recipiente/estornar`, {
        recipiente_id: id,
      });
      toast.success("Estornado com sucesso!");
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }, []);

  const disparoIndividual = useCallback(async (id) => {
    // async function disparoIndividual(id) {
    try {
      await api.post(`acao/fireonly`, {
        recipiente: id,
      });
      toast.success("Novo Disparo criado com sucesso!");
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }, []);

  const editaRecipiente = useCallback(
    async (id, campo, valor, row) => {
      const fields = recipiente.find(
        (r) => r.id === row.id_recipiente
      ).custom_fields;
      const result = fields ? JSON.parse(fields) : {};
      try {
        await api.put(`recipiente/${id}`, {
          custom_fields: {
            ...result,
            nome: campo === "nome" ? valor : row.usuario,
            celular:
              campo === "celular"
                ? valor
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", "")
                : row.telefone
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", ""),
            email: campo === "email" ? valor : row.email,
          },
        });
        toast.success("Editado com sucesso!");
      } catch (error) {
        toast.error("Algo deu errado");
      }
    },
    [recipiente]
  );

  const confirmaEditRecipiente = useCallback(
    (id, campo, valor, row) => {
      confirmAlert({
        title: "Editar",
        message: "O valor será alterado, deseja confirmar?",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              editaRecipiente(id, campo, valor, row);
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    },
    [editaRecipiente]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: "Nome Completo",
        field: "usuario",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.usuario}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "nome", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Celular",
        field: "telefone",
        width: 200,
        hide:
          topoDados?.tipo?.toLowerCase() === "sms" ||
          topoDados?.tipo?.toLowerCase() === "whatsapp"
            ? false
            : true,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <InputMask
                type="text"
                mask="(99) 99999-9999"
                defaultValue={row.telefone}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "celular", e.target.value, row)
                }
              />
            </div>
          );
        },
      },
      {
        headerName: "E-mail",
        field: "email",
        width: 300,
        hide: topoDados?.tipo?.toLowerCase() === "email" ? false : true,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.email}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "email", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Voucher",
        field: "voucher",
        width: 150,
        filterable: false,
      },
      {
        headerName: "Id",
        field: "id",
        width: 150,
        hide: true,
        filterable: false,
      },
      {
        headerName: "Data e hora do envio",
        field: "recebimento",
        width: 150,
        filterable: false,
      },
      {
        headerName: "Status",
        field: "status",
        width: 150,
        renderCell: ({ row }) => {
          return (
            <div
              className={`${
                row.status_pt && "status " + row.status_pt.toLowerCase()
              }`}
            >
              {row.status_pt}
            </div>
          );
        },
        filterable: false,
      },
      {
        headerName: "Data e hora da visualização",
        field: "visualizacao",
        width: 150,
        filterable: false,
      },
      {
        headerName: "Status",
        field: "yetz_estoque_status",
        width: 150,
        renderCell: ({ row }) => {
          return (
            <div
              className={`${
                row.yetz_estoque_status &&
                "status " + row.yetz_estoque_status.toLowerCase()
              }`}
            >
              {row.yetz_estoque_status}
            </div>
          );
        },
        filterable: false,
      },
      {
        headerName: "Data e Hora",
        field: "data_yetz_estoque",
        width: 200,
        filterable: false,
      },
      {
        headerName: "",
        field: "disparar",
        width: 100,
        renderCell: ({ row }) => {
          return (
            <div className="disparo-estorno">
              {topoDados.categoria !== "Comunicacao" && (
                <>
                  <Permissions role={["recipiente.reverse"]}>
                    <img
                      src={estornar}
                      alt=""
                      data-tip
                      data-for={`estorno${row.id}`}
                      onClick={() => estornoIndividual(row.id_recipiente)}
                    />
                  </Permissions>
                  <ReactTooltip
                    id={`estorno${row.id}`}
                    type="info"
                    place="left"
                  >
                    <span>Estornar</span>
                  </ReactTooltip>
                </>
              )}
              <Permissions role={["acao.fireOnlyAction"]}>
                <img
                  src={disparar}
                  alt=""
                  data-tip
                  data-for={`disparar${row.id}`}
                  onClick={() => disparoIndividual(row.id_recipiente)}
                />
              </Permissions>
              <ReactTooltip id={`disparar${row.id}`} type="info" place="left">
                <span>Disparar</span>
              </ReactTooltip>
            </div>
          );
        },
        filterable: false,
      },
    ],
    [
      disparoIndividual,
      confirmaEditRecipiente,
      estornoIndividual,
      topoDados.categoria,
      topoDados.tipo,
    ]
  );

  const getItens = useCallback(() => {
    async function fetchData() {
      if (id) {
        setLoading(true);
        const { result, topo, page, filhas, tab, pedidos, recipientes } =
          await getDetalhe(id);
        let redisparoCount = 0;
        let disparoCount = 0;
        let msgEnviados = 0;
        let msgRejeitados = 0;

        result.forEach(({ retries, sented, status }) => {
          if (retries === 1) {
            redisparoCount++;
          }
          if (sented === 1) {
            disparoCount++;
          }
          if (status === "REJECT" || status === "REJECTED") {
            msgRejeitados++;
          }
          if (
            status !== "REJECT" &&
            status !== "REJECTED" &&
            status !== "CANCELED" &&
            status !== "ERROR" &&
            status !== "DEFERRED" &&
            status !== "PROCESSING" &&
            status !== "DROPPED" &&
            status !== "BOUNCE"
          ) {
            msgEnviados++;
          }
        });
        setRejeitadosMsg(msgRejeitados);
        setEnviadosMsg(msgEnviados);
        setLinksDisparado(disparoCount);
        setRedisparo(redisparoCount);
        setPedido(pedidos);
        setConfigPage(page);
        setDetalhe(result);
        setFilhas(filhas);
        setTab(tab);
        setTopoDados(topo);
        setRecipiente(recipientes);
        setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  async function paginacao(paginacao) {
    setLoading(true);
    const { result, topo, page, filhas, tab, pedidos, recipientes } =
      await getDetalhe(id, paginacao);

    setPedido(pedidos);
    setConfigPage(page);
    setDetalhe(result);
    setFilhas(filhas);
    setTab(tab);
    setTopoDados(topo);
    setRecipiente(recipientes);
    setLoading(false);
  }

  async function exportar() {
    const link = await getLinkExport(id);
    openTab(link);
  }

  async function exportarPdf() {
    const blob = await getLinkExportPdf(id);
    // window.open(blob, "_blank");
    openTabBlob(blob);
  }

  useEffect(() => {
    if (success_disparar_acao) {
      getItens();
    }
  }, [success_disparar_acao, getItens]);

  useEffect(() => {
    if (success_estorno_acao) {
      getItens();
    }
  }, [success_estorno_acao, getItens]);

  useEffect(() => {
    if (success_cancelar_acao) {
      getItens();
    }
  }, [success_cancelar_acao, getItens]);

  useEffect(() => {
    if (!id) history.push("/acoes");

    setLoading(true);

    try {
      getItens();
    } catch (error) {
      toast.error("Algo deu errado");

      setLoading(false);
    }
  }, [id, history, dispatch, getItens]);

  useEffect(() => {
    if (reload) {
      getItens();
      setReload(false);
    }
  }, [id, reload, getItens]);

  useEffect(() => {
    if (filhas.length > 0) {
      setTabIds({
        acao_status_id: filhas[0][0].acao_status_id,
        id: filhas[0][0].id,
      });
    } else {
      setTabIds({
        acao_status_id: tab[0]?.acao_status_id,
        id: tab[0]?.id,
      });
    }
  }, [filhas, tab]);

  return (
    <Editar>
      <Cadastro>
        <p onClick={() => browserHistory.back()}>
          <img src={img} alt="" />
          {topoDados.job ? topoDados.job : ""} •{" "}
          {topoDados.cliente ? topoDados.cliente.nome : ""} •{" "}
          {topoDados.area ? topoDados.area.nome : ""} •{" "}
          {topoDados.nome ? topoDados.nome : ""}
        </p>

        {topoDados.status !== "Cancelada" ? (
          <div className={`btns ${loading_cancelar_acao ? "cancelar" : ""}`}>
            <div
              className="btnTopo"
              onClick={() =>
                history.push({
                  pathname: `/acoes/dashboard/${id}`,
                  id: id,
                })
              }
            >
              <img src={dashboard} alt="" />
              <span>Dashboard</span>
            </div>
            {/* <Button title="Dashboard" bgc="#FF8800" /> */}

            <Permissions role={["acao.copy"]}>
              <div
                className="btnTopo"
                onClick={() => dispatch(AcaoCreators.modal_copy_acao())}
              >
                <img src={clonar} alt="" />
                <span>Clonar</span>
              </div>
              {/* <Button
                title="Clonar"
                bgc="#FF8800"
                onClick={() => dispatch(AcaoCreators.modal_copy_acao())}
              /> */}
            </Permissions>
            <div
              className="btnTopo dropdownExport"
              onClick={() => setActiveDropdownExport(!activeDropdownExport)}
            >
              <img src={exportImg} alt="" />
              <span>Exportar</span>
              <img src={seta} alt="" />
              <div
                className={`dropdown ${activeDropdownExport ? "active" : ""}`}
              >
                <span onClick={() => exportar()}>Exportar xls</span>
                <span onClick={() => exportarPdf()}>Exportar pdf</span>
              </div>
            </div>

            {pedido?.id && (
              <Permissions role={["pedido.reverse"]}>
                <ButtonCancelar
                  type="button"
                  title="Estornar"
                  message="Esta ação será estornada, deseja continuar?"
                  titleBtn="Estornar"
                  bgc="#D10101"
                  transparent={false}
                  disabled={loading_estorno_acao}
                  onClick={() =>
                    dispatch(AcaoCreators.estornarAcaoDisparada(pedido.id))
                  }
                />
              </Permissions>
            )}

            <Permissions role={["acao.cancel"]}>
              <ButtonCancelar
                type="button"
                title="Cancelar"
                message="Esta ação será cancelada, deseja continuar?"
                titleBtn="Cancelar"
                bgc="#E25B5B"
                transparent={false}
                disabled={loading_cancelar_acao}
                onClick={() => dispatch(AcaoCreators.cancelarAcaoDisparada(id))}
              />
            </Permissions>

            <Permissions role={["acao.fireAction"]}>
              <Button
                title={`${
                  tabIds.acao_status_id === 5 ? "Disparar" : "Disparado"
                }`}
                bgc={`${tabIds.acao_status_id === 5 ? "#44E0A1" : "#E5E5E5"}`}
                color={`${tabIds.acao_status_id === 5 ? "#016896" : "#636363"}`}
                onClick={() => dispatch(AcaoCreators.disparadaAcao(tabIds.id))}
                disabled={
                  tabIds.acao_status_id === 5
                    ? loading_disparar_acao
                      ? loading_disparar_acao
                      : false
                    : true
                }
              />
            </Permissions>
          </div>
        ) : null}
      </Cadastro>

      <div className="topoDados">
        <div className="container-topo">
          <div className="item">
            <img src={area} alt="" />
            <div>
              <span>Cliente</span>
              <p>{topoDados.cliente ? topoDados.cliente.nome : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={job} alt="" />
            <div>
              <span>Área</span>
              <p>
                {topoDados.area ? topoDados.area.nome : ""} -{" "}
                {topoDados.cliente_area_contato
                  ? topoDados.cliente_area_contato.nome
                  : ""}
              </p>
            </div>
          </div>
          <div className="item">
            <img src={disparo} alt="" />
            <div>
              <span>Job</span>
              <p>{topoDados.job ? topoDados.job : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={data} alt="" />
            <div>
              <span>Tipo de Disparo</span>
              <p>{topoDados.tipo ? topoDados.tipo : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={categoria} alt="" />
            <div>
              <span>Categoria</span>
              <p>{topoDados.categoria ? topoDados.categoria : ""}</p>
            </div>
          </div>
          {/* <div className="item">
            <img src={categoria} alt="" />
            <div>
              <span>Nome da Ação</span>
              <p>{topoDados.nome ? topoDados.nome : ""}</p>
            </div>
          </div> */}

          <div className="item">
            <img src={links} alt="" />
            <div>
              <span>Data de Disp.</span>
              <p>
                {topoDados?.data_disparo
                  ? formatDataTime(topoDados.data_disparo)
                  : ""}
              </p>
            </div>
          </div>
          <div className="item">
            <img src={disparado} alt="" />
            <div>
              <span>Enviados / Rejeitados</span>
              <p>{enviadosMsg + " / " + rejeitadosMsg}</p>
            </div>
          </div>

          <div className="item">
            <img src={disparado} alt="" />
            <div>
              <span>Disparo / Redisparo</span>
              <p>{linksDisparado + " / " + redisparo}</p>
            </div>
          </div>

          {topoDados.status === "Cancelada" ? (
            <div className="item">
              <img src={area} alt="" />
              <div>
                <span>Status</span>
                <p>{topoDados.status}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="container editar-acao">
        {loading || loading_disparar_acao ? (
          <Loading />
        ) : (
          <>
            {filhas.length ? (
              <CopiaDetalheAcao
                detalhe={detalhe}
                tab={tab}
                setTabIds={setTabIds}
                configPaginacao={configPage}
                recipientes={recipiente}
                categoria={topoDados.categoria}
              />
            ) : (
              <>
                <Tabela columns={columns} rows={detalhe} id={id} />
                {configPage?.last_page > 1 ? (
                  <Pagination
                    total={configPage?.last_page}
                    currentPage={(page) => {
                      paginacao(page);
                    }}
                    page={configPage?.current_page}
                  />
                ) : null}
              </>
            )}

            <Modal
              component={ModalCopyAcao}
              active={modal_copy_acao}
              setReload={setReload}
              recipientes={recipiente}
            />
          </>
        )}
      </div>
    </Editar>
  );
}

export default EditarAcao;
