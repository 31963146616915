import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  checkDisparoData,
  tipoDisparo,
  convertDatePickerTimeToMySQLTime,
  convertDatePickerTimeToUser,
} from "../../utils/helper";

import { Creators as AcaoCreators } from "../../store/ducks/acao";
import {
  FormInitialState,
  validationDisparoDados,
} from "../../utils/configForm";

import Button from "../button";
import TipoDisparo from "./tipoDisparo";
import { DateTime } from "../dateTime";
import ButtonCancelar from "../ButtonCancelar";

function Disparo({ change, editar }) {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.acao);
  const { toggle_disparo } = data;
  const valuesStore = checkDisparoData(data, FormInitialState);

  return (
    <Formik
      enableReinitialize
      initialValues={valuesStore}
      validationSchema={validationDisparoDados}
      onSubmit={(value, actions) => {
        if (!value.data_disparo && value.data_disparo !== 1) {
          value.data_disparo = tipoDisparo(toggle_disparo);
        }

        dispatch(AcaoCreators.dados(value));
        dispatch(AcaoCreators.participante());
        change("participantes");
      }}
    >
      {(props) => {
        const { setFieldValue } = props;

        return (
          <Form>
            <div className="container-form-double">
              <Field component={TipoDisparo} />

              {toggle_disparo === 1 ? (
                <DateTime
                  initialValue={null}
                  label="Data/horario"
                  onChange={(date) => {
                    setFieldValue(
                      "data_disparo",
                      convertDatePickerTimeToMySQLTime(date)
                    );

                    dispatch(
                      AcaoCreators.createObject(
                        "data_disparo",
                        convertDatePickerTimeToMySQLTime(date)
                      )
                    );
                    dispatch(
                      AcaoCreators.preview(
                        "data_disparo",
                        convertDatePickerTimeToUser(
                          convertDatePickerTimeToMySQLTime(date)
                        )
                      )
                    );
                  }}
                />
              ) : null}
            </div>
            {!editar && <ButtonCancelar />}
            <Button title="Avançar" />
          </Form>
        );
      }}
    </Formik>
  );
}

export default Disparo;
