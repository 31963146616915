import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";

import { api } from "../../services/api";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Permissions } from "../../components/Permission";

import Loading from "../../components/Loading";
import Button from "../../components/button";
import ButtonCancelar from "./btnCancelar";

import { getDetalheMulti, formatDataTime } from "../../utils/helper";

import browserHistory from "../../config/browserHistory";

import Tabela, { Pagination } from "../../components/tabelaMui";

import { Editar } from "./styles";
import { Cadastro } from "../../components/topo/styles";

import erro from "../../assets/img/sistema/erro.svg";
import img from "../../assets/img/sistema/seta-azul.svg";

import area from "../../assets/img/sistema/detalhe/area.svg";
import categoria from "../../assets/img/sistema/detalhe/categoria.svg";
import data from "../../assets/img/sistema/detalhe/data.svg";
import disparado from "../../assets/img/sistema/detalhe/disparado.svg";
import disparo from "../../assets/img/sistema/detalhe/disparo.svg";
import job from "../../assets/img/sistema/detalhe/job.svg";
import links from "../../assets/img/sistema/detalhe/links.svg";

function DetalheProcessamento() {
  const { name: solicitante } = useSelector((state) => state.user);
  const {
    loading_cancelar_acao_multi,
    success_cancelar_acao_multi,
    success_estorno_acao,
    success_disparar_acao,
  } = useSelector((state) => state.acao);

  //loading_estorno_acao,

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [detalhe, setDetalhe] = useState([]);
  const [topoDados, setTopoDados] = useState({});
  const [configPage, setConfigPage] = useState({});
  const [recipiente, setRecipiente] = useState();

  const [loadingReenvio, setLoadingReenvio] = useState(false);

  const id = history.location.state.id;

  const editaRecipiente = useCallback(
    async (id, campo, valor, row) => {
      // async function editaRecipiente(id, campo, valor, row, indice) {
      const fields = recipiente.find(
        (r) => r.id === row.id_recipiente
      ).custom_fields;
      const result = fields ? JSON.parse(fields) : {};
      try {
        await api.put(`recipiente/${id}`, {
          custom_fields: {
            ...result,
            nome: campo === "nome" ? valor : row.usuario,
            celular:
              campo === "celular"
                ? valor
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", "")
                : row.telefone,
            email: campo === "email" ? valor : row.email,
          },
        });
        toast.success("Editado com sucesso!");
      } catch (error) {
        toast.error("Algo deu errado");
      }
    },
    [recipiente]
  );

  const confirmaEditRecipiente = useCallback(
    (id, campo, valor, row) => {
      confirmAlert({
        title: "Editar",
        message: "O valor será alterado, deseja confirmar?",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              editaRecipiente(id, campo, valor, row);
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    },
    [editaRecipiente]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: "Destinatário",
        field: "usuario",
        width: 250,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.usuario}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "nome", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Celular",
        field: "telefone",
        width: 250,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <InputMask
                type="text"
                mask="(99) 99999-9999"
                defaultValue={row.telefone}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "celular", e.target.value, row)
                }
              />
            </div>
          );
        },
      },
      {
        headerName: "Email",
        field: "email",
        width: 300,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.email}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "email", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Voucher",
        field: "voucher",
        width: 200,
      },
      {
        headerName: "Valor",
        field: "valor",
        width: 200,
      },
      {
        headerName: "Status",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className={`status ${row.status.toLowerCase()}`}>
              {row.status_pt}
            </div>
          );
        },
      },
    ],
    [confirmaEditRecipiente]
  );

  const getItens = useCallback(() => {
    async function fetchData() {
      if (id) {
        setLoading(true);
        const { result, topo, page, recipientes } = await getDetalheMulti(id);
        setConfigPage(page);
        setDetalhe(result);
        setTopoDados(topo);
        setRecipiente(recipientes);
        setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  async function paginacao(paginacao) {
    setLoading(true);
    const { result, topo, page, recipientes } = await getDetalheMulti(
      id,
      paginacao
    );

    setConfigPage(page);
    setDetalhe(result);
    setTopoDados(topo);
    setRecipiente(recipientes);
    setLoading(false);
  }

  useEffect(() => {
    if (success_disparar_acao) {
      getItens();
    }
  }, [success_disparar_acao, getItens]);

  useEffect(() => {
    if (success_estorno_acao) {
      getItens();
    }
  }, [success_estorno_acao, getItens]);

  useEffect(() => {
    if (success_cancelar_acao_multi) {
      getItens();
    }
  }, [success_cancelar_acao_multi, getItens]);

  useEffect(() => {
    if (!id) history.push("/processamento");

    setLoading(true);

    try {
      getItens();
    } catch (error) {
      toast.error("Algo deu errado");

      setLoading(false);
    }
  }, [id, history, dispatch, getItens]);

  useEffect(() => {
    if (reload) {
      getItens();
      setReload(false);
    }
  }, [id, reload, getItens]);

  async function enviarPedido() {
    setLoadingReenvio(true);

    try {
      await api.post(`acao/multi/retry`, {
        acao_id: id,
      });
      toast.success("Pedido reenviado com sucesso!");

      setReload(true);
    } catch (error) {
      toast.error("Erro ao reenviar pedido");
    } finally {
      setLoadingReenvio(false);
    }
  }

  function renderMsgErro(id_status) {
    if (id_status === 7 || id_status === 8) {
      return (
        <div className="msg-erro">
          <div className="msg">
            <img src={erro} alt="" />
            <span>
              {id_status === 7
                ? "Erro nos recipientes"
                : "Erro no pedido estoque"}
            </span>
          </div>
          {id_status === 8 && (
            <Button
              type="button"
              title={
                loadingReenvio
                  ? "Seu pedido está sendo processado"
                  : "Enviar pedido novamente"
              }
              bgc="#0191CD"
              transparent={false}
              className="enviar-novamente"
              onClick={enviarPedido}
              disabled={loadingReenvio}
            />
          )}
        </div>
      );
    }
  }

  return (
    <Editar>
      <Cadastro>
        <p onClick={() => browserHistory.back()}>
          <img src={img} alt="" />
          {topoDados.cliente ? topoDados.cliente.nome : ""} •{" "}
          {topoDados.nome ? topoDados.nome : ""}
        </p>

        <div
          className={`btns ${loading_cancelar_acao_multi ? "cancelar" : ""}`}
        >
          {topoDados.erros > 0 ? (
            <Button
              type="button"
              title={topoDados.erros ? "Erros: " + topoDados.erros : ""}
              bgc="#FF8800"
              transparent={false}
              // disabled={loading_cancelar_acao_multi}
            />
          ) : null}

          {detalhe.acao_status_id === 4 ||
          detalhe.acao_status_id === 5 ||
          detalhe.acao_status_id === 7 ||
          detalhe.acao_status_id === 8 ? (
            <Permissions role={["acao.cancel.multi"]}>
              <ButtonCancelar
                type="button"
                title="Cancelar"
                message="Esta ação será cancelada, deseja continuar?"
                titleBtn="Cancelar"
                bgc="#D10101"
                transparent={false}
                disabled={loading_cancelar_acao_multi}
                onClick={() =>
                  dispatch(AcaoCreators.cancelarAcaoDisparadaMulti(id))
                }
              />
            </Permissions>
          ) : null}
        </div>
      </Cadastro>

      <div className="topoDados">
        <div className="container-topo">
          <div className="item">
            <img src={area} alt="" />
            <div>
              <span>Cliente</span>
              <p>
                {topoDados.cliente
                  ? topoDados.cliente.areas.map((item) => item.nome)
                  : ""}
              </p>
            </div>
          </div>
          <div className="item">
            <img src={job} alt="" />
            <div>
              <span>Solicitante</span>
              <p>{solicitante ? solicitante : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={disparo} alt="" />
            <div>
              <span>Job</span>
              <p>{topoDados.job ? topoDados.job : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={categoria} alt="" />
            <div>
              <span>Nome da Ação</span>
              <p>{topoDados.nome ? topoDados.nome : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={data} alt="" />
            <div>
              <span>Tipo de Disparo</span>
              <p>{topoDados.tipo ? topoDados.tipo : ""}</p>
            </div>
          </div>
          <div className="item">
            <img src={links} alt="" />
            <div>
              <span>Data de Disp.</span>
              <p>
                {topoDados?.data_disparo
                  ? formatDataTime(topoDados.data_disparo)
                  : ""}
              </p>
            </div>
          </div>
          <div className="item">
            <img src={disparado} alt="" />
            <div>
              <span>Links Enviados / Entregues</span>
              <p>{topoDados.links ? topoDados.links : ""}</p>
            </div>
          </div>

          {topoDados.status === "Cancelada" ? (
            <div className="item">
              <img src={disparado} alt="" />
              <div>
                <span>Status</span>
                <p>{topoDados.status}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {detalhe.length > 0 && renderMsgErro(detalhe[0].acao_status_id)}

      <div className="container editar-acao">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Tabela
              columns={columns}
              rows={detalhe}
              opcao={false}
              recipientes={recipiente}
            />
            {configPage?.last_page > 1 ? (
              <Pagination
                total={configPage?.last_page}
                currentPage={(page) => {
                  paginacao(page);
                }}
                page={configPage?.current_page}
              />
            ) : null}
          </>
        )}
      </div>
    </Editar>
  );
}

export default DetalheProcessamento;
