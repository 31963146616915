import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { setToken } from "../../services/api";
import { hasPermissions } from "../../utils/permission";

function RouteWrapper(props) {
  const { token } = useSelector((state) => state.user);

  const { isPrivate, permissions = [] } = props;

  if (token) {
    setToken();
  }

  const canAccess = token && hasPermissions(permissions);

  if (isPrivate && !canAccess) {
    return <Redirect to="/" from={props.path} />;
  }

  return <Route {...props} />;
}

export default memo(RouteWrapper);
