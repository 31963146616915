import { useContex } from "./Hook";
import { ModalNovoCliente } from "./Modal";
import Modal from "../../NovoModal";
import { ModalAvisoExclusao } from "./modalAviso";
import AlertDialogSlide from "../../ModalAlert";
import { FormCliente } from "./form";
import Loading from "../../Loading";
import { Container } from "./styles";

export function Cliente() {
    const {
        modal,
        setModal,
        modalAviso,
        setModalAviso,
        setContato,
        modalAlert,
        setModalAlert,
        setContatoId,
        deleteContato,
        loading,
    } = useContex();

    return (
        <Container>
            <div className={`loading-update-dados ${loading ? "active" : ""}`}>
                {loading && <Loading />}
            </div>

            <FormCliente />

            <Modal
                open={modal}
                handleClose={() => {
                    setModal(false);
                    setContato({});
                }}
            >
                <ModalNovoCliente />
            </Modal>

            <Modal open={modalAviso} handleClose={() => setModalAviso(false)}>
                <ModalAvisoExclusao />
            </Modal>

            <AlertDialogSlide
                open={modalAlert}
                description="deseja realmente apagar este contato?"
                title="apagar contato"
                aceept={async () => {
                    setModalAlert(false);
                    await deleteContato();
                }}
                reject={() => {
                    setModalAlert(false);
                    setContatoId(null);
                }}
                handleClose={() => {
                    setModalAlert(false);
                    setContato({});
                }}
            />
        </Container>
    );
}
