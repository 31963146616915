import React from "react";
import Lottie from "react-lottie";
import { loadingAnimation } from "../../styles/plugins/loading";

function Loading(props) {
    const { item = 1, height = "auto", width = "auto" } = props;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation[item],
        rendererSettings: {},
    };

    return (
        <Lottie
            options={defaultOptions}
            background="transparent"
            speed={1}
            height={height}
            width={width}
            loop
            controls
            autoplay
        ></Lottie>
    );
}

export default Loading;
