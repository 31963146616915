import React from "react";
import { Link } from "react-router-dom";

import { Permissions } from "../Permission";

import { Container } from "./styles";

// import img1 from "../../assets/img/sistema/filtro.svg";
// import img2 from "../../assets/img/sistema/add.svg";
import img3 from "../../assets/img/sistema/gerenciar-usuario.svg";

function Topo({
  activeFiltro,
  toggleFiltro,
  title,
  linkCadastro,
  permission = "",
}) {
  return (
    <Container>
      {title === "Usuários ADM" ? (
        <div className="title-gerencia">
          <h4>{title}</h4>
          <Permissions role={["role.index"]}>
            <Link to="/usuarios/gerenciar">
              <img src={img3} alt="" />
              <span>GERENCIAR PERFIS</span>
            </Link>
          </Permissions>
        </div>
      ) : (
        <h4>{title}</h4>
      )}

      <div className="imgs">
        {/* <span
          className={activeFiltro ? "active" : ""}
          onClick={() => toggleFiltro(!activeFiltro)}
        >
          <img src={img1} alt="" />
        </span> */}
        {title !== "Gerenciar Perfis" && title !== "Downloads Pedidos" ? (
          <Permissions role={permission}>
            <Link to={linkCadastro} className="add">
              ADICIONAR
              {/* <img src={img2} alt="" /> */}
            </Link>
          </Permissions>
        ) : null}
      </div>
    </Container>
  );
}

export default Topo;
