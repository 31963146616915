import React from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import NovoSelect from "../NovoSelect";

function Outlet(props) {
  const dispatch = useDispatch();
  const { form } = props;
  const { setFieldValue } = form;

  const options = [
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
  ];

  return (
    <>
      <NovoSelect
        label="Outlet"
        options={options}
        onChange={({ value, label }) => {
          setFieldValue("is_outlet", parseInt(value));
          dispatch(AcaoCreators.preview("is_outlet", label));
        }}
      />
      <ErrorMessage component="span" name="is_outlet" />
    </>
  );
}

export default Outlet;
