import React from "react";

import { useSelector } from "react-redux";
import { ErroUpload as Container } from "./styles";
import erroImg from "../../assets/img/sistema/erro-cinza.svg";

export default function ErroUpload() {
    const { erroData } = useSelector((state) => state.acao);

    return (
        <Container>
            <h4>Erro no Upload</h4>
            <div className="erro">
                <img src={erroImg} alt="" />
                <span>Planilha fora do padrão de sistema</span>
            </div>

            {erroData.missing_columns ? (
                <ListError erros={Object.values(erroData.missing_columns)} />
            ) : typeof erroData === "string" ? (
                <p>{erroData}</p>
            ) : (
                ""
            )}

            <button>Reenviar Arquivo</button>
        </Container>
    );
}

export const ListError = (props) => {
    const { erros } = props;

    return (
        <>
            <h4>Lista de erros</h4>
            <ul>
                {erros.map((item) => (
                    <li>{item}</li>
                ))}
            </ul>
        </>
    );
};
