import { useEffect, useState, useCallback } from "react";
import Mask from "react-input-mask";
import { v4 as uuidv4 } from "uuid";
import Select from "../../../NovoSelect";

import { Formik, Form, ErrorMessage, Field } from "formik";
import { modalInitalValues, validationModal } from "./config";
import { useContex } from "../Hook";

import Button from "../../../button";
import { ModalStyles } from "./styles";
import imgXVerde from "../../../../assets/img/sistema/x.svg";

export function FormCliente(props) {
    const { setSucess } = props;
    const { setContatos, setContato, setModal, areas, contato, contatos } =
        useContex();
    const [initalValues, setInitalValues] = useState({});
    const hasEditar = Object.values(contato).length;

    const hasArea = useCallback(() => {
        if (hasEditar) {
            return areas.filter(
                (item) => item.label === contato?.area.label
            )[0];
        }
        return null;
    }, [areas, contato?.area?.label, hasEditar]);

    useEffect(() => {
        if (hasEditar > 0) {
            setInitalValues(contato);
        } else {
            setInitalValues(modalInitalValues);
        }
    }, [contato, hasEditar]);

    return (
        <ModalStyles>
            <Formik
                enableReinitialize
                initialValues={initalValues}
                validationSchema={validationModal}
                onSubmit={(values, actions) => {
                    if (hasEditar) {
                        const index = contatos.findIndex(
                            (item) => item.id === contato.id
                        );
                        contatos[index] = { ...values };

                        setContatos(contatos);
                        setContato({});
                        setSucess(true);
                        return;
                    } else {
                        const index = contatos.findIndex(
                            (item) => item.email === values.email
                        );

                        if (index > -1) {
                            actions.setErrors({ email: "Email já cadastrado" });
                            return;
                        } else {
                            setContatos([
                                ...contatos,
                                { id: uuidv4(), ...values },
                            ]);
                            actions.resetForm();
                            setSucess(true);
                        }
                    }
                }}
            >
                {(props) => {
                    const { handleChange, setFieldValue, values } = props;
                    const { celular, telefone } = values;

                    return (
                        <Form>
                            <div className="container-header">
                                <p>
                                    {hasEditar
                                        ? "Editar Contato"
                                        : "NOVO contato"}
                                </p>
                                <button
                                    type="button"
                                    onClick={() => setModal(false)}
                                >
                                    <img src={imgXVerde} alt="" />
                                </button>
                            </div>

                            <div className="container-content">
                                <span>
                                    Preencha os campos abaixo para adicionar um
                                    novo contato ao cliente.
                                </span>
                            </div>

                            <div className="container-form">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <Select
                                initalValue={hasEditar ? hasArea() : null}
                                options={areas}
                                label="AREA"
                                onChange={(values) => {
                                    setFieldValue("area", values);
                                }}
                            />
                            <ErrorMessage component="span" name="area" />

                            <div className="container-form">
                                <Field
                                    type="email"
                                    placeholder="EMAIL"
                                    name="email"
                                />
                            </div>
                            <ErrorMessage component="span" name="email" />

                            <div className="container-form">
                                <Mask
                                    mask="(99) 99999-9999"
                                    placeholder="CELULAR"
                                    name="celular"
                                    type="tel"
                                    onChange={handleChange}
                                    value={celular}
                                />
                            </div>
                            <ErrorMessage component="span" name="celular" />

                            <div className="container-form">
                                <Mask
                                    mask="(99) 9999-9999"
                                    placeholder="TELEFONE"
                                    name="telefone"
                                    type="tel"
                                    onChange={handleChange}
                                    value={telefone}
                                />
                            </div>
                            <ErrorMessage component="span" name="telefone" />

                            <div>
                                <Button
                                    title="ADICIONAR CONTATO"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </ModalStyles>
    );
}
