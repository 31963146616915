import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";

import NovoSelect from "../NovoSelect";
import { getAreas } from "../../utils/helper";

function Areas(props) {
    const [options, setOptions] = useState([]);
    const { id, form } = props;
    const { setFieldValue } = form;

    useEffect(() => {
        async function fetchData() {
            const options = await getAreas(id);
            setOptions(options);
        }
        if (id !== 9999) {
            fetchData();
        }
    }, [id]);

    return (
        <>
            <NovoSelect
                label="Área / Contato"
                options={options}
                onChange={({ value, label }) => {
                    setFieldValue("cliente_area_contato_id", parseInt(value));
                }}
            />
            <ErrorMessage component="span" name="contratante_id" />
        </>
    );
}

export default Areas;
