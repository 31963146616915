import * as Yup from "yup";

export const myFormValues = {
  type: "",
  name: "",
  cliente_id: "",
  status: "",
  phone_number: "",
  text: "",
  fields: [],
  sendgrid_id: "",
  html_content: "",
};

export const validationFormEmail = Yup.object().shape({
  type: Yup.string().required("Campo Obrigatório"),
  cliente_id: Yup.number().required("Campo Obrigatório"),
  status: Yup.string().required("Campo Obrigatório"),

  name: Yup.string().required("Campo Obrigatório"),
  sendgrid_id: Yup.string().required("Campo Obrigatório"),
  html_content: Yup.string(),
});

export const validationFormSms = Yup.object().shape({
  type: Yup.string().required("Campo Obrigatório"),
  cliente_id: Yup.number().required("Campo Obrigatório"),
  status: Yup.string().required("Campo Obrigatório"),

  name: Yup.string().required("Campo Obrigatório"),
  text: Yup.string().required("Campo Obrigatório"),
  fields: Yup.array().of(Yup.string()),
});
