import styled from "styled-components";
import { ResumoUpload } from "../../components/formsNovaAcao/styles";
import { Container as Select } from "../../components/select/styles";
import { Container as BoxProduto } from "../../components/boxProduto/styles";
import { Cadastro as Topo } from "../../components/topo/styles";

export const Container = styled.div`
    margin-left: 160px;
    min-height: 100vh;
    height: 100%;
    /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Loader = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`;

export const Conteudo = styled.div`
    .container-loading {
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .conteudo {
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(520px, 1fr)); */
        grid-gap: 30px;
        padding: 30px;
        padding-bottom: 90px;
        overflow: hidden;
        h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            display: flex;
            grid-gap: 20px;
        }
        .box-topo {
            display: flex;
            justify-content: space-between;
            .box {
                border: 1px solid #dfdfdf;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 24px;
                width: 198px;
                img {
                    margin-bottom: 20px;
                }
                span {
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #4b4b4b;
                    display: block;
                }
                p {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    color: #000000;
                    margin-top: 10px;
                }
                &.download {
                    border: 1px solid #00824e;
                    .btns {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 8px;
                        button {
                            font-weight: bold;
                            font-size: 13.5px;
                            line-height: 18px;
                            color: #00824e;
                            white-space: nowrap;
                        }
                    }
                }
                &.pedido-cliente {
                    border: 1px solid #0096d4;
                    span {
                        font-size: 11px;
                    }
                    img {
                        width: 25px;
                    }
                    .btns {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 8px;
                        button {
                            font-weight: bold;
                            font-size: 13.5px;
                            line-height: 18px;
                            color: #0096d4;
                            white-space: nowrap;
                        }
                    }
                }
                &.pedido {
                    border: 1px solid #0096d4;
                    img {
                        width: 25px;
                    }
                    .btns {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 8px;
                        button {
                            font-weight: bold;
                            font-size: 13.5px;
                            line-height: 18px;
                            color: #0096d4;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    &.detalhe {
        ${Topo} {
            h3 {
                display: none;
            }
        }
    }
`;

export const Editar = styled.div`
    min-height: 100vh;
    height: 100%;
    background: ${(props) => props.theme.colors.Cinza06};
    .topoDados {
        background: ${(props) => props.theme.colors.branco};
        .container-topo {
            width: 1140px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-gap: 10px;
            height: 100px;
            margin-bottom: 10px;
            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                color: ${(props) => props.theme.colors.Cinza02};
            }
            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                color: ${(props) => props.theme.colors.Cinza01};
                margin-top: 5px;
            }
            .btns {
                display: flex;
                grid-gap: 10px;
                a,
                span {
                    border: none;
                    background-color: transparent;
                    background-color: ${(props) => props.theme.colors.Vinho1};
                    border-radius: 4px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }

    .container {
        max-width: 1140px;
        width: auto;
        &.editar-acao {
            padding-bottom: 24px;
        }
    }
`;

export const Nova = styled.div`
    .container-resumo-pedido {
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            color: #333333;
            margin-bottom: 35px;
        }
        span {
            display: flex;
            flex-direction: column;
            align-items: start;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            color: ${(props) => props.theme.colors.Cinza07};
            position: relative;
            height: 48px;
            cursor: pointer;
            padding-left: 20px;
            margin-bottom: 32px;
            width: 180px;
            &:hover {
                p {
                    &.toolTip {
                        opacity: 1;
                        transition: all 0.5s;
                    }
                }
            }
            p {
                &.toolTip {
                    border-radius: 5px;
                    padding: 25px;
                    width: auto;
                    background-color: rgba(0, 0, 0, 0.5);
                    opacity: 0;
                    position: absolute;
                    bottom: 60px;
                    left: 15%;
                    transform: translateX(-50%);
                    transition: all 0.5s;
                    color: #fff;
                    font-size: 12px;
                    pointer-events: none;
                    white-space: pre;
                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 10px solid rgba(0, 0, 0, 0.5);
                        position: absolute;
                        bottom: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            small {
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 100%;
                color: #333333;
                display: block;
                margin-top: 8px;
                text-overflow: ellipsis;
                white-space: pre;
                overflow: hidden;
                width: 100%;
            }
            &::before {
                content: "";
                background-color: ${(props) => props.theme.colors.Laranja1};
                width: 9px;
                height: 9px;
                display: block;
                border-radius: 50%;
                margin-right: 10px;
                position: absolute;
                top: 6px;
                left: 0px;
            }
            &:not(:last-child) {
                &::after {
                    content: "";
                    background-color: ${(props) => props.theme.colors.Cinza04};
                    display: block;
                    height: 65px;
                    width: 2px;
                    position: absolute;
                    top: 20px;
                    left: 3.5px;
                }
            }
        }
    }
    .container-loader {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        pointer-events: none;
        transition: all 0.5s;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
            transition: all 0.5s;
        }
    }
    .container {
        padding-top: 100px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-gap: 150px;
        padding-left: 150px;
        transition: all 0.5s;
        &.active {
            filter: blur(4px);
            pointer-events: none;
            opacity: 0.6;
            transition: all 0.5s;
        }
        .s-esq {
            img {
                margin-bottom: 40px;
            }
        }
        .s-dir {
            button {
                margin-right: 76px;
            }
            ${Select} {
                background: ${(props) => props.theme.colors.Cinza06};
                border-radius: 4px;
            }

            form {
                &.disparo {
                    display: flex;
                    flex-direction: column;
                    .container-form {
                        order: 1;
                        margin-bottom: 0px;
                    }

                    button {
                        order: 3;
                    }

                    .voltar {
                        order: 3;
                    }

                    ${ResumoUpload} {
                        order: 2;
                        margin-top: 7px;
                        margin-bottom: 36px;
                    }
                }
            }

            h1 {
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.preto};
                margin: 50px 0;
            }
        }
        &.containerAprovar {
            display: grid;
            grid-template-columns: 100px 1fr 2fr;
            grid-gap: 50px;
            padding-left: 100px;
            padding-top: 100px;
            padding-bottom: 100px;
            h1 {
                font-weight: 600;
                font-size: 24px;
                line-height: 38px;
                color: #333333;
                margin-bottom: 30px;
            }
            .item {
                margin-bottom: 30px;
                position: relative;
                span {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 100%;
                    color: #828282;
                    display: flex;
                    align-items: center;
                    &::before {
                        content: "";
                        background-color: ${(props) =>
                            props.theme.colors.Laranja1};
                        width: 9px;
                        height: 9px;
                        display: block;
                        border-radius: 50%;
                        margin-right: 20px;
                    }
                }
                p {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 100%;
                    color: #333333;
                    margin-top: 10px;
                    margin-left: 30px;
                }

                &:not(:last-child) {
                    &::after {
                        content: "";
                        background-color: ${(props) =>
                            props.theme.colors.Cinza04};
                        display: block;
                        height: 46px;
                        width: 2px;
                        position: absolute;
                        top: 22px;
                        left: 3px;
                    }
                }
            }
            ${BoxProduto} {
                .qtd {
                    input {
                        background: #f6f6f6;
                        border-radius: 0px 4px 4px 0px;
                        border: none;
                        pointer-events: none;
                    }
                }
                .fechar {
                    display: none;
                }
            }
            .btns {
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                button {
                    margin: 0;
                }
            }
        }
    }

    @media (max-width: 1366px) {
        .container {
            grid-gap: 50px;
            padding-left: 50px;
        }
    }
`;

export const DadosAcao = styled.div`
    background-color: rgba(212, 212, 212, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin-top: 30px;
    .item {
        position: relative;
        .hidden {
            display: none;
        }
        &:hover {
            .toolTip {
                bottom: 30px;
                opacity: 1;
                transition: all 0.5s;
            }
        }
    }
    .toolTip {
        border-radius: 5px;
        padding: 25px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
        color: #fff;
        font-size: 12px;
        pointer-events: none;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    h4 {
        font-weight: bold;
        font-size: 18px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 20px;
    }
    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza07};
    }
    p {
        font-weight: 800;
        font-size: 14px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
    }
`;

export const Resumo = styled.div`
    .container {
        margin-top: 100px;
        margin-bottom: 100px;
        display: grid;
        grid-template-columns: 1fr 2fr 2fr;
        .detalhes {
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.Cinza01};
                margin-bottom: 30px;
            }
            span {
                font-weight: 600;
                font-size: 18px;
                line-height: 100%;
                color: ${(props) => props.theme.colors.Cinza07};
            }
            p {
                font-weight: 800;
                font-size: 18px;
                line-height: 100%;
                color: ${(props) => props.theme.colors.Cinza01};
                margin-top: 5px;
            }
            .item-detalhe {
                position: relative;
                padding-left: 30px;
                height: 72px;
                &::before {
                    content: "";
                    background-color: ${(props) => props.theme.colors.Laranja1};
                    width: 9px;
                    height: 9px;
                    display: block;
                    border-radius: 50%;
                    margin-right: 10px;
                    position: absolute;
                    top: 4px;
                    left: 0;
                }
                &:not(:last-of-type) {
                    &::after {
                        content: "";
                        background-color: ${(props) =>
                            props.theme.colors.Cinza04};
                        display: block;
                        height: 46px;
                        width: 1px;
                        position: absolute;
                        top: 20px;
                        left: 3px;
                    }
                }
            }
            button {
                margin-left: 30px;
                margin-top: 30px;
            }
        }
        .preview {
            margin: 0;
        }
    }
`;

export const ContainerFinal = styled.div`
    .container {
        padding-top: 100px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 205px 1fr;
        grid-gap: 150px;
        padding-left: 150px;
        .detalhes {
            width: 383px;
            h1 {
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                color: ${(props) => props.theme.colors.preto};
                margin: 50px 0;
            }
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 130%;
                color: ${(props) => props.theme.colors.Vinho1};
                margin-bottom: 20px;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.Cinza02};
                margin-bottom: 40px;
            }
            a {
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                text-decoration-line: underline;
                color: ${(props) => props.theme.colors.Vinho1};
                margin-bottom: 40px;
                display: block;
            }
        }
    }
`;

export const TopEmail = styled.div`
    background: ${(props) => props.theme.colors.branco};
    padding-top: 16px;
    h1 {
        width: 1609px;
        margin: 0 auto;
    }
    .container-topo {
        width: 1609px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 10px;
        height: 100px;
        margin-bottom: 10px;
        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.Cinza02};
        }
        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            color: ${(props) => props.theme.colors.Cinza01};
            margin-top: 5px;
        }
        .btns {
            display: flex;
            grid-gap: 10px;
            button {
                color: #016896;
                width: 90px;
            }
            a,
            span {
                border: none;
                background-color: transparent;
                background-color: ${(props) => props.theme.colors.Vinho1};
                border-radius: 4px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
`;
