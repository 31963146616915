import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { getToken } from "../../utils/helper";

import img8 from "../../assets/img/sistema/carrinho.svg";
import download from "../../assets/img/sistema/download.svg";

import TabelaPedidos, { Pagination } from "../../components/tabelaMui";

function TabelaDetalhesPedido({
  data,
  totalPages,
  onPageChange,
  estoque_id,
  id_pedido,
}) {
  const [currentTable, setCurrentTable] = useState(0);
  // const [loadingDowload, setLoadingDowload] = useState(false);

  const columns = [
    {
      headerName: "Voucher",
      field: "nome",
      width: 200,
    },
    {
      headerName: "Variação_ID",
      field: "variacao_id",
      width: 200,
    },
    {
      headerName: "Valor Reais",
      field: "valor",
      width: 200,
    },
    {
      headerName: "ID",
      field: "id",
      width: 200,
    },
    {
      headerName: "Status",
      field: "status",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div className={`status ${row.status.toLowerCase()}`}>
            {row.status}
          </div>
        );
      },
    },
    {
      headerName: "Validade",
      field: "validade",
      width: 250,
    },
    {
      headerName: "Data / Hora",
      field: "data",
      width: 250,
    },
  ];

  const tabs = data.map(({ id }) => id);

  const rows = useMemo(() => data[currentTable] ?? [], [currentTable, data]);

  const dowload = () => {
    // setLoadingDowload(true);
    const headers = {
      authorization: `Bearer ${getToken()}`,
    };

    const config = {
      url: `${process.env.REACT_APP_BASE_HTTP}/pedido/${id_pedido[currentTable]}/export?type=single`,
      method: "GET",
      responseType: "blob",
      headers,
    };
    const outputFilename = `${Date.now()}.csv`;
    axios(config)
      .then((res) => {
        var data = new Blob([res.data]);
        var csvURL = window.URL.createObjectURL(data);
        var tempLink;
        tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", outputFilename);
        tempLink.click();

        toast.success("Pedido exportado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao exportar pedido");
      });

    // setLoadingDowload(false);
  };

  return (
    <div className="container">
      {tabs.length > 1 ? (
        <div className="topo-vouchers">
          <div className="abas">
            {tabs.map((id, index) => (
              <h1
                key={id}
                className={currentTable === index ? "active" : undefined}
                onClick={() => setCurrentTable(index)}
              >
                <img src={img8} alt="" />
                PEDIDO {estoque_id[index]}
              </h1>
            ))}
          </div>
          <button onClick={() => dowload()}>
            <img src={download} alt="" />
            download do pedido
          </button>
        </div>
      ) : (
        <h1>
          <img src={img8} alt="" />
          Lista de Vouchers
        </h1>
      )}

      <TabelaPedidos columns={columns} rows={rows} />

      {totalPages > 1 && (
        <Pagination total={totalPages} currentPage={onPageChange} />
      )}
    </div>
  );
}

export { TabelaDetalhesPedido };
