import { Container } from "./styles";

export function Toggle(props) {
  const { active, onClick } = props;
  return (
    <Container>
      <div
        className={`${active === true ? "active" : ""} toggle`}
        onClick={onClick}
      >
        {active ? <p>Ativo</p> : <p>Inativo</p>}
        <span></span>
      </div>
    </Container>
  );
}
