/**
 * Types
 */
export const Types = {
    TOGGLE_NOTIFICACAO: "TOGGLE_NOTIFICACAO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    success: false,
    mensagem: "",
    tipoNotificacao: "",
};

export default function Acoes(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.TOGGLE_NOTIFICACAO:
            return {
                ...state,
                success: action.payload.success,
                mensagem: action.payload.mensagem,
                tipoNotificacao: action.payload.tipo,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    toggleNotificacao: (mensagem = "", success = false, tipo = "sucesso") => {
        return {
            type: Types.TOGGLE_NOTIFICACAO,
            payload: {
                mensagem,
                success,
                tipo,
            },
        };
    },
};
