import styled from "styled-components";
import { ResumoUpload } from "../../components/formsNovaAcao/styles";
import { Container as Select } from "../../components/select/styles";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Loader = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const Conteudo = styled.div`
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const Editar = styled.div`
  min-height: 100vh;
  height: 100%;
  background: ${(props) => props.theme.colors.Cinza06};
  .topoDados {
    padding: 0 8vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    background: ${(props) => props.theme.colors.branco};
    height: 100px;
    margin-bottom: 10px;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.Cinza02};
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: ${(props) => props.theme.colors.Cinza01};
      margin-top: 5px;
    }
    .btns {
      display: flex;
      grid-gap: 10px;
      a {
        background-color: ${(props) => props.theme.colors.Vinho1};
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .container {
    max-width: 1608px;
    width: auto;
  }
`;

export const Nova = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 205px 1fr;
    grid-gap: 150px;
    padding-left: 150px;
    transition: all 0.5s;
    &.active {
      filter: blur(4px);
      pointer-events: none;
      opacity: 0.6;
      transition: all 0.5s;
    }
    .s-esq {
      img {
        margin-bottom: 40px;
      }
    }
    .s-dir {
      ${Select} {
        background: ${(props) => props.theme.colors.Cinza06};
        border-radius: 4px;
      }

      form {
        &.disparo {
          display: flex;
          flex-direction: column;
          .container-form {
            order: 1;
            margin-bottom: 0px;
          }

          button {
            order: 3;
          }

          .voltar {
            order: 3;
          }

          ${ResumoUpload} {
            order: 2;
            margin-top: 7px;
            margin-bottom: 36px;
          }
        }
      }
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;

export const DadosAcao = styled.div`
  background-color: rgba(212, 212, 212, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  .item {
    position: relative;
    .hidden {
      display: none;
    }
    &:hover {
      .toolTip {
        bottom: 30px;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }
  .toolTip {
    border-radius: 5px;
    padding: 25px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
    color: #fff;
    font-size: 12px;
    pointer-events: none;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 20px;
  }
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza07};
  }
  p {
    font-weight: 800;
    font-size: 14px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza01};
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }
`;

export const Resumo = styled.div`
  .container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    .detalhes {
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-bottom: 30px;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza07};
      }
      p {
        font-weight: 800;
        font-size: 18px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-top: 5px;
      }
      .item-detalhe {
        position: relative;
        padding-left: 30px;
        height: 72px;
        &::before {
          content: "";
          background-color: ${(props) => props.theme.colors.Laranja1};
          width: 9px;
          height: 9px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          top: 4px;
          left: 0;
        }
        &:not(:last-of-type) {
          &::after {
            content: "";
            background-color: ${(props) => props.theme.colors.Cinza04};
            display: block;
            height: 46px;
            width: 1px;
            position: absolute;
            top: 20px;
            left: 3px;
          }
        }
      }
      button {
        margin-left: 30px;
        margin-top: 30px;
      }
    }
    .preview {
      margin: 0;
    }
  }
`;

export const ContainerFinal = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 205px 1fr;
    grid-gap: 150px;
    padding-left: 150px;
    .detalhes {
      width: 383px;
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: ${(props) => props.theme.colors.Vinho1};
        margin-bottom: 20px;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.Cinza02};
        margin-bottom: 40px;
      }
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-decoration-line: underline;
        color: ${(props) => props.theme.colors.Vinho1};
        margin-bottom: 40px;
        display: block;
      }
    }
  }
`;
