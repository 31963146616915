import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Creators as ContratantesCreators } from "../../store/ducks/contratante";

import { filtroContratante } from "../../utils/helper";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/filtro/index";
import Topo from "../../components/topo";
import TabelaContratantes from "../../components/tabelas";

import { Container, Conteudo } from "./styles";

function Contratantes() {
  const [active, toggleFiltro] = useState(false);
  const dispatch = useDispatch();
  var { data, busca } = useSelector((state) => state.contratante);

  data = filtroContratante(data, busca);

  useEffect(() => {
    dispatch(ContratantesCreators.show());
  }, [dispatch]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nome do Contratante",
        accessor: "nome",
      },
      {
        Header: "Área",
        accessor: "area",
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
      },
      {
        Header: "Contato",
        accessor: "contato",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telefones",
        accessor: "telefone",
      },
    ],
    []
  );

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Contratantes"
          linkCadastro="/contratantes/novo"
        />

        <Filtro activeFiltro={active} />

        <div className="conteudo">
          <TabelaContratantes columns={columns} data={data} />
        </div>
      </Conteudo>
    </Container>
  );
}

export default Contratantes;
