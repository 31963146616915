import React, { useCallback, useEffect, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import InputMask from "react-input-mask";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import browserHistory from "../../config/browserHistory";
import { api } from "../../services/api";
import {
  getContratante,
  getAreasUsuario,
  getRoles,
  formatCelular,
} from "../../utils/helper";
import { FormInitialState, validadtionUserDados } from "../../utils/configForm";

import { Creators as userCreators } from "../../store/ducks/user";

import Button from "../../components/button";
import SelectCustom from "../../components/SelectCustom";
import NovoSelect from "../../components/NovoSelect";
import Topo from "../../components/topo/cadastro";

import { Novo } from "./styles";

function NovoContratante() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [contratante, setContratante] = useState([]);
  const [areas, setAreas] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const {
    location: { id: idUser },
  } = history;
  const metricas = ["Fraca", "Fraca", "Certo", "Bom", "Forte"];

  const load = useSelector((state) => state.user.loading);

  const getOptionRole = useCallback(async () => {
    const perfis = await getRoles();
    setPerfis(perfis);
  }, []);

  const getUser = useCallback(
    async (id) => {
      const { data } = await api.get(`usuario/${idUser}`);
      console.log(data);
    },
    [idUser]
  );

  const getItem = useCallback(async () => {
    const contratante = await getContratante();
    setContratante(contratante);
  }, []);

  async function buscaAreas(id) {
    const options = await getAreasUsuario(id);
    setAreas(options);
  }

  // useEffect(() => {
  //   if (id) {
  //     getContratante(id);
  //   }
  // }, [id]);

  useEffect(() => {
    getItem();
    getOptionRole();
  }, [getItem, getOptionRole]);

  useEffect(() => {
    if (idUser) {
      getUser();
    }
  }, [idUser, getUser]);

  return (
    <Novo>
      <Topo />

      <div className="container">
        <h1>Dados do usuário</h1>

        <Formik
          enableReinitialize
          initialValues={FormInitialState.userDados}
          validationSchema={validadtionUserDados}
          onSubmit={(value) => {
            const { phone_number, ...rest } = value;

            const phone_format =
              "55" +
              formatCelular(phone_number + "")
                .replace("(", "")
                .replace(")", "")
                .replace(" ", "")
                .replace("-", "");

            dispatch(
              userCreators.store(
                { phone_number: phone_format, ...rest },
                history
              )
            );
          }}
        >
          {(props) => {
            const { handleChange, values, setFieldValue } = props;
            const { password, phone_number, cliente_id } = values;

            return (
              <Form>
                <div className="container-form-double">
                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="Nome"
                      name="name"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="name" />
                  </div>

                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="email" />
                  </div>
                </div>
                <div className="container-form">
                  <InputMask
                    type="text"
                    placeholder="Celular"
                    name="phone_number"
                    mask="(99) 99999-9999"
                    value={phone_number}
                    onChange={handleChange}
                  />
                  <ErrorMessage component="span" name="phone_number" />
                </div>

                <div className="container-form-double">
                  <div className="container-form">
                    <Field
                      type="password"
                      placeholder="Senha"
                      name="password"
                      autoComplete="new-password"
                    />
                    <PasswordStrengthBar
                      onChange={handleChange}
                      password={password}
                      scoreWords={metricas}
                      shortScoreWord="Muito curta"
                      type="password"
                      placeholder="Senha"
                      name="password"
                      autoComplete="new-password"
                    />
                    <ErrorMessage component="span" name="senha" />
                  </div>

                  <div className="container-form">
                    <Field
                      type="password"
                      placeholder="Confirmar Senha"
                      name="password_confirmation"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      component="span"
                      name="password_confirmation"
                    />
                  </div>
                </div>

                <div className="container-form select">
                  <NovoSelect
                    label="Perfil"
                    options={perfis}
                    onChange={(value) => {
                      setFieldValue("role", value.label);
                    }}
                  />
                </div>

                <div className="container-form-double">
                  <div className="container-form">
                    <NovoSelect
                      label="Cliente"
                      initialValue=""
                      options={contratante}
                      onChange={({ value, label }) => {
                        setFieldValue("cliente_id", label.toLocaleLowerCase());
                        buscaAreas(value);
                      }}
                    />
                  </div>

                  <div className="container-form">
                    {cliente_id && (
                      <SelectCustom
                        label="Áreas"
                        options={areas}
                        change={(value) => setFieldValue("areas", value)}
                      />
                    )}
                  </div>
                </div>
                <div className="btns">
                  <Button
                    title="Cancelar"
                    bgc={"#E25B5B"}
                    onClick={() => browserHistory.back()}
                  />
                  <Button
                    title="Cadastrar"
                    className={load ? "disabled" : ""}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Novo>
  );
}

export default NovoContratante;
