import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: 14px;
  width: fit-content;
  img {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 20px;
  }
`;
