import React from "react";

import { Container } from "./styles";

import img from "../../assets/img/sistema/gerencia.svg";

function BoxPerfil(props) {
  return (
    <Container
      to={{ pathname: `/usuarios/gerenciar/${props.id}`, id: props.id }}
    >
      <h4>{props.name}</h4>
      <span>
        <img src={img} alt="" />
        Gerenciar
      </span>
    </Container>
  );
}

export default BoxPerfil;
