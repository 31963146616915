import React, { useState, useCallback, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Creators as PedidosCreators } from "../../store/ducks/pedidos";

import { Permissions } from "../../components/Permission";
import Loading from "../../components/Loading";

import { api } from "../../services/api";
import { filtroPedidos } from "../../utils/helper";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
// import TabelaPedidos from "../../components/tabelas/pedidosVoucher";
import TabelaPedidos, { Pagination } from "../../components/tabelaMui";
import { Container, Conteudo } from "../pedidosVoucher/styles";

import exportar from "../../assets/img/sistema/link.svg";
import detalhes from "../../assets/img/sistema/editar.svg";

function DownloadPedido() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [active, toggleFiltro] = useState(false);

  const { data, paginacao, loading } = useSelector((state) => state.pedidos);

  const EnviarPedidoCliente = useCallback(async (id) => {
    try {
      await api.post(`/pedido/send`, { pedido_id: parseInt(id) });
      toast.success("Pedido enviado com sucesso!");
    } catch (error) {
      toast.error("Erro ao enviar pedido");
    }
  }, []);

  let dadosFiltrado = filtroPedidos(data);

  const columns = React.useMemo(
    () => [
      {
        headerName: "Status",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className={`status ${row.status.toLowerCase()}`}>
              {row.status}
            </div>
          );
        },
      },
      {
        headerName: "ID",
        field: "id",
        width: 150,
      },
      {
        headerName: "Job",
        field: "job",
        width: 150,
      },
      {
        headerName: "Cliente",
        field: "cliente",
        width: 200,
      },
      {
        headerName: "Título do pedido",
        field: "titulo",
        width: 250,
      },
      {
        headerName: "Qtd. vouchers",
        field: "qtd",
        width: 150,
      },
      {
        headerName: "Data",
        field: "data",
        width: 200,
      },
      {
        headerName: "",
        field: "exportar",
        renderCell: ({ row }) => {
          return (
            <button
              onClick={() => EnviarPedidoCliente(row.id)}
              className="exportar"
            >
              <img src={exportar} alt="" />
              <span>Exportar</span>
            </button>
          );
        },
      },
      {
        headerName: "",
        field: "editar",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["pedido.show"]}>
              <button
                onClick={() =>
                  history.push({
                    pathname: `/pedidos/detalhe/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
              >
                <img src={detalhes} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
    ],
    [EnviarPedidoCliente, history]
  );

  function handlePagination(id) {
    dispatch(PedidosCreators.paginacao_download({ id }));
  }

  useEffect(() => {
    dispatch(PedidosCreators.show_download());
  }, [dispatch]);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Downloads Pedidos"
          linkCadastro="/pedidos/novo"
          permission={["pedido.store"]}
        />

        <Filtro activeFiltro={active} />

        {loading ? (
          <div className="container-loading">
            <Loading width={500} height={500} />
          </div>
        ) : (
          <div className="conteudo">
            <TabelaPedidos columns={columns} rows={dadosFiltrado} />
            <Pagination
              total={paginacao?.data?.last_page}
              currentPage={(page) => {
                handlePagination(page);
              }}
              page={paginacao?.data?.current_page}
            />
          </div>
        )}
      </Conteudo>
    </Container>
  );
}

export default memo(DownloadPedido);
