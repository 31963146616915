import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/x-date-pickers";
import { ptBR as coreptBr } from "@mui/material/locale";
import ptBRLocale from "date-fns/locale/pt-BR";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

export function DateTime(props) {
  const { onChange, initialValue, label } = props;

  //   const [value, setValue] = (useState < Date) | (null > null);
  const [value, setValue] = useState(null);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#0191CD" },
      },
    },
    ptBR, // x-date-pickers translations
    coreptBr // core translations
  );

  useEffect(() => {
    if (initialValue) {
      setValue(new Date(initialValue));
    }
  }, [initialValue]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ptBRLocale}
      >
        <MobileDateTimePicker
          toolbarTitle="SELECIONE DATA E HORA"
          defaultCalendarMonth={new Date()}
          ampm={false}
          value={value}
          onOpen={() => {
            setValue(new Date());
          }}
          onChange={(newValue) => {
            onChange(newValue);
            if (newValue) {
              setValue(newValue);
            }
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box className="container-input-system calendario">
              <input ref={inputRef} {...inputProps} placeholder={label} />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
