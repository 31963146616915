import styled from "styled-components";

export const CardStyles = styled.div`
  background: #eff4f8;
  border-radius: 8px;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #0097d5;
    margin-top: 17px;
  }
  .container-img {
    background: #0097d5;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
