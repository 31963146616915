import React from "react";
import Loading from "../../components/Loading";
import { Container } from "./styles";

export default function Button(props) {
    const {
        title = "Acessar",
        seta = false,
        type = "submit",
        loading,
        onClick = () => {},
        bgc = "",
        color = "",
        disabled = false,
        className = "",
        transparent = false,
    } = props;
    return (
        <Container
            type={type}
            onClick={() => onClick()}
            bgc={bgc}
            color={color}
            disabled={disabled}
            className={className}
            transparent={transparent}
        >
            {loading ? <Loading width={100} height={100} item={1} /> : title}
            {seta && <div className="img"></div>}
        </Container>
    );
}
