import styles from "./newHeader.module.css";
import LogoYetzLink from "../../assets/img/general/LogoYetzLink.svg";

const NewHeader = () => {
  return (
    <div className={styles.generalContainer}>
        <div className={styles.insiderContainer}>
            <img src={LogoYetzLink} alt="Logo Yetz Link" />
        </div>
    </div>
  );
}

export default NewHeader;