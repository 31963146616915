import { ModalStyles } from "./styles";
import imgXVerde from "../../../assets/img/sistema/x.svg";
import imgCadastroCliente from "../../../assets/img/sistema/sucess-cadastro-cliente.svg";
import Button from "../../button";
import { useContex } from "./Hook";

export function ModalAvisoExclusao() {
    const { setModalAviso, cliemtesEnUtilizacao } = useContex();

    return (
        <ModalStyles>
            <div className="container-header">
                <p>ATENÇÃO</p>
                <button onClick={() => setModalAviso(false)}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>

            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />
                <h3>Está área está sendo utilizada</h3>

                {cliemtesEnUtilizacao.map((item, key) => (
                    <p key={key}>{item.area.label}</p>
                ))}

                <div width="234px">
                    <Button
                        title="Entendi"
                        onClick={() => {
                            setModalAviso(false);
                        }}
                    />
                </div>
            </div>
        </ModalStyles>
    );
}
