import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";

import { formatDateIso } from "../../utils/helper";

import { CardVoucher } from "./cardVoucher";
import ResumoUpload from "./resumoUpload";
import { Whats, Sms, Email } from "../preview";
import Button from "../button";

// import { formatCurrentMoney } from "../../utils/helper";
import { DadosAcao } from "./styles";

export function ConfirmeVoucher(props) {
  const { change, id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { data_voucher, data, preview, acao_id } = useSelector(
    (state) => state.acao
  );

  const { voucher } = data_voucher;
  const { resumo } = data;

  const tamanhoString = 15;

  if (!preview) return <></>;

  const {
    contratante = "",
    nome = "",
    acao_tipo = "",
    tipo_disparo_name = "",
    template = "",
    data_disparo = "",
    job = "",
  } = preview;

  //   const { valor_reais, nome: nome_voucher } = voucher;

  //   const valor = nome_voucher + formatCurrentMoney(parseFloat(valor_reais)) + "";

  const isUrl =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-._]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  return (
    <>
      <DadosAcao>
        <div className="container-resumo">
          <div className="titulo">
            <h2>Resumo da Ação</h2>
            <Button
              title="Concluir"
              onClick={() => {
                dispatch(
                  AcaoCreators.pedido(
                    id ? id : acao_id ? acao_id : resumo.acao_id,
                    voucher.id,
                    history
                  )
                );
              }}
            />
          </div>

          <h5>Dados da Ação</h5>
          <div className="row">
            <span className="active" data-indice="1">
              Cliente
              <small>{contratante}</small>
              {contratante.length >= tamanhoString ? (
                <p className="toolTip">{contratante}</p>
              ) : null}
            </span>
            <span className="active" data-indice="2">
              Nome da Ação
              <small>{nome}</small>
              {nome.length >= tamanhoString ? (
                <p className="toolTip">{nome}</p>
              ) : null}
            </span>
          </div>
          <div className="row">
            {job ? (
              <span className="active" data-indice="2">
                Job
                <small>{job}</small>
                {job.length >= tamanhoString ? (
                  <p className="toolTip">{job}</p>
                ) : null}
              </span>
            ) : null}

            <span className="active" data-indice="3">
              Tipo de Ação
              <small>{acao_tipo}</small>
              {acao_tipo.length >= tamanhoString ? (
                <p className="toolTip">{acao_tipo}</p>
              ) : null}
            </span>
          </div>
          <div className="row">
            <span className="active" data-indice="2">
              Data/hora de início
              <small>{formatDateIso(data.inicio)}</small>
              <p className="toolTip">{data.inicio}</p>
            </span>

            <span className="active" data-indice="3">
              Data/hora de fim
              <small>{formatDateIso(data.fim)}</small>
              <p className="toolTip">{data.fim}</p>
            </span>
          </div>

          <div className="row">
            <span className="active" data-indice="5">
              Template
              <small>{template}</small>
              {template.length >= tamanhoString ? (
                <p className="toolTip">{template}</p>
              ) : null}
            </span>
            <span className="active" data-indice="6">
              Tipo de Disparo
              <small>{tipo_disparo_name}</small>
              {tipo_disparo_name.length >= tamanhoString ? (
                <p className="toolTip">{tipo_disparo_name}</p>
              ) : null}
            </span>
          </div>
          {data_disparo && tipo_disparo_name === "Disparo Agendado" ? (
            <span className="active" data-indice="7">
              Data e hora do disparo
              <small>{data_disparo}</small>
            </span>
          ) : null}
        </div>

        <div className="container-preview">
          <div className="content-preview">
            <div className="container-text">
              <h5>Preview</h5>
            </div>
            {data.toggle_acao === 1 ? (
              <Sms text={data.preview_tema[0].text} />
            ) : null}
            {data.toggle_acao === 2 ? (
              data.preview_img ? (
                <Whats
                  img={
                    isUrl.test(data.preview_img)
                      ? data.preview_img
                      : URL.createObjectURL(data.preview_img)
                  }
                  text={data.preview_tema[0].text}
                />
              ) : (
                <Whats text={data.preview_tema[0].text} />
              )
            ) : null}
            {data.toggle_acao === 3 ? (
              data.preview_tema[0].html_content ? (
                <Email text={data.preview_tema[0].html_content} />
              ) : null
            ) : null}
          </div>
          <div className="resumo">
            <h5>Participantes</h5>
            <span>{data.nome_arquivo}</span>
            {resumo && <ResumoUpload {...resumo} />}
          </div>
          <div className="container-cards">
            <h5>Vouchers</h5>
            {voucher ? (
              <CardVoucher
                img={voucher.foto_capa}
                nome={voucher.nome}
                valor_reais={voucher.valor_reais}
                qtd={voucher.total_disponivel}
              />
            ) : null}
          </div>

          <div className="btns">
            <Button
              type="button"
              title="Cancelar"
              onClick={() => change("voucher")}
              bgc={"red"}
              transparent={true}
            />
            <Button
              title="Concluir"
              onClick={() => {
                dispatch(
                  AcaoCreators.pedido(
                    id ? id : acao_id ? acao_id : resumo.acao_id,
                    voucher.id,
                    history
                  )
                );
              }}
            />
          </div>
        </div>
      </DadosAcao>
    </>
  );
}
