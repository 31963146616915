/**
 * Types
 */
export const Types = {
  SHOW_PEDIDOS: "SHOW_PEDIDOS",
  PEDIDOS_SUCCESS: "PEDIDOS_SUCCESS",
  PEDIDOS_FAILUIRE: "PEDIDOS_FAILUIRE",
  TOGGLE_NOTIFICACAO: "TOGGLE_NOTIFICACAO",

  DATA_PEDIDOS: "DATA_PEDIDOS",
  BUSCA_PEDIDOS: "BUSCA_PEDIDOS",
  STATUS_PEDIDOS: "STATUS_PEDIDOS",
  TIPO_PEDIDOS: "TIPO_PEDIDOS",
  DATE_PEDIDOS: "DATE_PEDIDOS",

  REQUEST_PAGINACAO: "REQUEST_PAGINACAO",

  SHOW_DOWNLOAD_PEDIDOS: "SHOW_DOWNLOAD_PEDIDOS",
  REQUEST_DOWNLOAD_PAGINACAO: "REQUEST_DOWNLOAD_PAGINACAO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  loading: false,
  error: false,
  data: [],
  paginacao: {
    current_page: 1,
    last_page: null,
    first_page_url: "",
    from: null,
    last_page_url: "",
    next_page_url: "",
    path: "",
    per_page: null,
    prev_page_url: null,
    to: null,
    total: null,
  },
  busca: "",
  status: "",
  tipo: "",
  date: "",
};

export default function Pedidos(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.PEDIDOS_SUCCESS:
      return { ...state, loading: false, error: false };

    case Types.PEDIDOS_FAILUIRE:
      return { ...state, loading: false, error: true };

    case Types.DATA_PEDIDOS:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...action.payload.data],
        paginacao: { ...action.payload.paginacao },
      };

    case Types.SHOW_PEDIDOS:
      return { ...state, loading: true };

    case Types.BUSCA_PEDIDOS:
      return { ...state, busca: action.payload.str };

    case Types.STATUS_PEDIDOS:
      return { ...state, status: action.payload.str };

    case Types.TIPO_PEDIDOS:
      return { ...state, tipo: action.payload.str };

    case Types.DATE_PEDIDOS:
      return { ...state, date: action.payload.str };

    case Types.REQUEST_PAGINACAO:
      return { ...state, loading: true, error: false };

    case Types.SHOW_DOWNLOAD_PEDIDOS:
      return { ...state, loading: true };

    case Types.REQUEST_DOWNLOAD_PAGINACAO:
      return { ...state, loading: true, error: false };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  show: () => {
    return {
      type: Types.SHOW_PEDIDOS,
    };
  },

  busca: (str) => {
    return {
      type: Types.BUSCA_PEDIDOS,
      payload: {
        str,
      },
    };
  },
  status: (str) => {
    return {
      type: Types.STATUS_PEDIDOS,
      payload: {
        str,
      },
    };
  },
  tipo: (str) => {
    return {
      type: Types.TIPO_PEDIDOS,
      payload: {
        str,
      },
    };
  },
  date: (str) => {
    return {
      type: Types.DATE_PEDIDOS,
      payload: {
        str,
      },
    };
  },

  paginacao: (value) => {
    return {
      type: Types.REQUEST_PAGINACAO,
      payload: {
        ...value,
      },
    };
  },

  show_download: () => {
    return {
      type: Types.SHOW_DOWNLOAD_PEDIDOS,
    };
  },
  paginacao_download: (value) => {
    return {
      type: Types.REQUEST_DOWNLOAD_PAGINACAO,
      payload: {
        ...value,
      },
    };
  },
};
