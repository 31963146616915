import * as Yup from "yup";
import { RegExp } from "./helper";

export const FormInitialState = {
  login: {
    cpf: "",
    password: "",
  },

  recuperarSenha: {
    password: "",
    password_confirmation: "",
  },

  esqueciSenha: {
    email: "",
  },

  userDados: {
    name: "",
    email: "",
    phone_number: "",
    role: "",
    contratantes: [],
    cliente_id: "",
    areas: [],
    password: "",
    password_confirmation: "",
  },

  contratante: {
    nome: "",
    email: "",
    cnpj: "",
    area: "",
    telefone: "",
    celular: "",
    contato: "",
  },

  acoes: {
    dados: {
      cliente_id: "",
      cliente_area_contato_id: "",
      nome: "",
      acao_tipo_id: "",
      acao_voucher_tipo_id: "",
      template_id: "",
      template_arte: "",
      job: "",
      contratante_nome: "",
      contratante_email: "",
      inicio: "",
      fim: "",
    },

    tipo: {
      tipo_disparo: "",
      data_disparo: "",
    },

    participantes: {
      recipientes: "",
    },

    userDados: {
      name: "",
      email: "",
      phone_number: "",
      role: "",
      contratantes: [],
      password: "",
      password_confirmation: "",
    },

    contratante: {
      nome: "",
      email: "",
      cnpj: "",
      area: "",
      telefone: "",
      celular: "",
      contato: "",
    },

    acoes: {
      dados: {
        cliente_id: "",
        nome: "",
        acao_tipo_id: "",
        template_id: "",
        template_arte: "",
        entrega_voucher: 0,
      },

      tipo: {
        tipo_disparo: "",
        data_disparo: "",
      },

      participantes: {
        recipientes: "",
      },
    },
  },

  pedidos: {
    dados: {
      cliente_id: "",
      entrega_codigo: "",
      titulo: "",
      cliente_area_contato_id: "",
      contratante_nome: "",
      contratante_email: "",
      job: "",
      is_outlet: "",
    },
  },

  acao_copy: {
    acao_id: "",
    base: "",
    acao_tipo_id: "",
    template_id: "",
    recipientes: "",
    template_arte: "",
  },
};

export const validationLogin = Yup.object().shape({
  cpf: Yup.string()
  .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, "CPF inválido")
  .required("Campo Obrigatório"),
  password: Yup.string().required("Campo Obrigatório"),
});

export const validationEsqueciSenha = Yup.object().shape({
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
});

export const validadtionContratante = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
  cnpj: Yup.string()
    .matches(RegExp.cnpj, "O número de CNPJ não é válido")
    .required("CNPJ e um campo obrigatório"),
  area: Yup.string(),
  telefone: Yup.string().required("Número de Telefone é obrigatorio"),
  celular: Yup.string().required("Número de celular é obrigatorio"),
  contato: Yup.string().required("Campo Obrigatório"),
});

export const validationAcoesDados = Yup.object().shape({
  cliente_id: Yup.number().required("Campo Obrigatório"),
  cliente_area_contato_id: Yup.number().required("Campo Obrigatório"),
  nome: Yup.string().required("Campo Obrigatório"),
  acao_tipo_id: Yup.number().required("Campo Obrigatório"),
  template_id: Yup.number().required("Campo Obrigatório"),
  template_arte: Yup.string(),
  entrega_voucher: Yup.number(),
  acao_voucher_tipo_id: Yup.string(),
  inicio: Yup.string(),
  fim: Yup.string(),

  contratante_nome: Yup.string(),
  contratante_email: Yup.string().email("Insira um E-mail válido"),
});

export const validadeContratanteDados = Yup.object().shape({
  contratante_nome: Yup.string().required("Campo Obrigatório"),
  contratante_email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
});

export const validationAcoesDadosImg = Yup.object().shape({
  cliente_id: Yup.number().required("Campo Obrigatório"),
  cliente_area_contato_id: Yup.number().required("Campo Obrigatório"),
  nome: Yup.string().required("Campo Obrigatório"),
  acao_tipo_id: Yup.number().required("Campo Obrigatório"),
  template_id: Yup.number().required("Campo Obrigatório"),
  template_arte: Yup.string().required("Campo Obrigatório"),
});

export const validationDisparoDados = Yup.object().shape({
  tipo_disparo: Yup.number().required("Campo Obrigatório"),
  data_disparo: Yup.string(),
});

export const validationFile = Yup.object().shape({
  recipientes: Yup.mixed().required("Campo Obrigatório"),
});

export const validadtionUserDados = Yup.object().shape({
  name: Yup.string().required("Campo Obrigatório"),
  phone_number: Yup.string()
    .matches(RegExp.celular, "O número de celular não é válido")
    .required("Celular e um campo obrigatório"),
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
  role: Yup.string().required("Campo Obrigatório"),
  contratantes: Yup.array().of(Yup.number().required()),
  password: Yup.string().required("Campo Obrigatório"),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Senha Não Confere"
  ),
  cliente_id: Yup.string().required("Campo Obrigatório"),
  areas: Yup.array().of(Yup.number().required()),
});

export const validadtionUserDadosEdit = Yup.object().shape({
  name: Yup.string().required("Campo Obrigatório"),
  phone_number: Yup.string()
    // .matches(RegExp.celular, "O número de celular não é válido")
    .required("Celular e um campo obrigatório"),
  email: Yup.string()
    .email("Insira um E-mail válido")
    .required("E-mail e um campo obrigatório"),
  role: Yup.string(),
  contratantes: Yup.array().of(Yup.number().required()),
  cliente_id: Yup.string(),
  areas: Yup.array().of(Yup.number().required()),
});

export const validadtionRecuperarSenha = Yup.object().shape({
  password: Yup.string()
    .required("Campo Obrigatório")
    .min(6, "Mínimo 6 digitos"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Senha Não Confere")
    .required("Campo Obrigatório")
    .min(6, "Mínimo 6 digitos"),
});

export const validationPedidosDados = Yup.object().shape({
  cliente_id: Yup.number().required("Campo Obrigatório"),
  entrega_codigo: Yup.number().required("Campo Obrigatório"),
  cliente_area_contato_id: Yup.number().required("Campo Obrigatório"),
  titulo: Yup.string().required("Campo Obrigatório"),
  contratante_nome: Yup.string(),
  contratante_email: Yup.string().email("Insira um E-mail válido"),
  job: Yup.string(),
  is_outlet: Yup.number().required("Campo Obrigatório"),
});

export const validationAcaoCopy = Yup.object().shape({
  base: Yup.number().required("Campo Obrigatório"),
  acao_tipo_id: Yup.number().required("Campo Obrigatório"),
  template_id: Yup.number().required("Campo Obrigatório"),
  template_arte: Yup.string(),
});
