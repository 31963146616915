import { useState, memo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "../../button";
import { useContex } from "./Hook";
import imgX from "../../../assets/img/sistema/x-vermelho.svg";

function Campos(props) {
    const {
        areas,
        setAreas,
        contatos,
        setCliemtesEnUtilizacao,
        setModalAviso,
    } = useContex();

    const [tempArea, setTempArea] = useState([]);
    const ref = useRef(null);
    const uniqueValuesSet = new Set();

    function handleAddArea() {
        if (ref.current?.value) {
            setAreas([...areas, { value: uuidv4(), label: ref.current.value }]);

            ref.current.value = "";
        }
    }

    function handleRemoveArea(id) {
        const updatedAreas = areas.filter((a) => a.value !== id);

        const hasValues = contatos.filter(
            (contato) => id === contato.area.value
        );

        if (hasValues.length === 0) {
            setAreas(updatedAreas);
            setTempArea(updatedAreas);
        } else {
            setCliemtesEnUtilizacao(hasValues);
            setModalAviso(true);
        }
    }

    function handleChange(newValue, oldValue) {
        const updateArea = areas.filter((area) => {
            if (area.label === oldValue) {
                area.label = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempArea].filter((obj) => {
            // check if name property value is already in the set
            const isPresentInSet = uniqueValuesSet.has(obj.value);

            // add name property value to Set
            uniqueValuesSet.add(obj.value);

            // return the negated value of
            // isPresentInSet variable
            return !isPresentInSet;
        });

        setAreas(filteredArr);
        setTempArea(filteredArr);
    }

    return (
        <>
            <div className="container-form areas">
                <input type="text" placeholder="NOME DA ÁREA" ref={ref} />
                <Button onClick={handleAddArea} title="ok" type="button" />
            </div>

            <span fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </span>
            <div className="itens-areas">
                {areas?.map((area) => {
                    return (
                        <div key={area.value} className="container-form area">
                            <input
                                type="text"
                                onChange={(e) => {
                                    handleChange(e.target.value, area.label);
                                }}
                                value={area.label}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveArea(area.value)}
                            >
                                <img src={imgX} alt="" />
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
