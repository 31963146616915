import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Creators as EsqueciSenhaCreators } from "../../store/ducks/esqueciSenha";

const timerProps = {
    isPlaying: true,
    size: 130,
    strokeWidth: 6,
};

function RenderTime() {
    const [time, seTime] = useState(false);
    const [key, setKey] = useState(0);
    const dispatch = useDispatch();
    const {
        dados: { email },
    } = useSelector((state) => state.esqueciSenha);

    return (
        <div className="container-novo-sms">
            <CountdownCircleTimer
                {...timerProps}
                isPlaying
                duration={30}
                key={key}
                colors={[
                    ["#A30000", 0.33],
                    ["#004777", 0.33],
                    ["#F7B801", 0.33],
                ]}
            >
                {({ remainingTime }) => {
                    remainingTime === 0 && seTime(true);
                    return (
                        <div className="timer">
                            <div className="value">{remainingTime}</div>
                        </div>
                    );
                }}
            </CountdownCircleTimer>
            <button
                type="button"
                className={!time ? "active" : ""}
                onClick={() => {
                    dispatch(EsqueciSenhaCreators.requestNovoCodigo(email));
                    seTime(false);
                    setKey((prevKey) => prevKey + 1);
                }}
            >
                ENVIAR NOVO CÓDIGO
            </button>
        </div>
    );
}

export default RenderTime;
