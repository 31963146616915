import { put, call } from "redux-saga/effects";

import { Types as NotificacaoTypes } from "../ducks/notificacao";
import { Types as UserTypes } from "../ducks/user";

import { api } from "../../services/api";

export function* store({ payload }) {
    const { data, history } = payload;

    try {
        yield call(api.post, `usuario`, { ...data });

        yield put({
            type: UserTypes.SUCCESS_USER,
        });

        history.push({
            pathname: "/usuarios",
            user: true,
        });

        yield put({
            type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
            payload: {
                success: true,
                mensagem: "Sucesso",
                tipo: "sucesso",
            },
        });
    } catch (error) {
        yield put({
            type: UserTypes.FAILUIRE_USER,
        });

        // yield put({
        //     type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
        //     payload: {
        //         success: false,
        //         mensagem: "Sucesso",
        //         tipo: "sucesso",
        //     },
        // });
    }
}
