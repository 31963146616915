import React from "react";
import ConfirmaCode from "./confirmaCode";
import AlterarSenha from "./alterarSenha";
import Parabens from "./parabens";

import { Container } from "./styles";

function ModalSms() {
    return (
        <Container className="scroll-y">
            <ConfirmaCode />
            <AlterarSenha />
            <Parabens />
        </Container>
    );
}

export default ModalSms;
