import React, { useState, useEffect } from "react";

import { api } from "../../services/api";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/filtro/index";
import Topo from "../../components/topo";
import BoxPerfil from "../../components/boxPerfil";

import { Container, Conteudo } from "./styles";

function GerenciarPerfis() {
  const [active, toggleFiltro] = useState(false);
  const [perfis, setPerfis] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get("/role");
      setPerfis(data);
    }

    fetchData();
  }, []);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Gerenciar Perfis"
          linkCadastro="/usuarios/novo"
          permission={["usuario.store"]}
        />
        <Filtro activeFiltro={active} />

        <div className="conteudo">
          {perfis.map((perfil) => (
            <BoxPerfil key={perfil.id} {...perfil} />
          ))}
        </div>
      </Conteudo>
    </Container>
  );
}

export default GerenciarPerfis;
