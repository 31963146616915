import React from "react";
import { ResumoUpload as Container } from "./styles";
// import { Creators as ModalCreators } from "../../store/ducks/modal";

import link from "../../assets/img/sistema/link-cinza.svg";
import user from "../../assets/img/sistema/user-cinza.svg";
// import seta from "../../assets/img/sistema/seta.svg";

export default function ResumoUpload(props) {
    const { usuarios, links, erros } = props;

    return (
        <Container>
            <h4>Resumo do Upload</h4>
            <div className="usuarios">
                <img src={user} alt="" />
                <span>{usuarios} usuários</span>
            </div>
            <div className="link">
                <img src={link} alt="" />
                <span>{links} links para disparar</span>
            </div>
            <div className={`${erros === 0 ? " erro success" : "erro error"}`}>
                <span>{erros} erros</span>
            </div>
            {/* <span
                className="detalhes-arquivo"
                onClick={() =>
                    dispatch(ModalCreators.openModalDetalhesArquivo())
                }
            >
                Detalhes do arquivo
                <img src={seta} alt="" />
            </span> */}
        </Container>
    );
}
