import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Container } from "../select/styles";

function SelectCustom(props) {
    const { label, options, change = () => {} } = props;
    const animatedComponents = makeAnimated();

    function listId(arr) {
        return arr.map((arr) => arr.value);
    }

    return (
        <Container custon="custon">
            <Select
                placeholder={label}
                closeMenuOnSelect={false}
                components={animatedComponents}
                classNamePrefix="react-select"
                isMulti
                onChange={(data) => change(listId(data))}
                options={options}
            />
        </Container>
    );
}

export default SelectCustom;
