import React from "react";
import EnviadoIcone from "../../assets/img/sistema/enviado.svg";

function Enviado({ title }) {
    return (
        <p className="visualizado">
            <img src={EnviadoIcone} alt="" />
            {title}
        </p>
    );
}

export default Enviado;
