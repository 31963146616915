import React, { useState, useEffect } from "react";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { dataURLtoFile } from "../../utils/helper";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import inputImg from "../inputImg";
import { Whats } from "../preview";

export default function WhatsApp(props) {
    const dispatch = useDispatch();
    const { form } = props;
    const { values } = form;
    const { template_arte } = values;

    const { data } = useSelector((state) => state.acao);

    const { has_image, preview_tema = [], preview_img } = data;

    const [preview, setPreview] = useState(preview_img);

    useEffect(() => {
        if (template_arte) {
            const img = dataURLtoFile(template_arte);
            if (img) {
                const url = URL.createObjectURL(img);
                setPreview(url);
            }
        }

        if (template_arte === "") {
            setPreview(null);
        }
    }, [template_arte]);

    function removeFile() {
        setPreview(null);
        dispatch(AcaoCreators.createObject("nome_img", ""));
        form.setFieldValue("template_arte", "");
    }

    return (
        <>
            {has_image ? (
                <div className="container-form">
                    <Field
                        name="template_arte"
                        component={inputImg}
                        removeFile={removeFile}
                    />
                </div>
            ) : null}

            {preview_tema.length ? (
                <div className="preview">
                    <div className="container-text">
                        <h3>Preview</h3>
                    </div>
                    <Whats
                        img={preview ? preview : template_arte}
                        text={preview_tema[0].text}
                    />
                </div>
            ) : null}
        </>
    );
}
