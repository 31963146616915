/**
 * Types
 */
export const Types = {
    STORE: "STORE",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    SHOW: "SHOW",
    DATA: "DATA",
    BUSCA: "BUSCA",

    SUCCESS: "SUCCESS",
    FAILUIRE: "FAILUIRE",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    loading: false,
    error: false,

    data: [],
    busca: "",
};

export default function Contratante(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.DATA:
            return { ...state, data: [...action.payload.data] };

        case Types.STORE:
            return { ...state, loading: true, error: false };

        case Types.UPDATE:
            return { ...state, loading: true, error: false };

        case Types.DELETE:
            return { ...state, loading: true, error: false };

        case Types.SHOW:
            return { ...state, loading: true };

        case Types.BUSCA:
            return { ...state, busca: action.payload.str };

        case Types.SUCCESS:
            return { ...state, loading: false, error: false };

        case Types.FAILUIRE:
            return { ...state, loading: false, error: true };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    store: (data, history) => {
        return {
            type: Types.STORE,
            payload: { data, history },
        };
    },

    update: (data, history, id) => {
        return {
            type: Types.UPDATE,
            payload: { data, history, id },
        };
    },

    deleteContrante: (id) => {
        return {
            type: Types.DELETE,
            payload: {
                id,
            },
        };
    },

    show: () => {
        return {
            type: Types.SHOW,
        };
    },

    busca: (str) => {
        return {
            type: Types.BUSCA,
            payload: {
                str,
            },
        };
    },
};
