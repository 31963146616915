import React from "react";
import olho from "../../assets/img/sistema/olho.svg";

function visualizado({ title }) {
    return (
        <p className="visualizado">
            <img src={olho} alt="" />
            {title}
        </p>
    );
}

export default visualizado;
