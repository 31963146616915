import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import {
  formatPhoneNumber,
  formatCelular,
  formatCurrentMoney,
} from "../../utils/helper";

// import Table from "../TableDefault";
// import { Paginacao } from "../Paginacao";
import Tabela, { Pagination } from "../tabelaMui";
import Loading from "../Loading";
import StatusAcoes from "../StatusAcoes";
import { Permissions } from "../Permission";

import { Container } from "./styles";
import { api } from "../../services/api";

import disparar from "../../assets/img/sistema/disparar.svg";
import estornar from "../../assets/img/sistema/estornar.svg";

export function CopiaDetalheAcao(props) {
  const { tab, setTabIds, detalhe, configPaginacao, recipientes, categoria } =
    props;
  const [data, setData] = useState({});
  const [tabs, setTab] = useState([]);
  const [configPage, setConfigPage] = useState({});
  const [recipientesAtual, setRecipientesAtual] = useState(recipientes);

  const tabActive = tab.find((item) => item.active);
  const [loading, setLoading] = useState(false);

  const estornoIndividual = useCallback(async (id) => {
    try {
      await api.post(`recipiente/estornar`, {
        recipiente_id: id,
      });
      toast.success("Estornado com sucesso!");
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }, []);

  const disparoIndividual = useCallback(async (id) => {
    // async function disparoIndividual(id) {
    try {
      await api.post(`acao/fireonly`, {
        recipiente: id,
      });
      toast.success("Novo Disparo criado com sucesso!");
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }, []);

  const editaRecipiente = useCallback(
    async (id, campo, valor, row) => {
      const fields = recipientesAtual.find(
        (r) => r.id === row.id_recipiente
      ).custom_fields;
      const result = fields ? JSON.parse(fields) : {};
      try {
        await api.put(`recipiente/${id}`, {
          custom_fields: {
            ...result,
            nome: campo === "nome" ? valor : row.usuario,
            celular:
              campo === "celular"
                ? valor
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", "")
                : row.telefone,
            email: campo === "email" ? valor : row.email,
          },
        });
        toast.success("Editado com sucesso!");
      } catch (error) {
        toast.error("Algo deu errado");
      }
    },
    [recipientesAtual]
  );

  const confirmaEditRecipiente = useCallback(
    (id, campo, valor, row) => {
      confirmAlert({
        title: "Editar",
        message: "O valor será alterado, deseja confirmar?",
        buttons: [
          {
            label: "Sim",
            onClick: () => {
              editaRecipiente(id, campo, valor, row);
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
      });
    },
    [editaRecipiente]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: "Nome Completo",
        field: "usuario",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.usuario}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "nome", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Celular",
        field: "telefone",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <InputMask
                type="text"
                mask="(99) 99999-9999"
                defaultValue={row.telefone}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "celular", e.target.value, row)
                }
              />
            </div>
          );
        },
      },
      {
        headerName: "E-mail",
        field: "email",
        width: 300,
        renderCell: ({ row }) => {
          return (
            <div className="editaCampo">
              <input
                type="text"
                defaultValue={row.email}
                onBlur={(e) =>
                  confirmaEditRecipiente(row.id, "email", e.target.value, row)
                }
              ></input>
            </div>
          );
        },
      },
      {
        headerName: "Voucher",
        field: "voucher",
        width: 150,
      },
      {
        headerName: "Data e hora do envio",
        field: "recebimento",
        width: 150,
      },
      {
        headerName: "Status",
        field: "status",
        width: 150,
        renderCell: ({ row }) => {
          return (
            <div
              className={`${
                row.status_pt && "status " + row.status_pt.toLowerCase()
              }`}
            >
              {row.status_pt}
            </div>
          );
        },
      },
      {
        headerName: "Data e hora da visualização",
        field: "visualizacao",
        width: 150,
      },
      {
        headerName: "Status",
        field: "yetz_estoque_status",
        width: 150,
        renderCell: ({ row }) => {
          return (
            <div
              className={`${
                row.yetz_estoque_status &&
                "status " + row.yetz_estoque_status.toLowerCase()
              }`}
            >
              {row.yetz_estoque_status}
            </div>
          );
        },
      },
      {
        headerName: "Data e Hora",
        field: "data_yetz_estoque",
        width: 200,
      },
      {
        headerName: "",
        field: "disparar",
        width: 100,
        renderCell: ({ row }) => {
          return (
            <div className="disparo-estorno">
              {categoria !== "Comunicacao" && (
                <>
                  <Permissions role={["recipiente.reverse"]}>
                    <img
                      src={estornar}
                      alt=""
                      data-tip
                      data-for={`estorno${row.id}`}
                      onClick={() => estornoIndividual(row.id_recipiente)}
                    />
                  </Permissions>
                  <ReactTooltip
                    id={`estorno${row.id}`}
                    type="info"
                    place="left"
                  >
                    <span>Estornar</span>
                  </ReactTooltip>
                </>
              )}
              <Permissions role={["acao.fireOnlyAction"]}>
                <img
                  src={disparar}
                  alt=""
                  data-tip
                  data-for={`disparar${row.id}`}
                  onClick={() => disparoIndividual(row.id_recipiente)}
                />
              </Permissions>
              <ReactTooltip id={`disparar${row.id}`} type="info" place="left">
                <span>Disparar</span>
              </ReactTooltip>
            </div>
          );
        },
      },
    ],
    [disparoIndividual, confirmaEditRecipiente, estornoIndividual, categoria]
  );

  function setActiveTab(id) {
    const newTab = [
      ...tab.map((item) => {
        if (item.id === id) {
          item.active = true;
        } else {
          item.active = false;
        }

        return item;
      }),
    ];

    setTab(newTab);
  }

  const fetchData = useCallback(
    async (itemId, paginacao = "", key = undefined) => {
      setLoading(true);

      const { data: responseData } = await api.get(
        `acao/${itemId}${paginacao ? `?page=${paginacao}` : ""}`
      );

      if (!responseData) return { page: {} };

      const dados = responseData?.recipientes?.data.map((users) => {
        const {
          data_recebimento,
          data_visualizacao,
          status,
          custom_fields,
          id,
          status_pt,
        } = users;

        const { nome, email, celular } = JSON.parse(custom_fields);

        return {
          usuario: nome,
          telefone: formatCelular(celular + ""),
          email,
          recebimento: data_recebimento,
          status,
          visualizacao: data_visualizacao,
          yetz_estoque_status: users?.pedido_item?.status,
          data_yetz_estoque: users?.pedido_item?.status_updated_at,
          id,
          acao_status_id: data?.acao_status_id,
          id_recipiente: id,
          status_pt,
          voucher:
            users?.pedido_item?.produto_variacao_nome +
            " " +
            formatCurrentMoney(parseFloat(users?.pedido_item?.valor_reais)),
        };
      });

      const page = {
        ...responseData.recipientes,
      };

      const recipientesAtualizado = responseData?.recipientes?.data.map(
        (users) => {
          const { custom_fields, id } = users;
          return { id, custom_fields };
        }
      );

      setData({
        ...data,
        [`acao-${itemId}`]: [dados],
      });

      setLoading(false);
      setConfigPage(page);
      setRecipientesAtual(recipientesAtualizado);
    },
    [data]
  );

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      setData({
        ...data,
        [`acao-${tabActive.id}`]: [detalhe],
      });
    }
  }, [data, detalhe, tabActive]);

  useEffect(() => {
    setTab(tab);
  }, [tab]);

  useEffect(() => {
    if (configPaginacao.current_page) {
      setConfigPage(configPaginacao);
    }
  }, [configPaginacao]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <div className="container-tabs">
        {tabs.map((item, key) => (
          <div
            key={key}
            className={`tab ${item.active ? "active" : ""}`}
            onClick={async () => {
              if (!item.completed) {
                await fetchData(item.id);
                item.completed = true;
              }

              setActiveTab(item.id);
              setTabIds({
                acao_status_id: item.acao_status_id,
                id: item.id,
              });
            }}
          >
            <p>Ação {item.id}</p>
          </div>
        ))}
      </div>

      <div className="content">
        {data[`acao-${tabActive.id}`]?.map((item, key) => {
          const dados = item.map((item) => {
            return {
              ...item,
              status: <StatusAcoes value={item.status} />,
              telefone: formatPhoneNumber(item.telefone + ""),
              recebimento: item.recebimento,
              visualizacao: item.visualizacao,
            };
          });

          return (
            <div
              key={tabActive?.id}
              className={`container-item ${tabActive?.active ? "active" : ""}`}
            >
              <Tabela columns={columns} rows={dados} />
              {configPage?.last_page > 1 ? (
                <Pagination
                  total={configPage?.last_page}
                  currentPage={(page) => {
                    fetchData(
                      tabs.filter((item) => item.active)[0].id,
                      page,
                      tabs.findIndex((item) => item.active)
                    );
                  }}
                  page={configPage?.current_page}
                />
              ) : null}

              {/* <Table columns={columns} data={dados} recipientes={recipientes} />

              <Paginacao
                page={configPage?.current_page}
                nextPage={async () =>
                  fetchData(
                    tabs.filter((item) => item.active)[0].id,
                    nextPage,
                    tabs.findIndex((item) => item.active)
                  )
                }
                previousPage={async () =>
                  fetchData(
                    tabs.filter((item) => item.active)[0].id,
                    previusPage,
                    tabs.findIndex((item) => item.active)
                  )
                }
                canPreviousPage={canPreviustPage}
                canNextPage={canNextPage}
                goToPage={async (page) =>
                  fetchData(
                    tabs.filter((item) => item.active)[0].id,
                    page,
                    tabs.findIndex((item) => item.active)
                  )
                }
                lastPage={configPage?.last_page}
              /> */}
            </div>
          );
        })}
      </div>
    </Container>
  );
}
