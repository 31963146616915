import React from "react";
import { formatCurrentMoney } from "../../utils/helper";

import { Container } from "./styles";

import img1 from "../../assets/img/sistema/erro-code.svg";

function BoxView(props) {
  const { produto } = props;

  return (
    <Container>
      {produto ? (
        <>
          <div className="img-titulo">
            <img src={produto?.foto_capa} alt="" />
            <div>
              <h4>{produto.nome}</h4>
              <span>
                {formatCurrentMoney(parseFloat(produto?.valor_reais))}
              </span>
            </div>
          </div>
          <span>Estoque: {produto?.total_disponivel}</span>
          <div className="qtd">
            <span>Qtd.</span>
            <input type="number" min="0" value={produto.value}></input>
          </div>
          <img className="fechar" src={img1} alt="" />
        </>
      ) : (
        <h3>Nenhum produto selecionado </h3>
      )}
    </Container>
  );
}

export default BoxView;
