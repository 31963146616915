import React, { useEffect } from "react";
import { ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import { filterSelect } from "../../utils/helper";
import Select from "../select";

// import { Container } from './styles';

function TipoDisparo(props) {
  const dispatch = useDispatch();
  const { disparo } = useSelector((state) => state.acao.data);
  const { form } = props;
  const { setFieldValue, values } = form;
  const { tipo_disparo } = values;

  useEffect(() => {
    if (tipo_disparo !== 1) {
      setFieldValue("data_disparo", undefined);
      dispatch(AcaoCreators.createObject("data_disparo", undefined));
      dispatch(AcaoCreators.preview("data_disparo", undefined));
    }
  }, [dispatch, setFieldValue, tipo_disparo]);

  return (
    <div className="container-form">
      <Select
        label="Tipo de Envio"
        id={tipo_disparo}
        selectedValue={filterSelect(tipo_disparo, disparo)}
        options={disparo}
        change={({ value, label }) => {
          setFieldValue("tipo_disparo", value);

          dispatch(AcaoCreators.createObject("acao_periodo_id", value));

          dispatch(AcaoCreators.createObject("toggle_disparo", value));

          dispatch(AcaoCreators.createObject("tipo_disparo", parseInt(value)));
          dispatch(AcaoCreators.preview("tipo_disparo_name", label));
        }}
      />
      <ErrorMessage component="span" name="tipo_disparo" />
    </div>
  );
}

export default TipoDisparo;
