import * as Yup from "yup";

export const initalValues = {
    nome: "",
    razao_social: "",
    cnpj: "",
};

export const validadeForm = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    razao_social: Yup.string().required("Campo Obrigatório"),
    cnpj: Yup.string().required("Campo Obrigatório"),
});
