import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

import { Creators as AcaoCreators } from "../../store/ducks/acao";
import Button from "../button";

function ButtonCancelar(props) {
    const { pedido, detalheAcao, titleBtn = "Cancelar" } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    let title = "Cancelar ação";
    let message =
        "Ao cancelar esta ação todos os dados inseridos serão apagados";
    let path = "/acoes";

    if (pedido) {
        title = "Cancelar pedido";
        message =
            "Ao cancelar este pedido todos os dados inseridos serão apagados";
        path = "/pedidos";
    }

    if (detalheAcao) {
        title = "Cancelar ação";
        message = "Está operação não poderá ser desfeita";
        path = "/acoes";
    }

    function cancelar() {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        dispatch(AcaoCreators.clearAll());
                        history.push(path);
                    },
                },
                {
                    label: "Não",
                    onClick: () => {},
                },
            ],
        });
    }

    return (
        <Button
            type="button"
            title={titleBtn}
            onClick={cancelar}
            bgc={"red"}
            transparent={true}
        />
    );
}

export default ButtonCancelar;
