import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import { getDetalhe } from "../../utils/helper";

import img from "../../assets/img/sistema/seta-azul.svg";

import { Editar } from "./styles";
import { Cadastro } from "../../components/topo/styles";

ChartJS.register(ArcElement, Tooltip, Legend);

const statusConfig = {
  ERROR: {
    label: "Erro",
    color: "rgb(255, 99, 132)",
  },
  SENT: {
    label: "Enviado",
    color: "rgb(54, 162, 235)",
  },
  REJECT: {
    label: "Rejeitado",
    color: "rgb(255, 206, 862)",
  },
  REJECTED: {
    label: "Rejeitado",
    color: "rgb(75, 192, 192)",
  },
  DELIVERED: {
    label: "Entregue",
    color: "rgb(153, 102, 255)",
  },
  NOT_DELIVERED: {
    label: "Não entregue",
    color: "rgb(255, 159, 64)",
  },
  READ: {
    label: "Lido",
    color: "rgb(131,111,255)",
  },
  PROCESSED: {
    label: "Processado",
    color: "rgb(100,149,237)",
  },
  CLICK: {
    label: "Clicado",
    color: "rgb(173,216,230)",
  },
  OPEN: {
    label: "Aberto",
    color: "rgb(144,238,144)",
  },
  BOUNCE: {
    label: "Pulado",
    color: "rgb(244,164,96)",
  },
  DROPPED: {
    label: "Desistido",
    color: "rgb(238,130,238)",
  },
  CANCELED: {
    label: "Cancelado",
    color: "rgb(255,105,180)",
  },
  DEFERRED: {
    label: "Deferido",
    color: "rgb(220,20,60)",
  },
  PROCESSING: {
    label: "Processando",
    color: "rgb(255,215,0)",
  },
  GERADO: {
    label: "Gerado",
    color: "rgb(255, 99, 132)",
  },
  AGUARDANDO: {
    label: "Aguardando",
    color: "rgb(54, 162, 235)",
  },
  UTILIZADO: {
    label: "Utilizado",
    color: "rgb(255, 206, 862)",
  },
  ESTORNADO: {
    label: "Estornado",
    color: "rgb(75, 192, 192)",
  },
  BLOQUEADO: {
    label: "Bloqueado",
    color: "rgb(255, 159, 64)",
  },
};

function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [detalhe, setDetalhe] = useState([]);

  const {
    location: { id },
  } = history;

  const getItens = useCallback(() => {
    async function fetchData() {
      if (id) {
        const { result } = await getDetalhe(id);

        setDetalhe(result);
      }
    }

    fetchData();
  }, [id]);

  const messageStatusData = useMemo(() => {
    const statusMap = {};

    detalhe.forEach(({ status }) => {
      if (status) {
        statusMap[status] = (statusMap[status] || 0) + 1;
      }
    });

    const labels = [];
    const data = [];
    const backgroundColor = [];

    Object.entries(statusMap).forEach(([key, value]) => {
      const { label, color } = statusConfig[key];

      data.push(value);
      labels.push(label);
      backgroundColor.push(color);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  }, [detalhe]);

  const VoucherStatusData = useMemo(() => {
    const statusMap = {};

    detalhe.forEach(({ yetz_estoque_status }) => {
      if (yetz_estoque_status) {
        statusMap[yetz_estoque_status] =
          (statusMap[yetz_estoque_status] || 0) + 1;
      }
    });

    const labels = [];
    const data = [];
    const backgroundColor = [];

    Object.entries(statusMap).forEach(([key, value]) => {
      const { label, color } = statusConfig[key];

      data.push(value);
      labels.push(label);
      backgroundColor.push(color);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  }, [detalhe]);

  useEffect(() => {
    if (!id) history.push("/acoes");

    try {
      getItens();
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }, [id, history, dispatch, getItens]);

  return (
    <Editar>
      <Cadastro>
        <p
          onClick={() =>
            history.push({
              pathname: `/acoes/detalhe/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          <img src={img} alt="" />
          Dashboard
        </p>
      </Cadastro>
      <div className="container dashboard">
        <div className="s-esq">
          <h3>STATUS DE ENVIO DE MENSAGEM </h3>
          <Pie data={messageStatusData} />
        </div>
        <div className="s-dir">
          <h3>STATUS DO VOUCHER DIGITAL</h3>
          <Pie data={VoucherStatusData} />
        </div>
      </div>
    </Editar>
  );
}

export default Dashboard;
