import React from "react";
import NaoEntregue from "./NaoEntregue";
import Entregue from "./Entregue";
import Enviado from "./Enviado";
import Visualizado from "./Visualizado";
import Temp from "./temp";

function StatusAcoes({ value }) {
    switch (value) {
        case "SEND":
            return <Enviado title="Enviado" />;

        case "SENT":
            return <Enviado title="Enviado" />;

        case "REJECT":
            return <NaoEntregue title="Rejeitado" />;

        case "REJECTED":
            return <NaoEntregue title="Rejeitado" />;

        case "DELIVERED":
            return <Entregue title="Entregue" />;

        case "NOT_DELIVERED":
            return <NaoEntregue title="Não entregue" />;

        case "READ":
            return <Visualizado title="visualizado" />;

        default:
            return <Temp title={value} />;
    }
}

export default StatusAcoes;
