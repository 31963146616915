import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { api } from "../../services/api";

import browserHistory from "../../config/browserHistory";

import { Toggle } from "../../components/Toggle";
import Button from "../../components/button";

import { Novo, Cadastro } from "./styles";
import { PermissionsGroup } from "./utils";

import img from "../../assets/img/sistema/seta-azul.svg";

function DetalhePerfil() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [activePermissions, setActivePermissions] = useState([]);

  const {
    location: { id },
  } = history;

  useEffect(() => {
    if (!id) history.push("/usuarios/gerenciar");

    async function fetchData() {
      const { data: dataPermission } = await api.get(`/permission`);

      const groupedPermissions = {};

      dataPermission.forEach((permission) => {
        const { name } = permission;

        const prefix = name.split(".")[0];

        if (groupedPermissions[prefix]) {
          groupedPermissions[prefix] = [
            ...groupedPermissions[prefix],
            permission,
          ];
        } else {
          groupedPermissions[prefix] = [permission];
        }
      });

      setPermissions(groupedPermissions);

      const { data } = await api.get(`/role/${id}/permissions`);
      setActivePermissions(data.map(({ id }) => id));
    }

    fetchData();
  }, [history, id]);

  function togglePermission(id) {
    const index = activePermissions.indexOf(id);
    if (index >= 0) {
      setActivePermissions([
        ...activePermissions.slice(0, index),
        ...activePermissions.slice(index + 1),
      ]);
    } else {
      setActivePermissions([...activePermissions, id]);
    }
  }

  async function enviaPermissoes() {
    setLoading(true);
    try {
      await api.post(`role/${id}`, {
        permissions: activePermissions,
      });
      history.push("/usuarios/gerenciar");
      toast.success("Permissões alteradas com sucesso!");
    } catch (error) {
      toast.error("Algo deu errado");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Novo>
      <Cadastro>
        <button className="voltar" onClick={() => browserHistory.back()}>
          <img src={img} alt="" />
          Voltar
        </button>
        <Button
          title="SALVAR ALTERAÇÕES"
          onClick={() => enviaPermissoes()}
          loading={loading}
        />
      </Cadastro>

      <div className="container">
        <div className="listaPermissoes">
          {Object.entries(permissions).map(([key, value]) => (
            <div className="grupoPermissao" key={key}>
              <h4>{PermissionsGroup[key]}</h4>
              {value.map(({ id, name }) => (
                <div className="rowPermissoes" key={id}>
                  <span>{name}</span>
                  <Toggle
                    active={activePermissions.includes(id)}
                    onClick={() => togglePermission(id)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Novo>
  );
}

export default DetalhePerfil;
