import React, { useState } from "react";

import { Container } from "./styles";

import img1 from "../../assets/img/sistema/erro-code.svg";

function BoxProduto(props) {
  const {
    produto,
    setObjSelecionadosvalues,
    setValueInputCard,
    click,
    erros,
    outlet,
  } = props;

  const [value, setValue] = useState("");

  return (
    <>
      <Container>
        {produto ? (
          <>
            <div className="img-titulo">
              <img src={produto?.foto_capa} alt="" />
              <div>
                <h4>{produto.nome}</h4>
                <span>R$ {produto?.valor_reais}</span>
              </div>
            </div>
            <span>
              Estoque:{" "}
              {outlet ? produto?.total_outlet : produto?.total_disponivel}
            </span>
            <div className="qtd">
              <span>Qtd.</span>
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  setValueInputCard(e.target.value);
                  setObjSelecionadosvalues({});
                  setObjSelecionadosvalues(produto);
                }}
                type="number"
                min="0"
                value={value}
              ></input>
            </div>
            <img
              className="fechar"
              src={img1}
              alt=""
              onClick={(e) => click(e, produto)}
            />
          </>
        ) : (
          <h3>Nenhum produto selecionado </h3>
        )}
      </Container>
      {erros.find((erro) => erro.limit === true && erro.id === produto.id) ? (
        <span className="erro-qtd">Quantidade excede estoque</span>
      ) : erros.find((erro) => erro.id === produto.id)?.id ? (
        <span className="erro-qtd">Preencha a quantidade</span>
      ) : null}
    </>
  );
}

export default BoxProduto;
