import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import NovoSelect from "../NovoSelect";

function Cliente(props) {
    const dispatch = useDispatch();
    const { id, options, form, setNomeContratante } = props;
    const { setFieldValue } = form;

    useEffect(() => {
        if (id) {
            const [item] = options.filter((item) => item.value === id);
            if (item) {
                const { label = "" } = item;
                dispatch(AcaoCreators.preview("contratante", label));
            }
        }
    }, [dispatch, options, id]);

    return (
        <>
            <NovoSelect
                label="Cliente"
                options={options}
                onChange={({ value, label }) => {
                    setFieldValue("cliente_id", parseInt(value));
                    if (setNomeContratante) {
                        setNomeContratante(label);
                    }
                    dispatch(AcaoCreators.preview("contratante", label));
                }}
            />
            <ErrorMessage component="span" name="contratante_id" />
        </>
    );
}

export default Cliente;
