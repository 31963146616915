import { createGlobalStyle } from "styled-components";

import imgCalendario from "../../assets/img/sistema/calendario.svg";

export default createGlobalStyle`
    html{
        height: 100vh;
    }
	*{
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		outline: 0px;
		font-family: "Lato-Regular";
		font-weight: 400;
	}

	.fix-body{
		overflow: hidden;
  }

  .disabled{
      pointer-events: none;
      opacity: 0.5;
  }

  button{
      background: transparent;
  }

  .container-form-double {
    display: flex;
    grid-gap: 10px;
  }

  .MuiAutocomplete-root{
    .MuiOutlinedInput-root{
        &:hover{
            border-color: transparent;
        }
    }
    input[type="text"]{
        background: transparent;
        width: 100%;
        height: auto;
        outline: none;
        text-transform: uppercase;
    }   
  }
  .MuiAutocomplete-popper{
    text-transform: uppercase;
  }

  .MuiDataGrid-filterFormOperatorInput,
  .MuiDataGrid-panelFooter{
    display: none !important;
  }
  .MuiDataGrid-filterForm{
    input[type='text']{
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em !important;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    }
  }
  .MuiDataGrid-filterFormDeleteIcon{
    display: none !important;
  }
  .container-form{
		margin-bottom: 14px;
    position: relative;
    width: 383px;
    &.areas{
        input{
            padding-right: 45px;
        }
        button{
            width: auto;
            height: auto;
            margin: 0px !important;
            padding: 7px 5px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
    }
    .check-password {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        height: auto;
        width: auto;
        &.active {
            &:before {
                height: 30px;
                transition: all 0.5s;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 0px;
            background-color: #858585;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all 0.5s;
        }
        img {
            position: static;
            transform: unset;
            margin-bottom: 0px;
        }
    }
    
    img {
      position: absolute;
      right: 10px;
      top: 20px;
      cursor: pointer;
      &.info {
        right: -30px;
      }
    }
  }
  
  input[type='text'],input[type='password'],input[type='tel'],input[type='email'], .custom-file{
    background: #FFFFFF;
    border: 0.5px solid #BDBDBD;
    border-radius: 4px;

    height: 51px !important;
    width: 383px;
    padding-left: 29px;
    padding-right: 20px;
    font-family: "Lato-Regular";
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.Cinza03};
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
      font-family: "Lato-Regular";
    }
    &:focus {
      color: ${(props) => props.theme.colors.preto};
      border: 1px solid ${(props) => props.theme.colors.Laranja1};
      background: ${(props) => props.theme.colors.branco};
    }
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  .custom-file {
    border: 1px dashed #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type='file'] {
      display: none;
    }
    &.nome-arquivo {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.preto};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.scroll-y{
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(89,92,118,0.5);

		}

		&::-webkit-scrollbar-track {

			background-color: rgba(255,255,255, 0.1);
		}
	}

	#root{
		margin-left: auto;
		margin-right: auto;
		max-width: 1911px;
    min-height: 100vh;
	}

	a,button{
		text-decoration: none;
		outline: none;
		border: none;
		cursor: pointer;
	}

	.container{
		width: 1215px;
		margin-left: auto;
		margin-right: auto;
	}

  .preview {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin: 50px 0;
    .container-text {
      h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 18px;
      }
    }
  }

	li{
		list-style: none;
	}

	body{
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		/* font-family: Montserrat; */
	}

  div[style="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"]{
      display: none;
  }

  .container-input-system {
    margin-bottom: 8px;
    position: relative;

    input {
      background: #FFFFFF;
      border: 0.5px solid #BDBDBD;
      border-radius: 4px;
      height: 58px !important;
      width: 383px;
      padding-left: 29px;
      padding-right: 20px;
      font-family: "Lato-Regular";
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 2px;
      color: ${(props) => props.theme.colors.Cinza03};
      &::-webkit-input-placeholder {
        color: ${(props) => props.theme.colors.Cinza03};
        font-family: "Lato-Regular";
      }
      &:-ms-input-placeholder {
        color: ${(props) => props.theme.colors.Cinza03};
        font-family: "Lato-Regular";
      }
      &::placeholder {
        color: ${(props) => props.theme.colors.Cinza03};
        font-family: "Lato-Regular";
      }
      &:focus {
        color: ${(props) => props.theme.colors.preto};
        border: 1px solid ${(props) => props.theme.colors.Laranja1};
        background: ${(props) => props.theme.colors.branco};
      }
    }

    &.disabled{
        input{
            pointer-events: none;
            background: ${(props) => props.theme.colors.disabled};
        }
    }
    button {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        font-size: ${(props) => props.theme.fonts.xss};
        padding: 0px;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 55%;
        left: 21px;
        background-color: ${(props) => props.theme.colors.secondary};
        transform: translateY(-50%);
        z-index: 20;
        transition: background-color 0.5s;
    }

    &.calendario {
        input {
            
            /* padding-left: 50px; */
        }
        &:before {
            -webkit-mask: url(${imgCalendario}) no-repeat;
            width: 21px;
            height: 22px;
            left: 20px;
            top: 50%;
        }
    }

  }


  .opcoes {
    position: relative;
    /* right: 10px;
    top: 45%;*/
    cursor: pointer; 
  }
  .dropdown-opcoes {
    background-color: ${(props) => props.theme.colors.branco};
    border-radius: 4px;
    
    height: 0px;
    position: absolute;
    /* right: 10px; */
    /* top: 60%; */
    overflow: hidden;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 20px;
    z-index: 10000;
    &.active {
      overflow: inherit;
      height: 100px;
      transition: all 0.5s;
      border: 1px solid rgba(224, 224, 224, 1);
    }
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.Cinza01};
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 13px;
        width: 20px;
      }
    }
  }

  .__react_component_tooltip.show{
    opacity: 1 !important;
  }

	@media (max-width: 1366px) {
		.container {
			width: 90%;
		}
  }

  @media (max-width: 480px) {
    .container-form{
      width: 100%;
      input{
        width: 100%;
      }
    }
  }

`;
