import React from "react";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
import { openTab } from "../../utils/helper";

import { DadosTemplate as Container } from "./styles";

export default function FormsNovaAcao({ fields }) {
  //   const { hidden } = useSelector((state) => state.acao);

  return (
    // <Container className={hidden ? "hidden" : ""}>
    <Container>
      <p>
        Use nosso modelo de planilha para carregar os participantes.{" "}
        <Link
          onClick={() =>
            openTab(
              "https://yetzcards.s3.sa-east-1.amazonaws.com/now/modelo_planilha_yetznow.xlsx"
            )
          }
        >
          Baixar modelo de planilha
        </Link>
      </p>
      <span>
        Para o template escolhido os seguintes campos são de preenchimento
        obrigatório:
      </span>
      <ul>
        {fields ? fields.map((campo, key) => <li key={key}>{campo}</li>) : null}
      </ul>
    </Container>
  );
}
