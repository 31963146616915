import { useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Creators as LoginCreators } from "../../store/ducks/login";

import TextField from "@mui/material/TextField";
import { CountDown } from "../CountDown";

import { Container } from "./styles";

import ValidarLogin from "../../assets/img/sistema/validae-login.svg";
import Fechar from "../../assets/img/sistema/fechar-simples.svg";
import setaCima from "../../assets/img/sistema/seta-cima.svg";

export function ModalValidaLogin(props) {
    const [disabled, setDisabled] = useState(true);
    const [timer, setTimer] = useState(false);
    const input = useRef(null);
    const dispatch = useDispatch();
    const { status_error_code, data } = useSelector((state) => state.login);
    const history = useHistory();

    async function reveenviarCode() {
        try {
            await api.post(
                `generate-auth-code`,
                {},
                { headers: { Authorization: `Bearer ${data.token}` } }
            );
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (status_error_code) {
            setTimer(false);
            setDisabled(false);
        } else {
            setTimer(true);
        }
    }, [status_error_code]);

    return (
        <Container>
            <button
                className="close"
                onClick={() => {
                    if (props.rest.onclose) {
                        props.rest.onclose();
                    }
                }}
            >
                <img src={Fechar} alt="" />
            </button>

            <div className="text-img">
                <div className="text-validadacao">
                    <img src={ValidarLogin} alt="" />
                </div>
                <h3 fontSize="mdl">VALIDAÇÃO DE LOGIN</h3>
                <p>
                    Sua conta é protegida com autenticação de dois fatores.
                    Enviamos um WhatsApp. Por favor, digite o código abaixo.
                </p>
                <div className="input-validacao">
                    <TextField
                        className={`input-text ${
                            status_error_code ? "error" : ""
                        }`}
                        label="Código de validação"
                        variant="outlined"
                        fullWidth={true}
                        ref={input}
                    />
                </div>

                {status_error_code ? <span>Código inválido</span> : null}

                {timer ? (
                    <CountDown setDisabled={setDisabled} setTimer={setTimer} />
                ) : null}

                <div width="100%" mt="16px" mb="24px" className="container-btn">
                    <button
                        loading={props.rest.loadingConfirmCode}
                        className="btn"
                        onClick={() => {
                            const value =
                                input.current.querySelector("input").value;

                            if (value) {
                                dispatch(
                                    LoginCreators.requestCheckCode(
                                        value,
                                        history
                                    )
                                );
                            }
                        }}
                    >
                        CONTINUE
                    </button>
                </div>

                <div className="container-btn reenviar">
                    <button
                        className="btn"
                        disabled={disabled}
                        onClick={() => {
                            reveenviarCode();
                            setTimer(true);
                            setDisabled(true);
                        }}
                    >
                        Reenviar código <img src={setaCima} alt="" />
                    </button>
                </div>
            </div>
        </Container>
    );
}
