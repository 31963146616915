import styled from 'styled-components';

export const PreviewWhats = styled.div`
  width: 384px;
  .container-preview-whats {
    width: 100%;
    .topo {
      height: 114px;
      background: #d5d7d5;
      border-radius: 16px 16px 0px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 16px 0px 0px;
      }
    }

    .content {
      padding: 104px 45px 95px 38px;
      height: auto;
      width: 100%;
      background: #d4f6c1;
      border-radius: 0px 0px 16px 16px;
    }
  }
`;

export const PreviewSms = styled.div`
  width: 384px;
  .container-preview-sms {
    width: 100%;
  }

  .content {
    background: #f2f2f2;
    border-radius: 16px;
    padding: 29px 37px 48px 38px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: ${(props) => props.theme.colors.Cinza01};
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export const PreviewEmail = styled.div`
  max-width: 900px;
  overflow-x: auto;
`;
