import React from "react";
import NaoEntregueIcon from "../../assets/img/sistema/nao-entregue.svg";

function NaoEntregue({ title }) {
    return (
        <p className="visualizado">
            <img src={NaoEntregueIcon} alt="" />
            {title}
        </p>
    );
}

export default NaoEntregue;
