import React from "react";
import { Container } from "./styles";

import img from "../../assets/img/sistema/seta-cinza.svg";

function Breadcrumb(props) {
  const { active } = props;

  return (
    <Container>
      <span className={`${active === "dados" && "active"}`}>Dados da Ação</span>
      <img src={img} alt="" />
      <span className={`${active === "disparo" && "active"}`}>Envio</span>
      <img src={img} alt="" />
      <span className={`${active === "participantes" && "active"}`}>
        Carregar Participantes
      </span>
      <img src={img} alt="" />
      <span className={`${active === "voucher" && "active"}`}>
        Selecionar Voucher
      </span>
      <img src={img} alt="" />
      <span className={`${active === "confirma_vouchers" && "active"}`}>
        Finalizar
      </span>
    </Container>
  );
}

export default Breadcrumb;
