import { useContext, createContext } from "react";

import { api } from "../../../../services/api";

const Context = createContext({});

export function NovoClienteProvider(props) {
    const { children } = props;

    async function getClientes() {
        try {
            const response = await api.get("/cliente");

            return response.data;
        } catch (error) {
            return [];
        }
    }

    return (
        <Context.Provider
            value={{
                getClientes,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
