import React, { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../services/api";

const UseTemplateContext = createContext({});

export function TemplateProvider(props) {
    const { children } = props;
    const history = useHistory();

    async function store(values) {
        try {
            const response = await api.post("/template", {
                ...values,
            });

            return response;
        } catch (error) {
            return error;
        }
    }

    async function getTemplate() {
        try {
            const response = await api.get("/template");

            return response;
        } catch (error) {
            return error;
        }
    }

    async function getTemplateById(id) {
        const type = history.location.state.type;

        try {
            const response = await api.get(`/template/${id}/${type}`);

            return response;
        } catch (error) {
            return error;
        }
    }

    async function updateTemplate(values) {
        const id = history.location.state.id;
        const type = history.location.state.type;

        try {
            const response = await api.post(`/template/${id}/${type}`, {
                ...values,
            });

            return response;
        } catch (error) {
            return error;
        }
    }

    return (
        <UseTemplateContext.Provider
            value={{ store, getTemplate, getTemplateById, updateTemplate }}
        >
            {children}
        </UseTemplateContext.Provider>
    );
}

export function useTemplate() {
    const context = useContext(UseTemplateContext);

    return context;
}
