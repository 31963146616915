import { formatCurrentMoney } from "../../utils/helper";

import fecharVoucherImg from "../../assets/img/sistema/fechar-voucher.svg";
import voucherImg from "../../assets/img/sistema/voucher.png";

import { CardVoucherStyles } from "./styles";

export function CardVoucher(props) {
  const {
    check = false,
    cursor = false,
    hover = false,
    active = false,
    onChange = () => {},
    onClose = () => {},
    close = false,
  } = props;

  return (
    <CardVoucherStyles
      cursor={cursor}
      hover={hover}
      active={active}
      check={check}
      onClick={onChange}
    >
      {check ? (
        <div className="check">
          <input type="checkbox" checked={active} />
        </div>
      ) : (
        <></>
      )}

      <div className="container-img">
        <img src={props?.img ? props.img : voucherImg} alt="" />
      </div>

      <div className="container-text">
        <h3>{props?.nome ? props.nome : ""}</h3>
        <p>
          {props?.valor_reais
            ? formatCurrentMoney(parseFloat(props.valor_reais))
            : ""}
        </p>
      </div>

      <div className="container-estoque">
        <span>Estoque {props?.qtd ? props.qtd : "-"}</span>
      </div>

      {close ? (
        <button onClick={onClose}>
          <img src={fecharVoucherImg} alt="" />
        </button>
      ) : null}
    </CardVoucherStyles>
  );
}
