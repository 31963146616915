import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import browserHistory from "../../config/browserHistory";

import { useTemplate } from "../../Hook/useTemplate";

import { getContratante } from "../../utils/helper";

import Button from "../button";
import NovoSelect from "../NovoSelect";
import Topo from "../topo/cadastro";
import { Email } from "./email";
import { Sms } from "./sms";
import { myFormValues, validationFormEmail, validationFormSms } from "./config";

import { Novo } from "./styles";

export function NovoTemplate() {
  const history = useHistory();

  const [tipoForm, setTipoForm] = useState(null);
  const [validation, setValidation] = useState(null);
  const [values, setValues] = useState(myFormValues);
  const [success, setSuccess] = useState(false);

  const [contratante, setContratante] = useState([]);

  const { store } = useTemplate();

  const tipos = [
    {
      value: 1,
      label: "SMS",
    },
    {
      value: 2,
      label: "E-mail",
    },
  ];

  const getItem = useCallback(async () => {
    const contratante = await getContratante();

    setContratante(contratante);
  }, []);

  useEffect(() => {
    if (tipoForm) {
      setValidation(
        tipoForm === "e-mail" ? validationFormEmail : validationFormSms
      );
      setValues(myFormValues);
    }
  }, [tipoForm]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  async function handleSubmit(values, actions) {
    const response = await store(values);

    actions.resetForm();

    if (response.status === 200) {
      history.push("/template");
      toast.success("Cadastro realizado com sucesso!");
    } else {
      toast.error("Erro ao realizar cadastro!");
    }
  }

  return (
    <Novo>
      <Topo
        activeFiltro={success}
        toggleFiltro={setSuccess}
        title="Usuários do Sistema"
        linkCadastro=""
      />

      <div className="container">
        <h1>Cadastrar Template</h1>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validation}
          onSubmit={async (value, actions) => {
            const {
              fields,
              cliente_id,
              name,
              status,
              text,
              type,
              html_content,
              sendgrid_id,
            } = value;

            const sms = {
              cliente_id,
              name,
              status,
              text,
              type,
              fields,
            };

            const email = {
              html_content,
              cliente_id,
              name,
              sendgrid_id,
              status,
              type: "email",
            };

            await handleSubmit(tipoForm === "e-mail" ? email : sms, actions);
          }}
        >
          {(props) => {
            const { setFieldValue } = props;

            return (
              <Form>
                <NovoSelect
                  label="Tipo"
                  options={tipos}
                  onChange={({ value, label }) => {
                    setFieldValue("type", label.toLocaleLowerCase());
                    setTipoForm(label.toLocaleLowerCase());
                  }}
                />
                <ErrorMessage component="span" name="type" />

                <div className="container-form-double">
                  <NovoSelect
                    label="Cliente"
                    options={contratante}
                    onChange={({ value, label }) => {
                      setFieldValue("cliente_id", value);
                    }}
                  />
                  {/* <Field
                    component={Contratante}
                    id={cliente_id}
                    options={contratante}
                  /> */}
                </div>

                {tipoForm === "e-mail" ? <Field component={Email} /> : null}

                {tipoForm === "sms" ? <Field component={Sms} /> : null}

                <div className="btns">
                  <Button
                    title="CANCELAR"
                    bgc={"#E25B5B"}
                    onClick={() => browserHistory.back()}
                  />
                  <Button
                    title="CADASTRAR"
                    disabled={validation ? false : true}
                    // className={load ? "disabled" : ""}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Novo>
  );
}
