import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import { api } from "../../services/api";

import { formatCelular } from "../../utils/helper";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/filtro/index";
import Topo from "../../components/topo";
import Table from "../../components/tabelaMui";
import { Permissions } from "../../components/Permission";
import { Toggle } from "../../components/Toggle";

import { Container, Conteudo } from "./styles";

import imgEditar from "../../assets/img/sistema/editar.svg";

function Acoes() {
  const history = useHistory();
  const [active, toggleFiltro] = useState(false);
  const [user, setUser] = useState([]);

  const getAcoes = useCallback(async () => {
    const { data } = await api.get("usuario");

    setUser(
      data?.map((dados) => {
        const {
          id,
          name,
          email,
          phone_number,
          roles,
          cliente,
          areas,
          deleted_at,
        } = dados;
        return {
          id,
          name,
          email,
          telefone: formatCelular(phone_number, ""),
          perfil: roles[0].name,
          cliente: cliente?.nome + ", " + areas[0]?.nome,
          deleted_at,
        };
      })
    );
  }, []);

  const toggleStatusUsuario = useCallback(
    async (id, status) => {
      if (status) {
        try {
          await api.delete(`/usuario/${id}`);
          toast.success("Usuário bloqueado!");

          getAcoes();
        } catch (error) {
          toast.error("Algo deu errado");
        }
      } else {
        try {
          await api.get(`/usuario/restore/${id}`);
          toast.success("Usuário ativado!");

          getAcoes();
        } catch (error) {
          toast.error("Algo deu errado");
        }
      }
    },
    [getAcoes]
  );

  const columns = React.useMemo(
    () => [
      {
        headerName: "Nome",
        field: "name",
        width: 250,
      },
      {
        headerName: "Telefone",
        field: "telefone",
        width: 200,
      },
      {
        headerName: "Email",
        field: "email",
        width: 300,
      },
      {
        headerName: "Cliente e Área",
        field: "cliente",
        width: 350,
      },
      {
        headerName: "Perfil",
        field: "perfil",
        width: 200,
      },
      {
        headerName: "Status",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <Toggle
              active={row.deleted_at ? false : true}
              onClick={() =>
                toggleStatusUsuario(row.id, row.deleted_at ? false : true)
              }
            />
          );
        },
      },
      {
        headerName: "",
        field: "editar",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["usuario.update"]}>
              <button
                onClick={() =>
                  history.push({
                    pathname: `/usuarios/editar/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
              >
                <img src={imgEditar} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
    ],
    [history, toggleStatusUsuario]
  );

  useEffect(() => {
    getAcoes();
  }, [getAcoes]);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Usuários ADM"
          linkCadastro="/usuarios/novo"
          permission={["usuario.store"]}
        />
        <Filtro activeFiltro={active} />

        <div className="conteudo">
          <Table columns={columns} rows={user} />
        </div>
      </Conteudo>
    </Container>
  );
}

export default Acoes;
