import { api } from "../services/api";
import Moment from "moment";

export const optionsDisparo = [
  { value: 1, label: "Disparo Agendado" },
  { value: 2, label: "Imediato" },
  { value: 3, label: "Data da Planilha" },
];

export const RegExp = {
  celular: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  phone: /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
};

export function validatePhoneInput(event) {
  let { value } = event.target;

  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})/, "($1) ");
  const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }

  if (isMobilePhone) {
    event.currentTarget.maxLength = 16;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{1})/, "($1) $2.");
    value = value.replace(/(\d{4})/, "$1-");
    value = value.replace(/(\d{4})/, "$1");
  } else {
    event.currentTarget.maxLength = 14;
    value = value.replace(/(\d{4})/, "$1-");
    value = value.replace(/(\d{4})/, "$1");
  }

  event.currentTarget.value = value;
}

export function errorMenssage(error) {
  if (!ObjIsEmpty(error)) {
    return {
      err: error.request.response,
      status: error.request.status,
      url: error.config.url,
    };
  }

  return { err: "Não autorizado", status: 401, url: "" };
}

export function filtroContratante(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, [
      "area",
      "nome",
      "cnpj",
      "email",
      "contato",
      "telefone",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroAcoes(data = [], busca, status, tipo, date) {
  if (status && status !== "status") {
    data = data.filter((item) => item.status.toLocaleLowerCase() === status);
  }

  if (tipo && tipo !== "tipo") {
    data = data.filter((item) => item.tipo.toLocaleLowerCase() === tipo);
  }

  if (date) {
    data = data.filter((item) => item.data.split(" ")[0] === date);
  }

  return data.filter((data) => {
    return JSON.stringify(data, [
      "job",
      "cliente",
      "data",
      "links",
      "nome",
      "status",
      "tipo",
      "solicitante",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroPedidos(data = [], busca = "", status, tipo, date) {
  if (status && status !== "status") {
    data = data.filter((item) => item.status.toLocaleLowerCase() === status);
  }

  if (tipo && tipo !== "tipo") {
    data = data.filter((item) => item.tipo.toLocaleLowerCase() === tipo);
  }

  if (date) {
    data = data.filter((item) => item.data.split(" ")[0] === date);
  }

  return data.filter((data) => {
    return JSON.stringify(data, [
      "id",
      "status",
      "cliente",
      "titulo",
      "qtd",
      "data",
      "solicitante",
      "admin",
      "job",
      "area",
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function getMonth(today) {
  return today.getMonth() + 1 < 10
    ? "0" + (today.getMonth() + 1)
    : today.getMonth() + 1;
}

export function convertDatePickerTimeToMySQLTime(str) {
  var hours, minutes, seconds;
  var date = new Date(str);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);
  hours = ("0" + date.getHours()).slice(-2);
  minutes = ("0" + date.getMinutes()).slice(-2);
  seconds = ("0" + date.getSeconds()).slice(-2);

  var mySQLDate = [date.getFullYear(), month, day].join("-");
  var mySQLTime = [hours, minutes, seconds].join(":");
  return [mySQLDate, mySQLTime].join(" ");
}

export function convertDatePickerTimeToUser(str) {
  const data = str.split(" ")[0];
  const tempo = str.split(" ")[1];

  return data.split("-").reverse().join("/") + " " + tempo;
}

export function formatDataTime(dt) {
  if (!dt) return "";
  const arr = dt.split(" ");
  const data = arr[0].split("-").reverse().join("/");
  const tempo = arr[1];
  return data + " - " + tempo;
}

export function formatDateIso(date) {
  if (!date) return "";
  const dateIso = new Date(date);
  const dateFormated = dateIso.toLocaleDateString("pt-BR");
  const timeFormated = dateIso.toLocaleTimeString("pt-BR");
  const fullDate = dateFormated + " - " + timeFormated;
  return fullDate.replace(/\//g, "/");
}

export function file(arq) {
  var file = new File(arq, "sample.xlsx", {
    lastModified: new Date(0), // optional - default = now
  });

  var fr = new FileReader();

  return fr.readAsText(file);
}

export async function convertBlobToFileFromUrl(
  url,
  name = "modelo-planilha-yetz-now.xlsx"
) {
  let arqblob = await fetch(url).then((r) => r.blob());

  return new File([arqblob], name);
}

export async function convertBlobToImgFromUrl(
  url,
  name = "modelo-planilha-yetz-now.xlsx"
) {
  let imgblob = await fetch(url).then((r) => r.blob());
  return new File([imgblob], "431205_303844113064860_1743074902_n.jpg", {
    type: "image/png",
    lastModified: new Date().getTime(),
  });
}

export function dataURLtoFile(dataurl, filename) {
  if (dataurl === "editar-file") return false;

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function ObjIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function filterSelect(id, arr) {
  if (!arr.length) return [];
  return {
    ...arr.filter((value) => value.value === id)[0],
  };
}

export function nomeArq(name, size) {
  const extensions = name.split(".").pop();

  return name.substr(0, size) + "..." + extensions;
}

export function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export const TimeAgo = (function () {
  var self = {};

  // Public Methods
  self.locales = {
    prefix: "",
    sufix: "atrás",

    seconds: "menos de um minuto",
    minute: "cerca de um minuto",
    minutes: "%d minutos",
    hour: "cerca de uma hora",
    hours: "cerca de %d horas",
    day: "um dia",
    days: "%d dias",
    month: "cerca de um mês",
    months: "%d meses",
    year: "cerca de um ano",
    years: "%d anos",
  };

  self.inWords = function (timeAgo) {
    var seconds = Math.floor((new Date() - parseInt(timeAgo)) / 1000),
      separator = this.locales.separator || " ",
      words = this.locales.prefix + separator,
      interval = 0,
      intervals = {
        year: seconds / 31536000,
        month: seconds / 2592000,
        day: seconds / 86400,
        hour: seconds / 3600,
        minute: seconds / 60,
      };

    var distance = this.locales.seconds;

    for (var key in intervals) {
      interval = Math.floor(intervals[key]);

      if (interval > 1) {
        distance = this.locales[key + "s"];
        break;
      } else if (interval === 1) {
        distance = this.locales[key];
        break;
      }
    }

    distance = distance.replace(/%d/i, interval);
    words += distance + separator + this.locales.sufix;

    return words.trim();
  };

  return self;
})();

export function checkTokenExpired() {
  if (Object.entries(localStorage)[0]) {
    const local = JSON.parse(Object.entries(localStorage)[0][1]);
    const user = JSON.parse(local.user);
    const minutos = parseInt(fmtMSS(user.expires_in).split(":")[0]) - 5;

    if (user.now) {
      var startdate = Moment(user.now);
      var returned_endate = Moment(startdate)
        .add(minutos, "minutes")
        .format("YYYY-MM-DD HH:mm");
      return Moment().isAfter(returned_endate);
    }
  }

  return false;
}

export function getToken() {
  if (Object.entries(localStorage)[0]) {
    if (Object.entries(localStorage)[0][1]) {
      const local = JSON.parse(Object.entries(localStorage)[0][1]);
      const user = JSON.parse(local.user);
      if (user.token) return user.token;
    }
  }

  return false;
}

export async function getRoles() {
  const { data } = await api.get("role");

  return data.map((data) => {
    const { name, id } = data;
    return { label: name, value: id };
  });
}

export async function getContratante() {
  const { data } = await api.get("cliente");

  return data.map((data) => {
    const { nome, id } = data;
    return { label: nome, value: id };
  });
}

export async function getAreasUsuario(id) {
  const { data } = await api.get(`cliente/${id}`);

  let options = [];

  data.areas.forEach((area) => {
    options.push({
      label: `${area.nome}`,
      value: area.id,
    });
  });

  return options;
}

export async function getAreas(id) {
  const { data } = await api.get(`cliente/${id}`);

  let options = [];

  data.areas.forEach((area) => {
    area.contatos.forEach((contato) => {
      options.push({
        label: `${area.nome} - ${contato.nome}`,
        value: contato.id,
      });
    });
  });

  return options;
}

export async function getTipo() {
  const { data } = await api.get("acao/tipo");
  const tipos = data.tipos.map((data) => {
    const { tipo: label, id: value } = data;
    return { label, value };
  });

  const periodos = data.periodos.map((data) => {
    const { descricao: label, id: value } = data;
    return { label, value };
  });

  return { tipos, periodos };
}

export async function getTemplate() {
  const { data } = await api.get("template");
  const { templates_whatsapp, templates_mail, templates_sms } = data;
  return { templates_whatsapp, templates_mail, templates_sms };
}

export function checkAcaoData(data, objFormik) {
  const {
    cliente_id,
    nome,
    cliente_area_contato_id,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
    entrega_voucher = 0,
    job,
    contratante_nome,
    contratante_email,
    inicio,
    fim,
    acao_voucher_tipo_id,
  } = data;

  const initialValues = {
    cliente_id,
    cliente_area_contato_id,
    nome,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
    entrega_voucher,
    job,
    contratante_nome,
    contratante_email,
    inicio,
    fim,
    acao_voucher_tipo_id,
  };

  return ObjIsEmpty(data) ? objFormik.acoes.dados : initialValues;
}

export function checkDisparoData(data, objFormik) {
  const { tipo_disparo, data_disparo } = data;

  const initialValues = {
    tipo_disparo,
    data_disparo,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.tipo : initialValues;
}

export function checkParticipantesData(data, objFormik) {
  const { recipientes } = data;

  const initialValues = {
    recipientes,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.dados : initialValues;
}

export function sendDisparo(data, value) {
  const {
    template_arte,
    nome_arquivo,
    nome_img,
    recipientes,
    cliente_id,
    nome,
    acao_tipo_id,
    acao_voucher_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
    entrega_voucher,
    job,
    contratante_nome,
    contratante_email,
    cliente_area_contato_id,
    inicio,
    fim,
  } = data;

  const arquivo = dataURLtoFile(
    value.recipientes ? value.recipientes : recipientes,
    nome_arquivo
  );

  const img = template_arte ? dataURLtoFile(template_arte, nome_img) : null;
  const dados = {
    nome,
    acao_tipo_id,
    acao_voucher_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
    entrega_voucher,
    job,
    cliente_area_contato_id,
    inicio,
    fim,
  };

  const formData = new FormData();

  const objKeys = Object.keys(dados);

  objKeys.forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("recipientes", arquivo);

  if (cliente_id) {
    formData.append("cliente_id", cliente_id);
  } else {
    formData.append("contratante_nome", contratante_nome);
    formData.append("contratante_email", contratante_email);
  }

  if (template_arte) {
    formData.append("template_arte", img);
  }

  return formData;
}

export function tipoDisparo(id) {
  if (id === 2) return "imediato";
  if (id === 3) return "planilha";
}

export function changeBreadcrumb(resumo) {
  if (resumo.disparo_agendado) {
    return true;
  } else {
    return false;
  }
}

export function formatDateTimeDb(date) {
  if (!date) return "";
  const dateTime = new Date(date);
  const dateFormated = dateTime.toLocaleDateString("pt-BR");
  const timeFormated = dateTime.toLocaleTimeString("pt-BR");
  const fullDate = dateFormated + " - " + timeFormated;
  return fullDate.replace(/\//g, ".");
}

export function formatDateTimeDbAcao(date) {
  if (!date) return "";

  const dateTime = date.split(" ");
  const dateFormated = dateTime[0];
  const timeFormated = dateTime[1];
  return dateFormated + " " + timeFormated;
}

export function formatPhoneNumber(phone) {
  if (!phone) return "";
  if (phone) {
    return phone
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "$1 + ($2) $3-$4");
  }
  return phone;
}

export function formatCelular(phone) {
  if (!phone) return "";
  if (phone) {
    return phone
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "($2) $3-$4");
  }
  return phone;
}

export async function getDetalhe(id, paginacao, params) {
  const { data } = await api.get(
    `/acao/${id}${params ? params : ""}${paginacao ? `?page=${paginacao}` : ""}`
  );

  if (!data) return { result: [], topo: {}, page: {}, pedidos: {} };
  let filhas = [];
  let tab = [];
  const {
    status: { status },
    cliente = "Banco Itaú",
    nome,
    tipo: { tipo },
    qtd_links_entregues,
    qtd_links_enviados,
    data_disparo,
    pedidos,
    job,
    categoria,
    area,
    cliente_area_contato,
  } = data;

  const links = qtd_links_enviados + " / " + qtd_links_entregues;

  const topo = {
    status,
    cliente,
    nome,
    tipo,
    links,
    data_disparo,
    job,
    categoria: categoria?.descricao,
    area,
    cliente_area_contato,
  };

  const page = {
    ...data.recipientes,
  };

  const recipientes = data?.recipientes?.data.map((users) => {
    const { custom_fields, id } = users;
    return { id, custom_fields };
  });

  const result = data?.recipientes?.data.map((users) => {
    const {
      data_recebimento,
      data_visualizacao,
      status,
      custom_fields,
      id,
      acao_id,
      sented,
      retries,
      status_pt,
    } = users;

    const { nome, email, celular } = JSON.parse(custom_fields);

    return {
      usuario: nome,
      telefone: formatCelular(celular + ""),
      email,
      recebimento:
        data_recebimento !== "-" ? formatDateIso(data_recebimento) : "-",
      status,
      visualizacao:
        data_visualizacao !== "-" ? formatDateIso(data_visualizacao) : "-",
      yetz_estoque_status: users?.pedido_item?.status,
      data_yetz_estoque: formatDateIso(users?.pedido_item?.status_updated_at),
      id: id,
      acao_status_id: data?.acao_status_id,
      acao_id,
      id_recipiente: id,
      sented,
      retries,
      status_pt,
      voucher:
        users?.pedido_item?.produto_variacao_nome +
        " " +
        formatCurrentMoney(parseFloat(users?.pedido_item?.valor_reais)),
    };
  });

  data.filhas &&
    data.filhas.forEach((filha) => {
      tab.push({
        id: filha.id,
        acao_status_id: filha.acao_status_id,
        active: false,
        completed: false,
      });

      filhas.push([
        {
          usuario: nome,
          status,
          id,
          acao_status_id: filha.acao_status_id,
        },
      ]);
    });

  if (filhas.length > 0) {
    filhas = [result, ...filhas];
    tab = [
      {
        id: data.id,
        acao_status_id: data?.acao_status_id,
        active: true,
        completed: true,
      },
      ...tab,
    ];
  } else {
    tab = [
      {
        id: data.id,
        acao_status_id: data?.acao_status_id,
        active: true,
        completed: false,
      },
    ];
  }

  return { filhas, tab, result, topo, page, pedidos, recipientes };
}

export async function getDetalheMulti(id, paginacao) {
  const { data } = await api.get(
    `/acao/multi/${id}${paginacao ? `?page=${paginacao}` : ""}`
  );

  if (!data) return { result: [], topo: {}, page: {}, pedidos: {} };
  let filhas = [];
  let tab = [];
  const {
    status: { status },
    cliente = "Banco Itaú",
    nome,
    tipo: { tipo },
    qtd_links_entregues,
    qtd_links_enviados,
    data_disparo,
    pedidos,
    job,
  } = data;

  let erros = 0;
  let processados = 0;

  data?.recipientes?.data.forEach((users) => {
    if (users.status !== "PROCESSING") {
      processados++;
      if (users.status === "ERROR") {
        erros++;
      }
    }
  });

  const links = qtd_links_enviados + " / " + qtd_links_entregues;

  const topo = {
    status,
    cliente,
    nome,
    tipo,
    links,
    data_disparo,
    erros,
    processados,
    job,
  };

  const page = {
    ...data.recipientes,
  };

  const recipientes = data?.recipientes?.data.map((users) => {
    const { custom_fields, id } = users;
    return { id, custom_fields };
  });

  const result = data?.recipientes?.data.map((users) => {
    const {
      data_recebimento,
      data_visualizacao,
      status,
      custom_fields,
      id,
      acao_id,
      status_pt,
    } = users;

    const { nome, email, celular, voucher, valor_voucher } =
      JSON.parse(custom_fields);

    return {
      usuario: nome,
      telefone: formatCelular(celular + ""),
      email,
      recebimento: data_recebimento,
      status,
      visualizacao: data_visualizacao,
      yetz_estoque_status: users?.pedido_item?.status,
      data_yetz_estoque: users?.pedido_item?.status_updated_at,
      id: id,
      acao_status_id: data?.acao_status_id,
      acao_id,
      id_recipiente: id,
      voucher,
      valor: valor_voucher,
      status_pt,
    };
  });

  data.filhas &&
    data.filhas.forEach((filha) => {
      tab.push({
        id: filha.id,
        acao_status_id: filha.acao_status_id,
        active: false,
        completed: false,
      });

      filhas.push([
        {
          usuario: nome,
          status,
          id,
          acao_status_id: filha.acao_status_id,
        },
      ]);
    });

  if (filhas.length > 0) {
    filhas = [result, ...filhas];
    tab = [
      {
        id: data.id,
        acao_status_id: data?.acao_status_id,
        active: true,
        completed: true,
      },
      ...tab,
    ];
  } else {
    tab = [
      {
        id: data.id,
        acao_status_id: data?.acao_status_id,
        active: true,
        completed: false,
      },
    ];
  }

  return { filhas, tab, result, topo, page, pedidos, recipientes };
}

export async function getDados(id, template, periodos) {
  const { data } = await api.get(`/acao/${id}`);

  const {
    acao_tipo_id,
    contratante: { id: contratante_id },
    data_disparo,
    nome,
    template_id,
    acao_periodo_id,
    arte_temporary_url,
    arte_original,
    recipient_file,
    entrega_voucher,
  } = data;

  const tipoTemp = tipoTemplate(acao_tipo_id, template);

  const campos = tipoTemp.filter(
    (item) => item.id === template_id && item.has_image === true
  );

  const preview_tema = objTemplate(tipoTemp).filter(
    (item) => item.value === template_id
  );

  const disparo_tipo = periodos.filter(
    (item) => item.value === acao_periodo_id
  );

  const [preview = "Sem Template"] = preview_tema;
  const { label = "" } = preview;

  const [disparo] = disparo_tipo;
  const { label: tipo_disparo_name } = disparo;

  return {
    toggle_acao: acao_tipo_id,
    campos: campos.length ? campos[0].fields : "",
    has_image: campos.length ? campos[0].has_image : "",
    contratante_id,
    preview_tema,
    nome,
    disparo: periodos,
    acao_tipo_id,
    template_id,
    tipo_disparo: acao_periodo_id,
    data_disparo: data_disparo ? convertDatePickerTimeToUser(data_disparo) : "",
    template: label,
    tipo_disparo_name,
    arte_hash: arte_original,
    preview_img: arte_temporary_url,
    acao_periodo_id,
    toggle_disparo: acao_periodo_id,
    breadcrumb_disparo: false,
    breadcrumb_participante: false,
    nome_arquivo: recipient_file,
    id_disparo: id,
    template_arte: arte_temporary_url && "editar-file",
    entrega_voucher,
  };
}

export async function getDadosMultivoucher(id, template, periodos) {
  const { data } = await api.get(`/acao/multi/${id}`);

  const {
    acao_tipo_id,
    contratante: { id: contratante_id },
    data_disparo,
    nome,
    template_id,
    acao_periodo_id,
    arte_temporary_url,
    arte_original,
    recipient_file,
    entrega_voucher,
  } = data;

  const tipoTemp = tipoTemplate(acao_tipo_id, template);

  const campos = tipoTemp.filter(
    (item) => item.id === template_id && item.has_image === true
  );

  const preview_tema = objTemplate(tipoTemp).filter(
    (item) => item.value === template_id
  );

  const disparo_tipo = periodos.filter(
    (item) => item.value === acao_periodo_id
  );

  const [preview = "Sem Template"] = preview_tema;
  const { label = "" } = preview;

  const [disparo] = disparo_tipo;
  const { label: tipo_disparo_name } = disparo;

  return {
    toggle_acao: acao_tipo_id,
    campos: campos.length ? campos[0].fields : "",
    has_image: campos.length ? campos[0].has_image : "",
    contratante_id,
    preview_tema,
    nome,
    disparo: periodos,
    acao_tipo_id,
    template_id,
    tipo_disparo: acao_periodo_id,
    data_disparo: data_disparo ? convertDatePickerTimeToUser(data_disparo) : "",
    template: label,
    tipo_disparo_name,
    arte_hash: arte_original,
    preview_img: arte_temporary_url,
    acao_periodo_id,
    toggle_disparo: acao_periodo_id,
    breadcrumb_disparo: false,
    breadcrumb_participante: false,
    nome_arquivo: recipient_file,
    id_disparo: id,
    template_arte: arte_temporary_url && "editar-file",
    entrega_voucher,
  };
}

export async function getLinkExport(id) {
  const {
    data: { file_url },
  } = await api.get(`acao/${id}/export`);
  return file_url;
}

export async function getLinkExportPdf(id) {
  const { data } = await api.get(`acao/${id}/pdf`, { responseType: "blob" });
  return data;
}

export function openTab(link) {
  window.open(link);
}

export function openTabBlob(link) {
  console.log("link");
  const blobUrl = window.URL.createObjectURL(link);
  window.open(blobUrl);
}

export function linkDetaOrlheEdit(status, id) {
  if (
    status.toLowerCase() === "enviada" ||
    status.toLowerCase() === "processando" ||
    status.toLowerCase() === "aguardando disparo" ||
    status.toLowerCase() === "cancelada"
  ) {
    return `/acoes/detalhe/${id}`;
  } else {
    return `/acoes/editar/${id}`;
  }
}

export function selectItem(options, id, returnItem) {
  const item = options.filter((op) => op.value === id);

  if (returnItem) return item;

  if (item.length) {
    return item[0].label;
  }
}

export function objTemplate(options) {
  return options.map((data) => {
    const {
      name: label,
      id: value,
      has_image,
      html_content,
      text,
      fields,
      status,
    } = data;
    return { label, value, has_image, html_content, text, fields, status };
  });
}

export function previewAcao(itens, id) {
  return itens.filter((item) => item.value === id);
}

export function tipoTemplate(id, tempTemplate) {
  const { templates_mail, templates_whatsapp, templates_sms } = tempTemplate;
  switch (id) {
    case 1:
      return templates_sms;

    case 2:
      return templates_whatsapp;

    case 3:
      return templates_mail;

    default:
      break;
  }
}

export function getAcoes(data) {
  return data.map((dados) => {
    const {
      id,
      status: { status },
      cliente,
      tipo: { tipo },
      categoria,
      qtd_links_entregues,
      qtd_links_enviados,
      data_disparo,
      job,
      fim,
    } = dados;

    const data = formatDataTime(data_disparo);
    const links = qtd_links_enviados + " / " + qtd_links_entregues;
    return {
      id,
      status,
      cliente: cliente?.nome,
      nome: dados?.nome,
      tipo,
      categoria: categoria?.descricao,
      links,
      data,
      qtd_links_enviados,
      qtd_links_entregues,
      job,
      fim,
    };
  });
}

export function getAcoesMulti(data) {
  return data.map((dados) => {
    const {
      id,
      status: { status },
      cliente,
      tipo: { tipo },
      categoria,
      qtd_links_entregues,
      qtd_links_enviados,
      data_disparo,
      user,
      recipientes,
      job,
    } = dados;

    let erros = 0;
    let processados = 0;

    recipientes.forEach((r) => {
      if (r.status !== "PROCESSING") {
        processados++;
        if (r.status === "ERROR") {
          erros++;
        }
      }
    });

    const data = formatDataTime(data_disparo);
    const links = qtd_links_enviados + " / " + qtd_links_entregues;
    return {
      id,
      status,
      cliente: cliente?.nome,
      nome: dados?.nome,
      tipo,
      categoria: categoria?.descricao,
      links,
      data,
      qtd_links_enviados,
      qtd_links_entregues,
      user: user?.name,
      processados,
      erros,
      job,
    };
  });
}

export function getPedidos(data) {
  return data.map((dados) => {
    const {
      id,
      status,
      cliente,
      cliente_area_contato,
      nome = "",
      total_itens,
      created_at,
      user,
      can_export,
      job,
      voucher_status,
      is_outlet,
    } = dados;
    const dataCriacao = formataStringData(created_at);
    return {
      id,
      status,
      cliente: cliente?.nome,
      area: cliente.areas.map((item) => item.nome).join(", "),
      titulo: nome,
      qtd: total_itens,
      data: dataCriacao,
      solicitante: cliente_area_contato?.nome,
      admin: user?.name,
      can_export,
      job,
      gerado: voucher_status[0].gerado,
      visualizado: voucher_status[0].visualizado,
      estornado: voucher_status[0].estornado,
      is_outlet,
    };
  });
}

export function formataStringData(data) {
  let date = new Date(data);
  const ano = date.getFullYear();
  const mes = `00${date.getMonth() + 1}`.slice(-2);
  const dia = `00${date.getDate()}`.slice(-2);

  return `${dia}/${mes}/${ano}`;
}

export function formataNumeroTelefone(numero) {
  if (!numero) return false;

  const ddd = numero.substr(2, 2);
  numero = numero.substr(4);

  var length = numero.length;
  var telefoneFormatado;

  if (length === 8) {
    telefoneFormatado =
      "(" + ddd + ") " + numero.substring(0, 4) + "-" + numero.substring(4, 8);
  } else if (length === 9) {
    telefoneFormatado =
      "(" + ddd + ") " + numero.substring(0, 5) + "-" + numero.substring(5, 9);
  }

  var arr = telefoneFormatado.split("");

  var telefone = arr
    .map((item, key) => {
      if (key >= 5 && key <= 12) {
        return "*";
      } else {
        return item;
      }
    })
    .join("");

  return telefone;
}

export function returnLastItemUrl(str) {
  return str.split("/").pop();
}

export function formatCurrentMoney(value) {
  if (!value) return "";

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

export function filtroVouchers(data = [], busca = "") {
  return data.filter((data) => {
    return JSON.stringify(data, ["nome", "valor_reais", "total_disponivel"])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export async function getDetalhePedido(id, paginacao = null) {
  try {
    const { data } = await api.get(
      `/pedido/${id}${paginacao ? `?page=${paginacao}` : ""}`
    );

    if (!data) return { result: [], page: {} };
    const {
      status,
      created_at,
      nome,
      total_itens,
      cliente,
      can_export,
      recipiente,
      id: codigo,
      job,
      pedido_estoque_id,
    } = data.length ? data[0] : data;
    const topo = {
      job,
      codigo,
      can_export,
      status,
      created_at: formatDateIso(created_at),
      nome,
      total_itens,
      contratante: cliente?.nome,
      qtd_voucher: data.length
        ? data[0].itens.data.length
        : data.itens.data.length,
      valor_total: data.length
        ? formatCurrentMoney(
            data[0].itens.data.reduce((acc, prod) => {
              return (acc += parseFloat(prod.valor_reais));
            }, 0)
          )
        : formatCurrentMoney(
            data.itens.data.reduce((acc, prod) => {
              return (acc += parseFloat(prod.valor_reais));
            }, 0)
          ),
      pedido_estoque_id,
    };

    let page, result;
    if (data.length) {
      page = {
        ...data[0].itens,
      };

      const estoque_id = data.map(({ pedido_estoque_id }) => {
        return pedido_estoque_id;
      });

      const id_pedido = data.map(({ id }) => {
        return id;
      });

      const items = data.map(({ id, itens }) => {
        const mappedData = itens.data.map((item) => ({
          variacao_id: item.produto_variacao_id,
          nome: item.produto_variacao_nome ? item.produto_variacao_nome : "",
          validade: item.produto_vencimento_estoque
            ? item.produto_vencimento_estoque
            : "",
          valor: formatCurrentMoney(parseFloat(item.valor_reais)),
          id: item.id,
          status: item.status,
          data: formatDateIso(item.created_at),
        }));

        return mappedData;
      });

      return { result: items, topo, page, recipiente, estoque_id, id_pedido };
    } else {
      page = {
        ...data.itens,
      };
      result = data.itens.data.map((i) => {
        return {
          variacao_id: i.produto_variacao_id,
          nome: i.produto_variacao_nome ? i.produto_variacao_nome : "",
          validade: i.produto_vencimento_estoque
            ? formatDateIso(i.produto_vencimento_estoque)
            : "",
          valor: formatCurrentMoney(parseFloat(i.valor_reais)),
          id: i.id,
          status: i.status,
          data: formatDateIso(i.status_updated_at),
        };
      });
    }

    return { result: [result], topo, page, recipiente };
  } catch (error) {
    console.log(error);
    return { result: [], page: {}, topo: {}, recipiente: {} };
  }
}

export async function getDetalhePedidoVoucher(id, paginacao = null) {
  try {
    const { data } = await api.get(
      `/pedido/${id}${paginacao ? `?page=${paginacao}` : ""}`
    );

    if (!data) return { result: [], page: {} };
    const {
      cliente_id,
      cliente_area_contato_id,
      nome,
      entrega_codigo,
      job,
      pedido_estoque_id,
    } = data.length ? data[0] : data;
    const result = {
      job,
      nome,
      pedido_estoque_id,
      cliente_id,
      cliente_area_contato_id,
      entrega_codigo,
    };

    return { result };
  } catch (error) {
    console.log(error);
    return { result: [] };
  }
}
