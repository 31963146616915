import styled, { css } from "styled-components";

import { Container as Tabela } from "../tabelas/styles";

const active = css`
  background: ${(props) => props.overlay};
  pointer-events: all;
  z-index: 99;
  transition: all 0.5s;
  .container-body {
    top: 50% !important;
    transition: all 0.5s;
  }

  @media (max-width: 480px) {
    .container-body {
      top: 50% !important;
    }
  }
`;

const scroll = css`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(89, 92, 118, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: transparent;
  z-index: -1;
  pointer-events: none;
  ${(props) => (props.active ? active : null)};
  transition: all 0.5s;
  .container-body {
    position: absolute;
    top: -5000px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.5s;
    ${(props) => (props.scroll ? scroll : null)};
  }

  @media (max-width: 480px) {
    .container-body {
      width: 90%;
    }
  }
`;

export const DetalhesArquivo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.branco};
  width: 1064px;
  border-radius: 16px;
  padding: 20px;
  margin: 0 auto;
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
  }
  img {
    margin: 30px 0;
  }
  button {
    width: 295px;
  }
  ${Tabela} {
    padding: 0;
    width: 100%;
    &.tabela-modal {
      .table {
        .tr {
          &.body {
            background: #f8f8f8;
            &.erro {
              border: 1px solid ${(props) => props.theme.colors.Vinho5};
              span {
                color: ${(props) => props.theme.colors.Vinho5};
              }
            }
          }
        }
      }
    }
  }
`;

export const Topo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .close-modal {
    margin: 0;
    cursor: pointer;
  }
  .usuarios,
  .link,
  .erro {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
`;

export const Erros = styled.div`
  border-top: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .ver-todos {
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.Vinho1};
  }
  .erro {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
`;

export const AddProduto = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 736px;
  height: 725px;
  border-radius: 8px;
  padding: 48px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 48px;
    right: 48px;
    cursor: pointer;
    margin: 0;
    width: 32px;
  }
  input[type="text"] {
    border: 1px solid #dfdfdf;
    background: #fff;
    width: 100%;
    padding: 20px;
    margin: 20px 0px 20px 0px;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 20px;
  }
  button {
    width: 253px !important;
    height: 50px;
    background-color: #1cde90;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 20px 14px 20px;
  }
`;

export const EditaAcao = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 536px;
  height: 425px;
  border-radius: 8px;
  padding: 48px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 48px;
    right: 48px;
    cursor: pointer;
    margin: 0;
    width: 32px;
  }
  input[type="text"] {
    border: 1px solid #dfdfdf;
    background: #fff;
    width: 100%;
    padding: 20px;
    margin: 20px 0px 20px 0px;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 20px;
  }
  button {
    width: 253px !important;
    height: 50px;
    background-color: #1cde90;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 20px 14px 20px;
    margin: 0 auto;
  }
`;

export const Products = styled.div`
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(89, 92, 118, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
`;

export const EnvioPedido = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 436px;
  height: 100%;
  border-radius: 8px;
  padding: 48px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 48px;
    right: 48px;
    cursor: pointer;
    margin: 0;
    width: 32px;
  }
  .box {
    margin-top: 20px;
    p {
      margin-bottom: 10px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 20px;
  }
  button {
    width: 253px !important;
    height: 50px;
    background-color: #1cde90;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 20px 14px 20px;
  }
`;
