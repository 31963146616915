import { put, call } from "redux-saga/effects";
import { Types as PedidosTypes } from "../ducks/pedidos";
import { api } from "../../services/api";
import { returnLastItemUrl } from "../../utils/helper";
import { getPedidos } from "../../utils/helper";

export function* ShowPedidos() {
  try {
    const {
      data: { data, links, path, ...rest },
    } = yield call(api.get, `pedido`);

    yield put({
      type: PedidosTypes.PEDIDOS_SUCCESS,
    });

    yield put({
      type: PedidosTypes.DATA_PEDIDOS,
      payload: {
        data: getPedidos(data.data),
        paginacao: {
          ...rest,
          data,
          path: returnLastItemUrl(data.path),
        },
      },
    });
  } catch (error) {
    yield put({
      type: PedidosTypes.PEDIDOS_FAILUIRE,
    });
    console.log(error);
  }
}

export function* paginacaoPedidos({ payload }) {
  const { id } = payload;

  try {
    const {
      data: { data, links, path, ...rest },
    } = yield call(api.get, `pedido?page=${id}`);

    yield put({
      type: PedidosTypes.PEDIDOS_SUCCESS,
    });

    yield put({
      type: PedidosTypes.DATA_PEDIDOS,
      payload: {
        data: getPedidos(data.data),
        paginacao: {
          ...rest,
          data,
          path: returnLastItemUrl(data.path),
        },
      },
    });
  } catch (error) {
    yield put({
      type: PedidosTypes.PEDIDOS_FAILUIRE,
    });
    console.log(error);
  }
}

export function* downloadPedidos() {
  try {
    const {
      data: { data, links, path, ...rest },
    } = yield call(api.get, `pedido/area`);

    yield put({
      type: PedidosTypes.PEDIDOS_SUCCESS,
    });

    yield put({
      type: PedidosTypes.DATA_PEDIDOS,
      payload: {
        data: getPedidos(data.data),
        paginacao: {
          ...rest,
          data,
          path: returnLastItemUrl(data.path),
        },
      },
    });
  } catch (error) {
    yield put({
      type: PedidosTypes.PEDIDOS_FAILUIRE,
    });
    console.log(error);
  }
}

export function* paginacaoDownloadPedidos({ payload }) {
  const { id } = payload;

  try {
    const {
      data: { data, links, path, ...rest },
    } = yield call(api.get, `pedido/area?page=${id}`);

    yield put({
      type: PedidosTypes.PEDIDOS_SUCCESS,
    });

    yield put({
      type: PedidosTypes.DATA_PEDIDOS,
      payload: {
        data: getPedidos(data.data),
        paginacao: {
          ...rest,
          data,
          path: returnLastItemUrl(data.path),
        },
      },
    });
  } catch (error) {
    yield put({
      type: PedidosTypes.PEDIDOS_FAILUIRE,
    });
    console.log(error);
  }
}
