import React from "react";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

import Button from "../../components/button";

function ButtonCancelar(props) {
    const {
        title,
        message,
        titleBtn = "Cancelar",
        onClick = () => {},
        bgc = "red",
        transparent = true,
        disabled = false,
    } = props;

    function cancelar() {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        if (onClick) {
                            onClick();
                        }
                    },
                },
                {
                    label: "Não",
                    onClick: () => {},
                },
            ],
        });
    }

    return (
        <Button
            type="button"
            title={titleBtn}
            onClick={cancelar}
            bgc={bgc}
            transparent={transparent}
            disabled={disabled}
        />
    );
}

export default ButtonCancelar;
