import { NovoClienteProvider } from "./Hook";
import { Cliente } from "./cliente";

export function NovoCliente() {
    return (
        <NovoClienteProvider>
            <Cliente />
        </NovoClienteProvider>
    );
}
