import React, { useState, useEffect } from "react";
import { FormModalCopy } from "../../components/formModalCopy";
import { ConfirmaDados } from "../../components/formModalCopy/confirma";
import { Creators as AcaoCreators } from "../../store/ducks/acao";
import { useDispatch } from "react-redux";

import { Modal } from "./styles";

import imgFechar from "../../assets/img/sistema/fechar-modal.svg";

export function ModalCopyAcao(props) {
  const { setReload } = props;
  const [optRecipientes, setOptRecipientes] = useState();

  useEffect(() => {
    const arrayRecipientes = [];
    props.recipientes?.forEach((r) => {
      const recipientes = JSON.parse(r.custom_fields);
      const optionSelect = {
        value: r.id,
        label: recipientes.nome,
      };
      arrayRecipientes.push(optionSelect);
    });
    setOptRecipientes(arrayRecipientes);
  }, [props.recipientes]);

  const [checkItens, setCheckItens] = useState({});
  const [value, setValue] = useState({});
  const dispatch = useDispatch();

  return (
    <Modal>
      <div className="container-header">
        {!value.template_id ? (
          <h1>
            Configure novo disparo
            <button onClick={() => dispatch(AcaoCreators.modal_copy_acao())}>
              <img src={imgFechar} alt="" />
            </button>
          </h1>
        ) : (
          <h2>Confirmação</h2>
        )}

        {!value.template_id && props.active ? (
          <FormModalCopy
            setCheckItens={setCheckItens}
            setValue={setValue}
            recipientes={optRecipientes}
          />
        ) : (
          <ConfirmaDados
            {...checkItens}
            setValue={setValue}
            value={value}
            setReload={setReload}
          />
        )}
      </div>
    </Modal>
  );
}
