import React, { useState } from "react";

import { useDispatch } from "react-redux";
import Alerta from "../../components/alerta";
import { Creators as ContratanteCreatos } from "../../store/ducks/contratante";

import opcoes from "../../assets/img/sistema/opcoes.svg";
import excluir from "../../assets/img/sistema/excluir.svg";
import link from "../../assets/img/sistema/link.svg";

import { Link } from "./styles";

function Opcoes(props) {
    const { id } = props;
    const dipatch = useDispatch();
    const [dropDown, setdropDown] = useState(false);

    function excluirContrante(id) {
        dipatch(ContratanteCreatos.deleteContrante(id));
    }

    return (
        <>
            <img
                src={opcoes}
                alt=""
                className="opcoes"
                onClick={() => setdropDown(!dropDown)}
            />
            <div
                className={
                    dropDown ? "dropdown-opcoes active" : "dropdown-opcoes"
                }
            >
                <Link
                    to={{
                        pathname: `/contratantes/${id}`,
                        id: id,
                    }}
                >
                    <img src={link} alt="" />
                    Editar
                </Link>
                <Alerta
                    click={excluirContrante}
                    id={id}
                    title="Exclusão Contratante"
                    message="Está ação não podera ser revertida tem certeza disso?"
                >
                    <span>
                        <img src={excluir} alt="" />
                        Excluir
                    </span>
                </Alerta>
            </div>
        </>
    );
}

export default Opcoes;
