import React, { useCallback, useEffect, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import InputMask from "react-input-mask";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import browserHistory from "../../config/browserHistory";
import { api } from "../../services/api";
import {
  getContratante,
  getAreasUsuario,
  getRoles,
  formatCelular,
} from "../../utils/helper";
import { validadtionUserDadosEdit } from "../../utils/configForm";

import Button from "../../components/button";
import NovoSelect from "../../components/NovoSelect";
import SelectCustom from "../../components/SelectCustom";
import Topo from "../../components/topo/cadastro";

import { Novo } from "./styles";

function EditaUsuario() {
  const history = useHistory();
  const [contratante, setContratante] = useState([]);
  const [areasTodas, setAreasTodas] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [usuario, setUsuario] = useState();
  const [userDados, setUserDados] = useState();
  const historyId = history.location.state.id;
  const metricas = ["Fraca", "Fraca", "Certo", "Bom", "Forte"];

  const load = useSelector((state) => state.user.loading);

  const getOptionRole = useCallback(async () => {
    const perfis = await getRoles();
    setPerfis(perfis);
  }, []);

  const getUser = useCallback(async () => {
    const { data } = await api.get(`usuario/${historyId}`);
    const areasUsuario = [];
    data.areas.map((i) => areasUsuario.push(i.id));
    const userDados = {
      name: data.name,
      email: data.email,
      phone_number: data.phone_number.replace("55", ""),
      role: data.roles[0].name,
      contratantes: [],
      cliente_id: data.cliente.nome.toLocaleLowerCase(),
      areas: areasUsuario,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    setUsuario(data);
    setUserDados(userDados);
  }, [historyId]);

  const getItem = useCallback(async () => {
    const contratante = await getContratante();
    setContratante(contratante);

    const cliente = contratante.find(
      (item) =>
        item.label.toLocaleLowerCase() ===
        usuario?.cliente.nome.toLocaleLowerCase()
    );
    buscaAreas(cliente?.value);
  }, [usuario?.cliente.nome]);

  async function buscaAreas(id) {
    if (id) {
      const options = await getAreasUsuario(id);
      setAreasTodas(options);
    }
  }

  async function update(values, actions) {
    const { phone_number, ...rest } = values;

    const phone_format =
      "55" +
      formatCelular(phone_number + "")
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");

    try {
      const response = await api.post(`/usuario/${historyId}`, {
        phone_number: phone_format,
        ...rest,
      });

      actions.resetForm();

      if (response.status === 200) {
        history.push("/usuarios");
        toast.success("Cadastro atualizado com sucesso!");
      } else {
        toast.error("Erro ao atualizar cadastro!");
      }
    } catch (error) {
      console.log("errr", error);
      return error;
    }
  }

  useEffect(() => {
    getItem();
    getOptionRole();
  }, [getItem, getOptionRole]);

  useEffect(() => {
    if (historyId) {
      getUser();
    }
  }, [historyId, getUser]);

  return (
    <Novo>
      <Topo />

      <div className="container">
        <h1>Dados do usuário</h1>
        {userDados && (
          <Formik
            enableReinitialize
            initialValues={userDados}
            validationSchema={validadtionUserDadosEdit}
            // initialValues={usuario}
            onSubmit={async (values, actions) => {
              await update(values, actions);
            }}
          >
            {(props) => {
              const { handleChange, setFieldValue, values } = props;
              const { phone_number } = values;

              const cliente = contratante.find(
                (item) =>
                  item.label.toLocaleLowerCase() ===
                  usuario?.cliente.nome.toLocaleLowerCase()
              );

              const perfil = {
                value: usuario?.roles[0].id,
                label: usuario?.roles[0].name,
              };

              const area = {
                value: usuario?.areas[0].id,
                label: usuario?.areas[0].nome,
              };

              return (
                <Form>
                  <div className="container-form-double">
                    <div className="container-form">
                      <Field
                        type="text"
                        placeholder="Nome"
                        name="name"
                        autoComplete="new-password"
                        // value={usuario && usuario.name}
                      />
                      <ErrorMessage component="span" name="name" />
                    </div>

                    <div className="container-form">
                      <Field
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="new-password"
                        // value={usuario && usuario.email}
                      />
                      <ErrorMessage component="span" name="email" />
                    </div>
                  </div>
                  <div className="container-form">
                    <InputMask
                      type="text"
                      placeholder="Celular"
                      name="phone_number"
                      mask="(99) 99999-9999"
                      defaultValue={userDados?.phone_number}
                      value={phone_number}
                      onChange={handleChange}
                    />
                    <ErrorMessage component="span" name="phone_number" />
                  </div>

                  <div className="container-form-double">
                    <div className="container-form">
                      <Field
                        type="password"
                        placeholder="Senha"
                        name="password"
                        autoComplete="new-password"
                      />
                      <PasswordStrengthBar
                        onChange={handleChange}
                        //   password={password}
                        scoreWords={metricas}
                        shortScoreWord="Muito curta"
                        type="password"
                        placeholder="Senha"
                        name="password"
                        autoComplete="new-password"
                      />
                      <ErrorMessage component="span" name="senha" />
                    </div>

                    <div className="container-form">
                      <Field
                        type="password"
                        placeholder="Confirmar Senha"
                        name="password_confirmation"
                        autoComplete="new-password"
                      />
                      <ErrorMessage
                        component="span"
                        name="password_confirmation"
                      />
                    </div>
                  </div>

                  <div className="container-form select">
                    {perfis.length > 0 && perfil?.label ? (
                      <NovoSelect
                        label="Perfil"
                        initalValue={perfil}
                        options={perfis}
                        onChange={(value) => {
                          setFieldValue("role", value.label);
                        }}
                      />
                    ) : null}
                  </div>

                  <div className="container-form-double">
                    <div className="container-form">
                      {contratante.length > 0 && cliente?.label ? (
                        <NovoSelect
                          label="Cliente"
                          options={contratante}
                          initalValue={cliente}
                          onChange={({ value, label }) => {
                            setFieldValue(
                              "cliente_id",
                              label.toLocaleLowerCase()
                            );
                            buscaAreas(value);
                          }}
                        />
                      ) : null}
                    </div>

                    <div className="container-form">
                      {areasTodas.length > 0 && area?.label ? (
                        <SelectCustom
                          label="Áreas"
                          options={areasTodas}
                          change={(value) => setFieldValue("areas", value)}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="btns">
                    <Button
                      title="Cancelar"
                      bgc={"#E25B5B"}
                      onClick={() => browserHistory.back()}
                    />
                    <Button
                      title="Atualizar"
                      className={load ? "disabled" : ""}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </Novo>
  );
}

export default EditaUsuario;
