import React, { useState, useEffect, memo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { api } from "../../services/api";

import {
  FormInitialState,
  validationPedidosDados,
} from "../../utils/configForm";

import { getContratante } from "../../utils/helper";

import Button from "../button";
import ButtonCancelar from "../ButtonCancelar";
import Box from "../boxProduto";
import Cliente from "../formsNovaAcao/contratante";
import EntregaCodigo from "../formsNovaAcao/entregaCodigo";
import Outlet from "../formsNovoPedido/outlet";
import Areas from "../formsNovaAcao/areas";
import Modal from "../modal";
import ModalProdutos from "../modal/addProduto";

function Dados({ onSubmit, setNomeContratante }) {
  const [contratante, setContratante] = useState([]);
  const [values, setValues] = useState(FormInitialState.pedidos.dados);

  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [objSelecionadosvalues, setObjSelecionadosvalues] = useState({});
  const [valueInputCard, setValueInputCard] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalAddProdutoIsOpen, setModalAddProdutoIsOpen] = useState(false);
  const [produtoResult, setProdutoResult] = useState([]);
  const [erros, setErros] = useState([]);

  function removeProduto(produto) {
    const produtos = produtoSelecionado.filter(({ id }) => id !== produto.id);
    setProdutoSelecionado(produtos);
  }

  useEffect(() => {
    api
      .get(`/produto`)
      .then(({ data }) => {
        setLoading(false);
        if (values.is_outlet) {
          setProdutos(data.data.filter((prod) => prod.total_outlet));
        } else {
          setProdutos(data.data.filter((prod) => prod.total_disponivel));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [values.is_outlet]);

  useEffect(() => {
    if (objSelecionadosvalues) {
      const updateProduct = [...produtoSelecionado];

      const produto = updateProduct.map((produto) => {
        if (produto.id === objSelecionadosvalues.id) {
          produto.value = parseInt(valueInputCard);
        }

        return produto;
      });

      setProdutoResult(produto);
    }
  }, [objSelecionadosvalues, valueInputCard, produtoSelecionado]);

  useEffect(() => {
    async function getItem() {
      const contratante = await getContratante();
      setContratante(contratante);
    }
    getItem();
  }, []);

  function handleSubmit(value, actions) {
    const erroIds = produtoResult.reduce((acc, prod) => {
      if (!prod.value) {
        acc.push({
          id: prod.id,
          qtd: prod.total_disponivel,
        });
      }

      if (values.is_outlet) {
        if (prod.value > prod.total_outlet) {
          acc.push({
            id: prod.id,
            qtd: prod.total_outlet,
            limit: true,
          });
        }
      } else {
        if (prod.value > prod.total_disponivel) {
          acc.push({
            id: prod.id,
            qtd: prod.total_disponivel,
            limit: true,
          });
        }
      }

      return acc;
    }, []);

    if (erroIds.length === 0) {
      onSubmit(value, actions, produtoResult);
      setErros([]);
    } else {
      setErros(erroIds);
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationPedidosDados}
        onSubmit={(value, actions) => {
          handleSubmit(value, actions);
          // onSubmit(value, actions);
        }}
      >
        {(props) => {
          const { values } = props;

          setValues(values);

          const { cliente_id } = values;
          return (
            <Form>
              <Field
                component={Cliente}
                id={cliente_id}
                options={contratante}
                setNomeContratante={setNomeContratante}
              />
              {cliente_id && <Field component={Areas} id={cliente_id} />}

              <Field component={EntregaCodigo} />

              {values.cliente_id === 9999 ? (
                <>
                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="Nome"
                      name="contratante_nome"
                    />
                    <ErrorMessage component="span" name="contratante_nome" />
                  </div>
                  <div className="container-form">
                    <Field
                      type="text"
                      placeholder="E-mail"
                      name="contratante_email"
                    />
                    <ErrorMessage component="span" name="contratante_email" />
                  </div>
                </>
              ) : null}

              <Field component={Outlet} />

              <div className="container-form">
                <Field type="text" placeholder="Job" name="job" />
                <ErrorMessage component="span" name="job" />
              </div>

              <div className="container-form">
                <Field
                  type="text"
                  placeholder="Título do Pedido"
                  name="titulo"
                />
                <ErrorMessage component="span" name="titulo" />
              </div>

              <h1 className="vouchers">Vouchers</h1>

              <Button
                className="add"
                title="+ Adicionar Vouchers"
                type="button"
                color="#0191CD"
                bgc="#fff"
                onClick={() => setModalAddProdutoIsOpen(!modalAddProdutoIsOpen)}
              />

              <div className="produtos">
                {produtoSelecionado ? (
                  produtoSelecionado.map((produto) => {
                    return (
                      <Box
                        key={produto.id}
                        setObjSelecionadosvalues={setObjSelecionadosvalues}
                        setValueInputCard={setValueInputCard}
                        produto={produto}
                        erros={erros}
                        click={(e) => removeProduto(produto)}
                        outlet={values.is_outlet}
                      />
                    );
                  })
                ) : (
                  <Box />
                )}
              </div>
              <div className="btns">
                <ButtonCancelar pedido={true} />

                <Button title="Avançar" />
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        // onChange={onChange}
        rest={{ produtos, loading, outlet: values.is_outlet }}
        component={ModalProdutos}
        active={modalAddProdutoIsOpen}
        setProdutos={(produto) => {
          setProdutoSelecionado(produto);
        }}
        close={() => setModalAddProdutoIsOpen(false)}
      />
    </div>
  );
}

export default memo(Dados);
