import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import browserHistory from "../../config/browserHistory";

import { useTemplate } from "../../Hook/useTemplate";

import { getContratante } from "../../utils/helper";

import Button from "../button";
import NovoSelect from "../NovoSelect";
import Topo from "../topo/cadastro";
import { Email } from "./email";
import { Sms } from "./sms";
import { myFormValues, validationFormEmail, validationFormSms } from "./config";

import { Novo } from "./styles";

export function EditarTemplate() {
  const history = useHistory();
  const id = history.location.state.id;
  const type = history.location.state.type;

  const [tipoForm, setTipoForm] = useState(null);
  const [validation, setValidation] = useState(null);
  const [values, setValues] = useState(myFormValues);
  const [fields, setFields] = useState([]);
  const [success, setSuccess] = useState(false);

  const { updateTemplate, getTemplateById } = useTemplate();
  const [html_content, setHtml_content] = useState("");

  const [contratante, setContratante] = useState([]);

  const tipos = [
    {
      value: 1,
      label: "SMS",
    },
    {
      value: 2,
      label: "E-mail",
    },
    {
      value: 3,
      label: "Whatsapp",
    },
  ];

  const arquivados = [
    {
      value: 1,
      label: "Arquivado",
    },
    {
      value: 0,
      label: "Não arquivado",
    },
  ];

  const getItem = useCallback(async () => {
    const contratante = await getContratante();

    setContratante(contratante);
  }, []);

  useEffect(() => {
    if (tipoForm) {
      setValidation(
        tipoForm === "e-mail" || tipoForm === "whatsapp"
          ? validationFormEmail
          : validationFormSms
      );
      setValues(myFormValues);
    }
  }, [tipoForm]);

  useEffect(() => {
    async function fetchDados() {
      const { data } = await getTemplateById(id);
      setFields(Object.values(JSON.parse(data?.fields || "{}")));

      const values = {
        type: type === "email" ? "e-mail" : type,
        cliente_id: data?.cliente_id,
        name: data?.name,
        text: data?.text,
        fields: Object.values(JSON.parse(data?.fields || "{}")),
        status: data?.status,
        sendgrid_id: data?.sendgrid_id,
        html_content: data?.html_content,
        archived: data?.archived,
      };

      setTipoForm(type === "email" ? "e-mail" : type);
      setHtml_content(data?.html_content);
      setValues(values);
    }

    fetchDados();
  }, [getTemplateById, id, type]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  async function handleSubmit(values, actions) {
    const response = await updateTemplate(values);

    actions.resetForm();

    if (response.status === 200) {
      history.push("/template");
      toast.success("Cadastro realizado com sucesso!");
    } else {
      toast.error("Erro ao realizar cadastro!");
    }
  }

  return (
    <Novo>
      <Topo
        activeFiltro={success}
        toggleFiltro={setSuccess}
        title="Usuários do Sistema"
        linkCadastro=""
      />

      <div className="container">
        <h1>Editar Template</h1>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validation}
          onSubmit={async (value, actions) => {
            const {
              fields,
              cliente_id,
              name,
              status,
              text,
              type,
              html_content,
              sendgrid_id,
              archived,
            } = value;

            const sms = {
              name,
              cliente_id,
              status,
              text,
              type,
              archived,
              fields: Array.isArray(fields)
                ? fields
                : Object.values(JSON.parse(values?.fields || "{}")).map(
                    (item) => item
                  ) || [],
            };

            const email = {
              html_content,
              cliente_id,
              name,
              sendgrid_id,
              status,
              archived,
              type: "email",
            };

            await handleSubmit(tipoForm === "e-mail" ? email : sms, actions);
          }}
        >
          {(props) => {
            const { setFieldValue } = props;
            const { values } = props;

            const cliente = contratante.find(
              (item) => item.value === props?.values?.cliente_id
            );

            const tipo = tipos.find(
              (item) =>
                item.label.toLocaleLowerCase() ===
                props?.values?.type?.toLocaleLowerCase()
            );

            const valorArquiva = arquivados.find(
              (item) => item.value === props?.values?.archived
            );

            return (
              <Form>
                <div className="container-form select type">
                  <NovoSelect
                    label="Tipo"
                    options={tipos}
                    initalValue={tipo}
                    onChange={({ value, label }) => {
                      setFieldValue("type", label.toLocaleLowerCase());
                      setTipoForm(label.toLocaleLowerCase());
                    }}
                  />
                </div>
                <ErrorMessage component="span" name="type" />

                <div className="container-form-double">
                  <NovoSelect
                    label="Cliente"
                    options={contratante}
                    initalValue={cliente}
                    onChange={({ value, label }) => {
                      setFieldValue("cliente_id", value);
                    }}
                  />
                  {/* <Field
                    component={Contratante}
                    initalValue={values.cliente_id}
                    id={cliente_id}
                    options={contratante}
                  /> */}
                  <ErrorMessage component="span" name="cliente_id" />
                </div>

                <div className="container-form-double">
                  <NovoSelect
                    label="Arquivar"
                    options={arquivados}
                    initalValue={valorArquiva}
                    onChange={({ value, label }) => {
                      setFieldValue("archived", value);
                    }}
                  />
                  {/* <Field
                    component={Contratante}
                    initalValue={values.cliente_id}
                    id={cliente_id}
                    options={contratante}
                  /> */}
                  <ErrorMessage component="span" name="archived" />
                </div>

                {tipoForm === "e-mail" ||
                values.type === "e-mail" ||
                tipoForm === "whatsapp" ||
                values.type === "whatsapp" ? (
                  <Field>
                    {(props) => <Email {...props} testando={html_content} />}
                  </Field>
                ) : null}

                {tipoForm === "sms" || values.type === "sms" ? (
                  <Field component={Sms} fields={fields} />
                ) : null}

                <div className="btns">
                  <Button
                    title="CANCELAR"
                    bgc={"#E25B5B"}
                    onClick={() => browserHistory.back()}
                    type="button"
                  />
                  <Button
                    title="ATUALIZAR"
                    // className={load ? "disabled" : ""}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Novo>
  );
}
