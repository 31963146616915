import * as React from "react";

import Button from "../button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import imgXVerde from "../../assets/img/sistema/x.svg";
import imgCadastroCliente from "../../assets/img/sistema/sucess-cadastro-carga-infra.svg";

import { ModalStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
    const { open, handleClose, aceept, reject, title, description } = props;

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalStyles>
                    <div className="container-header">
                        <p>ATENÇÃO</p>
                        <button onClick={handleClose}>
                            <img src={imgXVerde} alt="" />
                        </button>
                    </div>

                    <div className="container-sucess">
                        <img src={imgCadastroCliente} alt="" />

                        <h3>{title}</h3>

                        <p>{description}</p>

                        <div className="container-btns">
                            <Button onClick={reject} title="Não" />
                            <Button onClick={aceept} title="Sim" />
                        </div>
                    </div>
                </ModalStyles>
            </Dialog>
        </div>
    );
}
