import styled from "styled-components";
import { ResumoUpload } from "../../components/formsNovaAcao/styles";
import busca from "../../assets/img/sistema/busca.svg";
import data from "../../assets/img/sistema/calendario.svg";
import { Container as Select } from "../../components/select/styles";
import { Container as Breadcrumb } from "../../components/breadcrumb/styles";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Loader = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const Conteudo = styled.div`
  .container-loading {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .conteudo {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(520px, 1fr)); */
    grid-gap: 30px;
    padding: 30px;
    padding-bottom: 90px;
    overflow: hidden;
    .status {
      border: none;
      border: 1px solid #1cde90;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #1cde90;
      background-color: transparent;
      cursor: auto;
      &.processando,
      &.processing {
        color: #ffa500;
        border: 1px solid #ffa500;
      }
      &.aguardando {
        color: #ffdb58;
        border: 1px solid #ffdb58;
      }
      &.agendada {
        color: #0097d5;
        border: 1px solid #0097d5;
      }

      &.rascunho,
      &.cancelada {
        color: #9d9d9d;
        border: 1px solid #9d9d9d;
      }
      &.erro,
      &.error,
      &.encerrada {
        color: #ff0000;
        border: 1px solid #ff0000;
      }
    }
  }
`;

export const Acoes = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  background-color: ${(props) => props.theme.colors.branco};
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  color: ${(props) => props.theme.colors.Cinza03};
  align-items: center;
  height: 0px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  padding: 0 20px;
  &.active {
    overflow: inherit;
    height: 80px;
    transition: all 0.5s;
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  input {
    border: none !important;
    width: 100% !important;
    height: 40px !important;
    background: inherit !important;
  }
  .busca {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 12px;
      right: 18px;
      background-image: url(${busca});
      z-index: 20;
    }
  }
  .data {
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 17px;
      height: 18px;
      position: absolute;
      top: 12px;
      right: 18px;
      background-image: url(${data});
      z-index: 20;
    }
  }
`;

export const Editar = styled.div`
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
  ${Acoes} {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.Cinza06};
    form {
      width: 1140px;
      margin: 0 auto;
      display: flex;
      .busca {
        width: 50%;
        input {
          padding-left: 0px;
        }
      }
    }
  }
  .editaCampo {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    width: 100%;
    input {
      /* border: 1px solid; */
      border: none;
      width: 100%;
      background: none !important;
      border-radius: none;
      height: auto !important;
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
      color: #333333;
      text-transform: uppercase;
      &:focus {
        border: 1px solid black;
      }
    }
    img {
      cursor: pointer;
    }
  }
  .btns {
    display: flex;
    grid-gap: 10px;
    &.cancelar {
      pointer-events: none;
      cursor: not-allowed;
    }
    button {
      width: auto;
      padding: 0px 20px;
    }
    a,
    span {
      border: none;
      background-color: transparent;
      background-color: ${(props) => props.theme.colors.Vinho1};
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .btnTopo {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      margin-right: 30px;
      span {
        width: 100%;
        background: none;
        height: auto;
        font-weight: 700;
        font-size: 11px;
        line-height: 100%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #767b7f;
      }
      &.dropdownExport {
        position: relative;
        .dropdown {
          position: absolute;
          height: 0;
          padding: 0 20px;
          overflow: hidden;
          background-color: #fff;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: max-content;
          top: 35px;
          span {
            margin-bottom: 10px;
            position: relative;
            padding-left: 5px;
            &:before {
              content: "";
              display: block;
              width: 5px;
              height: 5px;
              background-color: #0097d5;
              position: absolute;
              left: -10px;
              top: 3px;
            }
          }
          &.active {
            overflow: inherit;
            height: 85px;
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .topoDados {
    background: #0097d5;
    .container-topo {
      width: 1609px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(138px, 1fr));
      align-items: center;
      justify-content: space-between;
      grid-gap: 16px;
      min-height: 213px;
      margin-bottom: 10px;
      padding: 20px 0;
      .item {
        padding: 24px;
        min-height: 151px;
        height: 100%;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 24px;
        }
      }
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza02};
      }
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-top: 5px;
        overflow-wrap: anywhere;
      }
    }
  }

  .msg-erro {
    height: 76px;
    background: #ffd8d8;
    border-top: 3px #eb5757 solid;
    margin-top: -10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    .msg {
      display: flex;
      grid-gap: 20px;
    }
    .enviar-novamente {
      width: auto;
      padding: 0 10px;
    }
  }

  .status {
    border: none;
    border: 1px solid #1cde90;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #1cde90;
    background-color: transparent;
    cursor: auto;
    &.processando,
    &.processing {
      color: #ffa500;
      border: 1px solid #ffa500;
    }

    &.agendada {
      color: #0097d5;
      border: 1px solid #0097d5;
    }

    &.disparada {
    }

    &.rascunho {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
    &.erro,
    &.error {
      color: #ff0000;
      border: 1px solid #ff0000;
    }
  }

  .disparo-estorno {
    display: flex;
    grid-gap: 35px;
    img {
      cursor: pointer;
    }
  }
  .container {
    max-width: 1609px;
    width: 100%;
    &.editar-acao {
      padding-bottom: 24px;
    }
    &.dashboard {
      display: grid;
      grid-template-columns: 600px 600px;
      grid-gap: 50px;
      justify-content: center;
      margin-top: 70px;
      .s-esq {
        border-right: 1px solid #d7d7d7;
        padding-right: 50px;
      }
      .s-dir {
        padding-left: 50px;
      }
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: #000000;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
`;

export const Nova = styled.div`
  .container {
    padding-bottom: 100px;
    transition: all 0.5s;
    &.voucher {
      padding-left: 0px;
      /* grid-template-columns: 140px 1fr; */
      .s-esq {
        width: 100%;
      }
    }
    &.active {
      filter: blur(4px);
      pointer-events: none;
      opacity: 0.6;
      transition: all 0.5s;
    }
    ${Breadcrumb} {
      grid-column: span 2;
    }
    .s-esq {
      width: 774px;
      position: relative;
      /* img {
        margin-bottom: 40px;
      } */
      .preview {
        margin: 0;
        margin-bottom: 20px;
      }
    }
    /* .s-dir { */
    button {
      margin-right: 76px;
    }
    ${Select} {
      background: #ffffff;
      border: 0.5px solid #bdbdbd;
      border-radius: 4px;
    }

    form {
      &.disparo {
        display: flex;
        flex-direction: column;
        .container-form {
          order: 1;
          margin-bottom: 0px;
        }

        button {
          order: 3;
        }

        .voltar {
          order: 3;
        }

        ${ResumoUpload} {
          order: 2;
          margin-top: 7px;
          margin-bottom: 36px;
        }
      }
    }

    h1 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: ${(props) => props.theme.colors.preto};
      margin: 50px 0;
    }
    /* } */
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;

export const DadosAcao = styled.div`
  background-color: rgba(212, 212, 212, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  .item {
    position: relative;
    .hidden {
      display: none;
    }
    &:hover {
      .toolTip {
        bottom: 30px;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }
  .toolTip {
    border-radius: 5px;
    padding: 25px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
    color: #fff;
    font-size: 12px;
    pointer-events: none;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 20px;
  }
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza07};
  }
  p {
    font-weight: 800;
    font-size: 14px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza01};
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
  }
`;

export const Resumo = styled.div`
  .container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    .detalhes {
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-bottom: 30px;
      }
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza07};
      }
      p {
        font-weight: 800;
        font-size: 18px;
        line-height: 100%;
        color: ${(props) => props.theme.colors.Cinza01};
        margin-top: 5px;
      }
      .item-detalhe {
        position: relative;
        padding-left: 30px;
        height: 72px;
        &::before {
          content: "";
          background-color: ${(props) => props.theme.colors.Laranja1};
          width: 9px;
          height: 9px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          top: 4px;
          left: 0;
        }
        &:not(:last-of-type) {
          &::after {
            content: "";
            background-color: ${(props) => props.theme.colors.Cinza04};
            display: block;
            height: 46px;
            width: 1px;
            position: absolute;
            top: 20px;
            left: 3px;
          }
        }
      }
      button {
        margin-left: 30px;
        margin-top: 30px;
      }
    }
    .preview {
      margin: 0;
    }
  }
`;

export const ContainerFinal = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 205px 1fr;
    grid-gap: 150px;
    padding-left: 150px;
    .detalhes {
      width: 383px;
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: ${(props) => props.theme.colors.Vinho1};
        margin-bottom: 20px;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.Cinza02};
        margin-bottom: 40px;
      }
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-decoration-line: underline;
        color: ${(props) => props.theme.colors.Vinho1};
        margin-bottom: 40px;
        display: block;
      }
    }
  }
`;

export const Modal = styled.div`
  background: #fff;
  padding: 48px;
  width: 736px;
  border-radius: 16px;
  .container-input {
    margin-bottom: 16px;
  }

  .container-btn {
    width: 168px;
  }

  button {
    &.btn {
      width: 100%;
      background: #1cde90;
      mix-blend-mode: normal;
      border-radius: 4px;

      height: 50px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;

      display: flex;
      align-items: center;
      justify-content: center;

      &.cancelar {
        border: 1px solid #d4d4d4;
        color: #9d9d9d;
        background: transparent;
      }
    }
  }

  .container-header {
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 43px;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 43px;
      text-align: center;
    }
  }
`;
