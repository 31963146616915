import styled, { css } from "styled-components";

export const loading = css`
    pointer-events: none;
    opacity: 0.5;
`;

export const Container = styled.button`
    width: 154px;
    height: 40px;
    background-color: ${(props) =>
        props.transparent ? "transparent" : props.bgc ? props.bgc : "#0191CD"};
    color: ${(props) =>
        props.transparent
            ? props.bgc
            : props.color
            ? props.color
            : props.theme.colors.branco};
    font-weight: bold;
    font-size: 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    line-height: 100%;
    margin-left: ${(props) => props.left};
    cursor: pointer;
    ${(props) => props.loading && loading}
    border: solid 1px ${(props) =>
        props.transparent ? props.bgc : "transparent"};
    transition: all 0.5s;
    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        transition: all 0.5s;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;
