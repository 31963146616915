import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import setaDir from "../../assets/img/sistema/select.svg";

export default function CustomInputAutocomplete(props) {
  const { options, onChange, label, initalValue } = props;
  const [values, setValues] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const optionsOrder = options.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  useEffect(() => {
    if (initalValue) {
      setValues(initalValue);
    } else {
      setValues(null);
    }
  }, [initalValue]);

  return (
    <label>
      <Autocomplete
        disablePortal
        sx={{
          display: "inline-block",
          width: "100%",
          "& input": {
            width: "100%",
            bgcolor: "background.paper",
            color: (theme) =>
              theme.palette.getContrastText(theme.palette.background.paper),
          },
        }}
        onChange={(event, values) => {
          if (onChange) {
            onChange(values);
          }
          setValues(values);
        }}
        options={optionsOrder}
        value={values}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <div className={`container-form`} ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps} placeholder={label} />
            <img src={setaDir} alt="setaDir" />
          </div>
        )}
      />
    </label>
  );
}
