import React from "react";

import { Footer as Container } from "./styles";
import logoFooter from "../../assets/img/sistema/logo-footer.svg";

function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <Container>
      <span>
        YETZ LTDA | CNPJ: 28.325.166/0001-05 | {currentYear} | Todos os direitos
        reservados
      </span>

      <div className="container-img">
        <img src={logoFooter} alt="" />
      </div>
    </Container>
  );
}

export default Footer;
