import React, { useState } from "react";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
import { Clientes } from "../../components/Clientes/main";
import { Container, Conteudo } from "./styles";

export function PageClientes() {
  const [active, toggleFiltro] = useState(false);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Clientes"
          linkCadastro="/clientes/novo"
          permission={["cliente.store"]}
        />

        <Filtro activeFiltro={active} />

        <div className="conteudo">
          <Clientes />
        </div>
      </Conteudo>
    </Container>
  );
}
