import React from "react";

import { EnvioPedido } from "./styles";

import close from "../../assets/img/sistema/fechar-modal.svg";

const ModalEnvioPedido = (props) => {
  const { rest } = props;

  return (
    <EnvioPedido>
      <h3>Envio para:</h3>
      <div className="box">
        <p>Nome: {rest?.receiver?.nome}</p>
        <p>Email: {rest?.receiver?.email}</p>
      </div>

      <img className="close" src={close} alt="" onClick={() => props.close()} />
    </EnvioPedido>
  );
};

export default ModalEnvioPedido;
