import { ErrorMessage, Field, useFormikContext } from "formik";
import { Status } from "./status";
import { MemorizedCampo } from "./campos";

export function Sms() {
  const { values } = useFormikContext();

  return (
    <>
      <Field component={Status} />

      <div className="container-form">
        <Field
          type="text"
          placeholder="Nome do template"
          name="name"
          autoComplete="new-password"
        />
        <ErrorMessage component="span" name="name" />
      </div>

      <div className="container-form">
        <Field
          type="text"
          placeholder="Template text"
          name="text"
          autoComplete="new-password"
        />
        <p>Caracteres: {values.text.length}</p>
        <ErrorMessage component="span" name="text" />
      </div>

      <h1 className="variaveis">Variáveis</h1>

      <div className="container-form">
        <Field component={MemorizedCampo} />
        <ErrorMessage component="span" name="fields" />
      </div>
    </>
  );
}
