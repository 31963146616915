import React from "react";
import { useDispatch } from "react-redux";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import Button from "../button";
import { Voltar } from "./styles";
import { ObjIsEmpty } from "../../utils/helper";
import ButtonCancelar from "../ButtonCancelar";

function HandleButtonSubmit(props) {
  const dispatch = useDispatch();
  const {
    disparo,
    disparoAgendado = {},
    error,
    loading,
    editar,
    // entrega_voucher,
    change,
    tipo_voucher,
    categoria,
  } = props;

  if (error) return <></>;

  if (tipo_voucher === 2) {
    return <Button title="Efetivar" loading={loading} />;
  }

  if (!ObjIsEmpty(disparoAgendado)) {
    if (disparoAgendado.disparo_agendado && categoria === 1) {
      return (
        <Button
          title="Avança"
          onClick={() => {
            change("voucher");
          }}
          loading={loading}
        />
      );
    }

    if (disparoAgendado.disparo_agendado) {
      return (
        <Voltar
          className="voltar"
          to="/acoes"
          onClick={() => {
            dispatch(AcaoCreators.clearResumo());
            dispatch(AcaoCreators.clearAll());
          }}
        >
          Voltar
        </Voltar>
      );
    }
  }

  if (!ObjIsEmpty(disparoAgendado)) {
    if (disparoAgendado.disparo_agendado) {
      return <Button title="Efetivar Envio" loading={loading} />;
    }
  }

  if (!disparo) {
    return (
      <>
        {!editar && <ButtonCancelar />}
        <Button title="Avançar" loading={loading} />
      </>
    );
  }

  if (tipo_voucher === 1) {
    return (
      <Button
        title="Avançar"
        onClick={() => {
          change("voucher");
        }}
        loading={loading}
      />
    );
  }

  return <Button title="Efetivar" loading={loading} />;
}

export default HandleButtonSubmit;
