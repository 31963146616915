import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Creators as AcaoCreators } from "../../store/ducks/acao";

import {
  ObjIsEmpty,
  getDados,
  getContratante,
  getTipo,
  getTemplate,
} from "../../utils/helper";

import Topo from "../../components/topo/cadastro";
import Breadcrumb from "../../components/breadcrumb";
import Dados from "../../components/formsNovaAcao/dados";
import Disparo from "../../components/formsNovaAcao/disparo";
import Participantes from "../../components/formsNovaAcao/participantes";
import { Voucher } from "../../components/formsNovaAcao/voucher";
import { ConfirmeVoucher } from "../../components/formsNovaAcao/conFirmeVoucher";
// import Preview from "./preview";
import Loader from "./loader";

import { Nova } from "./styles";

// import logo from "../../assets/img/sistema/logo-menu.svg";
import { useEffect } from "react";
// import { closeModalDetalhesArquivo } from "../../store/modules/modal/actions";

function NovaAcao() {
  const [formSelecionada, alteraForm] = useState("dados");
  const [contratante, setContratante] = useState([]);
  const [tipo, setTipo] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { id },
  } = history;

  const { loading, data } = useSelector((state) => state.acao);
  const { data_voucher } = useSelector((state) => state.acao);
  const { resumo = {} } = data;
  const hasVoucher =
    data_voucher.voucher && formSelecionada === "confirma_vouchers"
      ? true
      : false;
  //   const isEmpty = ObjIsEmpty(preview);

  function _renderContent() {
    switch (formSelecionada) {
      case "dados": {
        if (ObjIsEmpty(resumo)) {
          if (resumo.disparo_agendado) {
            dispatch(AcaoCreators.clearResumo());
          }
        }
        return (
          <>
            <h1>Dados da Ação</h1>
            <Dados
              change={(form) => alteraForm(form)}
              editar={id}
              contratante={contratante}
              tipo={tipo}
            />
          </>
        );
      }

      case "disparo": {
        return (
          <>
            <h1>Envio</h1>
            <Disparo change={(form) => alteraForm(form)} editar={id} />
          </>
        );
      }

      case "participantes": {
        return (
          <>
            <h1>Carregar Participantes</h1>
            <Participantes change={(form) => alteraForm(form)} editar={id} />
          </>
        );
      }

      case "voucher": {
        return (
          <>
            <h1>Vouchers</h1>
            <Voucher change={(form) => alteraForm(form)} id={id} />
          </>
        );
      }

      case "confirma_vouchers": {
        return <ConfirmeVoucher change={(form) => alteraForm(form)} id={id} />;
      }
      default: {
        break;
      }
    }
  }

  const getItem = useCallback(async () => {
    const contratante = await getContratante();
    const { tipos, periodos } = await getTipo();
    const templates = await getTemplate();

    setContratante(contratante);
    setTipo(tipos);
    dispatch(AcaoCreators.createObject("tempTemplate", templates));
    dispatch(AcaoCreators.createObject("disparo", periodos));

    if (id) {
      const dados = await getDados(id, templates, periodos);

      const {
        nome,
        template: label,
        tipo_disparo_name: disparo,
        data_disparo,
      } = dados;

      dispatch(AcaoCreators.preview("nome", nome));
      dispatch(AcaoCreators.preview("template", label));
      dispatch(AcaoCreators.preview("tipo_disparo_name", disparo));
      dispatch(AcaoCreators.preview("data_disparo", data_disparo));
      dispatch(AcaoCreators.dados(dados));
      alteraForm("voucher");
    }
  }, [dispatch, id]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  return (
    <Nova>
      <Topo />

      {loading ? <Loader /> : null}

      <div
        className={`container ${loading ? "active" : ""} ${
          hasVoucher ? "voucher" : ""
        }`}
      >
        {/* {!hasVoucher ? <Breadcrumb active={formSelecionada} /> : null} */}
        <Breadcrumb active={formSelecionada} />
        <div className="s-esq">
          {/* <img src={logo} alt="" /> */}
          {_renderContent()}
        </div>
        {/* <div className="s-dir">
          {!isEmpty && !hasVoucher ? <Preview /> : null}
        </div> */}
      </div>
    </Nova>
  );
}

export default NovaAcao;
