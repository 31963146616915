import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { GridActionsCellItem } from "@mui/x-data-grid";
// import SecurityIcon from "@mui/icons-material/Security";
import { Creators as AcaoCreators } from "../../store/ducks/acao";
import { Creators as AcoesCreators } from "../../store/ducks/acoes";
import Loading from "../../components/Loading";

import { filtroAcoes } from "../../utils/helper";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
import TabelaAcoes, {
  Pagination,
} from "../../components/tabelaMui/tabelaFiltroAcao";
import { Container, Conteudo } from "./styles";

import Modal from "../../components/modal";
import ModalEditaAcao from "../../components/modal/editaJobTituloAcao";

function Acoes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [active, toggleFiltro] = useState(false);
  const [params, setParams] = useState("");
  const [filtroBusca, setFiltroBusca] = useState(false);

  const [modalEditaAcaoIsOpen, setModalEditaAcaoIsOpen] = useState(false);
  const [configModalEdita, setconfigModalEdita] = useState();

  const { data, paginacao, busca, status, date, tipo, loading, buscaActive } =
    useSelector((state) => state.acoes);

  const tipoLabel = tipo.label ? tipo.label.toLowerCase() : "";
  const statusLabel = status.label ? status.label.toLowerCase() : "";
  const formatedDate = date ? date.split(".").reverse().join("-") : "";

  let dadosFiltrado = filtroAcoes(
    data,
    busca ? busca : "",
    statusLabel,
    tipoLabel,
    date
  );

  const queryParams = `?${busca ? "nome=" + busca + "&" : ""}${
    formatedDate ? "data_disparo=" + formatedDate + "&" : ""
  }${tipo.value ? "acao_tipo_id=" + tipo.value + "&" : ""}${
    status.value ? "acao_status_id=" + status.value + "&" : ""
  }`.replace(/&$/, "");

  const excluirAcao = useCallback(
    async (id) => {
      dispatch(AcaoCreators.deleteRascunhoAgendada(id));
    },
    [dispatch]
  );

  function setJobTituloAcao(id, job, titulo) {
    const config = {
      id,
      job,
      titulo,
    };
    setconfigModalEdita(config);
    setModalEditaAcaoIsOpen(true);
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: "Status Ação",
        field: "status",
        width: 250,
        renderCell: ({ row }) => {
          if (row.status.toLowerCase() === "cancelada") {
            return (
              <div className={`status ${row.status.toLowerCase()}`}>
                {row.status}
              </div>
            );
          } else {
            if (row.fim) {
              let data = row.fim.split(" ");
              let parts = data[0].split("-");
              let today = new Date();

              let date = new Date(parts[0], parts[1] - 1, parts[2]);

              if (date >= today) {
                return (
                  <div className={`status ${row.status.toLowerCase()}`}>
                    {row.status}
                  </div>
                );
              } else {
                return <div className="status encerrada">Encerrada</div>;
              }
            } else {
              return (
                <div className={`status ${row.status.toLowerCase()}`}>
                  {row.status}
                </div>
              );
            }
          }
        },
      },
      {
        headerName: "Job",
        field: "job",
        width: 150,
        filterable: false,
      },
      {
        headerName: "Cliente",
        field: "cliente",
        width: 150,
      },
      {
        headerName: "Nome da Ação",
        field: "nome",
        width: 250,
      },
      {
        headerName: "Tipo de Disparo",
        field: "tipo",
        width: 150,
      },
      {
        headerName: "Categoria",
        field: "categoria",
        width: 150,
      },
      {
        headerName: "Data e hora",
        field: "data",
        width: 150,
      },
      {
        headerName: "Enviados / Entregues",
        field: "links",
        width: 250,
        filterable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: ({ row }) => {
          if (row.status === "Rascunho" || row.status === "Agendada") {
            return [
              <GridActionsCellItem
                label="Excluir"
                onClick={() =>
                  confirmAlert({
                    title: "Excluir ação",
                    message:
                      "Está ação não poderá ser revertida, tem certeza disso?",
                    buttons: [
                      {
                        label: "Sim",
                        onClick: () => {
                          excluirAcao(row.id);
                        },
                      },
                      {
                        label: "Não",
                        onClick: () => {},
                      },
                    ],
                  })
                }
                showInMenu
              />,
            ];
          }
          if (row.status === "Enviada") {
            return [
              <GridActionsCellItem
                // icon={<SecurityIcon />}
                label="Detalhes"
                onClick={() =>
                  history.push({
                    pathname: `/acoes/detalhe/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
                showInMenu
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                // icon={<SecurityIcon />}
                label="Detalhes"
                onClick={() =>
                  history.push({
                    pathname: `/acoes/detalhe/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
                showInMenu
              />,
              <GridActionsCellItem
                // icon={<SecurityIcon />}
                label="Editar"
                onClick={() => setJobTituloAcao(row.id, row.job, row.nome)}
                showInMenu
              />,
            ];
          }
        },
      },
    ],
    [history, excluirAcao]
  );

  function handlePagination(page) {
    const id =
      page + (queryParams !== "?" ? "&" + queryParams.replace(/\?/g, "") : "");
    setParams("");
    dispatch(AcoesCreators.paginacao({ id }));
  }

  useEffect(() => {
    dispatch(AcoesCreators.show());
  }, [dispatch]);

  useEffect(() => {
    if (tipo.value === 0 && status.value === 0) {
      dispatch(AcoesCreators.tipo({}));
      dispatch(AcoesCreators.status({}));
      dispatch(AcoesCreators.show());
    }

    if (buscaActive && !busca && filtroBusca) {
      dispatch(AcoesCreators.show());
    }

    if (!dadosFiltrado.length && buscaActive) {
      if (queryParams !== "?" && params !== queryParams) {
        setParams(queryParams);
        dispatch(AcoesCreators.show(queryParams));
      }
    }
  }, [
    dispatch,
    dadosFiltrado,
    queryParams,
    buscaActive,
    data,
    params,
    status.value,
    tipo.value,
    busca,
    filtroBusca,
  ]);

  useEffect(() => {
    if (buscaActive && busca) {
      setFiltroBusca(true);
    } else {
      setFiltroBusca(false);
    }
  }, [busca, buscaActive]);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Ações"
          linkCadastro="/acoes/nova"
          permission={["acao.processAction"]}
        />

        <Filtro activeFiltro={active} />

        {loading ? (
          <div className="container-loading">
            <Loading width={500} height={500} />
          </div>
        ) : (
          <div className="conteudo">
            <TabelaAcoes columns={columns} rows={dadosFiltrado} />
            <Pagination
              total={paginacao?.last_page}
              currentPage={(page) => {
                handlePagination(page);
              }}
              page={paginacao?.current_page}
            />
          </div>
        )}
      </Conteudo>

      <Modal
        // onChange={onChange}
        reload={() => dispatch(AcoesCreators.show())}
        dados={configModalEdita}
        component={ModalEditaAcao}
        active={modalEditaAcaoIsOpen}
        close={() => setModalEditaAcaoIsOpen(false)}
      />
    </Container>
  );
}

export default memo(Acoes);
