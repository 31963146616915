import { put, call } from "redux-saga/effects";

import { Types as AcaoTypes } from "../ducks/acao";
import { Types as AcoesTypes } from "../ducks/acoes";
import { Types as NotificacaoTypes } from "../ducks/notificacao";
import { api } from "../../services/api";

export function* store({ payload }) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const { dados } = payload;

  try {
    const { data } = yield call(api.post, `acao`, dados, headers);

    yield put({
      type: AcaoTypes.ACAO_DISPATCH_SUCCESS,
      payload: {
        resumo: {
          ...data,
        },
      },
    });

    yield put({
      type: AcaoTypes.ACAO_SUCCESS,
    });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Ação cadastrada",
        tipo: "sucesso",
      },
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.ACAO_FAILUIRE,
      payload: {
        error: error.response.data,
      },
    });
  }
}

export function* FireStore({ payload }) {
  const { id, entrega_voucher, history } = payload;

  try {
    yield call(api.post, `acao/fire`, { acao_id: id });

    yield put({
      type: AcaoTypes.ACAO_FIRE_SUCCESS,
      payload: {
        entrega_voucher: entrega_voucher ? true : false,
        acao_id: id,
      },
    });

    yield put({
      type: AcaoTypes.ACAO_SUCCESS,
    });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Ação Efetivada",
        tipo: "sucesso",
      },
    });

    if (entrega_voucher === 0) {
      yield put({
        type: AcaoTypes.CLEAR_ALL_ACAO,
      });

      if (history) {
        history.push({
          pathname: "/acoes",
          acao: true,
        });
      }
    }
  } catch (error) {
    yield put({
      type: AcaoTypes.ACAO_FAILUIRE,
    });
  }
}

export function* PedidoStore({ payload }) {
  const { acao_id, variacao_id, history } = payload;

  try {
    yield call(api.post, `acao/${acao_id}/pedido`, {
      variacao_id,
    });

    history.push({
      pathname: "/acoes",
      acao: true,
    });

    yield put({
      type: AcaoTypes.PEDIDO_ACAO_SUCCESS,
    });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Pedidos Efetivada",
        tipo: "sucesso",
      },
    });
  } catch (error) {
    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "ops, algo deu errado",
        tipo: "erro",
      },
    });

    yield put({
      type: AcaoTypes.ACAO_FAILUIRE,
      payload: {
        error: error.response.data,
      },
    });

    history.push({
      pathname: "/acoes",
      acao: true,
    });
  }

  yield put({
    type: AcaoTypes.CLEAR_ALL_ACAO,
  });
}

export function* DeleteAcao({ payload }) {
  const { id } = payload;

  try {
    yield call(api.delete, `acao/${id}`);

    yield put({
      type: AcaoTypes.ACAO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.ACAO_FAILUIRE,
    });
  }
}

export function* DeleteAcaoRascunhoAgendada({ payload }) {
  const { id } = payload;

  try {
    yield call(api.delete, `acao/${id}`);

    yield put({
      type: AcoesTypes.SHOW_ACOES,
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.ACAO_FAILUIRE,
    });
  }
}

export function* DispararAcao({ payload }) {
  const { id } = payload;

  try {
    yield call(api.post, `acao/fire`, { acao_id: id });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Ação Disparada",
        tipo: "sucesso",
      },
    });

    yield put({
      type: AcaoTypes.DISPARAR_ACAO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.DISPARAR_ACAO_FAILURE,
    });
  }
}

export function* CancelarAcao({ payload }) {
  const { id } = payload;

  try {
    yield call(api.post, `acao/${id}/cancel`);

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Cancelamento solicitado",
        tipo: "sucesso",
      },
    });

    yield put({
      type: AcaoTypes.CANCELAR_ACAO_DISPARADA_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.CANCELAR_ACAO_DISPARADA_FAILURE,
    });
  }
}

export function* CancelarAcaoMulti({ payload }) {
  const { id } = payload;

  try {
    yield call(api.post, `acao/${id}/cancel-multi`);

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Cancelamento solicitado",
        tipo: "sucesso",
      },
    });

    yield put({
      type: AcaoTypes.CANCELAR_ACAO_DISPARADA_SUCCESS_MULTI,
    });
  } catch (error) {
    yield put({
      type: AcaoTypes.CANCELAR_ACAO_DISPARADA_FAILURE_MULTI,
    });
  }
}

export function* EstornarAcao({ payload }) {
  const { id } = payload;

  try {
    yield call(api.post, `pedido/estornar`, { pedido_id: id });

    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "Estornado com sucesso",
        tipo: "sucesso",
      },
    });

    yield put({
      type: AcaoTypes.ESTORNAR_ACAO_DISPARADA_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: NotificacaoTypes.TOGGLE_NOTIFICACAO,
      payload: {
        success: true,
        mensagem: "ops, algo deu errado",
        tipo: "erro",
      },
    });

    yield put({
      type: AcaoTypes.ESTORNAR_ACAO_DISPARADA_FAILURE,
    });
  }
}
