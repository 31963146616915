import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { request2FA } from '../../../store/ducks/twoFactorAuth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NewHeader from "../../../components/newHeader";
import styles from "./registerAuthenticator.module.css";
import Shield from "../../../assets/img/login/Shield.svg";
import Loading from "../../../components/Loading";
import NewFooter from "../../../components/newFooter";

const RegisterAuthenticator = () => {
    const location = useLocation();
    const qrCodeImage = location.state?.qrCodeImage;
    const dispatch = useDispatch();
    const twoFactorAuthState = useSelector(state => state.twoFactorAuth);
    const history = useHistory();

    useEffect(() => {
      console.log('twoFactorAuthState:', twoFactorAuthState);
      if (twoFactorAuthState.success) {
          history.push('/sistema');
      } else if (twoFactorAuthState.error) {
          console.log('Erro:', twoFactorAuthState.error);
      }
  }, [twoFactorAuthState, history]);
  

    const handleSubmit = () => {
      const code = Array.from(inputRef.current).map(input => input.value).join('');
      console.log('handleSubmit Pressionado code:', code);
      dispatch(request2FA(code));
    };

    const inputRef = useRef([]);

    const handlePaste = (event) => {
        let paste = event.clipboardData.getData('text');
        paste = paste.slice(0, inputRef.current.length);
    
        event.preventDefault();
    
        paste.split('').forEach((char, index) => {
          const input = inputRef.current[index];
          if (input) {
            input.value = char;
            if (inputRef.current[index + 1]) {
              inputRef.current[index + 1].focus();
            }
          }
        });
      };

    const handleInputChange = (index) => (event) => {
        if (event.target.value) {
          if (inputRef.current[index + 1]) {
            inputRef.current[index + 1].focus();
          }
        } else if (event.target.value === '') {
          if (inputRef.current[index - 1]) {
            inputRef.current[index - 1].focus();
          }
        }
      };
    
      const handleKeyDown = (index) => (event) => {
        if (event.key === 'Backspace' && !event.target.value && index > 0) {
          inputRef.current[index - 1].focus();
        }
      };

    return (
        <div className={styles.generalContainer}>
            <div className={styles.insiderContainer}>
                <NewHeader />
                <div className={styles.shieldContainer}> 
                  <img src={Shield} alt="Shield" />
                </div>
                <p className={styles.title}>Habilitar Autenticação <br/> de Dois Fatores</p>
                <p className={styles.subtitle}>Escaneie o QR Code abaixo usando o <br/> 
                <span className={styles.highlightedText}>App Google Authenticator</span> no seu telefone.</p>
                <div className={styles.qrCode} dangerouslySetInnerHTML={{ __html: qrCodeImage }} />
                <p className={styles.label}>Código de Verificação</p>
                <div className={styles.codeInputs}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <input
                    key={index}
                    ref={(el) => (inputRef.current[index] = el)}
                    className={styles.inputSegmentado}
                    type="text"
                    maxLength="1"
                    onChange={handleInputChange(index)}
                    onKeyDown={handleKeyDown(index)}
                    onFocus={(e) => e.target.select()}
                    onPaste={handlePaste}
                />
                ))}
                </div>

                <button onClick={handleSubmit} className={styles.button}>
                {twoFactorAuthState.loading ? <Loading width={100} height={100} /> : 'Habilitar 2FA'}
                </button>
            </div>
            <NewFooter />            
        </div>
    );
};

export default RegisterAuthenticator;