import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Creators as LoginCreators } from "../../store/ducks/login";

import { Permissions } from "../../components/Permission";

import { Card } from "../../components/Card";

import { Container } from "./styles";

import logo from "../../assets/img/sistema/logo-sistema.svg";
import user from "../../assets/img/sistema/user-form.svg";
import x from "../../assets/img/sistema/x.svg";

import acoes from "../../assets/img/sistema/acoes-branco.svg";
import usuario from "../../assets/img/sistema/user-branco.svg";
import template from "../../assets/img/sistema/template-branco.svg";
import processamento from "../../assets/img/sistema/processamento-branco.svg";
import pedidos from "../../assets/img/sistema/pedidos-branco.svg";
import cliente from "../../assets/img/sistema/cliente.svg";
import download from "../../assets/img/sistema/download-branco.svg";

function Sistema() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { name } = useSelector((state) => state.user);

  const arrCards = [
    {
      title: "Ações",
      img: acoes,
      path: "acoes",
      permission: "acao.index",
    },
    {
      title: "Clientes",
      img: cliente,
      path: "clientes",
      permission: "cliente.index",
    },
    {
      title: "Usuários ADM",
      img: usuario,
      path: "usuarios",
      permission: "usuario.index",
    },
    {
      title: "Pedidos Planilha",
      img: pedidos,
      path: "pedidos",
      permission: "pedido.index",
    },
    {
      title: "Ações em Processamento",
      img: processamento,
      path: "processamento",
      permission: "acao.index.multi",
    },
    {
      title: "Templates",
      img: template,
      path: "template",
      permission: "template.index",
    },
    {
      title: "Downloads Pedidos",
      img: download,
      path: "download",
      permission: "pedido.area.user",
    },
  ];

  return (
    <>
      <Container>
        <div className="container">
          <div className="topo">
            <img src={logo} alt="" />
            <div className="info">
              <p>
                <img src={user} alt="" />
                Olá, {name}
              </p>
              <button
                onClick={() => dispatch(LoginCreators.requestLogout(history))}
              >
                <img src={x} alt="" />
                Sair
              </button>
            </div>
          </div>
          <div className="conteudo">
            <div className="box">
              {arrCards.map((item) => (
                <Permissions role={[`${item.permission}`]}>
                  <Link to={`/${item.path}`}>
                    <Card {...item} />
                  </Link>
                </Permissions>
              ))}
              {/* <Permissions role={["acao.index"]}>
                <Acoes />
              </Permissions>
              <Permissions role={["acao.processAction"]}>
                <NovaAcao />
              </Permissions>
              <Permissions role={["pedido.index"]}>
                <Pedidos />
              </Permissions> */}
            </div>
          </div>
          <div className="footer">
            <span>
              ® YETZ LTDA. Todos os direitos reservados | CNPJ:
              28.325.166/0001-05
            </span>
          </div>
        </div>
      </Container>
      {/* <Container>
        <div className="container">
          <div className="topo">
            <div className="text-img">
              <img src={logo} alt="" />
              <p>Olá, {name}</p>
              <p className="descricao">crie e acompanhe ações desenvolvidas</p>
              <h1>PARA SUA EQUIPE</h1>
            </div>
          </div>

          <div className="conteudo">
            <div className="box">
              <Acoes />
              <NovaAcao />
              <Pedidos />
            </div>
          </div>
          <div className="footer">
            <button
              onClick={() => dispatch(LoginCreators.requestLogout(history))}
            >
              Sair
            </button>
          </div>
        </div>
      </Container> */}

      {/* <Footer /> */}
    </>
  );
}

export default Sistema;
