import React, { useState, memo } from "react";
import Select from "react-select";
import { ObjIsEmpty } from "../../utils/helper";
import { Container } from "./styles";

function SelectOptions({
  change = () => {},
  options = [{ value: 1, label: "teste" }],
  label = "Diário",
  Search = true,
  selectedValue = {},
  id = null,
}) {
  const [defaullVaue, setDefaulValue] = useState(selectedValue);
  const value = ObjIsEmpty(defaullVaue) ? selectedValue : defaullVaue;

  const optionsOrder = options.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );

  return (
    <Container>
      <Select
        value={Number.isInteger(id) ? value : label}
        placeholder={label}
        classNamePrefix="react-select"
        onChange={(data) => {
          setDefaulValue(data);
          change(data);
        }}
        options={optionsOrder}
        isSearchable={Search}
      />
    </Container>
  );
}

export default memo(SelectOptions);
