import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/button';
import Topo from '../../components/topo/index';

import { ContainerFinal } from './styles';

import logo from '../../assets/img/sistema/logo-menu.svg';

function FinalAcao() {
  return (
    <ContainerFinal>
      <Topo />
      <div className="container">
        <img src={logo} alt="" />
        <div className="detalhes">
          <h1>Nova Ação</h1>
          <h3>O cadastro de sua nova ação foi realizado com sucesso!</h3>
          <p>
            Antes de finalizar o cadastro da ação você poderar visualizar como
            ficou sua ação clincando no link de Visualizar minha ação abaixo:
          </p>
          <Link to="">Visualizar minha ação</Link>
          <Button title="Criar nova Ação" />
        </div>
      </div>
    </ContainerFinal>
  );
}

export default FinalAcao;
