import { ErrorMessage, Field } from "formik";
// import { DrafEditor } from "../Editor";
import { Email as PreviewEmail } from "../preview";
import { Status } from "./status";

export function Email(props) {
  const { testando } = props;
  //   const { setFieldValue } = form;

  return (
    <>
      <Field component={Status} />

      <div className="container-form select type">
        <Field
          type="text"
          placeholder="Nome do template"
          name="name"
          autoComplete="new-password"
        />
        <ErrorMessage component="span" name="name" />
      </div>

      <div className="container-form select type">
        <Field
          type="text"
          placeholder="Sendgrid Id"
          name="sendgrid_id"
          autoComplete="new-password"
        />
        <ErrorMessage component="span" name="sendgrid_id" />
      </div>

      <div>
        {testando ? (
          <PreviewEmail text={testando} />
        ) : (
          <h3 className="avisoAguardando">
            AGUARDANDO SINCRONIZAÇÃO DO TEMPLATE
          </h3>
        )}

        {/* <DrafEditor
                    values={testando}
                    onChange={(values) => setFieldValue("html_content", values)}
                />
                <ErrorMessage component="span" name="html_content" /> */}
      </div>
    </>
  );
}
