import React from "react";
import { useSelector } from "react-redux";
import { Cadastro } from "./styles";

import browserHistory from "../../config/browserHistory";

import img from "../../assets/img/sistema/seta-azul.svg";
// import filtroImg from '../../assets/img/sistema/filtro.svg';

function TopoCadastro(props) {
  // const { notificacao = true, setFiltroActive, filtroActive } = props;
  const { name } = useSelector((state) => state.user);

  return (
    <>
      <Cadastro>
        <p className="voltar" onClick={() => browserHistory.back()}>
          <img src={img} alt="" />
          Voltar
        </p>
        <h3>
          Olá {name}{" "}
          {/* <button onClick={() => setFiltroActive(!filtroActive)}>
                        <img src={filtroImg} alt="" />
                    </button> */}
        </h3>
      </Cadastro>
    </>
  );
}

export default TopoCadastro;
