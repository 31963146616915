import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  convertDatePickerTimeToMySQLTime,
  convertDatePickerTimeToUser,
} from "../../utils/helper";

import { Container } from "../inputCalendar/styles";

function InputCalendar({ change = () => {} }) {
  const [startDate, setStartDate] = useState(new Date());
  const dateNow = convertDatePickerTimeToUser(
    convertDatePickerTimeToMySQLTime(startDate)
  );

  const placeHolder = dateNow.split(" ")[0];

  return (
    <Container>
      {/* <img src={icone} alt="" /> */}
      <DatePicker
        placeholderText={placeHolder ? placeHolder : startDate}
        onChange={(date) => {
          change(convertDatePickerTimeToMySQLTime(date).split(" ")[0]);

          setStartDate(date);
        }}
      />
    </Container>
  );
}

export default InputCalendar;
