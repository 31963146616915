import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { api } from "../../services/api";

import { getAcoesMulti } from "../../utils/helper";

import Loading from "../../components/Loading";
import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
// import TabelaAcoes from "../../components/tabelas/acao";
import TabelaAcoes, { Pagination } from "../../components/tabelaMui";
import { Container, Conteudo } from "./styles";

import Modal from "../../components/modal";
import ModalEditaAcao from "../../components/modal/editaJobTituloAcao";

import img from "../../assets/img/sistema/olho-azul.svg";
import imgEditar from "../../assets/img/sistema/editar.svg";

function AcoesProcessamento() {
  const [active, toggleFiltro] = useState(false);
  const [multi, setMulti] = useState([]);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const [modalEditaAcaoIsOpen, setModalEditaAcaoIsOpen] = useState(false);
  const [configModalEdita, setconfigModalEdita] = useState();

  async function fetchData(page = null) {
    setLoading(true);
    const { data } = await api.get(`/acao/multi?page=${page}`);
    setData(data);
    let dadosFiltrado = getAcoesMulti(data.data);
    setMulti(dadosFiltrado);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function setJobTituloAcao(id, job, titulo) {
    const config = {
      tipo: "processamento",
      id,
      job,
      titulo,
    };
    setconfigModalEdita(config);
    setModalEditaAcaoIsOpen(true);
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: "Status Ação",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className={`status ${row.status.toLowerCase()}`}>
              {row.status}
            </div>
          );
        },
      },
      {
        headerName: "Job",
        field: "job",
        width: 150,
      },
      {
        headerName: "Cliente",
        field: "cliente",
        width: 150,
      },

      {
        headerName: "Nome da Ação",
        field: "nome",
        width: 200,
      },
      {
        headerName: "Tipo de Disparo",
        field: "tipo",
        width: 150,
      },
      {
        headerName: "Categoria",
        field: "categoria",
        width: 150,
      },

      {
        headerName: "Data e hora",
        field: "data",
        width: 200,
      },
      {
        headerName: "Recipientes Processados",
        field: "processados",
        width: 220,
      },
      {
        headerName: "Erros",
        field: "erros",
        width: 100,
      },
      {
        headerName: "",
        field: "ver",
        width: 100,
        renderCell: ({ row }) => {
          return (
            <Link
              to={{
                pathname: `/processamento/detalhe/${row.id}`,
                state: {
                  id: row.id,
                },
              }}
            >
              <img src={img} alt="" />
            </Link>
          );
        },
      },
      {
        headerName: "",
        field: "edita",
        width: 100,
        renderCell: ({ row }) => {
          return (
            <button onClick={() => setJobTituloAcao(row.id, row.job, row.nome)}>
              <img src={imgEditar} alt="" />
            </button>
          );
        },
      },
    ],
    []
  );

  function handlePagination(page) {
    fetchData(page);
  }

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Ações em Processamento"
          linkCadastro="/acoes/nova"
          permission={["acao.processAction"]}
        />

        <Filtro activeFiltro={active} />

        {loading ? (
          <div className="container-loading">
            <Loading width={500} height={500} />
          </div>
        ) : (
          <div className="conteudo">
            <TabelaAcoes columns={columns} rows={multi} />
            <Pagination
              total={data?.last_page}
              currentPage={(page) => {
                handlePagination(page);
              }}
              page={data?.current_page}
            />
          </div>
        )}
      </Conteudo>
      <Modal
        // onChange={onChange}
        reload={() => fetchData()}
        dados={configModalEdita}
        component={ModalEditaAcao}
        active={modalEditaAcaoIsOpen}
        close={() => setModalEditaAcaoIsOpen(false)}
      />
    </Container>
  );
}

export default AcoesProcessamento;
