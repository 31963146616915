import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";
import { api } from "../../services/api";
import { CardVoucher } from "./cardVoucher";
import Button from "../button";
import Modal from "../modal";
import { ModalVoucher } from "./modalVoucher";
import maisImg from "../../assets/img/sistema/mais.svg";
import { VoucherStyles } from "./styles";

export function Voucher(props) {
  const { change, id } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [voucherActive, setVoucherActive] = useState([]);
  const { acao_id, data } = useSelector((state) => state.acao);

  const { resumo } = data;

  useEffect(() => {
    async function getVouchers() {
      setLoadingVoucher(true);

      // console.log(acao_id ? acao_id : id ? id : resumo.acao_id);

      try {
        const { data } = await api.get(
          `acao/${acao_id ? acao_id : id ? id : resumo.acao_id}/produtos`
        );
        setVouchers(data.data);
      } catch (error) {
        setVouchers([]);
        console.log(error);
      }
      setLoadingVoucher(false);
    }

    getVouchers();
  }, [acao_id, id, resumo]);

  const configModal = {
    onClose: () => setOpenModal(false),
    setVoucherActive: setVoucherActive,
    voucherActive,
    vouchers,
    loadingVoucher,
  };

  return (
    <>
      <VoucherStyles>
        <button className="add-voucher" onClick={() => setOpenModal(true)}>
          <img src={maisImg} alt="" />
          Adicionar Voucher
        </button>

        <div className="container-cards">
          {voucherActive.length > 0 && !openModal
            ? voucherActive.map((voucher) => {
                const { foto_capa, nome, valor_reais, total_disponivel } =
                  voucher;
                return (
                  <CardVoucher
                    img={foto_capa}
                    nome={nome}
                    valor_reais={valor_reais}
                    qtd={total_disponivel}
                    close={true}
                    onClose={() => {
                      dispatch(AcaoCreators.dataVoucher({}));
                      setVoucherActive([]);
                    }}
                  />
                );
              })
            : null}
        </div>

        {voucherActive.length > 0 && !openModal ? (
          <div className="container-btns">
            <Button
              type="button"
              title="Cancelar"
              onClick={() => {
                dispatch(AcaoCreators.dataVoucher({}));
                setVoucherActive([]);
                change("participantes");
              }}
              bgc={"red"}
              transparent={true}
            />

            <Button
              title="Avançar"
              onClick={() => change("confirma_vouchers")}
            />
          </div>
        ) : null}
      </VoucherStyles>
      <Modal component={ModalVoucher} active={openModal} {...configModal} />
    </>
  );
}
