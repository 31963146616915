import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Creators as EsqueciSenhaCreators } from "../../store/ducks/esqueciSenha";

import logo from "../../assets/img/sistema/logo-login.svg";
import Button from "../button";

import { StyleParabens } from "./styles";

function Parabens() {
    const dispatch = useDispatch();
    const { successResetaSenha } = useSelector((state) => state.esqueciSenha);

    function fechaModalTrocaSenha() {
        dispatch(EsqueciSenhaCreators.closeModalTrocaSenha());
    }

    function submitCode(e) {
        e.stopPropagation();
        e.preventDefault();
        fechaModalTrocaSenha();
    }

    return (
        <StyleParabens
            onSubmit={(e) => submitCode(e)}
            className={`${successResetaSenha ? "active" : ""}`}
        >
            <div className="content">
                <img src={logo} alt="" className="logo" />

                <h2>
                    Parabéns, sua senha no YETZ LINK foi alterada com sucesso!
                </h2>

                <h2>
                    ATENÇÃO! SEU ACESSO É PESSOAL E INTRANSFERÍVEL.NÃO
                    COMPARTILHE SEUS DADOS.
                </h2>

                <p>Para voltar ao login, clique no botão abaixo.</p>

                <Button
                    type="button"
                    title="OK, VOLTAR AO LOGIN"
                    onClick={() => fechaModalTrocaSenha()}
                />
            </div>
        </StyleParabens>
    );
}

export default Parabens;
