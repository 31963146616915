import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dataURLtoFile } from '../../utils/helper';

import { Email } from '../preview';

export default function EmailComponent(props) {
  const { form } = props;
  const { values } = form;
  const { template_arte } = values;

  const { data } = useSelector((state) => state.acao);
  const { preview_tema = [], preview_img } = data;

  const [preview, setPreview] = useState(preview_img);

  useEffect(() => {
    if (template_arte) {
      const img = dataURLtoFile(template_arte);
      if (img) {
        const url = URL.createObjectURL(img);
        setPreview(url);
      }
    }

    if (template_arte === '') {
      setPreview(null);
    }
  }, [template_arte]);
  return (
    <>
      {preview_tema.length ? (
        <div className="preview">
          <div className="container-text">
            <h3>Preview</h3>
          </div>
          <Email
            img={preview ? preview : template_arte}
            text={preview_tema[0].html_content}
          />
        </div>
      ) : null}
    </>
  );
}
