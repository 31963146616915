import React from "react";
import { confirmAlert } from "react-confirm-alert";

function Alerta(props) {
    const { click = () => {}, title, message, id } = props;

    function handelAlerta() {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: "Sim",
                    onClick: () => {
                        click(id);
                    },
                },
                {
                    label: "Não",
                    onClick: () => {},
                },
            ],
        });
    }

    return (
        <div>
            {React.cloneElement(props.children, { onClick: handelAlerta })}
        </div>
    );
}

export default Alerta;
