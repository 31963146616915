import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.theme.colors.branco};
    border: 1px solid;
    border-color: #dfdfdf;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 30px 20px;
    width: auto;
    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    &.active {
        border-color: #1cde90;
    }
    .img-titulo {
        display: flex;
        grid-gap: 20px;
    }
    img {
        width: 73px;
    }
    span {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #575757;
    }
    .input-qtd {
    }
    .qtd {
        input {
            width: 10%;
        }
        span {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #333333;
            &:first-child {
                background: #f6f6f6;
                border-radius: 4px 0px 0px 4px;
                padding: 15px;
            }
        }
    }
    &.nenhumProduto {
        display: flex;
        grid-gap: 20px;
    }
`;
