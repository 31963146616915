import { useState } from "react";

import { useDispatch } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";

import Button from "../button";
import Loading from "../Loading";
import { filtroVouchers } from "../../utils/helper";
import { CardVoucher } from "./cardVoucher";
import { ModalVoucherStyles } from "./styles";
import fecharModal from "../../assets/img/sistema/fechar-modal-voucher.svg";

export function ModalVoucher(props) {
  const dispatch = useDispatch();
  const [filtro, setFiltro] = useState("");
  const { vouchers, voucherActive, setVoucherActive, loadingVoucher } = props;

  const arrFilter = filtroVouchers(vouchers, filtro);

  return (
    <ModalVoucherStyles>
      <div className="container-header">
        <h3>Adicionar Voucher</h3>

        <button
          className="close"
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          <img src={fecharModal} alt="" />
        </button>
      </div>

      <div className="container-filtro">
        <input
          type="text"
          placeholder="Nome do produto"
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
        />
      </div>

      <div className="container-cards">
        {loadingVoucher ? (
          <Loading />
        ) : (
          arrFilter.map((voucher) => {
            const { nome, valor_reais, total_disponivel, id, foto_capa } =
              voucher;
            const active = voucherActive[0]?.id === id;
            return (
              <CardVoucher
                key={id}
                check={true}
                cursor={true}
                hover={true}
                active={active}
                img={foto_capa}
                nome={nome}
                valor_reais={valor_reais}
                qtd={total_disponivel}
                onChange={() => {
                  setVoucherActive([voucher]);
                  dispatch(AcaoCreators.dataVoucher(voucher));
                  if (active) {
                    setVoucherActive([]);
                    dispatch(AcaoCreators.dataVoucher({}));
                  }
                }}
              />
            );
          })
        )}
      </div>

      <div className="container-btn">
        <Button
          title="Adicionar Produto"
          bgc={"#1CDE90"}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      </div>
    </ModalVoucherStyles>
  );
}
