import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled(Link)`
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  padding: 20px;
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #0097d5;
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
`;
