import { useMemo, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useHistory } from "react-router-dom";
import imgEditar from "../../../assets/img/sistema/editar.svg";

import { Permissions } from "../../Permission";

import TableTemplate from "../../tabelaMui";

export function Table() {
  const history = useHistory();
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get("/cliente");
      setClientes(data);
    }

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        headerName: "Id",
        field: "id",
        width: 150,
      },
      {
        headerName: "Nome Fantasia",
        field: "nome",
        width: 250,
      },
      {
        headerName: "Razão Social",
        field: "razao_social",
        width: 500,
      },
      {
        headerName: "CNPJ",
        field: "cnpj",
        width: 250,
      },
      {
        headerName: "Área",
        field: "area",
        width: 250,
      },
      {
        headerName: "",
        field: "editar",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["cliente.update"]}>
              <button
                onClick={() =>
                  history.push({
                    pathname: `/clientes/editar/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
              >
                <img src={imgEditar} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
    ],
    [history]
  );

  const dados = clientes.map((cliente) => {
    return {
      id: cliente.id,
      nome: cliente.nome,
      razao_social: cliente.razao_social,
      cnpj: cliente.cnpj,
      area: cliente.areas.map((item) => item.nome).join(", "),
      editar: "",
    };
  });

  return <TableTemplate rows={dados} columns={columns} />;
}
