import { memo, useRef } from "react";
import { DebounceInput } from "react-debounce-input";

import Button from "../../button";
import { useContex } from "./Hook";
import imgX from "../../../assets/img/sistema/x-vermelho.svg";

function Campos(props) {
    const {
        areas,
        updateArea,
        storeArea,
        deleteArea,
        setAreas,
        contatos,
        setCliemtesEnUtilizacao,
        setModalAviso,
    } = useContex();

    const ref = useRef(null);

    function handleAddArea() {
        if (ref.current?.value) {
            storeArea({ nome: ref.current.value });
            ref.current.value = "";
        }
    }

    async function handleRemoveArea(id) {
        const hasValues = contatos.filter((contato) => id === contato.area.id);

        if (hasValues.length === 0) {
            await deleteArea(id);
        } else {
            setCliemtesEnUtilizacao(hasValues);
            setModalAviso(true);
        }
    }

    async function handleChange(newValue, oldValue) {
        let id = null;

        const update = areas.filter((area) => {
            if (area.label === oldValue) {
                area.label = newValue;
                id = area.id;
                return area;
            }
            return area;
        });

        setAreas(update);
        await updateArea(id);
    }

    return (
        <>
            <div className="container-form areas">
                <input type="text" placeholder="NOME DA ÁREA" ref={ref} />
                <Button onClick={handleAddArea} title="ok" type="button" />
            </div>

            <span fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </span>
            <div className="itens-areas">
                {areas?.map((area) => {
                    return (
                        <div key={area.value} className="container-form area">
                            <DebounceInput
                                type="text"
                                placeholder="Busca"
                                debounceTimeout={1000}
                                value={area.label}
                                onChange={(e) => {
                                    handleChange(e.target.value, area.label);
                                }}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveArea(area.id)}
                            >
                                <img src={imgX} alt="" />
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
