import React from "react";
import { TemplateProvider } from "../../Hook/useTemplate";

import { EditarTemplate } from "../../components/EditTemplate";

export function PageEditarTemplate() {
    return (
        <TemplateProvider>
            <EditarTemplate />
        </TemplateProvider>
    );
}
