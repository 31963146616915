import { useEffect, useMemo, useState } from "react";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import imgEditar from "../../assets/img/sistema/editar.svg";

import { Permissions } from "../Permission";
import { formataStringData } from "../../utils/helper";

import TableTemplate from "../tabelaMui";

export function Table() {
  const history = useHistory();
  const [dados, setDados] = useState([]);

  useEffect(() => {
    async function fetchDados() {
      const { data } = await api.get("/template");

      const email = data.templates_mail.map((item) => {
        return {
          ...item,
          type: "email",
        };
      });
      const sms = data.templates_sms.map((item) => {
        return {
          ...item,
          type: "sms",
        };
      });
      const whatsapp = data.templates_whatsapp.map((item) => {
        return {
          ...item,
          type: "whatsapp",
        };
      });

      const dados = [...email, ...sms, ...whatsapp];

      setDados(dados);
    }

    fetchDados();
  }, []);

  const columns = useMemo(
    () => [
      {
        headerName: "ID",
        field: "id",
        width: 100,
      },
      {
        headerName: "Tipo",
        field: "tipo",
        width: 200,
      },
      {
        headerName: "Status",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className={`status ${row.status.toLowerCase()}`}>
              {row.status}
            </div>
          );
        },
      },
      {
        headerName: "Nome",
        field: "nome",
        width: 400,
      },
      {
        headerName: "Variação",
        field: "variacao",
        width: 250,
      },
      {
        headerName: "Data de Criação",
        field: "data_criacao",
        width: 250,
      },
      {
        headerName: "",
        field: "editar",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["template.update"]}>
              <button
                onClick={() =>
                  history.push({
                    pathname: `/template/editar/${row.idReal}`,
                    state: {
                      id: row.idReal,
                      type: row.tipo.toLowerCase(),
                    },
                  })
                }
              >
                <img src={imgEditar} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
    ],
    [history]
  );

  const dataTemp = dados.map((item, key) => {
    return {
      idReal: item.id,
      id: key + 1,
      tipo: item.type,
      status: item.status ? "Ativo" : "Inativo",
      //   id_parceiro: item.sendgrid_id,
      nome: item.name,
      variacao:
        item.type === "sms" && item.fields
          ? Object.values(JSON.parse(item.fields ? item.fields : "{}")).join(
              ", "
            )
          : "",
      data_criacao: item.created_at ? formataStringData(item.created_at) : "",
      editar: "",
    };
  });

  return <TableTemplate rows={dataTemp} columns={columns} />;
}
