import React from "react";
import ReactTooltip from "react-tooltip";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Creators as LoginCreators } from "../../store/ducks/login";
import { Permissions } from "../Permission";

import { Container } from "./styles";

import logo from "../../assets/img/sistema/logo-menu.svg";
import img from "../../assets/img/sistema/acoes-icone.svg";
import img1 from "../../assets/img/sistema/usuarios.svg";
import img2 from "../../assets/img/sistema/solicitantes.svg";
import img3 from "../../assets/img/sistema/pedidos.svg";
import img4 from "../../assets/img/sistema/template.svg";
import img5 from "../../assets/img/sistema/processamento.svg";
import img6 from "../../assets/img/sistema/download-menu.svg";

function MenuLateral() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { name } = useSelector((state) => state.user);

  return (
    <Container>
      <img src={logo} alt="" />
      <div className="menu-principal">
        <Permissions role={["acao.index"]}>
          <Link
            to="/acoes"
            className={`${pathname !== "/acoes" && "icone-cinza"}`}
            data-tip
            data-for="acoes"
          >
            <img src={img} alt="" />
          </Link>
          <ReactTooltip id="acoes" type="info">
            <span>Ações</span>
          </ReactTooltip>
        </Permissions>

        <Permissions role={["cliente.index"]}>
          <Link
            to="/clientes"
            className={`${pathname !== "/clientes" && "icone-cinza"}`}
            data-tip
            data-for="clientes"
          >
            <img src={img2} alt="" />
          </Link>
          <ReactTooltip id="clientes" type="info">
            <span>Clientes</span>
          </ReactTooltip>
        </Permissions>
        <Permissions role={["usuario.index"]}>
          <Link
            to="/usuarios"
            className={`${pathname !== "/usuarios" && "icone-cinza"}`}
            data-tip
            data-for="usuarios"
          >
            <img src={img1} alt="" />
          </Link>
          <ReactTooltip id="usuarios" type="info">
            <span>Usuários ADM</span>
          </ReactTooltip>
        </Permissions>
        <Permissions role={["pedido.index"]}>
          <Link
            to="/pedidos"
            className={`${pathname !== "/pedidos" && "icone-cinza"}`}
            data-tip
            data-for="pedidos"
          >
            <img src={img3} alt="" />
          </Link>
          <ReactTooltip id="pedidos" type="info">
            <span>Pedidos Planilha</span>
          </ReactTooltip>
        </Permissions>
        <Permissions role={["template.index"]}>
          <Link
            to="/template"
            className={`${pathname !== "/template" && "icone-cinza"}`}
            data-tip
            data-for="templates"
          >
            <img src={img4} alt="" />
          </Link>
          <ReactTooltip id="templates" type="info">
            <span>Templates</span>
          </ReactTooltip>
        </Permissions>
        <Permissions role={["acao.index.multi"]}>
          <Link
            to="/processamento"
            className={`${pathname !== "/processamento" && "icone-cinza"}`}
            data-tip
            data-for="processamento"
          >
            <img src={img5} alt="" />
          </Link>
          <ReactTooltip id="processamento" type="info">
            <span>Ações em Processamento</span>
          </ReactTooltip>
        </Permissions>
        <Permissions role={["pedido.area.user"]}>
          <Link
            to="/download"
            className={`${pathname !== "/download" && "icone-cinza"}`}
            data-tip
            data-for="download"
          >
            <img src={img6} alt="" />
          </Link>
          <ReactTooltip id="download" type="info">
            <span>Download Pedidos</span>
          </ReactTooltip>
        </Permissions>
      </div>
      <div className="usuario">
        {/* <Link
                    to={{
                        pathname: `usuarios/${id}`,
                        id: id,
                    }}
                >

                </Link> */}
        <span>Olá {name}</span>
        <button onClick={() => dispatch(LoginCreators.requestLogout(history))}>
          Sair
        </button>
      </div>
    </Container>
  );
}

export default MenuLateral;
