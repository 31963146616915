import { useContex } from "./Hook";
import AlertDialogSlide from "../../ModalAlert";
import imgEditar from "../../../assets/img/sistema/editar.svg";
import imgContato from "../../../assets/img/sistema/contato-cinza.svg";
import imgCelular from "../../../assets/img/sistema/celular-cinza.svg";
import imgX from "../../../assets/img/sistema/x-vermelho.svg";
import { ContatoStyles } from "./styles";

export function Contato(props) {
    const { area, celular, email, nome, telefone } = props;
    const {
        setContatos,
        setContato,
        contatos,
        setModal,
        modalAlert,
        setModalAlert,
    } = useContex();

    function handleDeleteContato() {
        setContatos(contatos.filter((contato) => contato.id !== props.id));
        setModalAlert(false);
    }

    return (
        <>
            <ContatoStyles>
                <div className="pessoal">
                    <div>
                        <p>{nome}</p>
                        <p> {area.label}</p>
                    </div>

                    <div className="container-acaoes">
                        <button
                            type="button"
                            onClick={() => {
                                setContato({ ...props });
                                setModal(true);
                            }}
                        >
                            <img src={imgEditar} alt="" />
                            editar
                        </button>

                        <button
                            type="button"
                            onClick={() => setModalAlert(true)}
                        >
                            <img src={imgX} alt="" />
                        </button>
                    </div>
                </div>

                <div className="contato">
                    <span>{email}</span>

                    <div className="container-contato">
                        <div className="text-img">
                            <img src={imgContato} alt="" />
                            <p>{telefone}</p>
                        </div>
                        <div className="text-img celular">
                            <img src={imgCelular} alt="" />
                            <p>{celular}</p>
                        </div>
                    </div>
                </div>
            </ContatoStyles>
            <AlertDialogSlide
                open={modalAlert}
                description="deseja realmente apagar este contato?"
                title="apagar contato"
                aceept={handleDeleteContato}
                reject={() => setModalAlert(false)}
                handleClose={() => setModalAlert(false)}
            />
        </>
    );
}
