import { useState, memo, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "../button";
import imgX from "../../assets/img/sistema/x-vermelho.svg";

function Campos(props) {
    const { form, fields } = props;
    const { setFieldValue } = form;

    const ref = useRef(null);
    const uniqueValuesSet = new Set();
    const [areas, setAreas] = useState([]);
    const [tempArea, setTempArea] = useState([]);

    function handleAddArea() {
        if (ref.current?.value) {
            setAreas([...areas, { id: uuidv4(), area: ref.current.value }]);
            const dados = [
                ...tempArea,
                ...areas,
                { id: uuidv4(), area: ref.current.value },
            ].map((area) => area.area);

            setFieldValue("fields", [
                ...dados.filter((c, index) => dados.indexOf(c) === index),
            ]);
            ref.current.value = "";
        }
    }

    function handleRemoveArea(id) {
        const updatedAreas = areas.filter((a) => a.id !== id);
        const dados = [...updatedAreas].map((area) => area.area);

        setFieldValue("fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        setAreas(updatedAreas);
        setTempArea(updatedAreas);
    }

    function handleChange(newValue, oldValue) {
        const updateArea = areas.filter((area) => {
            if (area.area === oldValue) {
                area.area = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempArea].filter((obj) => {
            // check if name property value is already in the set
            const isPresentInSet = uniqueValuesSet.has(obj.id);

            // add name property value to Set
            uniqueValuesSet.add(obj.id);

            // return the negated value of
            // isPresentInSet variable
            return !isPresentInSet;
        });

        const dados = [...tempArea, ...areas].map((area) => area.area);

        setFieldValue("fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        setTempArea(filteredArr);
    }

    useEffect(() => {
        const initalValues =
            fields.map((item) => {
                return { id: uuidv4(), area: item };
            }) || [];

        setAreas(initalValues);
    }, [fields]);

    return (
        <>
            <div className="container-form campos">
                <input type="text" placeholder="Variáveis" ref={ref} />
                <Button
                    onClick={handleAddArea}
                    type="button"
                    title="+"
                    className="add-campos"
                />
            </div>

            <div className="itens-areas">
                {areas.map((area) => {
                    return (
                        <div key={area.value} className="container-form">
                            <input
                                type="text"
                                onChange={(e) => {
                                    handleChange(e.target.value, area.area);
                                }}
                                value={area.area}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveArea(area.id)}
                            >
                                <img src={imgX} alt="" />
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
