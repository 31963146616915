import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import imgCheck from "../../assets/img/sistema/check.svg";
import { ConfirmaStyles } from "./styles";

import { dataURLtoFile } from "../../utils/helper";

import { useDispatch, useSelector } from "react-redux";
import { Creators as AcaoCreators } from "../../store/ducks/acao";

export function ConfirmaDados(props) {
  const { base, disparo, template, recipientes, setValue, value, setReload } =
    props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.acao);
  const { nome_img } = data;

  async function handleSubmit() {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    const img = value.template_arte
      ? dataURLtoFile(value.template_arte, nome_img)
      : null;

    formData.append("template_arte", img);
    formData.append("acao_id", history?.location?.state.id);
    formData.append("acao_tipo_id", value.acao_tipo_id);
    formData.append("base", value.base);
    formData.append("recipientes", JSON.stringify(value.recipientes));
    formData.append("template_id", value.template_id + "");

    try {
      await api.post("acao/copy", formData, headers);
      toast.success("Novo Disparo criado com sucesso!");

      setReload(true);
    } catch (error) {
      toast.error("Algo deu errado");
    }
    setValue({});
    dispatch(AcaoCreators.modal_copy_acao());
  }

  return (
    <ConfirmaStyles>
      <ul>
        <li>
          <img src={imgCheck} alt="" />
          <p>Base para disparo</p> <span>{base}</span>
        </li>
        <li>
          <img src={imgCheck} alt="" />
          <p>Tipo de disparo</p> <span>{disparo}</span>
        </li>
        <li>
          <img src={imgCheck} alt="" />
          <p>Template</p> <span>{template}</span>
        </li>
        {value.base === 3 && (
          <li>
            <img src={imgCheck} alt="" />
            <p>Recipientes</p> <span>{recipientes.length} selecionado(s)</span>
          </li>
        )}
      </ul>
      <div className="container-btns">
        <button className="btn" onClick={() => handleSubmit()}>
          Clonar
        </button>
        <button className="btn cancelar" onClick={() => setValue({})}>
          Cancelar
        </button>
      </div>
    </ConfirmaStyles>
  );
}
