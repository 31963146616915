import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormInitialState, validationAcaoCopy } from "../../utils/configForm";

import { getTipo, getTemplate } from "../../utils/helper";
import Select from "../../components/NovoSelect";
import SelectCustom from "../../components/SelectCustom";
import inputImg from "../inputImg";

import { Container } from "./styles";

export function FormModalCopy(props) {
  const { setCheckItens, setValue, recipientes } = props;
  const [tipos, setTipos] = useState([]);
  const [template, setTemplate] = useState([]);
  const [tempTemplate, setTempTemplate] = useState({});
  const [itensSelect, setItensSelect] = useState({});

  const base = [
    {
      label: "É para a mesma base",
      value: 1,
    },
    {
      label: "Para quem deu erro",
      value: 2,
    },
    {
      label: "Escolher recipientes",
      value: 3,
    },
  ];

  function removeFile() {
    // setPreview(null);
    // dispatch(AcaoCreators.createObject("nome_img", ""));
    // form.setFieldValue("template_arte", "");
  }

  useEffect(() => {
    async function getItens() {
      const { tipos } = await getTipo();
      const templates = await getTemplate();

      setTempTemplate(templates);
      setTipos(tipos);
    }

    getItens();
  }, []);

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={FormInitialState.acao_copy}
        validationSchema={validationAcaoCopy}
        onSubmit={(value, actions) => {
          setCheckItens(itensSelect);
          setValue(value);
        }}
      >
        {(props) => {
          const { setFieldValue, values } = props;
          return (
            <Form>
              <Select
                options={base}
                label="Base para disparo"
                onChange={(value) => {
                  setFieldValue("base", value.value);
                  setItensSelect({
                    ...itensSelect,
                    base: value.label,
                  });
                }}
              />
              <ErrorMessage component="span" name="base" />
              <Select
                options={tipos}
                label="Tipo de disparo"
                onChange={(obj) => {
                  setFieldValue("acao_tipo_id", obj.value);

                  setItensSelect({
                    ...itensSelect,
                    disparo: obj.label,
                  });

                  if (obj.value === 1) {
                    setTemplate(
                      tempTemplate.templates_sms
                        .filter((o) => o.status === 1)
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })
                    );
                  }
                  if (obj.value === 2) {
                    setTemplate(
                      tempTemplate.templates_whatsapp
                        .filter((o) => o.status === 1)
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })
                    );
                  }
                  if (obj.value === 3) {
                    setTemplate(
                      tempTemplate.templates_mail
                        .filter((o) => o.status === 1)
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })
                    );
                  }
                }}
              />
              <ErrorMessage component="span" name="acao_tipo_id" />

              {values.acao_tipo_id === 2 && (
                <div className="container-form">
                  <Field
                    name="template_arte"
                    component={inputImg}
                    removeFile={removeFile}
                  />
                </div>
              )}

              <Select
                options={template}
                label="Template"
                onChange={(obj) => {
                  setFieldValue("template_id", obj.value);
                  setItensSelect({
                    ...itensSelect,
                    template: obj.label,
                  });
                }}
              />
              <ErrorMessage component="span" name="template_id" />
              {values.base === 3 && (
                <SelectCustom
                  label="Recipientes"
                  options={recipientes}
                  change={(value) => {
                    setFieldValue("recipientes", value);
                    setItensSelect({
                      ...itensSelect,
                      recipientes: value,
                    });
                  }}
                />
              )}
              <div className="container-btn">
                <button type="submit" className="btn">
                  Avançar
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
