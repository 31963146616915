import React from "react";

function visualizado({ title }) {
    const tradutor = {
        open: "Abriu",
        processed: "Processado",
        click: "Clicou",
    };

    return <p className="visualizado">{tradutor[title]}</p>;
}

export default visualizado;
