import React from 'react';

import { PreviewWhats, PreviewSms, PreviewEmail } from './styles';

import topo from '../../assets/img/preview/topo-carrefour.jpg';
import cartao from '../../assets/img/preview/cartao_outback.png';
import traco from '../../assets/img/preview/traco.png';
import btn from '../../assets/img/preview/btn.jpg';
import seta from '../../assets/img/preview/seta.png';
import footer from '../../assets/img/preview/footer.jpg';

export function Whats({ img = null, text = '' }) {
  text = text.replace(/\\\n/g, '');
  text = text.replace(/\\n/g, ' ');
  return (
    <PreviewWhats>
      <div className="container-preview-whats">
        <div className="topo">{img ? <img src={img} alt="" /> : null}</div>

        <div className="content">
          <p>{text}</p>
        </div>
      </div>
    </PreviewWhats>
  );
}

export function Sms({ img = null, text = '' }) {
  text = text.replace(/\\\n/g, '');
  text = text.replace(/\\n/g, ' ');
  return (
    <PreviewSms>
      <div className="container-preview-sms">
        <div className="content">
          <p>{text}</p>
        </div>
      </div>
    </PreviewSms>
  );
}

export function Email({ img = null, text = '' }) {
  return (
    <PreviewEmail>
      <div
        dangerouslySetInnerHTML={{
          __html: { text }.text,
        }}
      />
    </PreviewEmail>
  );
}

export function Preview() {
  return (
    <>
      <table
        width="750px"
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="#FFFFFF"
      >
        <tbody>
          <tr>
            <td>
              <img
                style={{ display: 'block', width: '100%' }}
                src={topo}
                width="750"
                height="91"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      ​
      <table
        style={{
          fontFamily: 'sans-serif',
          padding: '30px 80px 30px 80px',
          backgroundColor: '#f7f7f7',
        }}
        width="750px"
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="#FFFFFF"
      >
        <tbody>
          <tr>
            <td style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '16pt', color: '#FF6F00' }}>
                <strong>Olá Nome do Cliente</strong>
              </p>
              <p style={{ color: '#848484' }}>
                Parabéns, você acaba de adquirir um voucher virtual do Outback.
              </p>
              <img
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '30px',
                }}
                src={seta}
                width="20"
                height="10"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      ​ ​ ​
      <table
        style={{
          backgroundColor: '#f7f7f7',
          fontFamily: 'sans-serif',
          padding: '10px 80px 10px 80px',
          paddingLeft: '54px',
          paddingRight: '54px',
        }}
        width="750px"
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="#FFFFFF"
      >
        <tbody>
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                textAlign: 'left',
                padding: '40px',
                backgroundColor: 'white',
                border: '1px solid white',
                borderColor: '#DCDCDC',
              }}
              width="321px"
              colSpan="1"
            >
              <img
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                src={cartao}
                width="241"
                height="auto"
                alt=""
              />
              ​
              <p style={{ fontSize: '16pt', color: '#848484' }}>
                <strong>OUTBACK</strong>
                <br />
              </p>
              <p style={{ fontSize: '10pt', color: '#848484' }}>
                <strong>Validade: </strong>26/01/2019
              </p>
              <p style={{ fontSize: '16pt', color: '#FF6F00' }}>
                <strong>R$ 75,00</strong>
              </p>
              <img
                style={{ display: 'block' }}
                src={traco}
                width="18"
                height="auto"
                alt=""
              />
              <p
                style={{
                  lineHeight: '11pt',
                  fontSize: '8pt',
                  color: '#848484',
                }}
              >
                Em caso de dúvidas, entre em contato com a Central de
                Relacionamento Cartão Carrefour, de segunda a sábado, das 8h às
                21h (horário de Brasília), exceto domingos e feriados. Regiões
                metropolitanas: 4004-6200. Demais localidades: 0800-709-6200.
              </p>
            </td>

            <td
              style={{
                textAlign: 'justify',
                padding: '40px',
                backgroundColor: 'white',
                borderRight: '1px solid white',
                borderTop: '1px solid white',
                borderBottom: '1px solid white',
                borderColor: '#DCDCDC',
                verticalAlign: 'top',
              }}
              width="321px"
              colSpan="2"
            >
              <a href="/">
                <img
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  src={btn}
                  width="241"
                  height="auto"
                  alt=""
                />
              </a>
              <hr
                style={{
                  border: '0',
                  margin: '0',
                  width: '100%',
                  height: '1px',
                  background: '#DCDCDC',
                  marginTop: ' 25px',
                  marginBottom: '25px',
                }}
              />
              ​
              <p
                style={{
                  lineHeight: '11pt',
                  fontSize: '8pt',
                  color: '#848484',
                }}
              >
                <strong style={{ color: 'black' }}>Regras de uso:</strong>
                <br />
                • Cartão para uso nos restaurantes Outback Steakhouse.
                <br />• Para endereços e mais informações, acesse{' '}
                <a
                  style={{ textDecoration: 'none' }}
                  href="http://www.outback.com.br"
                >
                  <strong>www.outback.com.br</strong>
                </a>
                <br />
                • Aceito em lojas físicas.
                <br />
                • Não permite saldo remanescente.
                <br />
                • Caso o valor a pagar seja superior ao valor do voucher, a
                diferença deverá ser paga ao garçom utilizando as formas de
                pagamento aceitas pelo estabelecimento.
                <br />
                <br />
              </p>
              ​
              <p
                style={{
                  lineHeight: '11pt',
                  fontSize: '8pt',
                  color: '#848484',
                }}
              >
                <strong style={{ color: 'black' }}>
                  Mecânica de utilização:
                </strong>
                <br />
                • No Outback, informe ao garçom que você utilizará um voucher
                para pagamento da conta.
                <br />• No momento do pagamento, apresente ao garçom o código do
                seu voucher e a senha para utilizar o valor acima como parte do
                pagamento.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      ​
      <table
        style={{
          fontFamily: 'sans-serif',
          padding: '10px 54px',
          backgroundColor: '#f7f7f7',
        }}
        width="750px"
        border="0"
        align="center"
        cellPadding="0"
        cellSpacing="0"
        bgcolor="#FFFFFF"
      >
        <tbody>
          <tr>
            <td style={{ textSlign: 'center', textAlign: 'center' }}>
              <p
                style={{
                  color: '#848484',
                  fontSize: '10pt',
                  marginBottom: '50px',
                }}
              >
                Esta é uma mensagem gerada automaticamente, portanto não poderá
                ser respondida.
              </p>
            </td>
          </tr>

          <tr>
            <td style={{ textSlign: 'center' }}>
              <img
                style={{ display: 'block', width: '100%' }}
                src={footer}
                width="750"
                height="91"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
