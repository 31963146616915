/**
 * Types
 */
export const Types = {
  STORE_ACAO: "STORE_ACAO",
  ACAO_SUCCESS: "ACAO_SUCCESS",
  ACAO_FAILUIRE: "ACAO_FAILUIRE",

  DELETE_ACAO: "DELETE_ACAO",
  DELETE_ACAO_RASCUNHO_AGENDADA: "DELETE_ACAO_RASCUNHO_AGENDADA",

  STORE_FIRE: "STORE_FIRE",
  ACAO_DISPATCH_SUCCESS: "ACAO_DISPATCH_SUCCESS",

  STORE_PEDIDO_ACAO: "STORE_PEDIDO_ACAO",
  PEDIDO_ACAO_SUCCESS: "PEDIDO_ACAO_SUCCESS",

  ACAO_FIRE_SUCCESS: "ACAO_FIRE_SUCCESS",
  MODAL_COPY_ACAO: "MODAL_COPY_ACAO",
  SUCCESS_COPY_ACAO: "SUCCESS_COPY_ACAO",

  DADOS: "DADOS",
  CREATE_OBJECT: "CREATE_OBJECT",
  TIPO_TEMPLATE: "TIPO_TEMPLATE",
  PREVIEW: "PREVIEW",
  CLEAR_RESUMO: "CLEAR_RESUMO",
  CLEAR_ALL_ACAO: "CLEAR_ALL_ACAO",

  BREADCRUMB_DISPARO: "BREADCRUMB_DISPARO",
  BREADCRUMB_PARTICIPANTE: "BREADCRUMB_PARTICIPANTE",

  DATA_VOUCHER: "DATA_VOUCHER",

  DISPARAR_ACAO: "DISPARAR_ACAO",
  DISPARAR_ACAO_SUCCESS: "DISPARAR_ACAO_SUCCESS",
  DISPARAR_ACAO_FAILURE: "DISPARAR_ACAO_FAILURE",

  ESTORNAR_ACAO_DISPARADA: "ESTORNAR_ACAO_DISPARADA",
  ESTORNAR_ACAO_DISPARADA_SUCCESS: "ESTORNAR_ACAO_DISPARADA_SUCCESS",
  ESTORNAR_ACAO_DISPARADA_FAILURE: "ESTORNAR_ACAO_DISPARADA_FAILURE",

  CANCELAR_ACAO_DISPARADA: "CANCELAR_ACAO_DISPARADA",
  CANCELAR_ACAO_DISPARADA_SUCCESS: "CANCELAR_ACAO_DISPARADA_SUCCESS",
  CANCELAR_ACAO_DISPARADA_FAILURE: "CANCELAR_ACAO_DISPARADA_FAILURE",

  CANCELAR_ACAO_DISPARADA_MULTI: "CANCELAR_ACAO_DISPARADA_MULTI",
  CANCELAR_ACAO_DISPARADA_SUCCESS_MULTI:
    "CANCELAR_ACAO_DISPARADA_SUCCESS_MULTI",
  CANCELAR_ACAO_DISPARADA_FAILURE_MULTI:
    "CANCELAR_ACAO_DISPARADA_FAILURE_MULTI",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
  loading: false,
  error: false,
  disparo: false,
  hidden: false,
  efetivar: false,
  entrega_voucher: false,
  data_voucher: {},
  acao_id: null,
  data: {},
  erroData: {},
  preview: {},
  tipo_template: null,
  breadcrumb_disparo: true,
  breadcrumb_participante: true,

  loading_disparar_acao: false,
  success_disparar_acao: false,
  failure_disparar_acao: false,

  loading_cancelar_acao: false,
  success_cancelar_acao: false,
  failure_cancelar_acao: false,

  loading_cancelar_acao_multi: false,
  success_cancelar_acao_multi: false,
  failure_cancelar_acao_multi: false,

  loading_estorno_acao: false,
  success_estorno_acao: false,
  failure_estorno_acao: false,

  modal_copy_acao: false,
};

export default function Acao(state = INTIAL_STATE, action) {
  switch (action.type) {
    case Types.STORE_ACAO:
      return { ...state, loading: true, disparo: true };

    case Types.STORE_FIRE:
      return { ...state, loading: true, disparo: true };

    case Types.STORE_PEDIDO_ACAO:
      return { ...state, loading: true, disparo: true };

    case Types.CANCELAR_ACAO_DISPARADA:
      return {
        ...state,
        loading_cancelar_acao: true,
        success_cancelar_acao: false,
      };

    case Types.CANCELAR_ACAO_DISPARADA_SUCCESS:
      return {
        ...state,
        loading_cancelar_acao: false,
        success_cancelar_acao: true,
      };

    case Types.CANCELAR_ACAO_DISPARADA_FAILURE:
      return {
        ...state,
        loading_cancelar_acao: false,
        success_cancelar_acao: false,
        failure_cancelar_acao: true,
      };

    case Types.CANCELAR_ACAO_DISPARADA_MULTI:
      return {
        ...state,
        loading_cancelar_acao_multi: true,
        success_cancelar_acao_multi: false,
      };

    case Types.CANCELAR_ACAO_DISPARADA_SUCCESS_MULTI:
      return {
        ...state,
        loading_cancelar_acao_multi: false,
        success_cancelar_acao_multi: true,
      };

    case Types.CANCELAR_ACAO_DISPARADA_FAILURE_MULTI:
      return {
        ...state,
        loading_cancelar_acao_multi: false,
        success_cancelar_acao_multi: false,
        failure_cancelar_acao_multi: true,
      };

    case Types.DISPARAR_ACAO:
      return {
        ...state,
        loading_disparar_acao: true,
        success_disparar_acao: false,
      };

    case Types.DISPARAR_ACAO_SUCCESS:
      return {
        ...state,
        loading_disparar_acao: false,
        success_disparar_acao: true,
      };

    case Types.DISPARAR_ACAO_FAILURE:
      return {
        ...state,
        loading_disparar_acao: false,
        success_disparar_acao: false,
        failure_disparar_acao: true,
      };

    case Types.ESTORNAR_ACAO_DISPARADA:
      return {
        ...state,
        loading_estorno_acao: true,
        success_estorno_acao: false,
        failure_estorno_acao: false,
      };
    case Types.ESTORNAR_ACAO_DISPARADA_SUCCESS:
      return {
        ...state,
        loading_estorno_acao: false,
        success_estorno_acao: true,
      };

    case Types.ESTORNAR_ACAO_DISPARADA_FAILURE:
      return {
        ...state,
        loading_estorno_acao: false,
        success_estorno_acao: false,
        failure_estorno_acao: true,
      };

    case Types.MODAL_COPY_ACAO:
      return {
        ...state,
        modal_copy_acao: !state.modal_copy_acao,
      };

    case Types.ACAO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case Types.ACAO_DISPATCH_SUCCESS:
      return {
        ...state,
        hidden: true,
        erroData: {},
        data: {
          ...state.data,
          resumo: action.payload.resumo,
        },
        data_voucher: { data: state.data, preview: state.preview },
        breadcrumb_disparo: true,
        breadcrumb_participante: true,
      };

    case Types.DATA_VOUCHER:
      return {
        ...state,
        data_voucher: {
          ...state.data_voucher,
          voucher: action.payload.data,
        },
      };

    case Types.CLEAR_RESUMO:
      return {
        ...state,
        hidden: false,
        data: {
          resumo: "",
        },
      };

    case Types.CLEAR_ALL_ACAO:
      return {
        ...state,
        loading: false,
        error: false,
        disparo: false,
        hidden: false,
        efetivar: false,
        entrega_voucher: false,
        data_voucher: {},
        acao_id: null,
        data: {},
        erroData: {},
        preview: {},
        tipo_template: null,
        breadcrumb_disparo: true,
        breadcrumb_participante: true,
      };

    case Types.ACAO_FIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        disparo: false,
        hidden: false,
        efetivar: false,
        entrega_voucher: action.payload.entrega_voucher,
        acao_id: action.payload.acao_id,
      };
    case Types.PEDIDO_ACAO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        disparo: false,
        hidden: false,
        efetivar: false,
        entrega_voucher: false,
        data_voucher: {},
        acao_id: null,
        data: {},
        erroData: {},
        preview: {},
        tipo_template: null,
        breadcrumb_disparo: true,
        breadcrumb_participante: true,
      };

    case Types.ACAO_FAILUIRE:
      return {
        ...state,
        erroData: action.payload.error,
        loading: false,
        success: false,
        error: true,
        hidden: true,
        disparo: false,
      };

    case Types.DELETE_ACAO:
      return {
        ...state,
        loading: false,
        error: false,
        disparo: false,
        hidden: false,
        data: {},
        preview: {},
      };

    case Types.DADOS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case Types.CREATE_OBJECT:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };

    case Types.TIPO_TEMPLATE:
      return {
        ...state,
        tipo_template: action.payload.tipo,
      };

    case Types.PREVIEW:
      return {
        ...state,
        preview: { ...state.preview, ...action.payload },
      };

    case Types.BREADCRUMB_DISPARO:
      return {
        ...state,
        breadcrumb_disparo: false,
      };

    case Types.BREADCRUMB_PARTICIPANTE:
      return {
        ...state,
        breadcrumb_participante: false,
      };

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  store: (dados) => {
    return {
      type: Types.STORE_ACAO,
      payload: { dados },
    };
  },

  deleteAcao: (id) => {
    return {
      type: Types.DELETE_ACAO,
      payload: { id: id ? id : false },
    };
  },

  deleteRascunhoAgendada: (id) => {
    return {
      type: Types.DELETE_ACAO_RASCUNHO_AGENDADA,
      payload: {
        id,
      },
    };
  },

  fire: (id, entrega_voucher, history) => {
    return {
      type: Types.STORE_FIRE,
      payload: { id, entrega_voucher, history },
    };
  },

  pedido: (acao_id, variacao_id, history) => {
    return {
      type: Types.STORE_PEDIDO_ACAO,
      payload: { acao_id, variacao_id, history },
    };
  },

  dados: (data) => {
    return {
      type: Types.DADOS,
      payload: { data },
    };
  },

  clearResumo: () => {
    return {
      type: Types.CLEAR_RESUMO,
    };
  },

  clearAll: () => {
    return {
      type: Types.CLEAR_ALL_ACAO,
    };
  },

  createObject: (feild, data) => {
    return {
      type: Types.CREATE_OBJECT,
      payload: { [feild]: data },
    };
  },

  tipoTemplate: (tipo) => {
    return {
      type: Types.TIPO_TEMPLATE,
      payload: {
        tipo,
      },
    };
  },

  preview: (feild, data) => {
    return {
      type: Types.PREVIEW,
      payload: { [feild]: data },
    };
  },

  disparo: () => {
    return {
      type: Types.BREADCRUMB_DISPARO,
    };
  },

  modal_copy_acao: () => {
    return {
      type: Types.MODAL_COPY_ACAO,
    };
  },

  sucess_copy_acao: () => {
    return {
      type: Types.SUCCESS_COPY_ACAO,
    };
  },

  participante: () => {
    return {
      type: Types.BREADCRUMB_PARTICIPANTE,
    };
  },

  dataVoucher: (data) => {
    return {
      type: Types.DATA_VOUCHER,
      payload: {
        data,
      },
    };
  },

  disparadaAcao: (id) => {
    return {
      type: Types.DISPARAR_ACAO,
      payload: {
        id,
      },
    };
  },

  cancelarAcaoDisparada: (id) => {
    return {
      type: Types.CANCELAR_ACAO_DISPARADA,
      payload: {
        id,
      },
    };
  },
  cancelarAcaoDisparadaMulti: (id) => {
    return {
      type: Types.CANCELAR_ACAO_DISPARADA_MULTI,
      payload: {
        id,
      },
    };
  },
  estornarAcaoDisparada: (id) => {
    return {
      type: Types.ESTORNAR_ACAO_DISPARADA,
      payload: {
        id,
      },
    };
  },
};
