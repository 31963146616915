import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Creators as PedidosCreators } from "../../store/ducks/pedidos";

import { Permissions } from "../../components/Permission";
import Loading from "../../components/Loading";

import { filtroPedidos, getToken } from "../../utils/helper";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
// import TabelaPedidos from "../../components/tabelas/pedidosVoucher";
import TabelaPedidos, { Pagination } from "../../components/tabelaMui";
import { Container, Conteudo } from "./styles";

import Modal from "../../components/modal";
import ModalEditaAcao from "../../components/modal/editaJobTituloAcao";

import exportar from "../../assets/img/sistema/link.svg";
import imgEditar from "../../assets/img/sistema/editar.svg";
import img from "../../assets/img/sistema/olho-azul.svg";

function PedidosVoucher() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [active, toggleFiltro] = useState(false);

  const { data, paginacao, loading } = useSelector((state) => state.pedidos);

  const [modalEditaAcaoIsOpen, setModalEditaAcaoIsOpen] = useState(false);
  const [configModalEdita, setconfigModalEdita] = useState();

  const dowload = (id) => {
    const headers = {
      authorization: `Bearer ${getToken()}`,
    };

    const config = {
      url: `${process.env.REACT_APP_BASE_HTTP}/pedido/${id}/export`,
      method: "GET",
      responseType: "blob",
      headers,
    };
    const outputFilename = `${Date.now()}.xlsx`;
    axios(config)
      .then((res) => {
        var data = new Blob([res.data]);
        var xlsxURL = window.URL.createObjectURL(data);
        var tempLink;
        tempLink = document.createElement("a");
        tempLink.href = xlsxURL;
        tempLink.setAttribute("download", outputFilename);
        tempLink.click();

        toast.success("Pedido exportado com sucesso");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao exportar pedido");
      });
  };

  let dadosFiltrado = filtroPedidos(data);

  function setJobTituloAcao(id, job, titulo) {
    const config = {
      tipo: "pedido",
      id,
      job,
      titulo,
    };
    setconfigModalEdita(config);
    setModalEditaAcaoIsOpen(true);
  }

  const columns = React.useMemo(
    () => [
      {
        headerName: "Gerado/Visualizado/Estornado",
        field: "status",
        width: 200,
        renderCell: ({ row }) => {
          return (
            <div className="gerado-visualizado-estornado">
              <span className="gerado">{row.gerado} </span>/
              <span className="visualizado"> {row.visualizado} </span>/
              <span className="estornado"> {row.estornado}</span>
            </div>
          );
        },
      },
      {
        headerName: "ID",
        field: "id",
        width: 100,
      },
      {
        headerName: "Job",
        field: "job",
        width: 150,
      },
      {
        headerName: "Outlet",
        field: "is_outlet",
        width: 100,
        renderCell: ({ row }) => {
          return <span> {row.is_outlet ? "Sim" : "Não"}</span>;
        },
      },
      {
        headerName: "Cliente",
        field: "cliente",
        width: 150,
      },
      {
        headerName: "Área",
        field: "area",
        width: 250,
      },
      {
        headerName: "Título do pedido",
        field: "titulo",
        width: 250,
      },
      {
        headerName: "Qtd. vouchers",
        field: "qtd",
        width: 150,
      },
      {
        headerName: "Data",
        field: "data",
        width: 150,
      },
      {
        headerName: "",
        field: "exportar",
        renderCell: ({ row }) => {
          return (
            <button onClick={() => dowload(row.id)} className="exportar">
              <img src={exportar} alt="" />
              <span>Exportar</span>
            </button>
          );
        },
      },
      {
        headerName: "",
        field: "detalhe",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["pedido.show"]}>
              <button
                onClick={() => setJobTituloAcao(row.id, row.job, row.titulo)}
              >
                <img src={imgEditar} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
      {
        headerName: "",
        field: "editar",
        renderCell: ({ row }) => {
          return (
            <Permissions role={["pedido.show"]}>
              <button
                onClick={() =>
                  history.push({
                    pathname: `/pedidos/detalhe/${row.id}`,
                    state: {
                      id: row.id,
                    },
                  })
                }
              >
                <img src={img} alt="" />
              </button>
            </Permissions>
          );
        },
        width: 100,
      },
    ],
    [history]
  );

  function handlePagination(id) {
    dispatch(PedidosCreators.paginacao({ id }));
  }

  useEffect(() => {
    dispatch(PedidosCreators.show());
  }, [dispatch]);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Pedidos Planilha"
          linkCadastro="/pedidos/novo"
          permission={["pedido.store"]}
        />

        <Filtro activeFiltro={active} />

        {loading ? (
          <div className="container-loading">
            <Loading width={500} height={500} />
          </div>
        ) : (
          <div className="conteudo">
            <TabelaPedidos columns={columns} rows={dadosFiltrado} />
            <Pagination
              total={paginacao?.data?.last_page}
              currentPage={(page) => {
                handlePagination(page);
              }}
              page={paginacao?.data?.current_page}
            />
          </div>
        )}
      </Conteudo>
      <Modal
        // onChange={onChange}
        reload={() => dispatch(PedidosCreators.show())}
        dados={configModalEdita}
        component={ModalEditaAcao}
        active={modalEditaAcaoIsOpen}
        close={() => setModalEditaAcaoIsOpen(false)}
      />
    </Container>
  );
}

export default memo(PedidosVoucher);
