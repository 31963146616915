import { all, takeLatest } from "redux-saga/effects";

import { Types as LoginTypes } from "../ducks/login";
import { Types as EsqueciSenha } from "../ducks/esqueciSenha";
import { Types as UserTypes } from "../ducks/user";
import { Types as ContratanteTypes } from "../ducks/contratante";
import { Types as AcaoTypes } from "../ducks/acao";
import { Types as AcoesTypes } from "../ducks/acoes";
import { Types as PedidosTypes } from "../ducks/pedidos";
import { fork } from 'redux-saga/effects';
import { watchRequest2FA } from '../ducks/twoFactorAuth';

import { store } from "./user";
import { login, checkCode, logout, refresh } from "./login";
import {
  forgetPassword,
  confirmCode,
  ResetPassword,
  novoCode,
} from "./esqueciSenha";
import {
  store as StoreContratante,
  show,
  updateContratante,
  deleteContratante,
} from "./contratante";
import {
  store as AcaoStore,
  DispararAcao,
  CancelarAcao,
  CancelarAcaoMulti,
  EstornarAcao,
  FireStore,
  PedidoStore,
  DeleteAcao,
  DeleteAcaoRascunhoAgendada,
} from "./acao";
import { ShowAcoes, paginacao } from "./acoes";
import {
  ShowPedidos,
  paginacaoPedidos,
  downloadPedidos,
  paginacaoDownloadPedidos,
} from "./pedidos";

export default function* rootSaga() {
  yield all([
    fork(watchRequest2FA),
    takeLatest(UserTypes.STORE_USER, store),

    takeLatest(LoginTypes.REQUEST_LOGIN, login),
    takeLatest(LoginTypes.CHECK_CODE, checkCode),
    takeLatest(LoginTypes.REQUEST_LOGOUT, logout),
    takeLatest(LoginTypes.REQUEST_REFRESH, refresh),

    takeLatest(EsqueciSenha.REQUEST_ESQUECI_SENHA, forgetPassword),
    takeLatest(EsqueciSenha.REQUEST_NOVO_CODE, novoCode),
    takeLatest(EsqueciSenha.REQUEST_CONFIRM_CODE, confirmCode),
    takeLatest(EsqueciSenha.REQUEST_RESETA_SENHA, ResetPassword),

    takeLatest(ContratanteTypes.STORE, StoreContratante),
    takeLatest(ContratanteTypes.UPDATE, updateContratante),
    takeLatest(ContratanteTypes.DELETE, deleteContratante),
    takeLatest(ContratanteTypes.SHOW, show),

    takeLatest(AcaoTypes.STORE_ACAO, AcaoStore),
    takeLatest(AcaoTypes.DISPARAR_ACAO, DispararAcao),
    takeLatest(AcaoTypes.CANCELAR_ACAO_DISPARADA, CancelarAcao),
    takeLatest(AcaoTypes.CANCELAR_ACAO_DISPARADA_MULTI, CancelarAcaoMulti),
    takeLatest(AcaoTypes.ESTORNAR_ACAO_DISPARADA, EstornarAcao),
    takeLatest(AcaoTypes.STORE_FIRE, FireStore),
    takeLatest(AcaoTypes.STORE_PEDIDO_ACAO, PedidoStore),
    takeLatest(AcaoTypes.DELETE_ACAO, DeleteAcao),
    takeLatest(
      AcaoTypes.DELETE_ACAO_RASCUNHO_AGENDADA,
      DeleteAcaoRascunhoAgendada
    ),

    takeLatest(AcoesTypes.SHOW_ACOES, ShowAcoes),
    takeLatest(AcoesTypes.REQUEST_PAGINACAO, paginacao),

    takeLatest(PedidosTypes.SHOW_PEDIDOS, ShowPedidos),
    takeLatest(PedidosTypes.REQUEST_PAGINACAO, paginacaoPedidos),

    takeLatest(PedidosTypes.SHOW_DOWNLOAD_PEDIDOS, downloadPedidos),
    takeLatest(
      PedidosTypes.REQUEST_DOWNLOAD_PAGINACAO,
      paginacaoDownloadPedidos
    ),
  ]);
}
