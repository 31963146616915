import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #050505;
    margin-left: 8px;
  }
  .toggle {
    background: #e25b5b;
    border-radius: 250px;
    width: 99px;
    height: 30px;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    p {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      /* identical to box height */
      color: #ffefef;
    }
    &.active {
      background: #1cde90;
      transition: all 0.5s;
      p {
        right: unset;
        left: 5px;
      }
      span {
        left: 70px;
        transition: all 0.5s;
      }
    }
    span {
      width: 24px;
      height: 24px;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      transition: all 0.5s;
    }
  }
`;
