import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Creators as LoginCreators } from "../../store/ducks/login";

import { Login as Container } from "./styles";

import Footer from "../../components/Footer";
import Modal from "../../components/modal";
import Sms from "../../components/modalSms";
import Formlogin from "./login";
import FormEsqueciSenha from "./esqueciSenha";
import { ModalValidaLogin } from "../../components/ModalValidaLogin";

import user from "../../assets/img/sistema/users.png";

function Login() {
  const dispatch = useDispatch();
  const [esqueciSenha, setEsqueciSenha] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const {
    dados: { modal },
  } = useSelector((state) => state.esqueciSenha);

  const { login } = useSelector((state) => state.login);

  const config = {
    onclose: () => {
      dispatch(LoginCreators.closeModal());
      setModalConfirm(false);
    },
  };

  useEffect(() => {
    if (!modal) {
      setEsqueciSenha(false);
    }
  }, [modal]);

  useEffect(() => {
    if (login) {
      setModalConfirm(true);
    }
  }, [login]);

  return (
    <>
      <Container>
        <div className="container-esq">
          <img src={user} alt="" />
        </div>

        <div className="container-dir">
          {esqueciSenha ? (
            <FormEsqueciSenha
              setEsqueciSenha={setEsqueciSenha}
              esqueciSenha={esqueciSenha}
            />
          ) : (
            <Formlogin
              setEsqueciSenha={setEsqueciSenha}
              esqueciSenha={esqueciSenha}
            />
          )}
        </div>
      </Container>

      <Modal component={Sms} active={modal} />
      <Modal component={ModalValidaLogin} active={modalConfirm} rest={config} />
      <Footer />
    </>
  );
}

export default Login;
