import { ModalStyles } from "./styles";
import Button from "../../../button";
import { useContex } from "../Hook";

import imgXVerde from "../../../../assets/img/sistema/x.svg";
import imgCadastroCliente from "../../../../assets/img/sistema/sucess-cadastro-cliente.svg";

export function Sucess(props) {
    const { setSucess } = props;
    const { setModal } = useContex();

    return (
        <ModalStyles>
            <div className="container-header">
                <p>Editar Cliente</p>
                <button onClick={() => setModal(false)}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>

            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />

                <p>Contato adicionado com sucesso!</p>

                <p>
                    Você pode conferir e editar os dados desse contato antes de
                    criar o cliente.
                </p>

                <div width="234px" mb="86px">
                    <Button
                        title="FINALIZAR"
                        type="button"
                        onClick={() => {
                            setModal(false);
                            setSucess(false);
                        }}
                    />
                </div>
            </div>
        </ModalStyles>
    );
}
