import styled from "styled-components";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Conteudo = styled.div`
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.preto};
      margin-bottom: 24px;
      &.areas,
      &.contatos {
        border-top: 1px solid #d4d4d4;
        padding-top: 50px;
        margin-top: 50px;
      }
    }

    .btns {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
    }

    .rdw-editor-main {
      height: 375px;
      overflow: scroll;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      padding: 20px;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 5px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .container-form {
      &.campos {
        input {
          padding-right: 55px;
        }
      }
    }

    .add-cliente {
      width: auto;
      padding: 0px 20px;
    }

    button {
      &.add-contato {
        width: 383px;
      }

      &.add-campos {
        width: auto;

        height: auto;
        padding: 7px 9px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        margin-top: 0px;
      }
      margin-top: 20px;
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;

export const ContatoStyles = styled.div`
  width: 100%;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
  margin-top: 17px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #0096d4;
    margin-bottom: 10px;
    &.tel {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: -50px;
        transform: translateY(-50%);
        background-color: #e1e2e3;
        width: 1px;
        height: 12px;
      }
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #0096d4;
  }

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #0096d4;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 5px;
    }
  }

  .pessoal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e2e3;
    .container-acaoes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;
    }
  }

  .contato {
    padding-top: 20px;
    .container-contato {
      display: flex;
      margin-top: 16px;
      .text-img {
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0px;
          margin-left: 10px;
        }
        &.celular {
          margin-left: 48px;
        }
      }
    }
  }
`;

export const ModalStyles = styled.div`
  padding: 34px 24px 47px 24px;
  background-color: #fff;
  width: 375px;
  .container-sucess {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 166px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #0096d4;
      margin-bottom: 34px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #0096d4;
      margin-bottom: 105px;
    }
  }

  .container-header {
    padding-bottom: 22px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e2e3;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #0096d4;
    }
  }

  .container-content {
    width: 100%;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      text-align: left;
      color: #0096d4;
      width: 100%;
      display: block;
      margin-bottom: 18px;
    }
  }
`;
