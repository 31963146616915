/**
 * Types
 */
export const Types = {
    /**
     * Esqueci Senha
     */
    REQUEST_ESQUECI_SENHA: "REQUEST_ESQUECI_SENHA",
    SUCCESS_ESQUECI_SENHA: "SUCCESS_ESQUECI_SENHA",
    FAILUIRE_ESQUECI_SENHA: "FAILUIRE_ESQUECI_SENHA",

    /**
     * Código de verificação
     */
    REQUEST_CONFIRM_CODE: "REQUEST_CONFIRM_CODE",
    SUCCESS_CONFIRM_CODE: "SUCCESS_CONFIRM_CODE",
    FAILUIRE_CONFIRM_CODE: "FAILUIRE_CONFIRM_CODE",

    /*
     * Reseta Senha
     */
    REQUEST_RESETA_SENHA: "REQUEST_RESETA_SENHA",
    SUCCESS_RESETA_SENHA: "SUCCESS_RESETA_SENHA",
    FAILUIRE_RESETA_SENHA: "FAILUIRE_RESETA_SENHA",
    CLOSE_MODAL_RESETA_SENHA: "CLOSE_MODAL_RESETA_SENHA",

    /*
     * Reseta Senha
     */
    REQUEST_NOVO_CODE: "REQUEST_NOVO_CODE",
    SUCCESS_NOVO_CODE: "SUCCESS_NOVO_CODE",
    FAILUIRE_NOVO_CODE: "FAILUIRE_NOVO_CODE",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    /**
     * Esqueci senha
     */
    statusEsqueciSenha: false,
    loadingEsqueciSenha: false,
    erroEsqueciSenha: false,
    numero: null,
    dados: {
        modal: false,
        email: "",
    },

    /**
     * Confirma Código
     */
    loadingConfirmaCode: false,
    erroConfirmaCode: false,
    successConfirmaCode: false,

    /**
     * Reseta senha
     */
    loadingResetaSenha: false,
    erroResetaSenha: false,
    successResetaSenha: false,

    /**
     * Novo code
     */
    loadingNovocode: false,
    erroNovocode: false,
    successNovocode: false,
};

export default function EsqueciSenha(state = INTIAL_STATE, action) {
    switch (action.type) {
        /**
         * ESQUECI SENHA INICIO
         */
        case Types.REQUEST_ESQUECI_SENHA:
            return {
                ...state,
                loadingEsqueciSenha: true,
                erroEsqueciSenha: false,
                statusEsqueciSenha: false,
            };

        case Types.SUCCESS_ESQUECI_SENHA:
            return {
                ...state,
                loadingEsqueciSenha: false,
                statusEsqueciSenha: true,
                erroEsqueciSenha: false,
                numero: action.payload.numero,
                dados: {
                    ...action.payload.dados,
                },
            };

        case Types.FAILUIRE_ESQUECI_SENHA:
            return {
                ...state,
                loadingEsqueciSenha: false,
                statusEsqueciSenha: false,
                erroEsqueciSenha: true,
            };

        /**
         * Confirma Código
         */
        case Types.REQUEST_CONFIRM_CODE:
            return {
                ...state,
                loadingConfirmaCode: true,
                erroConfirmaCode: false,
                successConfirmaCode: false,
            };

        case Types.SUCCESS_CONFIRM_CODE:
            return {
                ...state,
                loadingConfirmaCode: false,
                erroConfirmaCode: false,
                statusEsqueciSenha: false,
                successConfirmaCode: true,
            };

        case Types.FAILUIRE_CONFIRM_CODE:
            return {
                ...state,
                loadingConfirmaCode: false,
                successConfirmaCode: false,
                statusEsqueciSenha: true,
                erroConfirmaCode: true,
            };

        /**
         * Reseta Senha
         */

        case Types.REQUEST_RESETA_SENHA:
            return {
                ...state,
                loadingResetaSenha: true,
            };

        case Types.SUCCESS_RESETA_SENHA:
            return {
                ...state,
                loadingResetaSenha: false,
                erroResetaSenha: false,
                successConfirmaCode: false,
                successResetaSenha: true,
            };

        case Types.FAILUIRE_RESETA_SENHA:
            return {
                ...state,
                loadingResetaSenha: false,
                successResetaSenha: false,
                erroResetaSenha: true,
                successConfirmaCode: true,
            };

        /**
         * Novo Code
         */
        case Types.REQUEST_NOVO_CODE:
            return {
                ...state,
                loadingNovocode: true,
                erroNovocode: false,
                successNovocode: false,
            };

        case Types.SUCCESS_NOVO_CODE:
            return {
                ...state,
                successNovocode: true,
                loadingNovocode: false,
                erroNovocode: false,
            };

        case Types.FAILUIRE_NOVO_CODE:
            return {
                ...state,
                erroNovocode: true,
                loadingNovocode: false,
                successNovocode: false,
            };

        case Types.CLOSE_MODAL_RESETA_SENHA:
            return {
                loadingResetaSenha: false,
                erroResetaSenha: false,
                successConfirmaCode: false,
                successResetaSenha: false,
                dados: {
                    ...state.dados,
                    modal: false,
                },
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    requestEsqueciSenha: (data) => {
        return {
            type: Types.REQUEST_ESQUECI_SENHA,
            payload: { data },
        };
    },

    requestNovoCodigo: (data) => {
        return {
            type: Types.REQUEST_NOVO_CODE,
            payload: { data },
        };
    },

    requestConfirmCode: (code, email) => {
        return {
            type: Types.REQUEST_CONFIRM_CODE,
            payload: {
                code,
                email,
            },
        };
    },

    requestResetaSenha: (data) => {
        return {
            type: Types.REQUEST_RESETA_SENHA,
            payload: {
                data,
            },
        };
    },

    closeModalTrocaSenha: () => {
        return {
            type: Types.CLOSE_MODAL_RESETA_SENHA,
        };
    },
};
