import React from "react";

import NovoSelect from "../NovoSelect";

export function Status(props) {
    const { form } = props;
    const { setFieldValue } = form;

    const status = [
        {
            value: 1,
            label: "Ativo",
        },
        {
            value: 2,
            label: "Inativo",
        },
    ];

    return (
        <NovoSelect
            label="Status"
            options={status}
            onChange={({ value, label }) => {
                setFieldValue("status", value);
            }}
        />
    );
}
