import React, { useState } from "react";

import { TemplateProvider } from "../../Hook/useTemplate";

import Menu from "../../components/menuLateral";
import Filtro from "../../components/FiltroAcaoes";
import Topo from "../../components/topo";
import { Template } from "../../components/Template";
import { Container, Conteudo } from "./styles";

export function PageTemplate() {
  const [active, toggleFiltro] = useState(false);

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          title="Templates"
          linkCadastro="/template/novo"
          permission={["template.store"]}
        />

        <Filtro activeFiltro={active} />

        <div className="conteudo">
          <TemplateProvider>
            <Template />
          </TemplateProvider>
        </div>
      </Conteudo>
    </Container>
  );
}
