import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";

import { Creators as AcoesCreators } from "../../store/ducks/acoes";
import { filterSelect } from "../../utils/helper";
import Select from "../select";
import Calendario from "../inputCalendarioTipoData";

import { Acoes } from "../filtro/styles";

function FiltroAcoes({ activeFiltro }) {
    const [changeStatus, setChangeStatus] = useState(0);
    const [changeTipo, setChangeTipo] = useState(0);
    const dispatch = useDispatch();

    function formatDate(date) {
        return date.split("-").reverse().join(".");
    }

    const status = [
        { value: 0, label: "Status" },
        { value: 2, label: "Agendada" },
        { value: 3, label: "Enviada" },
        { value: 4, label: "Rascunho" },
    ];
    const tipo = [
        { value: 0, label: "Tipo" },
        { value: 3, label: "Email" },
        { value: 1, label: "SMS" },
        { value: 2, label: "WhatsApp" },
    ];

    const debaounce = (e) => {
        if (e.target.value === "") {
            dispatch(AcoesCreators.busca(null));
        } else {
            dispatch(AcoesCreators.busca(e.target.value));
        }
    };

    return (
        <Acoes className={activeFiltro ? "active" : ""}>
            <Formik>
                <Form>
                    <div className="busca">
                        <DebounceInput
                            type="text"
                            placeholder="Busca"
                            debounceTimeout={500}
                            onChange={debaounce}
                        />
                    </div>
                    <div className="data">
                        <Calendario
                            change={(date) =>
                                dispatch(AcoesCreators.date(formatDate(date)))
                            }
                        />
                    </div>
                </Form>
            </Formik>
            <Select
                label="Tipo"
                id={changeTipo}
                options={tipo}
                selectedValue={filterSelect(changeTipo, tipo)}
                change={(value) => {
                    setChangeTipo(value.value);
                    dispatch(AcoesCreators.tipo(value));
                }}
            />
            <Select
                label="Status"
                id={changeStatus}
                options={status}
                selectedValue={filterSelect(changeStatus, status)}
                change={(value) => {
                    setChangeStatus(value.value);
                    dispatch(AcoesCreators.status(value));
                }}
            />
        </Acoes>
    );
}

export default FiltroAcoes;
