import { Table } from "./table";
import { NovoClienteProvider } from "./Hook";

export function Clientes() {
    return (
        <NovoClienteProvider>
            <Table />
        </NovoClienteProvider>
    );
}
