import React, { useState, useEffect } from "react";
import { formatCurrentMoney } from "../../utils/helper";

import Button from "../button";
import CheckBox from "../checkbox";
import Loading from "../Loading";

import { AddProduto } from "./styles";
import { Container as BoxProduto } from "../boxProduto/stylesBox";
import { Products } from "./styles";

import close from "../../assets/img/sistema/fechar-modal.svg";
import produtoNotFound from "../../assets/img/sistema/produto_not_found.png";

const ModalAddProduto = (props) => {
  const { rest } = props;
  const [produtos, setProdutos] = useState([]);
  const [busca, setBusca] = useState("");
  const [List, setList] = useState([]);

  const arrProdutosFiltrados = produtos.filter((prod) => {
    return JSON.stringify(prod, ["nome", "valor_reais", "total_disponivel"])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });

  const onItemCheck = (item) => {
    if (item.active) {
      setList(List.filter(({ id }) => id !== item.id));
      setProdutos(
        produtos.map((produto) => {
          if (produto.id === item.id) {
            produto.active = false;
          }
          return produto;
        })
      );
      return;
    }

    const tempList = produtos.filter((produto) => {
      if (produto.id === item.id) {
        produto.active = true;
        return produto;
      } else {
        return false;
      }
    });
    setList([...List, ...tempList]);
  };

  const addProdutoSelecionado = () => {
    props.setProdutos(List);
    props.close();
  };

  useEffect(() => {
    setProdutos(
      rest.produtos.map((produto) => {
        return { ...produto, active: false, value: "" };
      })
    );
  }, [rest.produtos]);

  return (
    <AddProduto>
      <h3>Novo Produto</h3>
      <img className="close" src={close} alt="" onClick={() => props.close()} />

      <input
        type="text"
        placeholder="Nome do Produto"
        onChange={(e) => setBusca(e.target.value)}
      />

      <Products>
        {rest.loading ? (
          <Loading />
        ) : arrProdutosFiltrados.length > 0 ? (
          arrProdutosFiltrados.map((item) => {
            return (
              <BoxProduto
                key={item.id}
                className={`${item.active ? "active" : ""}`}
                onClick={() => onItemCheck(item)}
              >
                <CheckBox type="checkbox" checked={item.active} />
                <img src={item.foto_capa ? item.foto_capa : ""} alt="" />
                <h4>{item.nome ? item.nome : ""}</h4>
                <span>
                  {item.valor_reais
                    ? formatCurrentMoney(parseFloat(item.valor_reais))
                    : 0}
                </span>
                <span>
                  {rest.outlet
                    ? item.total_outlet
                      ? "Estoque : " + item.total_outlet
                      : 0
                    : item.total_disponivel
                    ? "Estoque : " + item.total_disponivel
                    : 0}
                </span>
              </BoxProduto>
            );
          })
        ) : (
          <BoxProduto className="nenhumProduto">
            <img src={produtoNotFound} alt="" />
            <h4>Nenhum produto encontrado</h4>
            <span></span>
            <span></span>
          </BoxProduto>
        )}
      </Products>
      <Button
        title="Adicionar produto"
        onClick={addProdutoSelecionado}
        disabled={produtos.length <= 0 && "disabled"}
      />
    </AddProduto>
  );
};

export default ModalAddProduto;
