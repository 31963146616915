import styled from "styled-components";

export const ContainerProdutos = styled.div`
    p {
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: ${(props) => props.theme.colors.Cinza02};
    }
    .contadores {
        display: flex;
        grid-gap: 10px;
        margin: 20px 0;
        span {
            cursor: pointer;
            font-weight: 500;
            font-size: 18.6004px;
            line-height: 23px;
            color: ${(props) => props.theme.colors.Cinza03};
            background-color: #f6f6f6;
            border-radius: 3px;
            width: 54px;
            height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
                background-color: ${(props) => props.theme.colors.branco};
                color: ${(props) => props.theme.colors.Vinho1};
                font-weight: bold;
                border: 1px solid ${(props) => props.theme.colors.Laranja1};
            }
        }
    }
    .add {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #0191cd;
        border: 1px solid #0191cd;
        box-sizing: border-box;
        border-radius: 4px;
        width: 265px;
        height: 55px;
    }
    .produtos {
        display: grid;
        grid-template-columns: 1fr;
        margin: 20px 0;
        .erro-qtd {
            color: red;
            font-size: 16px;
            margin-top: 5px;
        }
    }
    button {
        width: 168px;
    }
`;
