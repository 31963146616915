/**
 * Types
 */
export const Types = {
    OPEN_MODAL_DETALHES_ARQUIVO: "OPEN_MODAL_DETALHES_ARQUIVO",
    CLOSE_MODAL_DETALHES_ARQUIVO: "CLOSE_MODAL_DETALHES_ARQUIVO",
};

/**
 * Reducers
 */
const INTIAL_STATE = {
    modalDetalhesArquivoIsOpened: false,
};

export default function Modal(state = INTIAL_STATE, action) {
    switch (action.type) {
        case Types.OPEN_MODAL_DETALHES_ARQUIVO:
            return {
                ...state,
                modalDetalhesArquivoIsOpened: true,
            };

        case Types.CLOSE_MODAL_DETALHES_ARQUIVO:
            return {
                ...state,
                modalDetalhesArquivoIsOpened: false,
            };

        default:
            return state;
    }
}

/**
 * Actions
 */
export const Creators = {
    openModalDetalhesArquivo: () => {
        return { type: Types.OPEN_MODAL_DETALHES_ARQUIVO };
    },

    closeModalDetalhesArquivo: () => {
        return { type: Types.CLOSE_MODAL_DETALHES_ARQUIVO };
    },
};
