import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { Creators as ContratanteCreators } from "../../store/ducks/contratante";

import { Acoes } from "./styles";

function FiltroAcoes({ activeFiltro }) {
    const dispatch = useDispatch();

    return (
        <Acoes className={activeFiltro ? "active" : ""}>
            <Formik>
                <Form>
                    <div className="busca">
                        <Field
                            type="text"
                            placeholder="Busca"
                            onChange={(e) =>
                                dispatch(
                                    ContratanteCreators.busca(e.target.value)
                                )
                            }
                        />
                    </div>
                </Form>
            </Formik>
        </Acoes>
    );
}

export default FiltroAcoes;
