import styled from "styled-components";

export const Container = styled.div`
  width: 160px;
  height: 100%;
  padding: 50px 0;
  position: fixed;
  left: 0;
  height: 100%;
  background-color: ${(props) => props.theme.colors.branco};
  border-right: 1px solid #d4d4d4;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  justify-content: center;
  z-index: 99;
  .menu-principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 40px;
    .icone-cinza {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }
  .usuario {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.Cinza02};
    }
    a,
    button {
      border: none;
      background-color: transparent;
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.Vinho1};
    }
  }
`;
