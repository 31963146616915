import styled from "styled-components";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Conteudo = styled.div`
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: grid;
    .listaPermissoes {
      .grupoPermissao {
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
      }
      h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        color: #000000;
        border-bottom: 2px solid #5f5f5f;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .rowPermissoes {
        border-bottom: 1px solid #e1e2e3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-bottom: 10px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          color: #333333;
        }
      }
    }
  }
`;

export const Cadastro = styled.div`
  height: 80px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e3;

  .voltar {
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.preto};
    display: flex;
    grid-gap: 10px;
    text-transform: uppercase;
    background: none;
    align-items: center;
  }
`;
