export const PermissionsGroup = {
  usuario: "Usuário",
  acao: "Ação",
  template: "Template",
  role: "Role",
  permission: "Permissão",
  produto: "Produto",
  pedido: "Pedido",
  cliente: "Cliente",
  recipiente: "Recipiente",
};
