import styled from "styled-components";

export const Container = styled.div`
  margin-left: 160px;
  min-height: 100vh;
  height: 100%;
  /* background: ${(props) => props.theme.colors.Cinza06}; */
`;

export const Conteudo = styled.div`
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const Novo = styled.div`
  .container {
    padding-top: 100px;
    padding-bottom: 100px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;

      color: ${(props) => props.theme.colors.preto};
      margin-bottom: 24px;
    }
    button {
      margin-top: 20px;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;
