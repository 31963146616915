import React from "react";
import NovoSelect from "../NovoSelect";

export function Status(props) {
    const { form } = props;
    const { setFieldValue, values } = form;

    const status = [
        {
            value: 1,
            label: "Ativo",
        },
        {
            value: 0,
            label: "Inativo",
        },
    ];

    const activeStatus = status.find((item) => item.value === values?.status);

    return (
        <NovoSelect
            label="Status"
            initalValue={activeStatus}
            options={status}
            onChange={({ value, label }) => {
                setFieldValue("status", value);
            }}
        />
    );
}
