import styled from "styled-components";
import { Link as Url } from "react-router-dom";

export const Link = styled(Url)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #333333;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 13px;
  }
`;

export const Container = styled.div`
  &.editar-acao {
  }
  .status {
    border: none;
    border: 1px solid #1cde90;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #1cde90;
    background-color: transparent;
    cursor: auto;
    &.processando,
    &.processing {
      color: #ffa500;
      border: 1px solid #ffa500;
    }

    &.agendada {
      color: #0097d5;
      border: 1px solid #0097d5;
    }

    &.disparada {
    }

    &.rascunho {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
    }
    &.erro,
    &.error {
      color: #ff0000;
      border: 1px solid #ff0000;
    }
  }
  .statusDetalhe {
    display: flex;
    align-items: center;
    &.acessado {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #1cde90;
    }
  }
  .table {
    border-spacing: 0;

    .detalhes {
      position: absolute;
      right: 10px;
      top: 25%;
      cursor: pointer;
      display: flex;
      cursor: pointer;
      img {
        width: 24px;
      }
    }

    .header {
      background: #373737;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      .th {
        align-self: center;
      }
    }

    .tr {
      margin-bottom: 10px;
      height: 52px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      &.body {
        background: ${(props) => props.theme.colors.branco};
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza01};
        position: relative;
        .td {
          align-self: center;
          &.center {
            text-align: center;
          }

          span {
            &.enviado {
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: ${(props) => props.theme.colors.Verde1};
              border: 1px solid ${(props) => props.theme.colors.Verde1};
              box-sizing: border-box;
              border-radius: 4px;
              padding: 5px;
            }
          }
        }
        .opcoes {
          position: absolute;
          right: 10px;
          top: 45%;
          cursor: pointer;
        }
        .dropdown-opcoes {
          background-color: ${(props) => props.theme.colors.branco};
          border-radius: 4px;
          height: 0px;
          position: absolute;
          right: 10px;
          top: 60%;
          overflow: hidden;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding: 0 20px;
          z-index: 1;
          &.active {
            overflow: inherit;
            height: 100px;
            transition: all 0.5s;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.Cinza01};
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              margin-right: 13px;
              width: 20px;
            }
          }
        }
      }
    }

    .td {
      .visualizado,
      span {
        display: flex;
        justify-content: center;
        img {
          margin-right: 13px;
        }
      }
      .editaCampo {
        display: flex;
        align-items: center;
        grid-gap: 5px;
        input {
          /* border: 1px solid; */
          width: 100%;
          background: none !important;
          border-radius: none;
          height: auto !important;
          padding-left: 0;
          padding-right: 0;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0;
          color: #333333;
          &:focus {
            border: 1px solid black;
          }
        }
        img {
          cursor: pointer;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        "" /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;
      }
    }
  }
  .exportar {
    display: flex;
    grid-gap: 10px;
  }
  .disparoIndividual {
    width: 74px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-right: 10px;
    position: absolute;
    right: 10px;
    overflow: hidden;
    transition: all 0.5s;
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
      background-color: #e5e5e5;
      color: #636363;
      border: none;
      border: solid 1px transparent;
      transition: all 0.5s;
    }
  }
`;

export const Paginacao = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
`;
