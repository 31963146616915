import React from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button";
import Topo from "../../components/topo/index";
import { Whats } from "../../components/preview";

import { Resumo } from "./styles";

import logo from "../../assets/img/sistema/logo-menu.svg";
import imgWhats from "../../assets/img/sistema/whats-img.png";

function ResumoAcao() {
    const { name } = useSelector((state) => state.user);
    return (
        <Resumo>
            <Topo />
            <div className="container">
                <img src={logo} alt="" />
                <div className="detalhes">
                    <h3>Resumo da Ação</h3>
                    <div className="item-detalhe">
                        <span>Contratante</span>
                        <p>{name}</p>
                    </div>
                    <div className="item-detalhe">
                        <span>Nome da Ação</span>
                        <p>Banco Itaú Papa Tudo</p>
                    </div>
                    <div className="item-detalhe">
                        <span>Tipo da Ação</span>
                        <p>Pontuação acumulativa</p>
                    </div>
                    <div className="item-detalhe">
                        <span>Template</span>
                        <p>Pontuação acumulativa</p>
                    </div>
                    <div className="item-detalhe">
                        <span>Tipo de Disparo</span>
                        <p>Agendado</p>
                    </div>
                    <div className="item-detalhe">
                        <span>Data e hora do disparo</span>
                        <p>22.02.2021 - 16:30</p>
                    </div>
                    <Button title="Avançar" type="button" />
                </div>
                <div className="preview">
                    <div className="container-text">
                        <h3>Preview</h3>
                    </div>
                    <Whats img={imgWhats} />
                </div>
            </div>
        </Resumo>
    );
}

export default ResumoAcao;
