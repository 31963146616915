import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e2e3;
  height: 70px;
  span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza04};
    position: relative;
    height: 48px;
    pointer-events: none;
    &.efetivar {
      pointer-events: none;
    }
    &.active {
      color: ${(props) => props.theme.colors.Vinho1};
      font-weight: 900;
      &::before {
        background-color: ${(props) => props.theme.colors.Laranja1};
      }
    }
  }
`;
