import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { api } from "../../../../services/api";

const Context = createContext({});

export function NovoClienteProvider(props) {
  const { children } = props;
  const history = useHistory();
  const historyId = history.location.state.id;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [areas, setAreas] = useState([]);

  const [initialValues, setInitialValues] = useState({});
  const [contatos, setContatos] = useState([]);
  const [contato, setContato] = useState({});
  const [contatoId, setContatoId] = useState(null);
  const [cliemtesEnUtilizacao, setCliemtesEnUtilizacao] = useState([]);

  async function update(values) {
    try {
      await api.put(`cliente/${historyId}`, { ...values });
      history.push("/clientes");
      toast.success("Cliente atualizado com sucesso!");
    } catch (error) {
      history.push("/clientes");
      toast.error("Erro ao atualizar cliente!");

      return error;
    }
  }

  async function storeArea(values) {
    setLoading(true);
    try {
      await api.post(`cliente/${historyId}/area`, { ...values });
      await getAreas();
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  async function updateArea(id) {
    setLoading(true);
    try {
      const area = areas.find((area) => area.id === id);

      await api.put(`cliente/${historyId}/area/${id}`, {
        nome: area.label,
      });
      await getAreas();
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  async function deleteArea(id) {
    setLoading(true);
    try {
      await api.delete(`cliente/${historyId}/area/${id}`);
      await getAreas();
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  async function storeContato(values) {
    const { id, ...rest } = values;
    setLoading(true);

    try {
      await api.post(`cliente/${historyId}/area/${id}/contato`, {
        ...rest,
      });
      await getAreas();
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  async function updateContato(values) {
    const { id, ...rest } = values;
    setLoading(true);

    try {
      if (contato.id) {
        await api.put(`cliente/${historyId}/area/${id}/contato/${contato.id}`, {
          ...rest,
        });
        await getAreas();
        setContato({});
      }
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  async function deleteContato() {
    setLoading(true);
    try {
      if (contato.id && contato.area.id) {
        await api.delete(
          `cliente/${historyId}/area/${contato.area.id}/contato/${contato.id}`
        );
        await getAreas();
        setContato({});
      }
    } catch (error) {
      return error;
    }
    setLoading(false);
  }

  const getAreas = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api.get(`/cliente/${historyId}`);

      const values = {
        nome: data.nome,
        razao_social: data.razao_social,
        cnpj: data.cnpj,
      };

      const areas = [];
      const contatos = [];

      data.areas.forEach((item) => {
        const area = { value: uuidv4(), label: item.nome, id: item.id };
        areas.push(area);

        item.contatos.forEach((item) => {
          const contato = {
            area: area,
            celular: item.celular,
            email: item.email,
            id: item.id,
            nome: item.nome,
            telefone: item.telefone,
          };
          contatos.push(contato);
        });
      });

      setInitialValues({ ...values });
      setAreas(areas);
      setContatos(contatos);
    } catch (error) {
      return error;
    }
    setLoading(false);
  }, [historyId]);

  useEffect(() => {
    getAreas();
  }, [getAreas]);

  return (
    <Context.Provider
      value={{
        loading,

        update,

        modal,
        setModal,

        initialValues,

        setContatos,
        contatos,

        storeContato,
        updateContato,
        contato,
        setContato,

        setContatoId,
        contatoId,
        deleteContato,

        storeArea,
        updateArea,
        deleteArea,
        areas,
        setAreas,

        setModalAviso,
        modalAviso,

        cliemtesEnUtilizacao,
        setCliemtesEnUtilizacao,

        modalAlert,
        setModalAlert,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useContex() {
  const context = useContext(Context);
  return context;
}
