import React, { useState } from "react";
import { ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Creators as AcaoCreators } from "../../store/ducks/acao";
import { nomeArq } from "../../utils/helper";

import upload from "../../assets/img/sistema/upload.svg";
import fechar from "../../assets/img/sistema/fechar-modal.svg";

function InputFile(props) {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.acao);
  const { nome_img, arte_hash } = data;

  const { field, form, removeFile } = props;
  const [arquivo, setArquivo] = useState(nome_img ? nome_img : arte_hash);

  function handleFile(arq) {
    if (arq) {
      if (arq.size < 1000000) {
        const file = arq;
        const reader = new FileReader();

        reader.onload = function (event) {
          form.setFieldValue(field.name, "");
          form.setFieldValue(field.name, event.target.result);
        };

        reader.readAsDataURL(file);

        dispatch(AcaoCreators.createObject("nome_img", file.name));
        dispatch(AcaoCreators.createObject("preview_img", file));

        setArquivo(arq.name);
      } else {
        toast.error("Imagem maior que 1MB");
      }
    }
  }

  return (
    <div className="container-form">
      <label
        htmlFor="file"
        className={arquivo ? "custom-file nome-arquivo" : "custom-file"}
      >
        <input
          type="file"
          id="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={(event) => {
            handleFile(event.target.files[0]);
          }}
        />

        {arquivo ? nomeArq(arquivo, 26) : "Upload da arte"}
      </label>

      <ErrorMessage component="span" name="template_arte" />

      {arquivo ? (
        <img
          src={fechar}
          onClick={() => {
            removeFile();
            setArquivo("");
          }}
          alt=""
        />
      ) : (
        <img src={upload} alt="" />
      )}
    </div>
  );
}

export default InputFile;
