import React from "react";
import Lottie from "react-lottie";
import { loadingAnimation } from "../../styles/plugins/loading";

import { Loader } from "./styles";

export default function acoes(props) {
    const { item = 0, height = "auto", width = "auto" } = props;
    const widthWindow = window.screen.width;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation[item],
        rendererSettings: {},
    };

    return (
        <Loader>
            <Lottie
                options={defaultOptions}
                background="transparent"
                speed={1}
                height={widthWindow <= 480 ? 300 : height}
                width={widthWindow <= 480 ? 300 : width}
                loop
                controls
                autoplay
            ></Lottie>
        </Loader>
    );
}
