import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { api } from "../../services/api";

import Button from "../button";

import { EditaAcao } from "./styles";

import close from "../../assets/img/sistema/fechar-modal.svg";

const ModalEditaAcao = (props) => {
  const { dados } = props;

  const validationSchema = Yup.object().shape({
    titulo: Yup.string(),
    job: Yup.string(),
  });

  async function update(values) {
    let url = `acao/${dados.id}`;
    if (dados.tipo === "processamento") {
      url = `acao/${dados.id}/multi`;
    } else if (dados.tipo === "pedido") {
      url = `pedido/${dados.id}`;
    }

    try {
      await api.post(url, {
        job: values.job,
        nome: values.titulo,
      });
      toast.success("Editado com sucesso!");
      props.close();
      props.reload();
    } catch (error) {
      toast.error("Algo deu errado");
    }
  }

  return (
    <EditaAcao>
      <h3>Editar Ação</h3>
      <img className="close" src={close} alt="" onClick={() => props.close()} />
      <Formik
        enableReinitialize
        initialValues={dados}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          await update(values);
        }}
      >
        <Form>
          <Field type="text" placeholder="Titulo" name="titulo" />

          <Field type="text" placeholder="Job" name="job" />

          <Button title="Editar" />
        </Form>
      </Formik>
    </EditaAcao>
  );
};

export default ModalEditaAcao;
