import React, { memo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Creators as EsqueciSenhaCreators } from "../../store/ducks/esqueciSenha";

import PasswordStrengthBar from "react-password-strength-bar";

import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    FormInitialState,
    validadtionRecuperarSenha,
} from "../../utils/configForm";

import Button from "../button";
import inputPassword from "../inputPassword";

import logo from "../../assets/img/sistema/logo-login.svg";

import { StyleAlterarSenha } from "./styles";

function AlterarSenha() {
    const metricas = ["Fraca", "Fraca", "Certo", "Bom", "Forte"];
    const dispatch = useDispatch();
    const {
        successConfirmaCode,
        loadingConfirmaCode,
        dados: { email },
    } = useSelector((state) => state.esqueciSenha);

    return (
        <StyleAlterarSenha className={`${successConfirmaCode ? "active" : ""}`}>
            <div className="content">
                <img src={logo} alt="" className="logo" />

                <h3>RECUPERAÇÃO DE SENHA</h3>

                <small>
                    Preencha os campos abaixo com a sua nova senha e clique em
                    alterar senha para confirmar.
                </small>

                <Formik
                    enableReinitialize
                    initialValues={FormInitialState.recuperarSenha}
                    validationSchema={validadtionRecuperarSenha}
                    onSubmit={(value, actions) => {
                        dispatch(
                            EsqueciSenhaCreators.requestResetaSenha({
                                ...value,
                                email,
                            })
                        );
                    }}
                >
                    {(props) => {
                        const { values, handleChange, handleSubmit } = props;
                        const { password } = values;
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.stopPropagation();
                                    handleSubmit(e);
                                }}
                            >
                                <Field
                                    component={inputPassword}
                                    name="password"
                                    placeholder="DIGITE A NOVA SENHA"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="password"
                                />

                                <Field
                                    component={inputPassword}
                                    name="password_confirmation"
                                    placeholder="CONFIRME A NOVA SENHA"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="password_confirmation"
                                />

                                <div className="forca-senha">
                                    <PasswordStrengthBar
                                        onChange={handleChange}
                                        password={password}
                                        scoreWords={metricas}
                                        shortScoreWord="Muito curta"
                                        type="password"
                                        placeholder="Senha"
                                        name="password"
                                        autoComplete="new-password"
                                    />
                                </div>

                                <small className="dica-senha">
                                    Sua senha deve possuir{" "}
                                    <strong>8 caracteres</strong> e pode conter
                                    somente
                                    <strong> letras</strong> e{" "}
                                    <strong>números</strong>. Não é obrigatório,
                                    mas a senha considera o uso de letras
                                    maiúsculas e minúsculas.
                                </small>

                                <Button
                                    type="submit"
                                    title="ALTERAR SENHA"
                                    loading={loadingConfirmaCode}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </StyleAlterarSenha>
    );
}

export default memo(AlterarSenha);
