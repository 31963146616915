import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Container as Button } from "../button/styles";
import erro from "../../assets/img/sistema/erro-cinza.svg";

export const Voltar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 40px;
  background-color: ${(props) => (props.bgc ? props.bgc : "#0191CD")};
  color: ${(props) => (props.bgc ? props.bgc : props.theme.colors.branco)};
  font-weight: bold;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 100%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const DadosTemplate = styled.div`
  max-width: 383px;
  margin-top: 30px;
  order: 2;
  &.hidden {
    display: none;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 30px;
    a {
      color: ${(props) => props.theme.colors.Vinho1};
    }
  }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  ul {
    margin-top: 15px;
    margin-bottom: 15px;
    li {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: ${(props) => props.theme.colors.Cinza02};
      list-style: disc;
      margin-left: 20px;
    }
  }
`;

export const ResumoUpload = styled.div`
  background-color: rgba(212, 212, 212, 0.2);
  border-radius: 4px;
  max-width: 383px;
  padding: 20px;
  margin-top: 30px;
  order: 2;
  margin-bottom: 15px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(434px);
  width: 100%;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 20px;
  }
  .erro {
    position: relative;
    padding-left: 32px;
    &.success {
      span {
        color: #1cde90;
      }
      &:before {
        background-color: #1cde90;
      }
    }

    &.error {
      span {
        color: #eb5757;
      }
      &:before {
        background-color: #eb5757;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      -webkit-mask: url(${erro});
      background-color: #848484;
      z-index: 20;
    }
  }
  .usuarios,
  .link,
  .erro {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
  .detalhes-arquivo {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.Cinza03};
    display: flex;
    img {
      margin-left: 5px;
      transition: all 0.5s;
      filter: grayscale(1);
      opacity: 0.4;
    }
    &:hover {
      color: ${(props) => props.theme.colors.Vinho1};
      img {
        margin-left: 20px;
        transition: all 0.5s;
        filter: none;
        opacity: 1;
      }
    }
  }
`;

export const ErroUpload = styled.div`
  background-color: rgba(212, 212, 212, 0.2);
  border-radius: 4px;
  max-width: 383px;
  padding: 20px;
  margin-top: 30px;
  p {
    margin-bottom: 16px;
  }
  ul {
    margin-bottom: 32px;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 20px;
  }
  .erro {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
  a,
  button {
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.Cinza03};
    display: flex;
    &:hover {
      color: ${(props) => props.theme.colors.Vinho1};
    }
  }
`;

export const ToggleStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 28px;
  width: 383px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #9d9d9d;
  }
`;

export const VoucherStyles = styled.div`
  .container-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    ${Button} {
      margin: 0px !important;
    }
  }
  button {
    &.add-voucher {
      width: 255.29px;
      height: 55px;
      background: transparent;
      border: 1px solid #0191cd;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 18.6004px;
      line-height: 23px;
      color: #0191cd;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      img {
        display: block;
        margin-right: 16px;
      }
    }
  }
`;

export const HoverVoucher = css`
  &:hover {
    border: 1px solid #1cde90;
    transition: all 0.5s;
  }
`;
export const activeVoucher = css`
  border: 1px solid #1cde90;
  transition: all 0.5s;
`;

export const CardVoucherStyles = styled.div`
  border: 1px solid #dfdfdf;
  padding: 0px 48px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.check ? "70px 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  align-items: center;
  justify-content: space-between;
  height: 109px;
  width: 100%;
  border-radius: 8px;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  ${(props) => (props.hover ? HoverVoucher : null)}
  ${(props) => (props.active ? activeVoucher : null)}
    transition: all 0.5s;
  margin-bottom: 16px;
  .check {
    margin-right: 24px;
  }

  button {
    background: transparent;
    margin: 0px !important;
    img {
      display: block;
      margin-left: auto;
    }
  }
  .container-img {
    margin-right: 32px;
    img {
      width: 73px;
      height: 46px;
    }
  }

  .container-text {
    margin-right: 32px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      color: #000000;
      margin-bottom: 8px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #575757;
    }
  }

  .container-estoque {
    margin-right: ${(props) => (props.check ? "32px " : "0px")};
    span {
      display: block;
      width: ${(props) => (props.check ? "auto" : "204px")};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #9d9d9d;
    }
  }
`;

export const ModalVoucherStyles = styled.div`
  background-color: #fff;
  padding: 48px;
  width: 736px;
  border-radius: 16px;

  .container-filtro {
    margin-bottom: 24px;
    input {
      background-color: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      border: 1px solid #6f3e98;
      width: 100%;
    }
  }

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: #000000;
    }
    button {
      background: transparent;
      margin-right: 0px !important;
    }
  }

  .container-cards {
    margin-bottom: 32px;
    height: 359px;
    overflow: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

export const DadosAcao = styled.div`
  /* display: flex; */
  .container-resumo {
    .titulo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 50px 0;
      h2,
      button {
        margin: 0;
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      border-bottom: 1px dashed #d4d4d4;
      padding-bottom: 32px;
      margin-top: 32px;
      margin-bottom: 32px;
      &:last-child {
        border: none;
      }
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 38px;
      color: #000000;
      margin-bottom: 26px;
      font-weight: bold;
      border-top: 1px solid #d4d4d4;
      padding-top: 26px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 100%;
      color: #000000;
      margin-bottom: 35px;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: ${(props) => props.theme.colors.Cinza07};
      position: relative;
      height: 48px;
      cursor: pointer;
      padding-left: 20px;
      /* margin-bottom: 32px; */
      /* width: 180px; */
      &:hover {
        p {
          &.toolTip {
            opacity: 1;
            transition: all 0.5s;
          }
        }
      }
      p {
        &.toolTip {
          border-radius: 5px;
          padding: 25px;
          width: auto;
          background-color: rgba(0, 0, 0, 0.5);
          opacity: 0;
          position: absolute;
          bottom: 60px;
          left: 15%;
          transform: translateX(-50%);
          transition: all 0.5s;
          color: #fff;
          font-size: 12px;
          pointer-events: none;
          white-space: pre;
        }
      }
      small {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 100%;
        color: #333333;
        display: block;
        margin-top: 8px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .container-preview {
    /* margin-left: 219px; */
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 38px;
      color: #000000;
      margin-bottom: 36px;
      font-weight: bold;
      border-top: 1px solid #d4d4d4;
      padding-top: 26px;
    }
    .content-preview {
      margin-bottom: 36px;
    }

    .btns {
      width: 383px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      /* background-image: linear-gradient(
        to right,
        black 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 23px 1px;
      background-repeat: repeat-x; */
      padding-top: 16px;
      margin-top: 54px;
      button {
        margin: 0 !important;
      }
    }
    .resumo {
      ${ResumoUpload} {
        position: static;
        transform: none;
        margin-bottom: 36px;
      }
    }
  }
`;
