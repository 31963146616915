import { useCallback, useState } from "react";

import { getDetalhe } from "../../utils/helper";

import Page from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import { translateHeaderTable } from "./config";
import { Toolbar } from "./toolbar";
import {
  AntDesignStyledDataGridPro,
  StyledBox,
  PaginationStyles,
} from "./styles";

export default function TablePro(props) {
  const { columns, rows, onRowClick, id } = props;
  const [rowsFiltrado, setRowsFiltrado] = useState();
  const apiRef = useGridApiRef();
  const { loading } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    editable: false,
  });

  async function getAcao(params) {
    const { result } = await getDetalhe(id, null, params);
    if (result.length) {
      setRowsFiltrado(result);
    }
  }

  const onFilterChange = useCallback((filterModel) => {
    let params = `?${filterModel.items[0].columnField}=${filterModel.items[0].value}`;
    if (filterModel.items[0].columnField === "usuario") {
      params = `?nome=${filterModel.items[0].value}`;
    } else if (filterModel.items[0].columnField === "telefone") {
      params = `?celular=${filterModel.items[0].value}`;
    } else {
      params = `?${filterModel.items[0].columnField}=${filterModel.items[0].value}`;
    }
    getAcao(params);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledBox>
      <AntDesignStyledDataGridPro
        className={onRowClick ? "clickable" : ""}
        columns={columns}
        rows={rowsFiltrado ?? rows}
        components={{
          Toolbar: Toolbar,
        }}
        getRowClassName={(params) => {
          return params.row.tipo || "";
        }}
        localeText={{
          ...translateHeaderTable,
        }}
        loading={loading}
        autoHeight={true}
        disableSelectionOnClick
        rowThreshold={0}
        apiRef={apiRef}
        onStateChange={(value) => {
          // console.log(value.rowsMeta.positions.length);
        }}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onRowClick={(values, event) => {
          const hasButton = event.target.tagName.includes("BUTTON");

          if (onRowClick) {
            if (!hasButton) {
              onRowClick(values);
            }
          }
        }}
      />
    </StyledBox>
  );
}

export function Pagination(pros) {
  const { total, currentPage, page } = pros;

  return (
    <PaginationStyles>
      <Stack spacing={2}>
        <Page
          count={total}
          onChange={(event, page) => currentPage(page)}
          page={page}
        />
      </Stack>
    </PaginationStyles>
  );
}
