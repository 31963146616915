import { put, call, takeLatest, select } from 'redux-saga/effects';
import { api } from '../../services/api';

// Suponha que o token está armazenado no estado login.data.token
const getToken = (state) => state.login.data.token;

export const Types = {
    REQUEST_2FA: 'twoFactorAuth/REQUEST_2FA',
    REQUEST_2FA_SUCCESS: 'twoFactorAuth/REQUEST_2FA_SUCCESS',
    REQUEST_2FA_FAILURE: 'twoFactorAuth/REQUEST_2FA_FAILURE',
};

const initialState = {
    loading: false,
    error: null,
    data: null,
    success: false,
};

export default function reducer(state = initialState, action) {
    console.log("action.type::: ", action.type);
    switch (action.type) {
      case Types.REQUEST_2FA:
        return { ...state, loading: true, error: null, success: false };
      case Types.REQUEST_2FA_SUCCESS:
        return { ...state, loading: false, data: action.payload, success: true };
      case Types.REQUEST_2FA_FAILURE:
        return { ...state, loading: false, error: action.payload, success: false };
      default:
        return state;
    }
}

export const request2FA = (code) => ({
    type: Types.REQUEST_2FA,
    payload: code,
});

function* request2FASaga(action) {
    console.log("request2FASaga >>>>>");
    try {
      const token = yield select(getToken);
      const response = yield call(api.post, '/google2fa/validate', { code: action.payload }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log("token >>>>>", token);
      console.log("response.data >>>>>", response.data);
      console.log("response.data.success", response.data.success);
      if (response.data.success) {
        yield put({ type: Types.REQUEST_2FA_SUCCESS, payload: response.data });
      } else {
        yield put({ type: Types.REQUEST_2FA_FAILURE, payload: 'Código inválido' });
      }
    } catch (error) {
      console.log("Error in request2FASaga:", error);
      yield put({ type: Types.REQUEST_2FA_FAILURE, payload: error.message });
    }
}

export function* watchRequest2FA() {
    console.log("watchRequest2FA");
    yield takeLatest(Types.REQUEST_2FA, request2FASaga);
}
