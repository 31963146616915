import { CardStyles } from "./styles";

export function Card(props) {
  const { title, img } = props;

  return (
    <CardStyles>
      <div className="container-img">
        <img src={img} alt="" />
      </div>
      <p>{title} </p>
    </CardStyles>
  );
}
