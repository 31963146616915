import styled from "styled-components";

export const Container = styled.div`
    padding: 46px 34px 33px 34px;
    background: #fff;
    width: 375px;
    .input-validacao {
        width: 100%;
        margin-bottom: 8px;
    }
    .input-text {
        &.error {
            .MuiOutlinedInput-notchedOutline {
                border-color: red !important;
            }

            .MuiOutlinedInput-input {
                color: red !important;
            }

            .MuiInputLabel-root {
                color: red !important;
            }
        }

        .MuiInputLabel-root {
            /* color: ${({ theme }) => theme.colors.gray300} !important; */
        }

        &.MuiTextField-root {
            input[type="text"] {
                height: auto !important;
                width: 100% !important;
                background: transparent !important;
            }
            input[type="text"]:focus {
                border: 1px solid transparent !important;
            }
            &::after {
                /* border-bottom: 1px solid ${({ theme }) =>
                    theme.colors.primary} !important; */
            }
            &:before {
                /* border-bottom: 1px solid ${({ theme }) =>
                    theme.colors.gray300} !important; */
            }
            .MuiInput-input {
                /* color: ${({ theme }) => theme.colors.primary}; */
                font-weight: bold;
            }

            .MuiInput-colorSecondary {
                color: ${({ theme }) => theme.colors.primary} !important;
            }
        }
    }
    .btn {
        width: 100%;
        &.enviar {
            background: transparent;
            color: ${({ theme }) => theme.colors.primary};
            img {
                position: relative;
                top: 4px;
                left: 4px;
            }
        }
    }
    .timer {
        width: 100%;
        display: flex;
        align-items: center;
        p {
            color: #000 !important;
            margin: 0px !important;
        }
    }
    .close {
        position: absolute;
        top: 14px;
        right: 13px;
    }
    .text-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .text-validadacao {
            margin-bottom: 18px;
        }
        p {
            color: #807d7d;
            margin-top: 22px;
            margin-bottom: 24px;
        }

        span {
            text-align: left;
            display: block;
            width: 100%;
            color: red;
        }
    }

    .container-btn {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 24px;
        &.reenviar {
            margin: 0px;
            button {
                background: transparent;
                color: #0096d4;
                height: 19px;
            }
        }
        button {
            background: #0096d4;
            border-radius: 4.55635px;

            width: 100%;
            height: 48px;

            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #ffffff;

            &[disabled] {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
`;
